import React, { Fragment } from 'react';
import { Typography, Box } from '@material-ui/core';
import Content from './Content';
import SEOStyling from './SEOStyling';
const useStyles = SEOStyling;

const REASON_WIDTH = '9rem';

export default function Reasons({ currCity }) {
  const classes = useStyles();

  return (
    <Fragment>
      {currCity === 'Mississauga' && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            WHY SHOULD YOU CHOOSE WEED DELIVERY IN MISSISSAUGA ONTARIO?
          </Typography>
          <Typography className={classes.seoText}>
            Ordering marijuana online in Mississauga is easy, fast, and discreet. With so many different options to
            choose from, you never have to leave your house to find the weed products you love. Find ratings and reviews
            from the local community about new Mississauga dispensaries and find your next favourite weed store today!
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 1
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Mississauga Weed Deals:</span> <br /> <br /> We’re always on the
            lookout for the very best weed deals. That’s why our listed weed dispensaries in Mississauga are always
            updating their cannabis products and deals. Check back often and get the latest deals.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 2
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Discreet Packaging & Delivery:</span> <br /> <br /> When you receive
            your weed delivery in Mississauga by mail, you can expect discreet, plain packaging. No need to worry about
            a nosey neighbour. Find a local weed dispensary near you and place an order!
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 3
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Fast, Same-Day Delivery:</span> <br /> <br /> Select from local
            Mississauga weed stores and choose one with same-day delivery options. Many dispensaries in Mississauga
            offer same-day delivery for orders. Choose your favourite product and get super-fast delivery currCity-wide.
          </Box>
        </Fragment>
      )}
      {(currCity === 'Toronto' || currCity === 'Toronto West') && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            WHY SHOULD YOU CHOOSE WEED DELIVERY IN {currCity.toUpperCase()} {currCity === 'Toronto' && 'ONTARIO'}?
          </Typography>
          <Typography className={classes.seoText}>
            Being able to order marijuana online for delivery is fast, convenient, and discreet. With so many different
            options to choose from, you never have to leave your house to find the weed products you love. Find reviews
            from the community about which {currCity} dispensary you should try next.
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 1
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Amazing Prices on Weed in {currCity}:</span> <br /> <br /> We’re
            always on the lookout for the very best weed deals. That’s why our listed weed dispensaries in {currCity}{' '}
            are always updating their cannabis products and deals. Check back often and get the latest deals.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 2
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Discreet & Protective Packaging:</span> <br /> <br /> When you receive
            your weed delivery in the mail, you can expect discreet, plain packaging. No need to worry about a nosey
            neighbour. Find a weed dispensary and place an order today!
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 3
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Same-Day Delivery:</span> <br /> <br /> Select from local {currCity}{' '}
            weed stores and choose one with same-day delivery options. Many dispensaries in {currCity} offer same-day
            delivery for orders. Choose your favourite product and get lightning-fast delivery.
          </Box>
        </Fragment>
      )}

      {currCity === 'Etobicoke' && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            WHY ORDER WEED DELIVERY IN ETOBICOKE ONTARIO?
          </Typography>
          <Typography className={classes.seoText}>
            Ordering weed online in Etobicoke is easy, fast, and discreet. At your fingertips is a huge variety of
            brands and products. And the best part? You never have to leave your house to find the weed products you
            love! Look at ratings and reviews from the local community about new Etobicoke dispensaries and find your
            next favourite weed store today!
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 1
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Etobicoke Weed Deals:</span> <br /> <br /> We’re always on the lookout
            for the very best weed deals. That’s why the listed weed dispensaries in Etobicoke are always updating their
            cannabis products and deals. Check back often and get the latest deals on weed!
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 2
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Discreet Packaging For Your Weed Order:</span> <br /> <br /> When you
            receive your weed delivery by mail, you can expect discreet, plain packaging. No need to worry about a nosey
            neighbour. Find a local weed dispensary in Etobicoke and place an order!
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 3
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Fast, Same-Day Delivery:</span> <br /> <br /> Select from local
            Etobicoke weed stores and choose one with same-day delivery options. Many dispensaries in Etobicoke offer
            same-day delivery for orders. Choose your favourite product and get super-fast delivery currCity-wide.
          </Box>
        </Fragment>
      )}

      {(currCity === 'Oakville' || currCity === 'Toronto East') && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            WHY SHOULD YOU CHOOSE WEED DELIVERY IN {currCity.toUpperCase()} ONTARIO?
          </Typography>
          <Typography className={classes.seoText}>
            Ordering marijuana online in {currCity} is fast, convenient, and discreet. With so many different options to
            choose from, you never have to leave your house to find the weed products you love. Find ratings and reviews
            from the community about new {currCity} dispensaries and find your next favourite weed store today!
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 1
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>The Best Deals on Weed in {currCity}:</span> <br /> <br /> We’re
            always on the lookout for the very best weed deals. That’s why our listed weed dispensaries in {currCity}{' '}
            are always updating their cannabis products and deals. Check back often and get the latest deals.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 2
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Discreet Packaging & Delivery:</span> <br /> <br /> When you receive
            your weed delivery in {currCity} by mail, you can expect discreet, plain packaging. No need to worry about a
            nosey neighbour. Find a weed dispensary near you today and place an order!
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            Reason # 3
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            <span className={classes.reasonTitle}>Same-Day Delivery:</span> <br /> <br /> Select from local {currCity}{' '}
            weed stores and choose one with same-day delivery options. Many dispensaries in {currCity} offer same-day
            delivery for orders. Choose your favourite product and get super-fast delivery currCity-wide.
          </Box>
        </Fragment>
      )}

      {currCity === 'Calgary' && Content.Calgary.middle(classes)}
      {currCity === 'Edmonton' && Content.Edmonton.middle(classes)}
      {currCity === 'Montreal' && Content.Montreal.middle(classes)}
      {currCity === 'Brampton' && Content.Brampton.middle(classes)}
      {currCity === 'Guelph' && Content.Guelph.middle(classes)}
      {currCity === 'Hamilton' && Content.Hamilton.middle(classes)}
      {currCity === 'Markham' && Content.Markham.middle(classes)}
      {currCity === 'Ottawa' && Content.Ottawa.middle(classes)}
      {currCity === 'Vancouver' && Content.Vancouver.middle(classes)}
      {currCity === 'Richmond' && Content.Richmond.middle(classes)}
      {currCity === 'Surrey' && Content.Surrey.middle(classes)}
      {currCity === 'Coquitlam' && Content.Coquitlam.middle(classes)}
      {currCity === 'Burnaby' && Content.Burnaby.middle(classes)}
      {currCity === 'Windsor' && Content.Windsor.middle(classes)}
    </Fragment>
  );
}
