import React, { useState, useEffect, Fragment } from 'react';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import 'mapbox-gl/dist/mapbox-gl.css';
import api from '../utils/api';
import { useStateValue } from '../state';
import { STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapsStoreList from '../components/MapsStoreList';
import MapsStoreProfile from '../components/MapsStoreProfile';
import axios from 'axios';
import { openStatus } from '../utils/helpers';
import AdminMapPanel from '../components/Drawer';
import MapFilter from '../components/MapFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleBar: {
    margin: 'auto',
    maxWidth: '1400px',
    // marginBottom: '25px !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // border: '2px dashed red',
  },
  mapTitle: {
    flexGrow: 2,
    textAlign: 'left',
    color: 'rgb(74, 74, 74)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
      display: 'none',
      //marginLeft: "50%",
    },
    marginTop: '30px',
    // marginBottom: '30px',
    fontSize: 'clamp(1.5rem, 2.6vw, 2rem)',
  },

  storeWindow: {
    position: 'absolute',
    left: '2%',
    top: '5%',
    width: '20%',
    height: '80%',
    backgroundColor: 'white',
    border: '2px solid #E3E3E3',
    borderRadius: '1rem',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      left: 'initial',
      top: 'initial',
      flexDirection: 'column',
      width: '100%',
      height: '40vh',
      borderRadius: 0,
      // backgroundColor: 'white',
      // border: '2px solid #E3E3E3',
      overflowY: 'scroll',
    },

    // [theme.breakpoints.down('sm')]: {
    //   position: 'relative',
    //   display: 'flex',
    //   left: 'initial',
    //   top: 'initial',
    //   flexDirection: 'column',
    //   width: '100%',
    //   height: '33%',
    //   borderRadius: 0,
    //   // backgroundColor: 'white',
    //   // border: '2px solid #E3E3E3',
    //   overflowY: 'scroll',
    // },
  },
}));

const getRanks = async (id) => {
  return api
    .getLandingProjection(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};
//mapbox
const accessToken = 'pk.eyJ1IjoiYW5kcmV3LWdpZ2VjbyIsImEiOiJja2h3Z3htcmwwYWJ5MnptczIwaDZldWc2In0.iJ_74UVlD-CFEcRaLrVhAQ';
// const accessToken = process.env.MAP_BOX_TOKEN; //[] Netlify will need to have this env var in place

const getStores = async (type, id) => {
  return api
    .getStores(type, id, true, false)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getGeocode = async (address) => {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address.city}${address.province}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    )
    .then((res) => res.data.results[0]?.geometry.location)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

let currentMarker = {};

export default function MapsPageView(props) {
  const { admin } = props;
  const [allStores, setAllStores] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCoordinates, setLoadingCoordinates] = useState(true);
  const [markerSelect, setMarkerSelect] = useState(false);
  const [previousMarker, setPreviousMarker] = useState(false);
  const [clickedStoreData, setClickedStoreData] = useState({});
  const [storeFronts, setStoreFronts] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [mailouts, setMailouts] = useState([]);

  const [featuredStores, setFeaturedStores] = useState([]);
  const [featuredDeliveries, setFeaturedDeliveries] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [loadingDeliveries, setLoadingDeliveries] = useState(true);

  const [value, setValue] = useState(0);

  const [displayFilter, setDisplayFilter] = useState({
    settings: {
      stores: false,
      delivery: false,
      open: false,
      mailout: false,
      clinic: false,
    },
    message: 'All Locations in Area',
    showFilter: false,
  });

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const mobile = useMediaQuery('(max-width:1024px)');
  const [{ location }] = useStateValue();
  const [viewport, setViewport] = useState({
    //marginTop: "50%",
    zoom: 3,
    // pitch: 50,
  });

  const gobackReset = () => {
    setClickedStoreData([]);
    setMarkerSelect(false);
  };
  useEffect(() => {
    getStores(false, location.link)
      .then((res) => {
        //heres were we need to do some calcs. we need to get the coordinates of the city that matches the location.link
        // this routine finds the pinaddress that matches the selected city, and returns the store with lat and long cordinates of the matching city
        // as [{...store, latitude: matching city latitude, longitude: matching city longitude}]
        setAllStores(res);
        let val = res
          .filter((store) => store.pinAddresses.find((entry) => entry.address.city === location.city && entry.latitude))
          .map((store) => {
            return {
              ...store,
              latitude: store.pinAddresses.find((entry) => entry.address.city === location.city).latitude,
              longitude: store.pinAddresses.find((entry) => entry.address.city === location.city).longitude,
            };
          });
        let storeFronts = val.filter((store) => store.storeType === 'Storefront');
        let deliverys = val.filter((store) => store.storeType === 'Delivery');
        let clinics = val.filter((store) => store.storeType === 'Clinic');

        let mailOuts = val.filter((store) => store.nationwide);
        setCoordinates(val);
        setStoreFronts(storeFronts);
        setDeliveries(deliverys);
        setClinics(clinics);
        setMailouts(mailOuts);

        getRanks(parseIdFromLink(location.link))
          .then((res) => {
            const featuredStores = res.featuredStoreRanks
              ? res.featuredStoreRanks
                  .sort((store1, store2) => store1.rank - store2.rank)
                  .map((store) => {
                    return { ...store.store, slug: store.storeSlug, rank: store.rank };
                  })
              : [];
            setFeaturedStores(featuredStores);
            setLoadingStores(false);

            const featuredDeliveries = res.featuredDeliveryRanks
              ? res.featuredDeliveryRanks
                  .sort((store1, store2) => store1.rank - store2.rank)
                  .filter(
                    (store) =>
                      store.delivery.storeType === 'Delivery' &&
                      ((store.nationwide === true && store.localDelivery === true) ||
                        (store.nationwide === false && store.localDelivery === true) ||
                        (store.nationwide === null && store.localDelivery === true))
                  )
                  .map((store) => {
                    return { ...store.delivery, slug: store.storeSlug, rank: store.rank };
                  })
              : [];

            setFeaturedDeliveries(featuredDeliveries);
            setLoadingDeliveries(false);
            //Now we have the stores and deliveries with ranks in the location. we need to sort storeList now by the ones that HAVE a rank first then the rest
          })
          .catch((err) => {
            setLoadingStores(false);
            setLoadingDeliveries(false);
          });

        setLoading(false);
        setClickedStoreData({});
        return val;
      })
      .then((allStoreRes) => {
        getGeocode(location).then((res) => {
          /* []TODO - getting the geoCode for every size is excessive, either these can be constants, or an average of all coords can be used */
          if (res) res.zoom = 10; // Adds a zoom lvl to the geoCode res
          if (!res) {
            // //Get 1st store coords else
            if (allStoreRes.length) {
              res = {
                //Got to first store zoomed out, or Hard Code Ctr TO.
                lat: allStoreRes[0].latitude || 43.423004,
                lng: allStoreRes[0].longitude || -80.160943,
                zoom: 10,
              };
            } else {
              res = {
                //Hard Code Ctr Canada
                lat: 49.296472,
                lng: -90.117444,
                zoom: 3,
              };
            }
          }
          setViewport((previousState) => ({
            ...previousState,
            latitude: res.lat,
            longitude: res.lng,
            transitionDuration: 300,
            zoom: res.zoom,
            transitionInterpolator: new FlyToInterpolator({ curve: 1.5 }),
          }));
          setLoadingCoordinates(false);
        });
      });
  }, [location.link, location]);

  const handleMarkerPosition = (event, store) => {
    if (event.currentTarget.id !== previousMarker) {
      currentMarker[event.currentTarget.id] = true;
      currentMarker[previousMarker] = false;
    }
    setClickedStoreData(store);
    setViewport((previousState) => ({
      ...previousState,
      latitude: mobile ? store.latitude : store.latitude,
      longitude: store.longitude,
      zoom: 11,
      transitionDuration: 300,
      transitionInterpolator: new FlyToInterpolator({ curve: 1.3 }),
    }));
    setMarkerSelect(currentMarker);
    setPreviousMarker(event.currentTarget.id);
  };

  const handleFilterSettings = (input) => {
    const oldSettings = displayFilter.settings;
    const newSettings = { ...oldSettings, [input]: !displayFilter['settings'][input] };
    const filterMessage = (settings) => {
      //Return a string of what is being shown on the map
      if (!settings) {
        return null;
      }
      const showing = [];
      for (const [key, value] of Object.entries(settings)) {
        if (value) {
          key === 'open' ? showing.push('Open Now') : showing.push(key.charAt(0).toUpperCase() + key.slice(1));
        }
      }
      if (showing.length === 0) {
        return 'All Locations in Area';
      }
      return showing.join(' ');
    };

    setDisplayFilter((prev) => {
      return {
        ...prev,
        settings: newSettings,
        message: filterMessage(newSettings),
      };
    });
  };

  const handleFilters = () => {
    if (
      !displayFilter.settings.stores &&
      !displayFilter.settings.delivery &&
      !displayFilter.settings.open &&
      !displayFilter.settings.mailout &&
      !displayFilter.settings.clinic
    ) {
      return coordinates;
    } else {
      let data = [];

      if (
        displayFilter.settings.open &&
        !displayFilter.settings.stores &&
        !displayFilter.settings.delivery &&
        !displayFilter.settings.mailout
      ) {
        return coordinates.filter((store) => openStatus(store.hours));
      }

      if (displayFilter.settings.stores) {
        data = [...data, ...storeFronts];
      }
      if (displayFilter.settings.delivery) {
        data = [...data, ...deliveries];
      }
      if (displayFilter.settings.clinic) {
        data = [...data, ...clinics];
      }
      if (displayFilter.settings.open) {
        data = data.filter((store) => openStatus(store.hours));
      }
      if (displayFilter.settings.mailout) {
        data = [...data, ...mailouts];
      }
      return data;
    }
  };
  const classes = useStyles();
  return (
    <Fragment>
      {(loading || loadingCoordinates || loadingStores || loadingDeliveries) && (
        <CircularProgress size={90} color="inherit" style={{ display: 'flex', marginLeft: '50%', marginTop: '20%' }} />
      )}

      {!mobile && !loading && !loadingCoordinates && !loadingDeliveries && !loadingStores && (
        <div className={classes.titleBar}>
          <Typography variant="h1" className={classes.mapTitle}>
            {`Cannabis Businesses in ${location.city}, ${location.province}`}
          </Typography>
          {/* wee need check box filters for storeFront, delivery, open now, and mailouts */}

          <MapFilter
            handleFilterSettings={handleFilterSettings}
            filterSettings={displayFilter.settings}
            showFilter={displayFilter.showFilter}
            value={value}
            handleTabChange={handleTabChange}
            displayFilterSettings={displayFilter.settings}
            mobile={mobile}
          />
        </div>
      )}

      {!loading && !loadingCoordinates && !loadingDeliveries && !loadingStores && (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <ReactMapGL
            {...viewport}
            width="100vw"
            height={mobile ? '35vh' : '70vh'}
            attributionControl={false}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(viewport) => {
              const { width, height } = viewport;
              setViewport({ ...viewport, width, height });
            }}
            mapboxApiAccessToken={accessToken}
          >
            {handleFilters(coordinates).map((city, index) => (
              <Marker key={index} latitude={city.latitude} longitude={city.longitude} offsetLeft={-20} offsetTop={-10}>
                {city.storeType === 'Delivery' && (
                  <img
                    id={parseIdFromLink(city._links.self.href)}
                    src={
                      markerSelect[parseIdFromLink(city._links.self.href)]
                        ? require('../assets/icons-for-map-feature/Delivery-2.svg')
                        : require('../assets/icons-for-map-feature/Delivery-1.svg')
                    }
                    alt={city.name}
                    style={{
                      zIndex: markerSelect[parseIdFromLink(city._links.self.href)] ? '9999' : '1',
                      height: markerSelect[parseIdFromLink(city._links.self.href)] ? '50px' : '35px',
                      width: markerSelect[parseIdFromLink(city._links.self.href)] ? '40px' : '28px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      handleMarkerPosition(e, city);
                    }}
                  />
                )}
                {/*
my next idea is to put the store filtering code in here. 
Now the idea is that im going to have a matrix value put on top of the index value.
That way we can store the .res in the return matrix. Otherwise it will not fit in the storage requirments.
*/}
                {city.storeType === 'Storefront' && (
                  <img
                    id={parseIdFromLink(city._links.self.href)}
                    src={
                      markerSelect[parseIdFromLink(city._links.self.href)]
                        ? require('../assets/icons-for-map-feature/Storefront-2.svg')
                        : require('../assets/icons-for-map-feature/Storefront-1.svg')
                    }
                    alt={city.name}
                    style={{
                      zIndex: markerSelect[parseIdFromLink(city._links.self.href)] ? '9999' : '1',
                      height: markerSelect[parseIdFromLink(city._links.self.href)] ? '45px' : '35px',
                      width: markerSelect[parseIdFromLink(city._links.self.href)] ? '38px' : '28px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      handleMarkerPosition(e, city);
                    }}
                  />
                )}

                {city.storeType === 'Clinic' && (
                  <img
                    id={parseIdFromLink(city._links.self.href)}
                    src={
                      markerSelect[parseIdFromLink(city._links.self.href)]
                        ? require('../assets/icons-for-map-feature/Clinics-2.svg')
                        : require('../assets/icons-for-map-feature/Clinics-1.svg')
                    }
                    alt={city.name}
                    style={{
                      zIndex: markerSelect[parseIdFromLink(city._links.self.href)] ? '9999' : '1',
                      height: markerSelect[parseIdFromLink(city._links.self.href)] ? '45px' : '35px',
                      width: markerSelect[parseIdFromLink(city._links.self.href)] ? '38px' : '28px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      handleMarkerPosition(e, city);
                    }}
                  />
                )}
              </Marker>
            ))}
          </ReactMapGL>

          {mobile && !loading && !loadingCoordinates && !loadingDeliveries && !loadingStores && (
            <div style={{ marginBottom: '20px', margin: 'auto' /*width: "50%"*/ }}>
              <MapFilter
                handleFilterSettings={handleFilterSettings}
                filterSettings={displayFilter.settings}
                showFilter={displayFilter.showFilter}
                value={value}
                handleTabChange={handleTabChange}
                displayFilterSettings={displayFilter.settings}
                mobile={mobile}
              />
            </div>
          )}
          <div className={classes.storeWindow}>
            {admin && (
              <AdminMapPanel
                fullStores={allStores.filter(
                  (store) => !coordinates.find((val) => store._links.self.href === val._links.self.href)
                )}
                validStores={coordinates}
              />
            )}

            {Object.keys(clickedStoreData).length > 0 &&
              !loading &&
              !loadingCoordinates &&
              !loadingDeliveries &&
              !loadingStores && (
                <MapsStoreProfile
                  mobile={mobile}
                  store={clickedStoreData}
                  setClickedStoreData={gobackReset}
                  location={location}
                />
              )}
            {Object.keys(clickedStoreData).length === 0 &&
              !loading &&
              !loadingCoordinates &&
              !loadingDeliveries &&
              !loadingStores && (
                <MapsStoreList
                  loading={loading}
                  originalList={coordinates}
                  storeList={handleFilters()
                    // eslint-disable-next-line
                    .map((store) => {
                      if (store.storeType === 'Delivery') {
                        const matchDeliv = featuredDeliveries.find((fDeliv) => {
                          if (
                            parseIdFromLink(fDeliv._links.self.href, 0, '{?projection}') ===
                            parseIdFromLink(store._links.self.href)
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        });
                        return {
                          ...store,
                          rank: matchDeliv ? matchDeliv.rank : null,
                        };
                      }
                      if (store.storeType === 'Storefront') {
                        const matchStore = featuredStores.find(
                          (fStore) =>
                            parseIdFromLink(fStore._links.self.href, 0, '{?projection}') ===
                            parseIdFromLink(store._links.self.href)
                        );
                        return {
                          ...store,
                          rank: matchStore ? matchStore.rank : null,
                        };
                      }
                      if (store.storeType === 'Clinic') {
                        const matchStore = featuredStores.find(
                          (fStore) =>
                            parseIdFromLink(fStore._links.self.href, 0, '{?projection}') ===
                            parseIdFromLink(store._links.self.href)
                        );
                        return {
                          ...store,
                          rank: matchStore ? matchStore.rank : null,
                        };
                      }
                    })}
                  handleMarkerPosition={handleMarkerPosition}
                  location={location}
                />
              )}
          </div>
        </div>
      )}
    </Fragment>
  );
}
