export default {
  'Clyde River': { lat: 70.4762, lon: -68.5907 },
  'Fort McPherson': { lat: 67.4265, lon: -134.8607 },
  'Gjoa Haven': { lat: 68.635, lon: -95.8506 },
  Iqaluit: { lat: 63.7467, lon: -68.517 },
  Kugluktuk: { lat: 67.8262, lon: -115.0983 },
  'Norman Wells': { lat: 65.282, lon: -126.8329 },
  Pangnirtung: { lat: 66.1465, lon: -65.7133 },
  'Rankin Inlet': { lat: 62.809, lon: -92.0948 },
};
