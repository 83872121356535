/* eslint-disable no-unexpected-multiline */
import { DAYS_OF_THE_WEEK } from './constants';
import moment from 'moment';
import businessPlaceholder from './assets/placeholders/businessPlaceholder.webp';
import dealPlaceholder from './assets/placeholders/dealPlaceholder.webp';
import productPlaceholder from './assets/placeholders/productPlaceholder.webp';

/* eslint-disable no-useless-escape */
const includes = (x, y) => x.toLowerCase().includes(y.toLowerCase());

const decimalHours = (time) => {
  const parts = time.split(/:| /);
  const hour = parts[2].toLowerCase() === 'pm' ? Number(parts[0]) + 12 : Number(parts[0]);
  const minutes = Number(parts[1]) / 60;

  return hour + minutes;
};

const capitalizeFirst = (str) => {
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
};

const convertToDate = (str) => {
  if (!str) return str;
  const year = str.substring(0, 4);
  const month = str.substring(5, 7);
  const day = str.substring(8, 10);

  return new Date(year, month - 1, day);
};

const getCurrentWeekDay = () => {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const day = new Date().getDay();

  return weekdays[day];
};

//Get the weekday offset by 1 day up or down the week
const getWeekDay = (offset) => {
  offset = offset ? offset : 0;

  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let day = new Date().getDay();

  if (day + offset === 7) {
    day = 0;
  } else if (day + offset === -1) {
    day = 6;
  } else {
    day = day + offset;
  }

  return weekdays[day];
};

const parseHour = (time) => {
  if (time === null) return 'Closed';

  let hour = time.split(':')[0];
  const minute = time.split(':')[1];
  let period = ' am';

  if (hour === '00') {
    hour = 12;
  } else if (hour > 12) {
    hour = hour - 12;
    period = ' pm';
  } else if (hour === '12') {
    period = ' pm';
  }

  hour = String(hour).replace(/^0/, '');

  return hour + ':' + minute + period;
};

const buildHour = (input) => {
  if (input === 'Closed' || input === '') {
    return '';
  }

  if (input === '24 Hours') {
    input = '12:00 am';
  }

  let hour = input.split(/[\:, ]+/)[0];
  const minute = input.split(/[\:, ]+/)[1];
  const period = input.split(/[\:, ]+/)[2];

  if (hour.length < 2) hour = '0' + hour;

  if (period === 'am' && hour === '12') {
    hour = '00';
  } else if (period === 'pm' && hour !== '12') {
    hour = Number(hour) + 12;
  }

  return hour + ':' + minute;
};

const parseHoursObject = (hours) => {
  const parsedHours = [];
  DAYS_OF_THE_WEEK.forEach((day) => {
    parsedHours.push({
      day: day,
      open: parseHour(hours[`${day.toLowerCase()}Open`]),
      close: parseHour(hours[`${day.toLowerCase()}Close`]),
    });
  });
  return parsedHours;
};

const parseIdFromLink = (link, offset, strip) => {
  if (!link) return link;
  let id;
  if (strip) {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)].replace(strip, '');
  } else {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)];
  }
  return id;
};

const processDealList = (rawList) => {
  // !!! Deprecated use filterFeatList,  can be removed when all branches use the new funct.
  /* Rx a list of deals from get ranks API
  **Used in Deals View & Landing Page
  Build Object only current (not expired) and Unique deals (by Deal ID)
  Convert and return array (unordered)
    */
  if (rawList.length === 0) {
    return [];
  }
  const currentOnly = rawList.filter((deal) => new Date() < convertToDate(deal.deal.endDate));
  //Remove Duplictes (Put currents into an object based on Deal ID, then feed values into sortedList)
  const uniqueList = {};
  currentOnly.forEach((deal) => {
    const parsedID = parseIdFromLink(deal.deal._links.self.href.replace('{?projection}', ''));
    uniqueList[parsedID] = deal;
  });
  return Object.values(uniqueList);
};

const formatPhoneNumber = (number) => {
  const areaCode = number.slice(0, 3);
  const cityCode = number.slice(3, 6);
  const localNum = number.slice(6, 10);
  const formattedNumber = `${areaCode}-${cityCode}-${localNum}`;
  return formattedNumber;
};

const formatURL = (url) =>
  url.slice(0, 8) === 'https://'
    ? url.slice(8, url.length)
    : url.slice(0, 7) === 'http://'
    ? url.slice(7, url.length)
    : url;

const abbreviate = (prov) => {
  switch (prov) {
    case 'Alberta':
      return 'AB';
    case 'British Columbia':
      return 'BC';
    case 'Manitoba':
      return 'MB';
    case 'New Brunswick':
      return 'NB';
    case 'Newfoundland and Labrador':
      return 'NL';
    case 'Northwest Territories':
      return 'NT';
    case 'Nova Scotia':
      return 'NS';
    case 'Nunavut':
      return 'NU';
    case 'Ontario':
      return 'ON';
    case 'Prince Edward Island':
      return 'PE';
    case 'Quebec':
      return 'QC';
    case 'Saskatchewan':
      return 'SK';
    case 'Yukon':
      return 'YK';
    default:
      return 'N/A';
  }
};

const navbarTabLink = (navbarPath, province, city) => {
  if (
    navbarPath === '/stores' ||
    navbarPath === '/deliveries' ||
    navbarPath === '/deals'
    // || navbarPath === '/clinics'
  ) {
    return `${navbarPath}/${province.replace(/\s+/g, '-').toLowerCase()}/${city.replace(/\s+/g, '-').toLowerCase()}`;
  } else {
    return navbarPath;
  }
};

const allStoresRedirect = (location, store) => {
  return `${location.province.replace(/\s+/g, '-').toLowerCase()}/${location.city
    .replace(/\s+/g, '-')
    .toLowerCase()}/${parseIdFromLink(store._links.self.href)}/${store.slug}`;
};

const navBarSelectRedirect = (currentPath, province, city, res, cookies, dispatch) => {
  if (
    ['stores', 'deliveries', 'deals', 'maps', 'clinics'].some((tab) => tab.includes(currentPath.split('/')[3])) &&
    currentPath.split('/').length <= 6
  ) {
    dispatch({
      type: 'changeLocation',
      newLocation: {
        province,
        city,
        link: res,
      },
    });

    cookies.setLocation({
      province,
      city,
      link: res,
    });

    window.history.pushState(
      {},
      null,
      `/${currentPath.split('/')[3]}/${province.replace(/\s+/g, '-').toLowerCase()}/${city
        .replace(/\s+/g, '-')
        .toLowerCase()}`
    );
  } else if (
    ['stores', 'deliveries', 'deals', 'clinics'].some((tab) => tab.includes(currentPath.split('/')[3])) &&
    currentPath.split('/').length > 6
  ) {
    dispatch({
      type: 'changeLocation',
      newLocation: {
        province,
        city,
        link: res,
      },
    });
    cookies.setLocation({
      province,
      city,
      link: res,
    });

    window.location.assign(
      `/${currentPath.split('/')[3]}/${province.replace(/\s+/g, '-').toLowerCase()}/${city
        .replace(/\s+/g, '-')
        .toLowerCase()}`
    );
  } else if (
    ['brands', 'mailouts'].some((tab) => tab.includes(currentPath.split('/')[3])) &&
    currentPath.split('/').length <= 4
  ) {
    dispatch({
      type: 'changeLocation',
      newLocation: {
        province,
        city,
        link: res,
      },
    });
    cookies.setLocation({
      province,
      city,
      link: res,
    });
  } else if (
    ['brands', 'mailouts', '404error'].some((tab) => tab.includes(currentPath.split('/')[3])) &&
    currentPath.length > 4
  ) {
    dispatch({
      type: 'changeLocation',
      newLocation: {
        province,
        city,
        link: res,
      },
    });
    cookies.setLocation({
      province,
      city,
      link: res,
    });

    window.location.assign(`/${currentPath.split('/')[3]}/`);
  }
};

const deAbbreviate = (prov) => {
  switch (prov) {
    case 'AB':
      return 'Alberta';
    case 'BC':
      return 'British Columbia';
    case 'MB':
      return 'Manitoba';
    case 'NB':
      return 'New Brunswick';
    case 'NL':
      return 'Newfoundland and Labrador';
    case 'NT':
      return 'Northwest Territories';
    case 'NS':
      return 'Nova Scotia';
    case 'NU':
      return 'Nunavut';
    case 'ON':
      return 'Ontario';
    case 'PE':
      return 'Prince Edward Island';
    case 'QC':
      return 'Quebec';
    case 'SK':
      return 'Saskatchewan';
    case 'YK':
      return 'Yukon';
    default:
      return 'N/A';
  }
};

const MOMENT_DATE_FORMAT = 'HH:mm a';
const openStatus = (hours) => {
  if (hours === null) return false;

  const weekday = getWeekDay().toLowerCase();
  const open = moment(hours[`${weekday}Open`], MOMENT_DATE_FORMAT);
  const close = moment(hours[`${weekday}Close`], MOMENT_DATE_FORMAT);

  const prevWeekday = getWeekDay(-1).toLowerCase();
  const prevOpen = moment(hours[`${prevWeekday}Open`], MOMENT_DATE_FORMAT);
  const prevClose = moment(hours[`${prevWeekday}Close`], MOMENT_DATE_FORMAT);

  const currentTime = moment();

  //Today's opening is null but yesterday's closing is after the current time
  if (hours[`${weekday}Open`] === null && prevClose.isBefore(prevOpen) && currentTime.isBefore(prevClose)) {
    return true;
  }
  //Current time is before today's opening but yesterday's closing is after the current time
  else if (currentTime.isBefore(open) && prevClose.isBefore(prevOpen) && currentTime.isBefore(prevClose)) {
    return true;
  }
  //24 hours case
  else if (open.isSame(close) && open.isSame(moment('00:00:00', MOMENT_DATE_FORMAT))) {
    return true;
  }
  //Opens in the current day and closes in the next. Current time is after today's open
  else if (close.isBefore(open) && currentTime.isSameOrAfter(open)) {
    return true;
  }
  //Standard case, opening is before closing and the current time is between the two
  else if (open.isBefore(close) && currentTime.isSameOrAfter(open) && currentTime.isSameOrBefore(close)) {
    return true;
  }
  //Matches no cases
  else {
    return false;
  }
};

const capitalizeFirstOfEach = (value) => {
  const words = value.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

const generateSlug = (name) => {
  if (typeof name !== 'string') return ''; //if No string given rtn empty string
  const strippedName = name
    .replace(/[^\w\s]/gi, '')
    .toLowerCase()
    .trim(); //Drop Special Chars & make lower case & eliminate white spaces
  const slug = strippedName.replace(/ /g, '-'); //Replace spaces with -
  return slug;
};

const filterFeaturedList = (listType, featuredList, completeList, currLocation) => {
  /* Filter valid deals, rmv duplicates, and Cross check to remove any feat items that don't exist in the location 
  completeList is optional
  Should handle all featured List types */
  //[] TODO: Add type handling / exit clause(s)
  if (!featuredList) return [];
  if (listType === 'deal') {
    const today = new Date();
    //Only Active & Upcoming Deals
    featuredList = featuredList.filter((deal) => today < convertToDate(deal.deal.endDate));
    completeList = completeList?.filter((deal) => today < convertToDate(deal.endDate));
  }

  let validItems = featuredList;

  if (currLocation) {
    validItems = featuredList.filter((featItem) => {
      const storeLocations = featItem[listType].locations;
      const { city: currCity, province: currProvince } = currLocation;
      return storeLocations.some((loc) => loc.city === currCity && loc.province === currProvince);
    });
  }

  if (completeList) {
    /* Keeps only items that belong in that location */
    const fullListIDs = completeList.map((item) => item._links.self.href.replace('{?projection}', ''));
    validItems = featuredList.filter((featItem) =>
      fullListIDs.includes(featItem[listType]._links.self.href.replace('{?projection}', ''))
    );
  }
  const unique = {};
  validItems.forEach((item) => {
    const entityID = parseIdFromLink(item[listType]._links.self.href, 0, '{?projection}');
    unique[entityID] = item;
  });
  const newData = Object.values(unique);
  const sorted = newData.sort((x, y) => x.rank - y.rank);
  return [...sorted];
  /* Tried to use new Set but since items are objects it treated them as unique */
};

const parseImgURL = (url) => {
  const imageURLParts = url.split('/');
  const imageFileName = imageURLParts[imageURLParts.length - 1];
  return imageFileName;
};

const makeImageKitURL = (sourceImage, options, placeholderType) => {
  if (!sourceImage) {
    // If no image is provided, return a placeholder
    // placeholder is specified at the component level b/c it may change
    let placeholderPath = '';
    switch (placeholderType) {
      case 'PRODUCT':
      case 'product':
      case 'Featured Products':
        placeholderPath = productPlaceholder;
        break;
      case 'DEAL':
      case 'deal':
      case 'Deals':
        placeholderPath = dealPlaceholder;
        break;
      case 'BUSINESS':
      case 'storefront':
      case 'delivery':
        placeholderPath = businessPlaceholder;
        break;
      default:
        placeholderPath = businessPlaceholder;
        break;
    }
    return placeholderPath ?? '';
  }

  if (sourceImage?.includes('blob:')) return sourceImage; //Image is added from the file system skips imagekit.
  const imageFileName = parseImgURL(sourceImage);
  if (!options) options = { width: '200' }; //If options is forgotten will default to 200px wide
  const width = options.width ? options.width : 'false';
  const height = options.height ? options.height : 'false';
  const imageKitURL = `https://ik.imagekit.io/gln6kperair/${imageFileName}?tr=w-${width},h-${height}`;
  return imageKitURL;
};

const isLocalInstance = window.location?.host.includes('192.168') || window.location?.host.includes('localhost'); //Location optional is for the app.
const isProductionEnv = process.env.REACT_APP_ENV === 'prodapi' && !isLocalInstance;

const validateEmail = (inputEmail) => {
  if (!inputEmail) return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(inputEmail).toLowerCase());
};

const validatePassword = (inputPassword) => {
  //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
  const containsUpperCase = inputPassword.match(/[A-Z]/);
  const containsLowerCase = inputPassword.match(/[a-z]/);
  const containsDigit = inputPassword.match(/\d/);
  const containsSpecial = inputPassword.match(/[^a-zA-Z\d]/);

  return (
    inputPassword.length >= 8 &&
    inputPassword.length <= 50 &&
    containsDigit &&
    containsSpecial &&
    containsUpperCase &&
    containsLowerCase
  );
};

const createOpenHoursString = function (dayInfo) {
  if (!dayInfo) throw new Error('dayInfo is missing');
  const { open, close } = dayInfo;
  if (open === '12:00 am' && close === '12:00 am') return '24 hours';
  if (open === 'Closed') return 'Closed';
  return `${open} - ${close}`;
};

const encodeShortURL = (entityInfo, options) => {
  const BASE_URL = window.location.origin;
  const entityID = entityInfo._links.self.href.split('/').pop().replace('{?projection}', '');
  const entityType = options?.entityType; //entityInfo.storeType;

  const entityTypeMap = {
    Brand: 'b',
    Product: 'p',
    Deal: 'd',
    //default to store 's' in return statement
  };

  return `${BASE_URL}/q?${entityTypeMap[entityType] || 's'}=${entityID}`;
};

const calcDaysFromToday = (endDate) => {
  const today = new Date();
  const end = new Date(endDate);
  const diffTime = end - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const determinePinChanges = (cityStreetData, currPin) => {
  let newCitys = [];
  let deleteCitys = [];
  let editPins = [];
  //PATCH if .street values for matching citys are different, POST new ones, DELETE the ones that dont exist in
  // these are the new pins [{city: "", street : ""}]
  newCitys = cityStreetData.filter((record) => !currPin.find((pin) => pin.address.city === record.city));
  //these are the pin href that have to be deleted
  deleteCitys = currPin
    .filter((pin) => !cityStreetData.find((record) => record.city === pin.address.city))
    .map((pin) => {
      return { ...pin, ...pin.address, id: parseIdFromLink(pin._links.self.href, 0, '{?projection}') };
    });

  currPin.forEach((pin) => {
    cityStreetData.forEach((val) => {
      if (pin.address.city === val.city) {
        if (pin.address.street !== val.street || pin.address.postalCode !== val.postalCode) {
          editPins.push({
            //Clear old coordinates and send new addr.
            longitude: null,
            latitude: null,
            street: val.street,
            postalCode: val.postalCode,
            id: parseIdFromLink(pin._links.self.href, 0, '{?projection}'),
          });
        }
      }
    });
  });
  return { newCitys, deleteCitys, editPins };
};

const isProtectFields = (isLocked, isPublished) => {
  //Used in AddEditStore, and useAdminLogData.  Standard bool for lock however because this is an added item
  //some store may not have a lock field set yet.  Thus the isPublished is used as a fallback.
  //Business rules as of 2023 - certain storefields lock AFTER the first time a store is published.

  //Clause to hand legacy stores that dont have a lock field set yet.
  if (isLocked === null) return isPublished;
  //Lock is the prefered field to use.
  if (isLocked) return true;
  return false;
};

export {
  openStatus,
  includes,
  decimalHours,
  capitalizeFirst,
  convertToDate,
  getCurrentWeekDay,
  getWeekDay,
  parseHour,
  buildHour,
  parseHoursObject,
  parseIdFromLink,
  processDealList,
  formatPhoneNumber,
  formatURL,
  abbreviate,
  deAbbreviate,
  navbarTabLink,
  navBarSelectRedirect,
  MOMENT_DATE_FORMAT,
  capitalizeFirstOfEach,
  allStoresRedirect,
  generateSlug,
  filterFeaturedList,
  parseImgURL,
  makeImageKitURL,
  isProductionEnv,
  validateEmail,
  validatePassword,
  createOpenHoursString,
  encodeShortURL,
  calcDaysFromToday,
  determinePinChanges,
  isProtectFields,
};
