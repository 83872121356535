import React, { useState, Fragment } from 'react';
import {
  makeStyles,
  Typography,
  // Divider,
  Button,
  Modal,
  Backdrop,
  Fade,
  useMediaQuery,
  Tooltip,
  Fab,
  IconButton,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {
  parseHoursObject,
  getCurrentWeekDay,
  abbreviate,
  openStatus,
  makeImageKitURL,
  createOpenHoursString,
} from '../utils/helpers';
import StaticMap from './StaticMap';
import MapIcon from '@material-ui/icons/Map';
import { Call, Email, StarRate, Share /*, Add*/ } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '../components/TwitterIcon';
import InstagramIcon from '@material-ui/icons/Instagram';
import OpenStatusBadge from './OpenStatusBadge';
import OpenStatusText from './OpenStatusText';

const useStyles = makeStyles((theme) => ({
  root: {},
  profileGrid: {
    margin: theme.spacing(3, 2, 1, 2),
    display: 'grid',
    gridTemplateColumns: '50% 20% 27%',
    gridTemplateRows: 'auto auto',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0),
      gridTemplateColumns: '3fr 1fr',
      gridTemplateRows: 'auto auto auto',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'auto auto auto auto',
    },
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  logo: {
    width: 220,
    height: 220,
    objectFit: 'contain',
    border: '1px solid #ebebeb',
  },
  details: {
    display: 'flex',
    gridColumn: 1,
    gridRow: 1,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      // marginTop: theme.spacing(1),
    },
  },
  hours: {
    gridColumn: 2,
    gridRow: 1,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 2,
    },
  },
  rightContainerText: {
    color: '#000000',
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(13),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  location: {
    gridColumn: '3 / span 1',
    gridRow: 1,
    overflow: 'hidden',
    backgroundColor: '#EBEBEB',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 3',
      gridRow: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      backgroundColor: 'white',
      // marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 3,
    },
  },
  about: {
    gridColumn: '1 / span 3',
    gridRow: 2,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 2',
      gridRow: 3,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 4,
    },
    margin: theme.spacing(2, 1, 1, 1),
  },
  detailsText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  title: { fontSize: '16pt', marginBottom: theme.spacing(1) },
  detailLine: { marginBottom: theme.spacing(0.2) },
  brandName: {
    fontSize: '16pt',
    fontWeight: '700',
    marginBottom: theme.spacing(1),
  },
  rating: { display: 'flex' },
  stars: { marginRight: theme.spacing(1) },
  dayHours: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  divider: {
    margin: theme.spacing(1, 2, 1, 2),
  },
  map: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mapButton: {
    width: '180px',
    boxShadow: '0px 0px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  actionButton: {
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 12,
    },
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px',
  },
  internetMedia: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(0),
  },
  mediaIcon: {
    color: theme.palette.grey.A700,
    marginRight: theme.spacing(1),
  },
  boldCell: {
    fontWeight: 'bold',
  },
}));

export default function BrandProfile(props) {
  const { brand, jumpToReview, loading } = props;
  const [mapOpen, setMapOpen] = useState(false);
  const classes = useStyles();

  const phone = brand.phone.split('');
  const open = openStatus(brand.hours);

  const formattedPhone =
    phone.slice(0, 3).join('') + '-' + phone.slice(3, 6).join('') + '-' + phone.slice(6, 10).join('');

  const parsedHours = brand.hours ? parseHoursObject(brand.hours) : '';

  const [openMap, setOpenMap] = useState(false);

  const mobile = useMediaQuery('(max-width:768px)');

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 3000);
  };

  const currentWeekDay = getCurrentWeekDay();

  const showStaticMap = brand.displayMap === null || brand.displayMap ? brand.address.postalCode : false;

  return (
    <div className={classes.root}>
      <div className={classes.profileGrid}>
        <div className={classes.details}>
          <img src={makeImageKitURL(brand.brandImage, { width: '300' })} alt="brand-logo" className={classes.logo} />
          <div className={classes.detailsText}>
            <Typography variant="h1" className={classes.brandName}>
              {brand.name}
            </Typography>
            <div className={classes.rating}>
              <Rating
                name="read-only"
                value={brand.avgRating}
                precision={0.5}
                size="small"
                className={classes.stars}
                readOnly
              />
              <Typography variant="body2">
                {`${Number(brand.avgRating).toFixed(2)} (${brand.numOfReviews} review${
                  brand.numOfReviews === '1' ? '' : 's'
                })`}
              </Typography>
            </div>
            {brand.address.street && <Typography variant="body2">{brand.address.street}</Typography>}
            <Typography variant="body2" className={classes.detailLine}>
              {brand.address.city}, {abbreviate(brand.address.province)}
            </Typography>
            {brand.address.postalCode && <Typography variant="body2">{brand.address.postalCode}</Typography>}
            <Typography variant="body2" className={classes.detailLine}>
              {formattedPhone}
            </Typography>
            {brand.hours !== null && (
              <div>
                {loading ? <Typography> </Typography> : <OpenStatusBadge open={open} />}
                {loading ? <Typography> </Typography> : <OpenStatusText open={open} hours={brand.hours} />}
              </div>
            )}
            <div className={classes.actionButtons} id="store-actions">
              <Tooltip title="Call" bottom arrow>
                <Fab
                  color="default"
                  size="small"
                  aria-label="call"
                  className={classes.actionButton}
                  href={`tel:${brand.phone}`}
                  style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                >
                  <Call />
                </Fab>
              </Tooltip>
              <Tooltip title="Email" bottom arrow>
                <Fab
                  color="default"
                  size="small"
                  aria-label="email"
                  className={classes.actionButton}
                  href={`mailto:${brand.email}`}
                  style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                >
                  <Email />
                </Fab>
              </Tooltip>
              <Tooltip title="Read Reviews" bottom arrow>
                <Fab
                  color="default"
                  size="small"
                  aria-label="rate"
                  className={classes.actionButton}
                  onClick={() => {
                    window.scroll(0, 325);
                    jumpToReview();
                  }}
                >
                  <StarRate />
                </Fab>
              </Tooltip>
              <Tooltip
                title={openTooltip ? 'Copied Store URL' : 'Share URL'}
                bottom
                arrow
                open={useMediaQuery('(max-width:768px)') ? openTooltip : undefined}
              >
                <CopyToClipboard className={classes.actionButton} text={window.location.href}>
                  <Fab color="default" size="small" aria-label="share" onClick={handleTooltipOpen}>
                    <Share />
                  </Fab>
                </CopyToClipboard>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className={classes.hours}
          style={{
            gridColumn: !brand.displayMap ? (mobile ? 1 : 3) : mobile ? 1 : 2,
            // gridColumn: 2,
            // gridRow: 1,
            // [theme.breakpoints.down("sm")]: {
            //   gridColumn: 1,
            //   gridRow: 2,
            //   // marginLeft: theme.spacing(2),
            // },
          }}
        >
          {parsedHours && (
            <Fragment>
              <Typography className={classes.title}>Hours:</Typography>
              {parsedHours.map((day, index) => {
                let boldClass = '';

                if (day.day === currentWeekDay) {
                  boldClass = boldClass + `${classes.boldCell}`;
                }

                return (
                  <Typography variant="body2" key={index} className={`${classes.dayHours} ${boldClass}`}>
                    {day.day.slice(0, 3)}: {createOpenHoursString(day)}
                  </Typography>
                );
              })}
            </Fragment>
          )}
        </div>
        {showStaticMap && (
          <div className={classes.location}>
            {!openMap && (
              <div
                className={classes.rightContainer}
                hidden={mapOpen}
                variant="contained"
                color="primary"
                //className={classes.mapButton2}
                onClick={() => setMapOpen(true)}
              >
                <div className={classes.rightContainerText}>
                  <MapIcon style={{ marginRight: '10px' }} />
                  View Map
                </div>
              </div>
            )}
            {mapOpen && (
              <div className={classes.map}>
                <StaticMap className={classes.mapOpen} address={brand.address} storeName={brand.name}></StaticMap>
              </div>
            )}
            <Button variant="contained" color="primary" className={classes.mapButton} onClick={() => setOpenMap(true)}>
              <MapIcon style={{ marginRight: '10px' }} />
              View Map
            </Button>
          </div>
        )}
        <div className={classes.about}>
          <Typography className={classes.title}>About Us</Typography>
          <Typography variant="body2">{brand.aboutUs}</Typography>
          <div className={classes.internetMedia}>
            {brand.website && (
              <IconButton className={classes.mediaIcon} href={'//' + brand.website} target="_blank">
                <LanguageIcon />
              </IconButton>
            )}
            {brand.facebook && (
              <IconButton className={classes.mediaIcon} href={'//' + brand.facebook} target="_blank">
                <FacebookIcon />
              </IconButton>
            )}
            {brand.instagram && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  brand.instagram[0] === '@'
                    ? `https://www.instagram.com/${brand.instagram.slice(1, brand.instagram.length)}`
                    : '//' + brand.instagram
                }
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
            )}
            {brand.snapchat && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  brand.snapchat[0] === '@'
                    ? `https://www.snapchat.com/add/${brand.snapchat.slice(1, brand.snapchat.length)}`
                    : '//' + brand.snapchat
                }
                target="_blank"
              >
                {/* <CameraEnhanceIcon /> */}
                <img src={require(`../assets/logos/iconSnapchat.svg`)} alt={`snapchat-main-logo`} height="30" />
              </IconButton>
            )}
            {brand.twitter && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  brand.twitter[0] === '@'
                    ? `https://x.com/${brand.twitter.slice(1, brand.twitter.length)}`
                    : '//' + brand.twitter
                }
                target="_blank"
              >
                <TwitterIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={openMap}
        onClose={() => {
          setOpenMap(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openMap}>
          <div className={classes.modalPaper}>
            <StaticMap address={brand.address} storeName={brand.name}></StaticMap>
            <div className={classes.actionButtons}>
              <Button variant="contained" onClick={() => setOpenMap(false)} className={classes.cancelButton}>
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
