export default {
  Assiniboia: { lat: 49.6168, lon: -105.9905 },
  Biggar: { lat: 52.0586, lon: -107.9852 },
  Canora: { lat: 51.6354, lon: -102.4342 },
  Carlyle: { lat: 49.6336, lon: -102.1283 },
  Dalmeny: { lat: 52.3334, lon: -106.7667 },
  Esterhazy: { lat: 50.65, lon: -102.0671 },
  Estevan: { lat: 49.1391, lon: -102.9914 },
  'Foam Lake': { lat: 51.6337, lon: -103.5344 },
  Gravelbourg: { lat: 49.8751, lon: -106.5523 },
  'Hudson Bay': { lat: 52.8547, lon: -102.3879 },
  Humboldt: { lat: 52.2014, lon: -105.1231 },
  'Indian Head': { lat: 50.5336, lon: -103.6682 },
  Kamsack: { lat: 51.5642, lon: -101.8968 },
  Kerrobert: { lat: 51.9169, lon: -109.1322 },
  Kindersley: { lat: 51.467, lon: -109.1571 },
  Langenburg: { lat: 50.8411, lon: -101.7012 },
  Langham: { lat: 52.3746, lon: -106.9818 },
  Lanigan: { lat: 51.8539, lon: -105.0344 },
  'La Ronge': { lat: 55.1005, lon: -105.301 },
  Lloydminster: { lat: 53.2776, lon: -110.0039 },
  Lumsden: { lat: 50.6472, lon: -104.8678 },
  Macklin: { lat: 52.3276, lon: -109.9341 },
  'Maple Creek': { lat: 49.9134, lon: -109.4848 },
  Martensville: { lat: 52.2891, lon: -106.666 },
  'Meadow Lake': { lat: 54.1251, lon: -108.4343 },
  Melfort: { lat: 52.8555, lon: -104.61 },
  Melville: { lat: 50.9305, lon: -102.8078 },
  'Moose Jaw': { lat: 50.3917, lon: -105.5344 },
  Moosomin: { lat: 50.1416, lon: -101.6681 },
  Nipawin: { lat: 53.3588, lon: -104.0198 },
  'North Battleford': { lat: 52.7575, lon: -108.2867 },
  Outlook: { lat: 51.5036, lon: -107.053 },
  Oxbow: { lat: 49.2335, lon: -102.1736 },
  'Pilot Butte': { lat: 50.4673, lon: -104.4173 },
  Preeceville: { lat: 51.9534, lon: -102.6687 },
  'Prince Albert': { lat: 53.2033, lon: -105.7531 },
  Regina: { lat: 50.4452, lon: -104.6189 },
  Rosetown: { lat: 51.5551, lon: -107.9909 },
  Rosthern: { lat: 52.6596, lon: -106.3346 },
  Saskatoon: { lat: 52.1332, lon: -106.67 },
  Shaunavon: { lat: 49.6494, lon: -108.4152 },
  Shellbrook: { lat: 53.2167, lon: -106.3842 },
  'Swift Current': { lat: 50.2851, lon: -107.7972 },
  Tisdale: { lat: 52.8337, lon: -104.0505 },
  Unity: { lat: 52.4468, lon: -109.1633 },
  Wadena: { lat: 51.9465, lon: -103.7974 },
  Warman: { lat: 52.3217, lon: -106.5847 },
  Watrous: { lat: 51.674, lon: -105.4682 },
  Weyburn: { lat: 49.6618, lon: -103.8527 },
  'White City': { lat: 50.4327, lon: -104.3566 },
  Wilkie: { lat: 52.4168, lon: -108.7017 },
  Wynyard: { lat: 51.7664, lon: -104.1806 },
  Yorkton: { lat: 51.2139, lon: -102.4628 },
};
