import React from "react";

import "./styles/PercentageCircle.css";
import { useMediaQuery } from "@material-ui/core";

function PercentageCircle(props) {
  const { percent } = props;

  const fixedDecimalPercentage = Number(percent).toFixed(1);

  const mobile = useMediaQuery("(max-width:768px)");

  return (
    // <div className="clearfix">
    <div
      className={`c100 p${Math.round(fixedDecimalPercentage)} ${
        mobile ? "extra-" : ""
      }small green`}
    >
      <span>
        {Number(fixedDecimalPercentage) !== 0
          ? `${fixedDecimalPercentage}%`
          : "N/A"}
      </span>
      <div className="slice">
        <div className="bar"></div>
        <div class="fill"></div>
      </div>
    </div>
    // </div>
  );
}

export default PercentageCircle;
