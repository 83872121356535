import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Typography, Button } from '@material-ui/core'; //Button
import Rating from '@material-ui/lab/Rating';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import cookies from '../cookies';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../utils/api';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0, 1, 0),
    padding: theme.spacing(2, 3, 2, 3),
    border: '1px solid #ebebeb',
    overflow: 'hidden',
    borderRadius: 5,
  },
  reviewResponse: {
    // alignItems: "flex-end",

    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(7),
    border: '1px solid #d6d6d6',
    borderRadius: 5,
    padding: theme.spacing(2, 3, 2, 3),
    //border: "1px solid green",
    overflow: 'visible',
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.65)', // (default alpha is 0.38)
    },
    //borderRadius: 5,
  },
  editReviewResponse: {
    // alignItems: "flex-end",

    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(7),
    //border: "1px solid #d6d6d6",
    //borderRadius: 4,
    //padding: theme.spacing(2, 3, 2, 3),
    //border: "1px solid green",
    overflow: 'visible',
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.65)', // (default alpha is 0.38)
    },
    //borderRadius: 5,
  },
  ownersName: {
    marginLeft: theme.spacing(4),
    padding: theme.spacing(0.3, 0.5, 0.3, 0.5),
    //border: "1px solid #44a9df",
    //backgroundColor: "#44a9df",
    border: '1px solid lightgrey',
    backgroundColor: 'lightgrey',
    //#ebebeb
    borderRadius: '30px',
    fontWeight: 'bold',
    fontSize: '11pt',
    color: '#ffff',
  },

  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  name: {
    fontWeight: 'bold',
  },
  homeButton: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: '10px',
    marginBottom: '3px',
    marginRight: '8px',
    Color: 'white',
    width: '80%',
    height: '20px',
  },

  cancelButton: {
    backgroundColor: 'lightred',
    marginBottom: '3px',
    width: '80%',
    height: '20px',
  },
  days: {
    marginLeft: theme.spacing(2),
  },
  ratingContainer: { display: 'flex', alignItems: 'center' },
  ratingStars: {},
  ratingNumbers: { marginLeft: theme.spacing(1), fontWeight: 'bold' },
  reviewForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  flexEditContainer: {
    //width: "100%",
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
  },

  rightSide: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 'auto',
  },

  replyRightSide: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 'auto',
  },

  leftSide: {
    marginTop: '6px',
    color: 'gray',
    display: 'flex',
    alignItems: 'flex-start',
    //marginRight: theme.spacing(60),
  },

  edit: {
    width: 32,
    height: 32,
    marginTop: '6px',
    padding: '6px',
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'lightgray',
      borderRadius: '100%',
      transition: 'all .2s ease-in-out',
      cursor: 'pointer',
    },
  },
  DeleteConfirm: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //justifyContent: "space-between",
  },

  title: {
    fontWeight: 'bold',
    fontSize: '14pt',
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

//const lines = review.reply.split(/[\n]/);

function ReviewItem(props) {
  const { review, editMode, itemID, ownerName, product } = props;
  const classes = useStyles();

  const [invalidTitle, setInvalidTitle] = useState(false); //eslint-disable-line
  const [editing, setEditing] = useState(false);
  const [invalidDesc, setInvalidDesc] = useState(false);
  const [invalidReply, setInvalidReply] = useState(false);
  const [editReply, setEditReply] = useState(false);

  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  //const[lines, setLines] = useState([]);

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const [deleteReplyConfirm, setDeleteReplyConfirm] = useState(false);
  const [deleteReplyError, setDeleteReplyError] = useState(false);

  const [owner, setOwner] = useState(false);

  //const [reply, setReply] = useState("");
  const [replyText, setReplyText] = useState('');
  const [replying, setReplying] = useState(false);
  const isAdmin = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole());

  const updateReview = async (rID, data) => {
    return api
      .updateReview(rID, data)
      .then((res) => res)
      .catch((err) => {
        throw STATUS.ERROR;
      });
  };

  const getProduct = async (id) => {
    return api
      .getProductCustomized(id)
      .then((res) => {
        return res;
      })
      .catch(() => {
        throw STATUS.ERROR;
      });
  };

  //grab all the owned items of the logged in user
  const getOwnedItems = async (id) => {
    let owned = [];
    return api.getUser(id).then((res) => {
      if (res._embedded) {
        //For each brand and store in the brand/store array add it to the owned list with its id
        if (res._embedded.brands && res._embedded.brands.length > 0) {
          res._embedded.brands.forEach((iter) =>
            owned.push(parseIdFromLink(iter._links.self.href, 0, '{?projection}'))
          );
        }
        if (res._embedded.stores && res._embedded.stores.length > 0) {
          res._embedded.stores.forEach((iter) =>
            owned.push(parseIdFromLink(iter._links.self.href, 0, '{?projection}'))
          );
        }
      }
      if (product) {
        getProduct(itemID).then((res) => {
          //in our owned ids list if we find a value that matches the prodcuts parents brand id
          if (res.brand) {
            if (owned.includes(parseIdFromLink(res.brand._links.self.href, 0, '{?projection}'))) {
              setOwner(true);
            }
          }
          //if there is brand array it must be a product owned by a store
          else {
            if (owned.includes(parseIdFromLink(res.stores[0]._links.self.href, 0, '{?projection}'))) {
              setOwner(true);
            }
          }
        });
      }
      return owned;
    });
  };

  const deleteReview = async (rID) => {
    return api
      .deleteReview(rID)
      .then((res) => res)
      .catch((err) => {
        throw STATUS.ERROR;
      });
  };

  const validate = (rID) => {
    if (title === review.title && description === review.description && rating === Number(review.rating)) {
      setEditing(false);
    } else if (title !== '' && description !== '') {
      submit(rID);
    }

    if (title === '') {
      setInvalidTitle(true);
    }

    if (description === '') {
      setInvalidDesc(true);
    }
  };

  const validateReply = (rID) => {
    //If the text is the same we just pretend to save and carry on
    if (replyText === review.reply) {
      setReplying(false);
    } else if (replyText === '') {
      setInvalidReply(true);
    } else if (replyText !== review.reply) {
      submit(rID);
    }
  };

  const submit = (rID) => {
    setSaving(true);
    //updateReview
    updateReview(rID, { description, rating, title, reply: replyText })
      .then((res) => {
        setSaving(false);
        if (res.status === 200) {
          setEditing(false);
          setReplying(false);
          setEditReply(false);
          editMode();
        } else {
          setSaveError(true);
        }
      })
      .catch((err) => {
        setSaving(false);
      });
  };

  const days = moment.utc(review.createDateTime).local().fromNow();

  useEffect(() => {
    setRating(review.rating);
    setDescription(review.description);
    setTitle(review.title);
    // if(review.reply) {setLines(review.reply.split(/[\n]/))}
    //setReply(review.reply)
    if (cookies.getUserID() !== undefined && cookies.getUserID() !== null) {
      getOwnedItems(cookies.getUserID())
        .then((res) => {
          if (owner !== true) {
            res.includes(itemID) ? setOwner(true) : setOwner(false);
          }
        })
        .catch((error) => {});
    }
    //If not logged in set owner to false
    else {
      setOwner(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.name}>{review.name}</Typography>
          <Typography className={classes.days}>{days}</Typography>
        </div>

        {!editing && (
          <div>
            <div className={classes.ratingContainer}>
              <Rating
                name="read-only"
                value={review.rating ? Number(review.rating) : 0}
                precision={0.5}
                size="small"
                className={classes.ratingStars}
                readOnly
              />
              <Typography className={classes.ratingNumbers}>{review.rating}</Typography>
            </div>

            <Typography className={classes.title} hidden={editing ? true : false}>
              {review.title}
            </Typography>

            <Typography className={classes.description}>{review.description}</Typography>
          </div>
        )}

        {editing && saveError && (
          <div style={{ display: 'flex' }}>
            <Typography style={{ color: 'red' }}> There was an error saving your post </Typography>
          </div>
        )}

        {editing && !saveError && (
          <div className={classes.reviewForm}>
            <div className={classes.ratingForm}>
              <Rating
                //disabled={saved || !loggedIn}
                name="user-store-rating"
                //value={review.rating ? Number(review.rating) : 0}
                defaultValue={review.rating ? Number(review.rating) : 0}
                precision={0.5}
                size="large"
                className={classes.reviewInput}
                onChange={(event, newValue) => {
                  //setRated(true);
                  setRating(newValue);
                }}
              />
              {/*invalidRating && (
              <Typography variant="body2" style = {{color:"red"}}className={classes.ratingError}>
                Add a rating
              </Typography>
            )*/}
            </div>
            <TextField
              style={{ marginTop: '10px' }}
              // autoFocus={focusReviewForm}
              //disabled={saved || !loggedIn}
              variant="outlined"
              id="name-input"
              label="Title"
              className={classes.reviewInput}
              //value={title}
              defaultValue={review.title}
              error={invalidTitle}
              helperText={invalidTitle ? 'Please enter a title' : ''}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                //setInvalidTitle(false);
                setTitle(e.target.value);
              }}
            />
            <TextField
              style={{ marginTop: '10px' }}
              // disabled={saved || !loggedIn}
              variant="outlined"
              id="review-input"
              label="Description"
              multiline
              rows="4"
              className={classes.reviewInput}
              error={invalidDesc}
              helperText={invalidDesc ? 'Please enter a review' : ''}
              defaultValue={review.description}
              //value={description}
              inputProps={{ maxLength: 500 }}
              onChange={(e) => {
                setInvalidDesc(false);
                setDescription(e.target.value);
              }}
            />
            <Typography
              style={description.length === 500 ? { color: 'red' } : { color: 'inherit' }}
              align="right"
              variant="caption"
              className={description.length === 500 ? classes.errorTextLength : ''}
            >
              {500 - description.length} Characters Remaining
            </Typography>
          </div>
        )}

        <Divider />

        <div className={!deleteConfirm ? classes.flexEditContainer : classes.DeleteConfirm}>
          {isAdmin && (
            <div>
              <Typography id={'user'} className={classes.leftSide} hidden={isAdmin && !deleteConfirm ? false : true}>
                {`Username: ${review.reviewPoster}`}
              </Typography>
              <Typography
                id={'ipAddress'}
                className={classes.leftSide}
                hidden={isAdmin && !deleteConfirm ? false : true}
              >
                {review.ipAddress !== null ? `IP: ${review.ipAddress}` : 'IP: N/A'}
              </Typography>
            </div>
          )}

          <div className={classes.rightSide}>
            {!(
              (!owner && !isAdmin) ||
              replying ||
              editing ||
              (review.reply !== null && review.reply !== undefined && review.reply !== '')
            ) && (
              <Button
                //if the store/brand/product isnt owned by the loggedin User then we hide the reply button
                // hidden={
                //   (!owner && !isAdmin) ||
                //   replying ||
                //   editing ||
                //   (review.reply !== null && review.reply !== undefined && review.reply !== '')
                // }
                edge="start"
                // style={{marginRight: "5px"}}
                className={classes.homeButton}
                color="inherit"
                aria-label="logo"
                style={{ color: 'inherit', textDecoration: 'inherit' }}
                onClick={() => {
                  setReplying(true);
                  setEditReply(true);
                  //we want the reply form to appear
                }}
              >
                <Typography style={{ color: 'white' }}>reply</Typography>
              </Button>
            )}
            {!(editing ||
            (cookies.getUserID() !== review.userID &&
              !['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole())) ||
            deleteConfirm
              ? true
              : false) && (
              <CreateIcon
                className={classes.edit}
                size="medium"
                // hidden={
                //   editing ||
                //   (cookies.getUserID() !== review.userID &&
                //     !['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole())) ||
                //   deleteConfirm
                //     ? true
                //     : false
                // }
                onClick={() => {
                  setEditing(true);
                }}
              />
            )}
            {saving && (
              <CircularProgress
                style={{ marginBottom: '5px', marginTop: '0px', marginRight: '5px' }}
                className={classes.saving}
                size={18}
                color="inherit"
                hidden={replying || deleteConfirm || deleteReplyConfirm}
              />
            )}
            {editing && !saveError && !deleteConfirm && (
              <>
                <SaveIcon
                  style={{ color: 'green' }}
                  className={classes.edit}
                  // hidden={editing && !saveError && !deleteConfirm ? false : true}
                  size="medium"
                  onClick={() => validate(review.reviewID)}
                />
                <ClearIcon
                  className={classes.edit}
                  style={{ color: 'red' }}
                  // hidden={editing && !saveError && !deleteConfirm ? false : true}
                  size="medium"
                  onClick={() => {
                    setEditing(false);
                    setInvalidDesc(false);
                    setInvalidTitle(false);
                  }}
                />
              </>
            )}
            {!(editing ||
            (cookies.getUserID() !== review.userID &&
              !['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole())) ||
            deleteConfirm
              ? true
              : false) && (
              <DeleteIcon
                //style={{ color: "red" }}
                className={classes.edit}
                size="medium"
                // hidden={
                //   editing ||
                //   (cookies.getUserID() !== review.userID &&
                //     !['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole())) ||
                //   deleteConfirm
                //     ? true
                //     : false
                // }
                onClick={() => {
                  setDeleteConfirm(true);
                }}
              />
            )}
          </div>

          {deleteConfirm && (
            <Typography className={classes.DeleteConfirm}> Are you certain you want to delete your review? </Typography>
          )}

          {deleteError && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ color: 'red' }}> There was an error deleting your post </Typography>
            </div>
          )}
          {deleteConfirm && (
            <>
              <CheckIcon
                style={{ color: 'green' }}
                className={classes.edit}
                // hidden={deleteConfirm ? false : true}
                size="medium"
                onClick={() => {
                  deleteReview(review.reviewID).then((res) => {
                    if (res.status === 204) {
                      setEditing(false);
                      editMode();
                    } else {
                      setDeleteError(true);
                    }
                  });
                }}
              />
              <ClearIcon
                className={classes.edit}
                style={{ color: 'red' }}
                // hidden={deleteConfirm ? false : true}
                size="medium"
                onClick={() => {
                  setEditing(false);
                  setDeleteConfirm(false);
                }}
              />
            </>
          )}
        </div>
      </div>

      {/* ================================================= REPLYING TO REVIEWS SECTION ============================================================== */}

      {/* ================================================= NO INITIAL REPLY FIELD ============================================================== */}

      {replying && (
        <div>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignContent: 'flex-end', width: '100%' }}>
              <TextField
                // disabled={saved || !loggedIn}
                variant="outlined"
                id="review-input"
                label="Response"
                multiline
                rows="4"
                className={editReply ? classes.editReviewResponse : classes.reviewResponse}
                error={invalidReply}
                helperText={invalidReply ? 'Please enter a response' : ''}
                //value={description}
                inputProps={{ maxLength: 500 }}
                onChange={(e) => {
                  setInvalidReply(false);
                  setReplyText(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              style={replyText.length === 500 ? { color: 'red' } : { color: 'inherit' }}
              align="right"
              variant="caption"
              className={replyText.length === 500 ? classes.errorTextLength : ''}
            >
              {500 - replyText.length} Characters Remaining
            </Typography>
          </div>
          <div className={classes.flexEditContainer}>
            <div className={classes.rightSide}>
              {/*if we are replying it means there was no reply set and so we render a save and clear*/}

              <div>
                {saveError && (
                  <div style={{ display: 'flex' }}>
                    <Typography style={{ color: 'red' }}> There was an error saving your post </Typography>
                  </div>
                )}
                {saving && (
                  <CircularProgress
                    style={{ marginBottom: '5px', marginTop: '5px', marginRight: '5px' }}
                    className={classes.saving}
                    size={18}
                    color="inherit"
                    hidden={!replying}
                  />
                )}
                <SaveIcon
                  style={{ color: 'green' }}
                  className={classes.edit}
                  size="medium"
                  onClick={() => {
                    validateReply(review.reviewID);
                  }}
                />
                <ClearIcon
                  className={classes.edit}
                  style={{ color: 'red' }}
                  size="medium"
                  onClick={() => {
                    setReplying(false);
                    setEditReply(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ================================================= EXISTING REPLY FIELD ============================================================== */}
      {review.reply !== null && review.reply !== undefined && review.reply !== '' && !replying && (
        <div>
          <Typography style={{ marginTop: '15px' }}>
            <span className={classes.ownersName}>{ownerName} replied:</span>
          </Typography>

          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignContent: 'flex-end', width: '100%' }}>
              {!editReply && (
                <div
                  className={classes.reviewResponse} //hidden={editReply}
                >
                  {review.reply.split(/[\n]/).map((line, index) => (
                    <Typography /*className={classes.line}*/ key={index}>
                      {line}
                      {line === '' && <Typography /*className={classes.emptyLineHelper}*/>&nbsp;</Typography>}
                    </Typography>
                  ))}
                </div>
              )}

              {editReply && (
                <TextField
                  style={{ padding: 'none' }}
                  disabled={!replying}
                  // hidden={!editReply}
                  variant="outlined"
                  id="review-input"
                  value={review.reply}
                  multiline
                  rows="4"
                  className={classes.reviewResponse}
                  error={invalidReply}
                  helperText={invalidReply ? 'Please enter a response' : ''}
                  //value={description}
                  inputProps={{ maxLength: 500 }}
                  onChange={(e) => {
                    setInvalidReply(false);
                    setReplyText(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            {(!owner || !isAdmin) && <Typography hidden={owner || isAdmin}>&nbsp;</Typography>}

            <Typography
              hidden={!replying}
              style={replyText.length === 500 ? { color: 'red' } : { color: 'inherit' }}
              align="right"
              variant="caption"
              className={replyText.length === 500 ? classes.errorTextLength : ''}
            >
              {500 - replyText.length} Characters Remaining
            </Typography>
          </div>
          <div className={classes.flexEditContainer}>
            <div className={classes.rightSide}>
              {/*If there is an existing reply we render a pencil and garbage can*/}

              {!((!owner && !isAdmin) || replying || deleteReplyConfirm) && (
                <>
                  <CreateIcon
                    className={classes.edit}
                    size="medium"
                    // hidden={(!owner && !isAdmin) || replying || deleteReplyConfirm}
                    onClick={() => {
                      setEditReply(true);
                      setReplying(true);
                    }}
                  />

                  <DeleteIcon
                    //style={{ color: "red" }}
                    className={classes.edit}
                    size="medium"
                    // hidden={(!owner && !isAdmin) || replying || deleteReplyConfirm}
                    onClick={() => {
                      setDeleteReplyConfirm(true);
                    }}
                  />
                </>
              )}

              {deleteReplyConfirm && (
                <Typography className={classes.DeleteConfirm}>
                  {' '}
                  Are you certain you want to delete your response?{' '}
                </Typography>
              )}

              {deleteReplyError && (
                <div style={{ display: 'flex' }}>
                  <Typography style={{ color: 'red' }}> There was an error deleting your post </Typography>
                </div>
              )}
              {console.log('deleteReplyConfirm', deleteReplyConfirm)}
              {deleteReplyConfirm && (
                <>
                  <CheckIcon
                    style={{ color: 'green' }}
                    className={classes.edit}
                    // hidden={deleteReplyConfirm ? false : true}
                    size="medium"
                    onClick={() => {
                      setSaving(true);
                      //updateReview
                      //setReply("");
                      updateReview(review.reviewID, { description, rating, title, reply: '' })
                        .then((res) => {
                          setSaving(false);
                          if (res.status === 200) {
                            setReplying(false);
                            setDeleteReplyConfirm(false);
                            setEditReply(false);
                            editMode();
                          } else {
                            //setSaveError(true);
                            setDeleteReplyError(true);
                          }
                        })
                        .catch((err) => {
                          setSaving(false);
                        });
                    }}
                  />
                  <ClearIcon
                    className={classes.edit}
                    style={{ color: 'red' }}
                    // hidden={deleteReplyConfirm ? false : true}
                    size="medium"
                    onClick={() => {
                      setReplying(false);
                      setDeleteReplyConfirm(false);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewItem;
