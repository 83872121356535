import React from 'react';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      // fontSize: '60%',
      // Prevent wrapping
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  cardTextTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '95%',
    },
  },
  rating: {
    display: 'flex',
  },
  stars: {
    marginRight: theme.spacing(1),
  },
}));

export default function RatingStars({ avgRating, numOfReviews }) {
  const classes = useStyles();

  return (
    <div className={classes.rating}>
      <Rating
        name="read-only"
        value={avgRating ? Number(avgRating) : 0}
        precision={0.5}
        size="small"
        className={classes.stars}
        readOnly
      />
      <Typography variant="caption" className={classes.cardText}>
        {`${avgRating ? Number(avgRating.toFixed(1)) : 0} (${numOfReviews ? numOfReviews : 0})`}
      </Typography>
    </div>
  );
}
