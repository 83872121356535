import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 96,
    },
    largeToolbar: {
      minHeight: 96 + 60,
    },
  },
  breakpoints: {
    values: {
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      separator: {
        margin: 0,
      },
    },
  },
  screen: {
    maxWidth: 1400, //1245
    drawer: {
      width: 250,
    },
    aboutContent: {
      width: 420,
    },
  },
  palette: {
    common: {
      darkGrey: '#3c3f3d',
      darkerGrey: '#0c0f0d',
    },
    primary: {
      light: '#4e652e',
      main: '#4e652e',
      dark: '#4e652e',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#9ea137',
      main: '#9ea137',
      dark: '#9ea137',
      contrastText: '#ffffff',
    },
    tertiary: '#585858',
    sdawWarning: '#ff5252', //Pale Red
  },
  typography: {
    fontFamily: `"Lato", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});
