import React, { useState, Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, Tooltip, Modal, Backdrop, Fade } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import api from '../utils/api';
import { STATUS, ROLES } from '../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import TermsConditionsPopup from '../components/TermsConditionsPopup';
import SignUpThankYou from '../components/SignUpThankYou';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formNote: {},
  submit: {
    margin: theme.spacing(3, 1, 2, 1),
  },
  signInLink: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  terms: {
    marginTop: theme.spacing(2),
  },
  copyright: {
    marginBottom: theme.spacing(10),
  },
}));

const createUser = async (data, role) => {
  return api
    .createUser(data, role)
    .then((res) => res)
    .catch((error) => {
      if (error.message[0].includes('ERROR: duplicate key value violates unique constraint')) {
        throw STATUS.DUPLICATE;
      } else throw STATUS.ERROR;
    });
};

export default function UserSignUpView(props) {
  const { history, changeNavBar } = props;

  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMask, setPasswordMask] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [saving, setSaving] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);

  //const [loginError, setLoginError] = useState(false);
  const [ShowVerifyModal, setShowVerifyModal] = useState(false);

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  };

  const validatePassword = () => {
    //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
    const containsUpperCase = password.match(/[A-Z]/);
    const containsLowerCase = password.match(/[a-z]/);
    const containsDigit = password.match(/\d/);
    const containsSpecial = password.match(/[^a-zA-Z\d]/);

    return (
      password.length >= 8 &&
      password.length <= 50 &&
      containsDigit &&
      containsSpecial &&
      containsUpperCase &&
      containsLowerCase
    );
  };

  const validate = () => {
    let valid =
      firstName !== '' &&
      lastName !== '' &&
      validateEmail() &&
      email === emailConfirm &&
      validatePassword() &&
      password === passwordConfirm;

    return valid;
  };

  /*
  const login = async (email, password) => {
    return api
      .login(email.toLowerCase(), password)
      .then((res) => {
        setLoginError(false);
        cookies.setJWT(res.data.token);
        cookies.setUserID(res.data.userID);
        // setJWT(cookies.getJWT());
        // setUserId(cookies.getUserID());
        api
          .getUser(cookies.getUserID())
          .then((res) => {
            setRole(res._embedded.role.name);
            // if (res._embedded.role.name === ROLES.ROLE_USER) {
            // history.goBack();
            // }
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  };
*/

  const register = async () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      username: email.toLowerCase(),
      tcAccepted: true,
    };

    setSaving(true);

    createUser(data, ROLES.ROLE_USER)
      .then(async (res) => {
        try {
          /*
          apostwait login(data.username, data.password);
          setSaving(false);
          changeNavBar();
          history.push("/");
          window.location.reload(false);
        */
          setShowVerifyModal(true);
        } catch (error) {
          throw STATUS.ERROR;
        }
      })
      .catch((error) => {
        setSaving(false);
        if (error === STATUS.DUPLICATE) {
          setUserExists(true);
        } else if (error === STATUS.ERROR) {
          //setLoginError(true);
        } else setSignUpError(true);
      });
  };
  return (
    <div className={classes.root}>
      <Helmet>
        <title>S7DAW | Sign Up</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Sign up to review dispensaries, products and stay in the know for all things s7daw.com"
        />
      </Helmet>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              error={submitted && firstName === ''}
              helperText={submitted && firstName === '' ? 'Please enter a first name' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              error={submitted && lastName === ''}
              helperText={submitted && lastName === '' ? 'Please enter a last name' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                if (userExists) setUserExists(false);
                setEmail(event.target.value.trim());
              }}
              error={submitted && (!validateEmail() || userExists)}
              helperText={
                submitted && !validateEmail()
                  ? 'Enter a valid email address'
                  : submitted && userExists
                  ? 'A user with this email already exists'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="confirm-email"
              label="Confirm Email Address"
              name="confirmEmail"
              autoComplete="email"
              value={emailConfirm}
              onChange={(event) => {
                setEmailConfirm(event.target.value.trim());
              }}
              error={submitted && email !== emailConfirm}
              helperText={submitted && email !== emailConfirm ? 'Emails do not match' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type={passwordMask ? 'password' : 'text'}
              id="password"
              // autoComplete="current-password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              error={submitted && !validatePassword()}
              helperText={submitted && !validatePassword() ? 'Password does not match requirements' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordMask && (
                      <Tooltip title="Show password" placement="bottom" className={classes.endAdornment}>
                        <VisibilityIcon onClick={() => setPasswordMask(!passwordMask)} />
                      </Tooltip>
                    )}
                    {!passwordMask && (
                      <Tooltip title="Hide password" placement="bottom">
                        <VisibilityOffIcon
                          onClick={() => setPasswordMask(!passwordMask)}
                          className={classes.endAdornment}
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirm-password"
              // autoComplete="current-password"
              value={passwordConfirm}
              onChange={(event) => {
                setPasswordConfirm(event.target.value);
              }}
              error={submitted && password !== passwordConfirm}
              helperText={submitted && password !== passwordConfirm ? 'Passwords do not match' : ''}
            />
            <Typography variant="caption" className={classes.formNote}>
              Password Requirements: Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          <Grid item xs={12} className={classes.terms}>
            <TermsConditionsPopup
              termsAccepted={termsAccepted}
              setTermsAccepted={(val) => setTermsAccepted(val)}
            ></TermsConditionsPopup>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              event.preventDefault();
              setSubmitted(true);
              if (validate()) {
                register();
              }
            }}
            disabled={!termsAccepted}
          >
            {!saving && 'Sign Up'}
            {saving && <CircularProgress size={24} color="inherit" />}
          </Button>
          <Grid container className={classes.signInLink}>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={5} className={classes.copyright}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" to="/">
            SDAW
          </Link>{' '}
          2020.
        </Typography>
      </Box>
      <Modal
        className={classes.modal}
        open={signUpError}
        onClose={() => {
          setSignUpError(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={signUpError}>
          <div className={classes.modalPaper}>
            <Fragment>
              <Typography className={classes.statusMessage}>
                There was an issue while saving your data. Please try again.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSignUpError(false)}
                className={classes.statusAcceptButton}
              >
                Ok
              </Button>
            </Fragment>
          </div>
        </Fade>
      </Modal>
      {/*  <Modal
        className={classes.modal}
        open={loginError}
        onClose={() => {
          setSignUpError(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={loginError}>
          <div className={classes.modalPaper}>
            <Fragment>
              <Typography className={classes.statusMessage}>
                Please login using your newly created account.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  changeNavBar();
                  history.push("/");
                }}
                className={classes.statusAcceptButton}
              >
                Ok
              </Button>
            </Fragment>
          </div>
        </Fade>
      </Modal>  */}

      <Modal
        className={classes.modal}
        open={ShowVerifyModal}
        onClick={() => {
          setShowVerifyModal(false);
          changeNavBar();
          history.push('/');
          window.location.reload(false);
        }}
        //closeAfterTransition
        // BackdropComponent={Backdrop}
        //BackdropProps={{
        //  timeout: 500,
        //}}
      >
        <Fade in={ShowVerifyModal}>
          <SignUpThankYou></SignUpThankYou>
        </Fade>
      </Modal>
    </div>
  );
}
