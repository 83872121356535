import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    marginTop: theme.spacing(10)
  }
}));

function LoadingError(props) {
  const classes = useStyles();

  const { onClick, browse } = props;

  return (
    <div className={classes.root}>
      <Typography>
        There was an issue loading this resource. It may not exist or the server
        may be down at this time. Please try again later
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        className={classes.button}
      >
        {`Browse all ${browse}`}
      </Button>
    </div>
  );
}

export default LoadingError;
