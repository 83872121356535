import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  formControl: {
    width: "100%"
  }
});

function DropDownSelect(props) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <Select
        value={props.value}
        onChange={event => props.onChange(event.target.value)}
      >
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropDownSelect;
