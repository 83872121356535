import { createContext } from "react";
// import { SCREEN_STATES } from "./utils/constants";

const PreviewContext = createContext({
  state: {
    storeId: "",
    query: {},
    productId: "",
    dealId: "",
    screen: "",
  },
  changeScreen: () => {},
  // setStore: () => {},
  // setProduct: () => {},
  // setDeal: () => {},
});

export const PreviewProvider = PreviewContext.Provider;
export const Previewconsumer = PreviewContext.Consumer;

export default PreviewContext;
