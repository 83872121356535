/* 
TODO - currenetly asked everyone if they are 21 even if they are in a province where the legal age is 18 or 19
Need to make a cleaner form asking for province first then age
*/

import React, { useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import cookies from '../cookies';
// import useClientLocation from '../hooks/useClientLocation';
// import { useStateValue } from '../state';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  confirmationCard: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  cardContentRoot: {
    minWidth: 336,
  },
  logoAndTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  question: {
    margin: theme.spacing(4),
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
  },
  button: {
    flexGrow: 1,
    margin: theme.spacing(2),
    fontWeight: 'bold',
    boxShadow: '0px 0px',
  },
  logoHeader: {
    [theme.breakpoints.down('sm')]: {
      width: 180,
    },
  },
}));

function AgeCheck(props) {
  const classes = useStyles();
  const { confirm } = props;

  const [region, setRegion] = useState(null); //eslint-disable-line
  const [rejected, setRejected] = useState(false);

  /* Using closest location is based off of existing store locatoins - meaning its possible to end up in a neighboring province
 will need to refactor or add different method just for user location
 - or just add a return for the users location ... but how do I get the province? use the constants?
 Best to leave as manual setting for now 
  */

  // const [{ location }] = useStateValue();
  // // TODO - region has been disabled.  Need new method for localized age verification
  // const { closestLocation, updateClostestLocation, loadingNearestLoc } = useClientLocation({ locationList: [] });

  // console.log('age closest loc', closestLocation);
  // console.log('staete loc', location);
  // useEffect(() => {
  //   if (closestLocation) {
  //     setRegion(closestLocation.province);
  //   }
  // }, [closestLocation]);

  const handleChange = (event) => {
    setRegion(event.target.value);
  };

  //=========== Render logic
  const PROVINCES = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Saskatchewan',
    'Yukon',
    'Quebec',
  ];

  const eighteen = PROVINCES.filter((province) => province === 'Alberta');
  const nineteen = PROVINCES.filter((province) => province !== 'Alberta' && province !== 'Quebec');
  const twentyone = PROVINCES.filter((province) => province === 'Quebec');

  let age = '21';
  if (eighteen.includes(region)) age = '18';
  if (nineteen.includes(region)) age = '19';
  if (twentyone.includes(region)) age = '21';

  return (
    <div className={classes.root}>
      <Card className={classes.confirmationCard} variant="outlined">
        <CardContent className={classes.cardContentRoot}>
          <div className={classes.logoAndTitle}>
            <img
              src={require(`../assets/logos/logoHeader.svg`)}
              alt={`sdaw-main-logo`}
              className={classes.logoHeader}
            />
          </div>
          <Typography variant="h6" align="center" className={classes.title}>
            Age Verification
          </Typography>
          <FormControl className={classes.provFormControl} style={{ width: '100%', marginTop: 8 }}>
            <InputLabel id="regionProvince">What province are you in?</InputLabel>
            <Select labelId="regionProvince" id="regionSelect" value={region} onChange={handleChange}>
              {PROVINCES.map((province, i) => (
                <MenuItem key={`ageProv-${i}`} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* 
          <Button onClick={updateClostestLocation}>
            <Typography>Use My Current Location</Typography>
          </Button> */}

          {!rejected && region && (
            <>
              <Typography className={classes.question} variant="h6" align="center">
                {`Are you over ${age} years old?`}
              </Typography>
              <div className={classes.buttonsContainer}>
                <Button id="noBtn" className={classes.button} variant="contained" onClick={() => setRejected(true)}>
                  No
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    cookies.setAgeVerified('true');
                    confirm();
                  }}
                >
                  Yes
                </Button>
              </div>
            </>
          )}

          {rejected && (
            <Typography style={{ fontWeight: 'bold', marginTop: 40 }}>
              {`You must be at least ${age} years of age to enter this website.`}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default AgeCheck;
