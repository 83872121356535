import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { PRODUCT_FLAGS } from '../utils/constants';
import { makeImageKitURL } from '../utils/helpers';
import productPlaceholder from '../utils/assets/placeholders/productPlaceholder.webp';
import Zoom from './Zoom';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    '&:hover': {
      cursor: 'crosshair',
    },
  },
  productBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  thumbnailList: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  imageThumbnail: {
    width: 57,
    height: 57,
    margin: theme.spacing(0, 0.5, 0, 0),
    objectFit: 'contain',
    '&:hover': {
      cursor: 'pointer',
    },
    borderRadius: '5px',
  },
}));

export default function ProductImages(props) {
  const { name, images, flags } = props;

  const classes = useStyles();

  const [selected, setSelected] = useState('');
  const [badgeDisplay, setBadgeDisplay] = useState(true);

  const handleMouseHover = () => {
    setBadgeDisplay(!badgeDisplay);
  };

  useEffect(() => {
    setSelected(0);
  }, []);

  const primaryImage = images[selected] ? images[selected] : productPlaceholder;
  const PLACEHOLDER = 'PRODUCT'; //Uses product placeholder img

  return (
    <div style={{ position: 'relative' }}>
      <div
        onMouseEnter={() => handleMouseHover()}
        onMouseLeave={() => handleMouseHover()}
        className={classes.imageContainer}
      >
        <Zoom
          //PRIMARY / SELECTED IMAGE
          key={selected}
          img={
            primaryImage?.includes('sdaw')
              ? makeImageKitURL(primaryImage, { width: '1024' }, PLACEHOLDER)
              : primaryImage
          } //Only pull a 1024 wide image (max width on desktop)
          zoomScale={3}
          height={300}
          width={300}
        />
      </div>
      {flags && flags.includes(PRODUCT_FLAGS.LIMITED) && (
        <img
          src={require(`../assets/product-banners/productdetails_limited.png`)}
          alt={name}
          className={classes.productBadge}
          style={{ display: !badgeDisplay ? 'none' : '' }}
        ></img>
      )}
      {flags && flags.includes(PRODUCT_FLAGS.NEW) && (
        <img
          src={require(`../assets/product-banners/productdetails_new.png`)}
          alt={name}
          className={classes.productBadge}
          style={{ display: !badgeDisplay ? 'none' : '' }}
        ></img>
      )}
      {flags && flags.includes(PRODUCT_FLAGS.OUTOFSTOCK) && (
        <img
          src={require(`../assets/product-banners/productdetails_soldout.png`)}
          alt={name}
          className={classes.productBadge}
          style={{ display: !badgeDisplay ? 'none' : '' }}
        ></img>
      )}
      {flags && flags.includes(PRODUCT_FLAGS.SPECIAL) && (
        <img
          src={require(`../assets/product-banners/productdetails_special.png`)}
          alt={name}
          className={classes.productBadge}
          style={{ display: !badgeDisplay ? 'none' : '' }}
        ></img>
      )}
      {images.length > 1 && (
        /* Image options (multiple) */
        <div className={classes.thumbnailList}>
          {images.map((image, index) => (
            <img
              key={`prodImg-${index}`}
              className={classes.imageThumbnail}
              src={image?.includes('sdaw') ? makeImageKitURL(image, { width: '90' }, PLACEHOLDER) : image}
              alt={name}
              onClick={() => {
                setSelected(index);
              }}
              style={{
                border: selected === index ? '1px solid #a6a6a6' : '1px solid #ebebeb',
              }}
            ></img>
          ))}
        </div>
      )}
    </div>
  );
}
