const productDataSubSet = [
  {
    id: 0,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Alien OG',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    categories: {
      1: 'Category 1',
      2: 'Sub-Category',
    },
    timeOfUse: 'Night',
    genetics: 'Indica',
    thc: '24%',
    cbd: '0.01%',
    flavours: ['Fruity, Earthy, Sweet'],
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '1.00',
      },
      {
        quantity: '3.5G',
        price: '22.00',
      },
      {
        quantity: '7G',
        price: '33.00',
      },
      {
        quantity: '14G',
        price: '44.00',
      },
      {
        quantity: '1OZ',
        price: '115.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 1,
    storeId: '1',
    storeName: 'Store Name',
    productName: "God's Green Crack",
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4,
      count: 2,
    },
    categories: {
      1: 'Category 2',
      2: 'Sub-Category',
    },
    timeOfUse: 'Day',
    genetics: 'Indica',
    thc: '14%',
    cbd: '0.06%',
    flavours: ['Fruity, Earthy, Sweet'],
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis'],
    productPricing: [
      {
        quantity: '1G',
        price: '7.00',
      },
      {
        quantity: '3.5G',
        price: '40.00',
      },
      {
        quantity: '7G',
        price: '99.00',
      },
      {
        quantity: '14G',
        price: '150.00',
      },
      {
        quantity: '1OZ',
        price: '211.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 2,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Organic Super Lemon Haze',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 0,
      count: 0,
    },
    categories: {
      1: 'Category 3',
      2: 'Sub-Category',
    },
    thc: '24%',
    cbd: '0.01%',
    flavours: ['Fruity, Earthy, Sweet'],
    effects: ['sedated', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    timeOfUse: 'Night',
    genetics: 'Sativa',
    productPricing: [
      {
        quantity: '1G',
        price: '2.00',
      },
      {
        quantity: '3.5G',
        price: '3.00',
      },
      {
        quantity: '7G',
        price: '4.00',
      },
      {
        quantity: '14G',
        price: '5.00',
      },
      {
        quantity: '1OZ',
        price: '6.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: ['NEW'],
  },
  {
    id: 3,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Purple Space Cookies',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 0,
      count: 0,
    },
    categories: {
      1: 'Category 1',
      2: 'Sub-Category',
    },
    timeOfUse: 'Day',
    genetics: 'Indica',
    thc: '34%',
    cbd: '0.05%',
    effects: ['sleep'],
    uses: ['pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '2.00',
      },
      {
        quantity: '3.5G',
        price: '3.00',
      },
      {
        quantity: '7G',
        price: '4.00',
      },
      {
        quantity: '14G',
        price: '5.00',
      },
      {
        quantity: '1OZ',
        price: '6.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 4,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Citrique',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    categories: {
      1: 'Category 1',
      2: 'Sub-Category',
    },
    timeOfUse: 'Day',
    thc: '24%',
    cbd: '0.01%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    genetics: 'Sativa',
    productPricing: [
      {
        quantity: '1G',
        price: '2.00',
      },
      {
        quantity: '3.5G',
        price: '3.00',
      },
      {
        quantity: '7G',
        price: '4.00',
      },
      {
        quantity: '14G',
        price: '5.00',
      },
      {
        quantity: '1OZ',
        price: '6.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 5,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Black Tuna',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    categories: {
      1: 'Category 1',
      2: 'Sub-Category',
    },
    timeOfUse: 'Night',
    genetics: 'Indica',
    thc: '1%',
    cbd: '15%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '7.00',
      },
      {
        quantity: '3.5G',
        price: '8.00',
      },
      {
        quantity: '7G',
        price: '9.00',
      },
      {
        quantity: '14G',
        price: '10.00',
      },
      {
        quantity: '1OZ',
        price: '11.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: ['NEW'],
  },
  {
    id: 6,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Hawaiian Snow',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    categories: {
      1: 'Category 2',
      2: 'Sub-Category 2',
    },
    timeOfUse: 'Day',
    genetics: 'Sativa',
    thc: '1%',
    cbd: '15%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '1.00',
      },
      {
        quantity: '3.5G',
        price: '2.00',
      },
      {
        quantity: '7G',
        price: '3.00',
      },
      {
        quantity: '14G',
        price: '4.00',
      },
      {
        quantity: '1OZ',
        price: '5.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 7,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Organic White Death',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 3.8,
      count: 34,
    },
    categories: {
      1: 'Category 2',
      2: 'Sub-Category',
    },
    timeOfUse: 'Night',
    genetics: 'Hybrid',
    thc: '15%',
    cbd: '15%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '1.00',
      },
      {
        quantity: '3.5G',
        price: '2.00',
      },
      {
        quantity: '7G',
        price: '3.00',
      },
      {
        quantity: '14G',
        price: '4.00',
      },
      {
        quantity: '1OZ',
        price: '5.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: ['NEW'],
  },
  {
    id: 8,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Organic Pink Tuna',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.1,
      count: 15,
    },
    categories: {
      1: 'Category 2',
      2: 'Sub-Category',
      3: 'Sub-Sub-Category',
    },
    timeOfUse: 'Night',
    genetics: 'Sativa',
    thc: '1%',
    cbd: '15%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '17.00',
      },
      {
        quantity: '3.5G',
        price: '18.00',
      },
      {
        quantity: '7G',
        price: '19.00',
      },
      {
        quantity: '14G',
        price: '110.00',
      },
      {
        quantity: '1OZ',
        price: '111.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
  {
    id: 9,
    storeId: '1',
    storeName: 'Store Name',
    productName: 'Pineapple Express',
    productDescription:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    categories: {
      1: 'Category 2',
      2: 'Sub-Category',
    },
    timeOfUse: 'Day',
    genetics: 'Hybrid',
    thc: '1%',
    cbd: '15%',
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain'],
    productPricing: [
      {
        quantity: '1G',
        price: '7.00',
      },
      {
        quantity: '3.5G',
        price: '8.00',
      },
      {
        quantity: '7G',
        price: '9.00',
      },
      {
        quantity: '14G',
        price: '10.00',
      },
      {
        quantity: '1OZ',
        price: '11.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: ['NEW'],
  },
];

const productData = [...productDataSubSet, ...productDataSubSet, ...productDataSubSet];

const storeDataSubSet = [
  {
    id: 1,
    name: 'Store Name',
    type: 'Delivery',
    image: 'https://dummyimage.com/300x300/ff655e/ffffff',
    reviews: {
      rating: 3.45,
      count: 50,
    },
    aboutUs:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    location: {
      street: '1 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M6E 1W7',
    },
    phoneNumber: '555-555-5555',
    email: 'store@store.com',
    hours: [
      {
        day: 'Monday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Tuesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Wednesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Thursday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Friday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Saturday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Sunday',
        open: '10:00 am',
        close: '10:00 pm',
      },
    ],
  },
  {
    id: 2,
    name: 'Store Name 2',
    type: 'Delivery',
    image: 'https://dummyimage.com/300x300/65ff5e/ffffff',
    reviews: {
      rating: 4.5,
      count: 23,
    },
    aboutUs:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M6E 1W7',
    },
    phoneNumber: '555-555-5555',
    email: 'store2@store.com',
    hours: [
      {
        day: 'Monday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Tuesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Wednesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Thursday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Friday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Saturday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Sunday',
        open: '10:00 am',
        close: '10:00 pm',
      },
    ],
  },
];

const storeData = [
  {
    id: 0,
    name: 'Store START',
    type: 'Storefront',
    image: 'https://dummyimage.com/300x300/65ff5e/ffffff',
    reviews: {
      rating: 4.9,
      count: 23,
    },
    aboutUs:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M5E 1W7',
    },
    phoneNumber: '555-555-5555',
    email: 'store2@store.com',
    hours: [
      {
        day: 'Monday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Tuesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Wednesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Thursday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Friday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Saturday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Sunday',
        open: '10:00 am',
        close: '10:00 pm',
      },
    ],
  },
  ...storeDataSubSet,
  ...storeDataSubSet,
  ...storeDataSubSet,
  ...storeDataSubSet,
  ...storeDataSubSet,
  ...storeDataSubSet,
  {
    id: 13,
    name: 'Store END',
    type: 'Store Type',
    image: 'https://dummyimage.com/300x300/65ff5e/ffffff',
    reviews: {
      rating: 4.8,
      count: 23,
    },
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M5E 1W7',
    },
    aboutUs:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    phoneNumber: '555-555-5555',
    email: 'store2@store.com',
    hours: [
      {
        day: 'Monday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Tuesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Wednesday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Thursday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Friday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Saturday',
        open: '10:00 am',
        close: '10:00 pm',
      },
      {
        day: 'Sunday',
        open: '10:00 am',
        close: '10:00 pm',
      },
    ],
  },
];

const dealDataSubSet = [
  {
    id: 0,
    dealName: 'BIG DISCOUNT',
    storeName: 'Store Name',
    storeId: 0,
    dealImage: 'https://dummyimage.com/600x400/bb655e/ffffff',
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M6E 1W7',
    },
    dealDescription:
      "It's a big discount off everything! HUGE! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    starts: '2019-12-01',
    ends: '2019-12-17',
  },
  {
    id: 1,
    dealName: '75% off select items',
    storeName: 'Store Name 2',
    storeId: 1,
    dealImage: 'https://dummyimage.com/600x400/65ff5e/ffffff',
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M6E 1W7',
    },
    dealDescription:
      '75% off flower and edibles. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    starts: '2019-12-10',
    ends: '2020-10-29',
  },
  {
    id: 2,
    dealName: '25% off ALL items',
    storeName: 'Store Name 2',
    storeId: 2,
    dealImage: 'https://dummyimage.com/600x400/655eff/ffffff',
    location: {
      street: '23 Yonge St.',
      city: 'Toronto',
      province: 'ON',
      postalCode: 'M6E 1W7',
    },
    dealDescription:
      '25% off all items in store, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    starts: '2021-01-01',
    ends: '2021-03-25',
  },
];

const dealData = [...dealDataSubSet];

const bannerData = [
  {
    name: 'giveaway',
    header: '',
    caption: '',
    image: 'giveawayHeader.jpg',
    link: '/stores',
  },
  {
    name: 'stores-near-you',
    header: 'FIND DISPENSARIES NEARBY.',
    caption: 'SEE STORES',
    image: 'homeHeader.webp',
    link: '/stores',
  },
  {
    name: 'deals-near-you',
    header: 'FIND DEALS NEAR YOU.',
    caption: 'SEE DEALS',
    image: 'homeHeader.webp',
    link: '/deals',
  },

  // {
  //   name: "deliveries-near-you",
  //   header: "VIEW DELIVERY SERVICES.",
  //   caption: "DELIVERIES",
  //   image: "homeHeader.jpg",
  //   link: "/deliveries"
  // }
];
const storeFrontBannerData = [
  {
    name: 'deals-near-you',
    header: 'SHOP MARIJUANA STORES NEARBY.',
    caption: 'SEE DEALS',
    image: 'primaryStore.webp',
    //link: "/deals",
  },
  {
    name: 'stores-near-you',
    header: '',
    caption: 'SEE STORES',
    image: 'secondaryStore.webp',
    //link: "/stores",
  },
];

const deliveryBannerData = [
  {
    name: 'deals-near-you',
    header: 'FIND DISPENSARIES THAT DELIVER NEARBY.',
    caption: 'SEE DEALS',
    image: 'primaryDelivery.webp',
    //link: "/deals",
  },
  {
    name: 'stores-near-you',
    header: 'DELIVERING HAPPINESS.',
    caption: 'SEE STORES',
    image: 'secondaryDelivery.webp',
    //link: "/stores",
  },
];

const dealBannerData = [
  {
    name: 'deals-near-you',
    header: 'GET THE BEST DEALS FROM LOCAL DISPENSARIES.',
    caption: 'SEE DEALS',
    image: 'primaryDeal.webp',
    //link: "/deals",
  },
  {
    name: 'stores-near-you',
    header: 'PAY LESS, GET MORE.',
    caption: 'SEE STORES',
    image: 'secondaryDeal.webp',
    //link: "/stores",
  },
];

const mailOutsBannerData = [
  {
    name: 'deals-near-you',
    header: 'VIEW TOP CANADIAN MARIJUANA MAIL ORDER OPTIONS',
    caption: 'SEE DEALS',
    image: 'mailOutsPrimary.webp',
    //link: "/deals",
  },
  {
    name: 'stores-near-you',
    header: 'GOOD THINGS COME TO THOSE WHO WAIT.',
    caption: 'SEE STORES',
    image: 'mailOutsSecondary.webp',
    //link: "/stores",
  },
];

const myStoreData = [
  {
    id: 0,
    name: 'OG',
    type: 'Storefront',
    created: new Date().setFullYear(new Date().getFullYear() - 1),
    address: {
      street: '55 Test St.',
      city: 'Toronto',
      province: 'Ontario',
      postalCode: 'L6P1P1',
    },
    published: false,
    menuItems: 1,
    reviews: 0,
    rating: 0,
    phone: '5555555555',
    website: 'www.google.com',
    email: 'email@email.com',
    contactFirstName: 'First',
    contactLastName: 'Last',
    avatar: 'https://dummyimage.com/300x300/000/fff',
    visits: 0,
    licenseType: null,
    licenseNumber: null,
    facebook: null,
    twitter: null,
    instagram: null,
    aboutUs:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget aliquam tellus. Suspendisse pretium egestas augue, a consectetur augue faucibus ultrices. Pellentesque in lectus suscipit, elementum augue in, malesuada augue. Aenean et consequat ligula. Nam at dapibus nisl. Quisque a malesuada lorem, id sodales mi. Curabitur ac risus sapien. Proin laoreet egestas nibh in molestie. Suspendisse vulputate vestibulum lectus sed tincidunt. Fusce sollicitudin mauris dolor, non pretium ipsum mollis ac. Morbi nec est a erat semper porta. Suspendisse potenti. Sed varius luctus risus eu condimentum.',
    announcement:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget aliquam tellus. Suspendisse pretium egestas augue, a consectetur augue faucibus ultrices. Pellentesque in lectus suscipit, elementum!!!',
    firstCustomerSpecial: '50% off on your first visit when you spend over $150!',
    minimumAge: 19,
    paymentOptions: ['Cash', 'Credit'],
    hours: {
      Monday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Tuesday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Wednesday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Thursday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Friday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Saturday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
      Sunday: {
        open: '10:00 am',
        close: '10:00 pm',
      },
    },
  },
];

const myProductDataSubSet = [
  {
    id: 0,
    created: new Date().setFullYear(new Date().getFullYear() - 1),
    storeId: 0,
    name: 'Alien OG',
    brand: 'Test Brand',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    categories: {
      1: 'Flower',
      2: 'Infused Flower',
    },
    genetics: 'Indica',
    thc: '24%',
    cbd: '0.01%',
    timeOfUse: 'Night',
    flavours: ['Fruity', 'Earthy', 'Sweet'],
    effects: ['Sedated', 'Happy', 'Sleepy'],
    uses: ['Arthritis', 'Pain', 'Stress'],
    flags: ['New'],
    tags: ['thc', 'test tag', 'low-cbd', 'alien', 'flower'],
    productPricing: [
      {
        quantity: '1G',
        price: '1.00',
      },
      {
        quantity: '3.5G',
        price: '22.00',
      },
      {
        quantity: '7G',
        price: '33.00',
      },
      {
        quantity: '14G',
        price: '44.00',
      },
      {
        quantity: '1OZ',
        price: '115.00',
      },
    ],
    published: false,
    rating: 0,
    reviews: 0,
  },
  {
    id: 1,
    storeId: 0,
    name: 'Citrus',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    rating: 0,
    reviews: 0,
    categories: {
      1: 'Flower',
      2: 'Pre Rolls',
      3: 'Infused Pre Rolls',
    },
    tags: ['thc', 'citrus', 'flower'],
    brand: 'Test Brand 2',
    published: false,
    created: new Date().setFullYear(new Date().getFullYear() - 1),
    timeOfUse: 'Night',
    genetics: 'Indica',
    thc: '24%',
    cbd: '0.01%',
    flavours: ['Fruity', 'Earthy', 'Sweet'],
    effects: ['sedated', 'happy', 'sleep'],
    uses: ['arthritis', 'pain', 'stress'],
    productPricing: [
      {
        quantity: '1G',
        price: '1.00',
      },
      {
        quantity: '3.5G',
        price: '22.00',
      },
      {
        quantity: '7G',
        price: '33.00',
      },
      {
        quantity: '14G',
        price: '44.00',
      },
      {
        quantity: '1OZ',
        price: '115.00',
      },
    ],
    image: 'https://dummyimage.com/300x300/5eb55e/ffffff',
    flags: [],
  },
];

const myProductData = [
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
  ...myProductDataSubSet,
];

const myDealData = [
  {
    id: 0,
    name: 'BIG DISCOUNT',
    description:
      "It's a big discount off everything! HUGE! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    storeId: 0,
    published: false,
    image: 'https://dummyimage.com/600x400/bb655e/ffffff',
    starts: '2019-12-01',
    ends: '2019-12-17',
    discount: {
      type: 'Percent (%) Off',
      amount: '15',
    },
    appliesTo: 'Storewide',
    spendAtleast: '100',
    buyAtleastGrams: '',
    buyAtleastQuantity: '',
  },
  {
    id: 1,
    name: 'Bigger Discount',
    description:
      "It's a big discount off everything! HUGE! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    storeId: 0,
    published: false,
    image: 'https://dummyimage.com/600x400/bb655e/ffffff',
    starts: '2020-01-10',
    ends: '2020-01-25',
    discount: {
      type: 'Percent (%) Off',
      amount: '25',
    },
    appliesTo: 'Indica',
    spendAtleast: '',
    buyAtleastGrams: 14,
    buyAtleastQuantity: 1,
  },
  {
    id: 2,
    name: 'Biggest Discount',
    description:
      "It's a big discount off everything! HUGE! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    storeId: 0,
    published: false,
    image: 'https://dummyimage.com/600x400/bb655e/ffffff',
    starts: '2020-01-10',
    ends: '2020-01-25',
    discount: {
      type: 'Percent (%) Off',
      amount: '35',
    },
    appliesTo: 'Concentrates',
    spendAtleast: '',
    buyAtleastGrams: 5,
    buyAtleastQuantity: 1,
  },
];

const featuredStores = {
  Ontario: {
    Toronto: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        rank: 3,
        storeId: 3,
        storeName: 'The Store',
      },
      {
        rank: 4,
        storeId: 4,
        storeName: 'The Other Store',
      },
      {
        rank: 5,
        storeId: 5,
        storeName: 'The Other Other Store',
      },
    ],
    Ajax: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
    Brampton: [
      {
        rank: 4,
        storeId: 4,
        storeName: 'The Other Store',
      },
      {
        rank: 5,
        storeId: 5,
        storeName: 'The Other Other Store',
      },
    ],
    Hamilton: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        rank: 3,
        storeId: 3,
        storeName: 'The Store',
      },
      {
        rank: 4,
        storeId: 4,
        storeName: 'The Other Store',
      },
      {
        rank: 5,
        storeId: 5,
        storeName: 'The Other Other Store',
      },
    ],
  },
  'British Columbia': {
    Vancouver: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
  },
  Quebec: {
    Montreal: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
  },
  Alberta: {
    Calgary: [
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        rank: 3,
        storeId: 3,
        storeName: 'The Store',
      },
    ],
    Edmonton: [],
  },
  Manitoba: {
    Winnipeg: [],
  },
};

const featuredDeliveries = {
  Ontario: {
    Toronto: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
    Ajax: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
    ],
    Brampton: [],
    Hamilton: [],
  },
  'British Columbia': {
    Vancouver: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
    ],
  },
  Quebec: {
    Montreal: [
      {
        rank: 1,
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
  },
  Alberta: {
    Calgary: [
      {
        rank: 2,
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        rank: 3,
        storeId: 3,
        storeName: 'The Store',
      },
    ],
    Edmonton: [],
  },
  Manitoba: {
    Winnipeg: [],
  },
};

const storesByLocation = {
  Ontario: {
    Toronto: [
      {
        storeId: 1,
        storeName: 'Weed Store',
        storeType: 'Delivery',
      },
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
      {
        storeId: 3,
        storeName: 'The Store',
        storeType: 'Delivery',
      },
      {
        storeId: 4,
        storeName: 'The Other Store',
        storeType: 'Delivery',
      },
      {
        storeId: 5,
        storeName: 'The Other Other Store',
        storeType: 'Delivery',
      },
    ],
    Ajax: [
      {
        storeId: 1,
        storeName: 'Weed Store',
        storeType: 'Delivery',
      },
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
    ],
    Brampton: [
      {
        storeId: 4,
        storeName: 'The Other Store',
        storeType: 'Delivery',
      },
      {
        storeId: 5,
        storeName: 'The Other Other Store',
        storeType: 'Delivery',
      },
    ],
    Hamilton: [
      {
        storeId: 1,
        storeName: 'Weed Store',
        storeType: 'Delivery',
      },
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
      {
        storeId: 3,
        storeName: 'The Store',
        storeType: 'Delivery',
      },
      {
        storeId: 4,
        storeName: 'The Other Store',
        storeType: 'Delivery',
      },
      {
        storeId: 5,
        storeName: 'The Other Other Store',
        storeType: 'Delivery',
      },
    ],
  },
  'British Columbia': {
    Vancouver: [
      {
        storeId: 1,
        storeName: 'Weed Store',
        storeType: 'Delivery',
      },
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
    ],
  },
  Quebec: {
    Montreal: [
      {
        storeId: 1,
        storeName: 'Weed Store',
        storeType: 'Delivery',
      },
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
    ],
  },
  Alberta: {
    Calgary: [
      {
        storeId: 2,
        storeName: 'Bud Lynx',
        storeType: 'Delivery',
      },
      {
        storeId: 3,
        storeName: 'The Store',
        storeType: 'Delivery',
      },
    ],
    Edmonton: [],
  },
  Manitoba: {
    Winnipeg: [],
  },
};

const featuredDeals = {
  Ontario: {
    Toronto: [
      {
        rank: 1,
        dealId: 1,
        dealName: 'BestDeal',
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        dealId: 2,
        dealName: 'BestestDeal',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
    Ajax: [
      {
        rank: 1,
        dealId: 3,
        dealName: 'BetterDeal',
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        rank: 2,
        dealId: 4,
        dealName: 'BestDeal2',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
  },
  'British Columbia': {
    Vancouver: [
      {
        rank: 1,
        dealId: 5,
        dealName: 'BC DEAL',
        storeId: 1,
        storeName: 'Weed Store',
      },
    ],
  },
  Quebec: {
    Montreal: [],
  },
  Alberta: {
    Calgary: [],
    Edmonton: [],
  },
  Manitoba: {
    Winnipeg: [],
  },
};

const dealsByLocation = {
  Ontario: {
    Toronto: [
      {
        dealId: 1,
        dealName: 'BestDeal',
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        dealId: 2,
        dealName: 'BestestDeal',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        dealId: 3,
        dealName: 'BestestDeal ever',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
    Ajax: [
      {
        dealId: 7,
        dealName: 'BetterDeal',
        storeId: 1,
        storeName: 'Weed Store',
      },
      {
        dealId: 4,
        dealName: 'BestDeal2',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        dealId: 5,
        dealName: 'BestDeal3',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
      {
        dealId: 6,
        dealName: 'BestDeal4',
        storeId: 2,
        storeName: 'Bud Lynx',
      },
    ],
  },
  'British Columbia': {
    Vancouver: [
      {
        dealId: 8,
        dealName: 'BC DEAL',
        storeId: 1,
        storeName: 'Weed Store',
      },
    ],
  },
  Quebec: {
    Montreal: [],
  },
  Alberta: {
    Calgary: [],
    Edmonton: [],
  },
  Manitoba: {
    Winnipeg: [],
  },
};

const brandsData = [
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    createDateTime: '2020-03-16T16:28:51.796336',
    lastMenuUpdate: '2020-04-17T21:42:35.026896',
    published: true,
    contactFirstName: 'ni',
    contactLastName: 'agra',
    storeType: 'Delivery',
    avgRating: 4.5,
    numOfReviews: '1',
    menuItems: 1,
    storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
    name: 'Niagra',
    address: {
      street: '',
      city: 'Niagara Falls',
      province: 'Ontario',
      postalCode: 'L6R1H1',
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
];

const singleBrandData = {
  hours: null,
  createDateTime: '2020-03-16T16:28:51.796336',
  displayMap: null,
  published: true,
  phone: '1092392323',
  website: '',
  facebook: '',
  twitter: '',
  instagram: '',
  aboutUs: 'fd',
  announcement: '',
  firstCustomerSpecial: '',
  minimumAge: null,
  storeType: 'Delivery',
  avgRating: 4.5,
  numOfReviews: '1',
  menuItems: 1,
  storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
  paymentOptions: ['Debit', 'Credit', 'Cash'],
  email: 'hdf@df.com',
  name: 'Niagra',
  address: {
    street: '',
    city: 'Niagara Falls',
    province: 'Ontario',
    postalCode: 'L6R1H1',
  },
  _links: {
    self: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810',
    },
    store: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
      templated: true,
    },
    locations: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
      templated: true,
    },
    owner: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
      templated: true,
    },
    deals: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
      templated: true,
    },
    allStoreRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
      templated: true,
    },
    products: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
      templated: true,
    },
    featuredStoreRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
      templated: true,
    },
    file: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
      templated: true,
    },
    featuredDeliveryRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
      templated: true,
    },
    allDeliveryRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
      templated: true,
    },
  },
};

const singleBrandDataWithHours = {
  hours: null,
  createDateTime: '2020-03-16T16:28:51.796336',
  displayMap: false,
  published: true,
  phone: '1092392323',
  website: '',
  facebook: '',
  twitter: '',
  instagram: '',
  aboutUs:
    'ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ABOUT US ',
  announcement: '',
  firstCustomerSpecial: '',
  minimumAge: null,
  storeType: 'Delivery',
  avgRating: 4.5,
  numOfReviews: '1',
  menuItems: 1,
  storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
  paymentOptions: ['Debit', 'Credit', 'Cash'],
  email: 'hdf@df.com',
  name: 'Niagra',
  address: {
    street: '',
    city: 'Niagara Falls',
    province: 'Ontario',
    postalCode: 'L6R1H1',
  },
  _links: {
    self: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810',
    },
    store: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
      templated: true,
    },
    locations: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
      templated: true,
    },
    owner: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
      templated: true,
    },
    deals: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
      templated: true,
    },
    allStoreRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
      templated: true,
    },
    products: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
      templated: true,
    },
    featuredStoreRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
      templated: true,
    },
    file: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
      templated: true,
    },
    featuredDeliveryRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
      templated: true,
    },
    allDeliveryRanks: {
      href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
      templated: true,
    },
  },
};

const brandProducts = [
  {
    latestRank: 0,
    flags: [],
    tags: [],
    name: 'latest 0',
    description:
      'test test test Cultivation test test test Cultivation test test test Cultivation test test test Cultivation test test test Cultivation test test test Cultivation test test test Cultivation test test test Cultivation ',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    latestRank: 1,
    flags: [],
    tags: [],
    name: 'latest 1',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    latestRank: 2,
    flags: [],
    tags: [],
    name: 'latest 2',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    latestRank: 3,
    flags: [],
    tags: [],
    name: 'latest 3',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    latestRank: 4,
    flags: [],
    tags: [],
    name: 'latest 4',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    popularRank: 0,
    flags: [],
    tags: [],
    name: 'popular 0',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    popularRank: 1,
    flags: [],
    tags: [],
    name: 'popular 1',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    popularRank: 2,
    flags: [],
    tags: [],
    name: 'popular 2',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    popularRank: 3,
    flags: [],
    tags: [],
    name: 'popular 3',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
  {
    flags: [],
    tags: [],
    name: 'test',
    description: 'test',
    updateDateTime: '2020-05-06T16:05:56.918054',
    rank: null,
    removeFromMenu: false,
    brand: '',
    // productPricingList: [
    //   {
    //     quantity: "1",
    //     price: 1.0,
    //   },
    // ],
    thc: 1,
    cbd: 1.0,
    categories: {
      category: 'Cultivation',
      subcategory: '',
      subsubcategory: '',
    },
    avgRating: 4.75,
    numOfReviews: '2',
    store: {
      name: 'Niagra',
      address: {
        street: '',
        city: 'Niagara Falls',
        province: 'Ontario',
        postalCode: 'L6R1H1',
      },
      createDateTime: '2020-03-16T16:28:51.796336',
      published: true,
      nationwide: null,
      contactFirstName: 'ni',
      contactLastName: 'agra',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-05-06T16:05:56.918054',
      menuItems: 1,
      storeImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/1561556297-file-4-1584376131136.jpeg',
      avgRating: 4.75,
      numOfReviews: '2',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/810/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
    genetics: 'Indica',
    timeOfUse: '',
    flavours: [],
    effects: [],
    uses: [],
    imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/marijuana-logo-1588781157195.jpg',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083',
      },
      product: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083{?projection}',
        templated: true,
      },
      store: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/store{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/reviews{?projection}',
        templated: true,
      },
      files: {
        href: 'https://devapi.s7daw.com:8080/api/v1/products/1083/files{?projection}',
        templated: true,
      },
    },
  },
];

const singleBrandProductProjection = {
  flags: ['New'],
  tags: [],
  name: 'Watermelon Blastt',
  description: '',
  updateDateTime: '2020-04-29T16:31:28.780941',
  rank: 0,
  removeFromMenu: false,
  brand: 'Buddha',
  productPricingList: [
    {
      quantity: '',
      price: 1.0,
    },
  ],
  thc: 12,
  cbd: 12.0,
  categories: {
    category: 'Concentrates',
    subcategory: '',
    subsubcategory: '',
  },
  avgRating: 0.0,
  numOfReviews: '0',
  store: {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  genetics: '',
  timeOfUse: '',
  flavours: [],
  effects: [],
  uses: [],
  imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/41430030_stiiizy_pic_deal_-1585061508726.jpg',
  _links: {
    self: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857',
    },
    product: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857{?projection}',
      templated: true,
    },
    store: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/store{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/reviews{?projection}',
      templated: true,
    },
    files: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/files{?projection}',
      templated: true,
    },
  },
};

const singleBrandProductData = {
  createDateTime: '2020-03-24T14:51:48.971969',
  updateDateTime: '2020-04-29T16:31:28.780941',
  name: 'Watermelon Blastt',
  removeFromMenu: false,
  brand: 'Buddha',
  description: '',
  rating: 0.0,
  productPricingList: [
    {
      quantity: '',
      price: 1.0,
    },
  ],
  effects: [],
  flavours: [],
  uses: [],
  flags: ['New'],
  tags: [],
  genetics: '',
  timeOfUse: '',
  thc: 12.0,
  cbd: 12.0,
  rank: 0,
  categories: {
    category: 'Concentrates',
    subcategory: '',
    subsubcategory: '',
  },
  _embedded: {
    files: [
      {
        name: '41430030_stiiizy_pic_deal_.jpg',
        url: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/41430030_stiiizy_pic_deal_-1585061508726.jpg',
        rank: 0,
        _links: {
          self: {
            href: 'https://devapi.s7daw.com:8080/api/v1/files/856{?projection}',
            templated: true,
          },
          product: {
            href: 'https://devapi.s7daw.com:8080/api/v1/files/856/product{?projection}',
            templated: true,
          },
        },
      },
    ],
    store: {
      name: 'Test1',
      address: {
        street: '',
        city: 'Airdrie',
        province: 'Alberta',
        postalCode: '',
      },
      createDateTime: '2020-03-23T13:27:15.275987',
      published: true,
      nationwide: false,
      contactFirstName: 'test',
      contactLastName: 'test',
      storeType: 'Delivery',
      lastMenuUpdate: '2020-04-29T16:32:04.087041',
      menuItems: 6,
      storeImage:
        'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
      avgRating: 0.0,
      numOfReviews: '0',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
          templated: true,
        },
        locations: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
          templated: true,
        },
        products: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
          templated: true,
        },
        owner: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
          templated: true,
        },
        allDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
          templated: true,
        },
        allStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
          templated: true,
        },
        deals: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
          templated: true,
        },
        featuredStoreRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
          templated: true,
        },
        file: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
          templated: true,
        },
        reviews: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
          templated: true,
        },
        featuredDeliveryRanks: {
          href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
          templated: true,
        },
      },
    },
  },
  _links: {
    self: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857',
    },
    product: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857{?projection}',
      templated: true,
    },
    store: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/store{?projection}',
      templated: true,
    },
    reviews: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/reviews{?projection}',
      templated: true,
    },
    files: {
      href: 'https://devapi.s7daw.com:8080/api/v1/products/857/files{?projection}',
      templated: true,
    },
  },
};

const brandProductStores = [
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
  {
    name: 'Test1',
    address: {
      street: '',
      city: 'Airdrie',
      province: 'Alberta',
      postalCode: '',
    },
    createDateTime: '2020-03-23T13:27:15.275987',
    published: true,
    nationwide: false,
    contactFirstName: 'test',
    contactLastName: 'test',
    storeType: 'Delivery',
    lastMenuUpdate: '2020-04-29T16:32:04.087041',
    menuItems: 6,
    storeImage:
      'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/5de41ef1747d4165c9795dbc_cannabis-leaf-1587744598876.jpeg',
    avgRating: 0.0,
    numOfReviews: '0',
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836{?projection}',
        templated: true,
      },
      locations: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/locations{?projection}',
        templated: true,
      },
      products: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/products{?projection}',
        templated: true,
      },
      owner: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/owner{?projection}',
        templated: true,
      },
      allDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allDeliveryRanks{?projection}',
        templated: true,
      },
      allStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/allStoreRanks{?projection}',
        templated: true,
      },
      deals: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/deals{?projection}',
        templated: true,
      },
      featuredStoreRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredStoreRanks{?projection}',
        templated: true,
      },
      file: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/file{?projection}',
        templated: true,
      },
      reviews: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/reviews{?projection}',
        templated: true,
      },
      featuredDeliveryRanks: {
        href: 'https://devapi.s7daw.com:8080/api/v1/stores/836/featuredDeliveryRanks{?projection}',
        templated: true,
      },
    },
  },
];

const featuredProductRanks = [
  {
    rank: 0,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 0',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5000',
      },
    },
  },
  {
    rank: 1,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 1',
      numOfReviews: '21',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 4.22,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5001',
      },
    },
  },
  {
    rank: 2,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 2',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5002',
      },
    },
  },
  {
    rank: 3,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 3',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5003',
      },
    },
  },
  {
    rank: 4,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 4',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5004',
      },
    },
  },
  {
    rank: 5,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Edibles',
    product: {
      name: 'Featured Edibles 5',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Edibles',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5005',
      },
    },
  },
  {
    rank: 0,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Flower',
    product: {
      name: 'Featured Flower 0',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Flower',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5006',
      },
    },
  },
  {
    rank: 1,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Flower',
    product: {
      name: 'Featured Flower 1',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Flower',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5007',
      },
    },
  },
  {
    rank: 0,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Concentrates',
    product: {
      name: 'Featured Concentrates 0',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Concentrates',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5008',
      },
    },
  },
  {
    rank: 1,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Concentrates',
    product: {
      name: 'Featured Concentrates 1',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Concentrates',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5009',
      },
    },
  },
  {
    rank: 0,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'CBD',
    product: {
      name: 'Featured CBD 0',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'CBD',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5010',
      },
    },
  },
  {
    rank: 0,
    brand: {
      name: 'Brand',
      address: {
        city: 'Airdrie',
        province: 'Alberta',
      },
      published: true,
      brandImage: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/picture15088591492890-1589990698953.jpg',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/brands/1283',
        },
      },
    },
    type: 'Vape Pens',
    product: {
      name: 'Featured Vape Pens 0',
      numOfReviews: '0',
      removeFromMenu: false,
      categories: {
        category: 'Vape Pens',
        subcategory: '',
        subsubcategory: '',
      },
      avgRating: 0.0,
      imgURL: 'https://sdaw-dev-bucket.s3.ca-central-1.amazonaws.com/files/blob-1590522901644.',
      _links: {
        self: {
          href: 'https://devapi.s7daw.com:8080/api/v1/products/1388',
        },
      },
    },
    _links: {
      self: {
        href: 'https://devapi.s7daw.com:8080/api/v1/featuredProductRanks/5011',
      },
    },
  },
];

export {
  productData,
  storeData,
  bannerData,
  dealData,
  myStoreData,
  myProductData,
  myDealData,
  featuredStores,
  featuredDeliveries,
  storesByLocation,
  featuredDeals,
  dealsByLocation,
  brandsData,
  singleBrandData,
  singleBrandDataWithHours,
  brandProducts,
  singleBrandProductData,
  singleBrandProductProjection,
  storeFrontBannerData,
  deliveryBannerData,
  mailOutsBannerData,
  dealBannerData,
  brandProductStores,
  featuredProductRanks,
};
