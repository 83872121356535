import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export function ListCaption({ text }) {
  const classes = useStyles();

  return <p className={classes.text}>{text}</p>;
}
