export default {
  Amherst: { lat: 45.8269, lon: -64.1994 },
  Antigonish: { lat: 45.6269, lon: -61.9986 },
  Berwick: { lat: 45.0547, lon: -64.7484 },
  Bridgewater: { lat: 44.3775, lon: -64.5204 },
  Chester: { lat: 44.6489, lon: -64.2406 },
  Dartmouth: { lat: 44.6714, lon: -63.5772 },
  Digby: { lat: 44.6203, lon: -65.7604 },
  'Glace Bay': { lat: 46.1933, lon: -59.9564 },
  Greenwood: { lat: 44.9653, lon: -64.9328 },
  Hantsport: { lat: 45.0728, lon: -64.314 },
  Kentville: { lat: 45.0767, lon: -64.4984 },
  Lunenburg: { lat: 44.3786, lon: -64.3189 },
  Middleton: { lat: 44.9424, lon: -65.0631 },
  'New Glasgow': { lat: 45.5906, lon: -62.6436 },
  Oxford: { lat: 45.6302, lon: -63.8637 },
  Parrsboro: { lat: 45.4097, lon: -64.3355 },
  Pictou: { lat: 45.6856, lon: -62.7144 },
  'Port Hawkesbury': { lat: 45.6167, lon: -61.3488 },
  Princeville: { lat: 45.5711, lon: -62.7222 },
  Shelburne: { lat: 43.7658, lon: -65.3217 },
  Springhill: { lat: 45.6506, lon: -64.0521 },
  Truro: { lat: 45.3659, lon: -63.2865 },
  Windsor: { lat: 44.9906, lon: -64.1356 },
  Wolfville: { lat: 45.0891, lon: -64.3654 },
  Yarmouth: { lat: 43.8374, lon: -66.1156 },
  Halifax: { lat: 44.6488, lon: -63.5752 },
  Sydney: { lat: 46.1351, lon: -60.1831 },
  'Cole Harbour': { lat: 44.6726, lon: -63.4722 },
  'Sydney Mines': { lat: 46.2435, lon: -60.2179 },
  'Lower Sackville': { lat: 44.7666, lon: -63.6739 },
};
