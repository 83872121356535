import Cookies from 'js-cookie';

export const setJWT = (value) => {
  Cookies.set('jwt', value, { expires: 5 });
};

export const setUserID = (value) => {
  Cookies.set('userID', value, { expires: 5 });
};

export const setRole = (value) => {
  Cookies.set('role', value, { expires: 5 });
};

export const setLocation = (value) => {
  Cookies.set('location', JSON.stringify(value), { expires: 30 });
};

export const setAgeVerified = (value) => {
  Cookies.set('ageVerified', value, { expires: 30 });
};

export const setRegion = (value) => {
  Cookies.set('region', value, { expires: 30 });
};

// export const setRemember = () => {
//   return Cookies.get("jwt");
// };

export const getJWT = () => {
  return Cookies.get('jwt');
};

export const getUserID = () => {
  return Cookies.get('userID');
};

export const getRole = () => {
  return Cookies.get('role');
};

export const getLocation = () => {
  return Cookies.get('location') ? JSON.parse(Cookies.get('location')) : undefined;
};

export const getAgeVerified = () => {
  return Cookies.get('ageVerified');
};

export const getRegion = () => {
  return Cookies.get('region');
};

// export const getRemember = () => {
//   return Cookies.get("jwt");
// };

const removeJWT = () => {
  Cookies.remove('jwt');
};

export const removeUserID = () => {
  Cookies.remove('userID');
};

export const removeJWTAndUserID = () => {
  removeJWT();
  removeUserID();
  Cookies.remove('role');
};

export default {
  setJWT,
  setUserID,
  setRole,
  setLocation,
  setAgeVerified,
  setRegion,
  getJWT,
  getUserID,
  getRole,
  getLocation,
  getAgeVerified,
  getRegion,
  removeJWTAndUserID,
};
