import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FeaturedListItem from './FeaturedListItem';
import SkeletonFeaturedListItem from './SkeletonFeaturedListItem';
import { Typography, Button, List, ListItem, ButtonGroup, useMediaQuery } from '@material-ui/core';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BrandProductItem from './BrandProductItem';
import FeaturedProductItem from './FeaturedProductItem';
import { FEATURED_TYPES } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
import { ListCaption } from './ListCaption';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
  },
  storeListContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    position: 'relative',
  },
  list: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTop: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    flexGrow: 1,
    fontSize: '26pt',
    fontWeight: '900',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      fontSize: '22pt',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  title: {
    marginTop: theme.spacing(1.5),
    flexGrow: 1,
    fontSize: '26pt',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      fontSize: '22pt',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  titleSection: {
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
  },
  viewAllButton: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    height: '95%',
    width: 110,
    [theme.breakpoints.down('sm')]: {
      height: '85%',
      width: 80,
      fontSize: '0.75em',
      padding: theme.spacing(0.5, 1, 0.5, 1),
    },
  },
  pageButton: {
    height: '95%',
    width: 40,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  pageButtons: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  storeListCard: {
    width: 232,
    [theme.breakpoints.down('md')]: {
      width: 192,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 210,
    [theme.breakpoints.down('md')]: {
      height: 170,
    },
  },
  symbol: {
    width: '6em',
    [theme.breakpoints.down('md')]: {
      width: '5em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '3em',
    },
  },
  directionalButton: {
    height: '100%',
    width: '100%',
  },
  leftIndicator: {
    position: 'absolute',
    left: -10,
    top: '50%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  rightIndicator: {
    position: 'absolute',
    right: -10,
    top: '50%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const iconPicker = (image) => {
  switch (image) {
    case 'storefronts':
      return 'iconTopStorefronts';
    case 'MailOuts':
      return 'iconMailOuts';
    case 'deliveries':
      return 'iconTopDeliveries';
    case 'clinics':
      return 'iconTopClinics';
    case 'deals':
      return 'iconTopDeals';
    case 'latest':
      return 'iconLatest';
    case 'popular':
      return 'iconPopular';
    case 'flowers':
      return 'iconFlowers';
    case 'edibles':
      return 'iconEdibles';
    case 'concentrates':
      return 'iconConcentrates';
    case 'cbd':
      return 'iconCBD';
    case 'vape pens':
      return 'iconVapePens';
    default:
      return 'iconTopStorefronts';
  }
};

function FeaturedList(props) {
  const cardWidth = useMediaQuery('(max-width:1023px)') ? 192 : 232;

  const [listWidth, setListWidth] = useState(window.innerWidth > 1200 ? 1200 : window.innerWidth);

  window.addEventListener('resize', function () {
    setListWidth(window.innerWidth > 1200 ? 1200 : window.innerWidth);
  });

  const {
    loading,
    listData,
    title,
    type,
    image,
    viewAllPath,
    index,
    handleNavBarChange,
    brandBrowse,
    setBrowse,
    brandId,
    brandSlug,
    location,
    caption,
    showOpenStatus,
  } = props;

  const [endReached, setEndReached] = useState(listData.length > 2 ? false : true);
  const [startReached, setStartReached] = useState(true);

  useEffect(() => {
    if (listData.length > 2) {
      setEndReached(false);
    }
  }, [listData]);

  const handleScroll = (e) => {
    const end = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth;

    if (end && listData.length > 0) {
      setEndReached(true);
    } else {
      setEndReached(false);
    }

    const start = e.target.scrollLeft <= 0;

    if (start && listData.length > 0) {
      setStartReached(true);
    } else {
      setStartReached(false);
    }
  };

  const classes = useStyles();

  const [page, setPage] = useState(0);

  const count = listWidth / cardWidth;

  const pageUp = () => {
    setPage(page + 1);
    $(`#store-list-${index}`).animate({
      scrollLeft: Math.floor((cardWidth * (page + 1) * count) / cardWidth) * cardWidth,
    });
  };

  const pageDown = () => {
    setPage(page - 1);
    $(`#store-list-${index}`).animate({
      scrollLeft: Math.floor((cardWidth * (page - 1) * count) / cardWidth) * cardWidth,
    });
  };

  let highlighted = title.split(' ')[0].toUpperCase();
  let nonHighlighted = title.split(' ').slice(1, title.length).join(' ').toUpperCase();

  if (type === FEATURED_TYPES.FEATUREDPRODUCT /*|| type === FEATURED_TYPES.DEAL*/) {
    nonHighlighted = title;
    highlighted = '';
  }
  const isSmallerScreen = useMediaQuery('(max-width:768px)'); // TODO convert to standard media query in classes
  if (!loading && listData.length === 0) return null; //Escape for empty lists (don't render anything)
  return (
    <div className={classes.root}>
      <div className={classes.storeListContainer}>
        <div className={classes.header}>
          <div className={classes.titleSection}>
            <img
              src={require(`../assets/navigation-icons/${iconPicker(image)}.svg`)}
              alt={`navigation-icon-${title}`}
              className={classes.symbol}
            />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {/* {highlighted && ( */}
              <Typography variant={isSmallerScreen ? '' : 'h2'}>
                <span className={classes.titleTop}>{highlighted}</span>{' '}
                <span className={classes.title}>{nonHighlighted}</span>
              </Typography>
              {/* )} */}
              {!highlighted && (
                <Typography variant="h5" className={classes.titleTop}>
                  &nbsp;
                </Typography>
              )}
              {/* <Typography variant="h2" className={classes.title}>
                {nonHighlighted}
              </Typography> */}
            </div>
          </div>
          <div className={classes.buttonGroup}>
            {viewAllPath !== 'NONE' && (
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={(event) => {
                  handleNavBarChange(event, index);
                }}
                className={classes.viewAllButton}
                component={Link}
                to={
                  viewAllPath === '/deliveries' ? `${viewAllPath}/${location.province}/${location.city}` : viewAllPath
                }
                style={{ color: '#ffffff', textDecoration: '#ffffff' }}
              >
                View All
              </Button>
            )}
            {brandBrowse && (
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={(event) => {
                  setBrowse();
                }}
                className={classes.viewAllButton}
                // component={Link}
                // to={viewAllPath}
                style={{ color: '#ffffff', textDecoration: '#ffffff' }}
              >
                View All
              </Button>
            )}
            <ButtonGroup className={classes.pageButtons}>
              <Button variant="text" onClick={() => pageDown()} disabled={page === 0} className={classes.pageButton}>
                {page === 0 && (
                  <img
                    src={require(`../assets/navigation-icons/leftArrowUnSelected.svg`)}
                    alt={`left-arrow`}
                    className={classes.directionalButton}
                  />
                )}
                {page !== 0 && (
                  <img
                    src={require(`../assets/navigation-icons/leftArrowSelected.svg`)}
                    alt={`left-arrow`}
                    className={classes.directionalButton}
                  />
                )}
              </Button>
              <Button
                variant="text"
                onClick={() => pageUp()}
                disabled={page === Math.floor(listData.length / count)}
                className={classes.pageButton}
              >
                {page === Math.floor(listData.length / count) && (
                  <img
                    src={require(`../assets/navigation-icons/rightArrowUnSelected.svg`)}
                    alt={`right-arrow`}
                    className={classes.directionalButton}
                  />
                )}
                {page !== Math.floor(listData.length / count) && (
                  <img
                    src={require(`../assets/navigation-icons/rightArrowSelected.svg`)}
                    alt={`right-arrow`}
                    className={classes.directionalButton}
                  />
                )}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {caption && <ListCaption text={caption} className={classes.captionText} />}
        {loading && (
          <List
            className={classes.list}
            // id={`store-list-${index}`}
            disablePadding
            // onScroll={e => handleScroll(e)}
          >
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
            <ListItem className={classes.storeListCard}>
              <SkeletonFeaturedListItem></SkeletonFeaturedListItem>
            </ListItem>
          </List>
        )}

        {!loading && listData.length > 0 && (
          <List className={classes.list} id={`store-list-${index}`} disablePadding onScroll={(e) => handleScroll(e)}>
            {listData.map((item, index) => (
              <ListItem className={classes.storeListCard} key={index}>
                {type !== FEATURED_TYPES.BRANDPRODUCT && type !== FEATURED_TYPES.FEATUREDPRODUCT && (
                  <FeaturedListItem
                    item={item}
                    key={index}
                    type={type}
                    location={location}
                    // onClick={() => onStoreClick()}
                    onClick={(event) => {
                      handleNavBarChange(event /*, getNavBarIndex(type)*/);
                    }}
                    showOpenStatus={showOpenStatus}
                  ></FeaturedListItem>
                )}
                {type === FEATURED_TYPES.BRANDPRODUCT && (
                  <BrandProductItem
                    name={item.name}
                    key={index}
                    slug={item.slug}
                    details={item.description}
                    image={item.imgURL}
                    id={`${parseIdFromLink(item._links.self.href, 0, '{?projection}')}`}
                    brandId={brandId}
                    brandSlug={brandSlug}
                  ></BrandProductItem>
                )}
                {type === FEATURED_TYPES.FEATUREDPRODUCT && (
                  <FeaturedProductItem
                    productName={item.product.name}
                    key={index}
                    productImage={item.product.imgURL ? item.product.imgURL : item.product.productImage}
                    id={`${parseIdFromLink(item.product._links.self.href, 0, '{?projection}')}`}
                    brandId={`${parseIdFromLink(item.brand._links.self.href, 0, '{?projection}')}`}
                    brandImage={item.brand.brandImage}
                    address={item.brand.address}
                    rating={item.product.avgRating}
                    reviews={item.product.numOfReviews}
                    // aos: add slugs to props
                    brandSlug={item.product.brand.slug}
                    productSlug={item.product.slug}
                  ></FeaturedProductItem>
                )}
              </ListItem>
            ))}
          </List>
        )}
        {!startReached && <ChevronLeftIcon fontSize="large" className={classes.leftIndicator} />}
        {!endReached && <ChevronRightIcon fontSize="large" className={classes.rightIndicator} />}
      </div>
    </div>
  );
}

export default FeaturedList;
