import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SignUpThankYouContent from "./SignUpThankYouContent";

export default function ScrollDialog(props) {
    const handleClose = () => {
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="terms-and-conditions"
                maxWidth="md"
            >
                <DialogTitle id="terms-and-conditions-title">
                   
                 </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText> */}
                    <SignUpThankYouContent></SignUpThankYouContent>
                    {/* </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                        color="primary"
                    >
                        Okay
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
