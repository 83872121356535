import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button } from '@material-ui/core';
import { OverflowDetector } from 'react-overflow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  detector: {
    borderRadius: '5px',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
  },
  line: { width: '100%' },
  emptyLineHelper: { color: theme.palette.common.white },
  readMore: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-end',
  },
}));

function DescriptionBox(props) {
  const { text, fullMode } = props;

  const classes = useStyles();

  const lines = text.split(/[\n]/);

  const [overflowed, setOverflowed] = useState(false);
  const [expand, setExpand] = useState(false);

  function handleOverflowChange(isOverflowed) {
    setOverflowed(true);
  }

  return (
    <div className={classes.root}>
      <OverflowDetector
        onOverflowChange={handleOverflowChange}
        style={{
          maxHeight: !fullMode && !expand ? '275px' : '',
          border: !fullMode && overflowed && !expand ? '1px solid #cbcbcb' : '',
          overflow: !fullMode && !expand ? 'hidden' : '',
        }}
        className={classes.detector}
      >
        {lines.map((line, index) => (
          <Typography className={classes.line} key={index}>
            {line}
            {line === '' && <Typography className={classes.emptyLineHelper}>&nbsp;</Typography>}
          </Typography>
        ))}
      </OverflowDetector>
      {!fullMode && !expand && overflowed && (
        <Button
          className={classes.readMore}
          variant="outlined"
          onClick={() => {
            setExpand(true);
          }}
        >
          Read More
        </Button>
      )}
    </div>
  );
}

export default DescriptionBox;
