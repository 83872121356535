import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Countdown from "react-countdown";

const useStyles = makeStyles(theme => ({
  root: {},
  timer: {
    fontSize: "18pt",
    fontWeight: "700",
    color: theme.palette.primary.main
  }
}));

function CountdownTimer(props) {
  const { time } = props;

  const classes = useStyles();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Typography className={classes.timer}>NOW!</Typography>;
    } else if (days > 0) {
      return (
        <Typography className={classes.timer}>{`${days} Day${
          days > 1 ? "s" : ""
        }`}</Typography>
      );
    } else {
      // Render a countdown
      return (
        <Typography className={classes.timer}>
          {hours > 9 ? "" + hours : "0" + hours}:
          {minutes > 9 ? "" + minutes : "0" + minutes}:
          {seconds > 9 ? "" + seconds : "0" + seconds}
        </Typography>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Countdown date={time} renderer={renderer} />
    </div>
  );
}

export default CountdownTimer;
