import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Collapse, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  header: {
    fontSize: "250%",
    marginTop: theme.spacing(2),
  },
  subHeader: {
    fontSize: "150%",
    marginBottom: theme.spacing(1),
    weight: "700",
  },
  text: {
    marginBottom: theme.spacing(8),
  },
  answerTextBox: {
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    fontWeight: "400",
    borderRadius: "5px",
  },
}));

export function DeliveryFAQ({ city }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Button onClick={() => setOpen(!open)} variant="outlined">
        <h3 className={classes.header}>Frequently Asked Questions: </h3>
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={classes.root}>
          <h4 className={classes.subHeader}>
            Which cannabis products can be delivered online?{" "}
          </h4>
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
          >
            Surely you didn't think that delivery services would only sell or deliver standard buds, right? There's a wide range of products that a weed delivery service or online store offers which includes flower buds, edibles, concentrates, vapes and much more.
          </Box>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Fortunately, the majority of brands in London offer delivery
              services for all cannabis products including buds, edibles,
              concentrates and vapes.
            </Box>
          )}
          <h4 className={classes.subHeader}>Weed Flower </h4>
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              If you're looking for a special or more potent strain, then
              flowers might be a great place to start. Unlike standard
              marijuana, flowers have a more premium feeling to them, not to
              mention the added potency effect. In effect, flowers provide
              instant relief compared to standard buds. A standard gram should
              cost you between $7 to $15 per gram on average, and with delivery
              fees, this may depend on the delivery service.{" "}
            </Box>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Weed flowers are the most popular and traditional way of
              purchasing cannabis online. With the natural aromas and texture
              that a user gets to experience, many cannabis users prefer and
              enjoy the process of grinding and consuming real cannabis.
            </Box>
          )}
          <h4 className={classes.subHeader}>Weed Edibles</h4>
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Edibles have been a popular “treat” as a way to enhance a user's
              overall feeling. The reason for this is because digesting edibles
              activates different enzymes which is why it takes long for it to
              kick in, but the advantage is it can also last much longer. The
              majority of the local vendors provide edibles and delivery
              assistance. Edibles come mostly in the form of gummies, capsules,
              teas, candies, chocolate, and the very classic brownie, all of
              which are available for delivery throughout Toronto. It's no
              surprise to find edibles available for delivery throughout Toronto
              with so much popularity!{" "}
            </Box>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Edibles started to gain in popularity as dispensaries started to
              innovate the way edibles were made. You can find many variations
              such as gummies, capsules, chocolate, cookies and many more
              creative variations. While the THC and CBD levels might be the
              same as a weed flower, the effects are much different due to the
              way it is processed in your body.
            </Box>
          )}
          <h4 className={classes.subHeader}>Concentrates</h4>
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Concentrates as just as popular as the two previous types in
              Toronto. However, concentrates offer a higher potency, not to
              mention you'll feel the effects almost immediately after
              consumption. Some prefer concentrates more due to the higher THC
              content and diverse ways you can use concentrates. Pre Filled vape
              cartridges, for example, is a concentrate popular among users in
              Toronto and widely available for delivery. Other types of
              concentrates include shatter, badder & budder, crystalline,
              crumble, and distillate. With the right smoking tools,
              concentrates offer various ways to experience the effects of
              marijuana and its health benefits. You can ask your local delivery
              vendor in Toronto if they offer any smoking tools to pair with
              your concentrates to enhance your overall experience.
            </Box>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Concentrates are the purest form of THC or CBD extract which comes
              from natural weed flowers. With its high potency and purity you
              avoid other chemicals which can be found in your weed flowers
              along with the harsh coughing you may experience.
            </Box>
          )}
          <h4 className={classes.subHeader}>Vape Pens</h4>
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Vape pens are relatively new but are gaining popularity among many
              users. Although it has less potency than most marijuana types, its
              main benefit is that it's a healthier alternative than most. Since
              the vapor released from vape pens don't release any tar or
              carcinogens during the combustion process, it's considered to be
              healthier and can allow you to feel the health benefits of
              marijuana with lesser risks. Additionally, it's easier to vape
              marijuana than learning how to smoke it. For first-timers, this
              could be a great place for you to start. Since vape pens are
              relatively new, not all vendors will have vape pens available for
              delivery. It may be convenient to choose a vape pen among the
              product listings provided online; however, we recommend testing it
              out yourself in person to find the best vape pen for you.{" "}
            </Box>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Vape pens have gained a lot of popularity as cannabis started to
              move towards its recreational use. With the ability to smoke
              unnoticed, vape pens are filtered quite well and offer little to
              no smell which you may experience from smoking weed flowers
              Although some cannabis users say vape pens are a much healthier
              alternative, they are certainly a better solution for first time
              users as it offers a more mild effect.
            </Box>
          )}
          {city === "Toronto" && (
            <h4 className={classes.subHeader}>
              How soon can I get my product through delivery?{" "}
            </h4>
          )}
          {city === "Toronto" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              Although every weed delivery brand is different, most brands are
              able to deliver within the same day or within 5 business days
              depending on the size of your order and your exact location.
            </Box>
          )}
          {city === "London" && (
            <h4 className={classes.subHeader}>
              How safe is it to have cannabis delivered?
            </h4>
          )}
          {city === "London" && (
            <Box 
            display="block"
            boxShadow={3}
            className={classes.answerTextBox}
            >
              It is quite safe to have your cannabis delivered straight to you,
              as many dispensaries leverage 3rd-party providers such as Canada
              Post to offer fast and reliable shipments.
            </Box>
          )}
        </div>
      </Collapse>
    </Fragment>
  );
}
