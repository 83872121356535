import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Modal, Backdrop, Fade, Button } from '@material-ui/core';
import api from '../utils/api';
import { STATUS } from '../utils/constants';
import ReviewItem from './ReviewItem';
import ReviewForm from './ReviewForm';
import cookies from '../cookies';
import { parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  reviewsTab: {
    margin: theme.spacing(2),
  },
  reviewsFlexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  reviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  reviews: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  leaveReview: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(6),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

const getReviewsForStore = async (id) => {
  return api
    .getStoreReviews(id)
    .then((res) =>
      res.sort((x, y) => {
        if (x.createDateTime > y.createDateTime) {
          return -1;
        } else if (x.createDateTime < y.createDateTime) {
          return 1;
        } else {
          return 0;
        }
      })
    )
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getReviewsForBrand = async (id) => {
  return api
    .getBrandReviews(id)
    .then((res) =>
      res.sort((x, y) => {
        if (x.createDateTime > y.createDateTime) {
          return -1;
        } else if (x.createDateTime < y.createDateTime) {
          return 1;
        } else {
          return 0;
        }
      })
    )
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getReviewsForProduct = async (id) => {
  return api
    .getProductReviews(id)
    .then((res) =>
      res.sort((x, y) => {
        if (x.createDateTime > y.createDateTime) {
          return -1;
        } else if (x.createDateTime < y.createDateTime) {
          return 1;
        } else {
          return 0;
        }
      })
    )
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function ReviewsSection(props) {
  const { type, itemId /*, focusReviewForm*/, ownerName, product, disableReviews } = props;

  const classes = useStyles();
  const [editing, setEditing] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [postError, setPostError] = useState(false);

  const loggedIn = cookies.getUserID() && cookies.getJWT() && cookies.getRole();
  const postReview = async (data, type, itemId) => {
    return api
      .createReview(data, type, itemId, cookies.getUserID())
      .then((res) => res)
      .catch((err) => {
        if (err.message.includes('between') || err.message.includes('48')) {
          setErrorMsg(err.message);
        } else if (err.message.includes('owner')) {
          setErrorMsg(err.message);
        } else {
          setErrorMsg('An error has occured while trying to post your review');
        }
        //throw STATUS.ERROR;
      });
  };

  useEffect(() => {
    if (type === 'store') {
      getReviewsForStore(itemId)
        .then((res) => {
          setReviews(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err);
        });
    } else if (type === 'product') {
      getReviewsForProduct(itemId)
        .then((res) => {
          setReviews(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err);
        });
    } else if (type === 'brand') {
      getReviewsForBrand(itemId)
        .then((res) => {
          setReviews(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err);
        });
    }
  }, [type, itemId, editing]);
  const submit = () => {
    setSaving(true);
    postReview(
      {
        description,
        rating,
        title,
      },
      type,
      itemId
    )
      .then((res) => {
        setSaved(true);
        setSaving(false);
        const newReview = {
          ...res,
          reviewerFirstName: res._embedded.user.firstName,
          user: res._embedded.user,
        };
        const newReviewList = [newReview, ...reviews];
        setReviews(newReviewList);
      })
      .catch((err) => {
        setSaving(false);
        setPostError(true);
      });
  };

  const editMode = () => {
    setEditing(editing + 1);
  };
  // console.log(reviews);
  return (
    <div className={classes.reviewsTab}>
      <Typography variant="h4" className={classes.title}>
        Reviews
      </Typography>
      <div className={classes.reviewsFlexBox}>
        {loading && <Typography>Loading...</Typography>}
        {status === STATUS.ERROR && (
          <Typography>An error occurred when retrieving reviews. Please try again later.</Typography>
        )}
        {!loading && status === '' && (
          <div className={classes.reviews}>
            {reviews.length === 0 && <Typography variant="body1">There are no reviews yet!</Typography>}
            {reviews.length > 0 &&
              reviews.map((review) => (
                <div key={review._links.self.href} className={classes.reviewContainer}>
                  <ReviewItem
                    review={{
                      createDateTime: review.createDateTime,
                      name: review.reviewerFirstName,
                      title: review.title,
                      rating: review.rating,
                      description: review.description,
                      userID:
                        review.user !== undefined
                          ? parseIdFromLink(review.user._links.self.href, 0, '{?projection}')
                          : parseIdFromLink(review._embedded.user._links.self.href, 0, '{?projection}'),
                      reviewID: parseIdFromLink(review._links.self.href, 0, '{?projection}'),
                      ipAddress: review.ipAddress,
                      reviewPoster: review.user.username,
                      reply: review.reply,
                    }}
                    ownerName={ownerName}
                    itemID={itemId}
                    editMode={() => editMode()}
                    product={product}
                  ></ReviewItem>
                </div>
              ))}
          </div>
        )}
        <div className={classes.leaveReview}>
          <ReviewForm
            // focusReviewForm={focusReviewForm}
            disableReviews={disableReviews}
            loggedIn={loggedIn}
            type={type}
            rating={rating}
            setRating={(val) => setRating(val)}
            title={title}
            setTitle={(val) => setTitle(val)}
            description={description}
            setDescription={(val) => setDescription(val)}
            //userID={cookies.getUserID()}
            submit={() => submit()}
            saving={saving}
            saved={saved}
          ></ReviewForm>
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={postError}
        onClose={() => {
          setPostError(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={postError}>
          <div className={classes.modalPaper}>
            <Typography className={classes.statusMessage}>{errorMsg}</Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.statusAcceptButton}
              onClick={() => setPostError(false)}
            >
              Ok
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ReviewsSection;
