import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Divider } from '@material-ui/core';
import Content from './Content';

const useStyles = makeStyles((theme) => ({
  root: {},
  topBanner: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerWrapper: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    margin: theme.spacing(2),
    fontSize: '2rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
  storeGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
  seoTitle: {
    margin: theme.spacing(2, 2, 2, 2),
    color: theme.palette.primary.main,
    fontWeight: '400',
  },
  seoText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: '400',
  },
  seoIntro: {
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: '700',
    '@media (max-width: 425px)': {
      display: 'none',
    },
  },
  seoIntroMobile: {
    '@media (min-width: 426px)': {
      display: 'none',
    },
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(-1.25),
    border: 'none',
    boxShadow: 'none',
    fontWeight: '700',
  },
  seoIntroTitleMobile: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    fontWeight: '700',
    border: 'none',
    boxShadow: 'none',
  },
  seoIntroTextMobile: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(0),
    fontWeight: '700',
    border: 'none',
    boxShadow: 'none',
  },
  resultsText: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  currCityText: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#9ea137',
    fontWeight: '700',
  },
  reasonBox: {
    marginLeft: theme.spacing(2),
    borderRadius: '5px',
    zIndex: 2,
    textAlign: 'center',
  },
  reasonIcon: {
    fontSize: '1rem',
  },
  reasonTitle: {
    fontWeight: '700',
  },
  reasonText: {
    marginTop: theme.spacing(-2.5),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    fontWeight: '400',
    borderRadius: '5px',
    zIndex: 1,
  },
}));
const REASON_WIDTH = '9rem';

export default function Remainder({ currCity }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Divider variant="middle" />
      {(currCity === 'Toronto' || currCity === 'Toronto West' || currCity === 'Toronto East') && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            HOW TO ORDER WEED IN TORONTO ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            In Toronto, you can legally possess, carry, and share (with anyone over 19+) 30 grams of cannabis. To order
            weed online, you need to be at least 19 years old. Use <a href="https://www.s7daw.com">S7DAW.com </a> to
            find dispensaries that offer weed delivery and mail outs. Browse weed deals and catalogues to find the
            strains, products types, and price range you’re looking for. From there, contact the dispensary and place
            your order!
          </Typography>
          {/* ao: Insert What kind of weed I can order in... here */}
          <Typography variant="h4" className={classes.seoTitle}>
            WHAT KIND OF WEED CAN I ORDER IN TORONTO ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            There are a lot of dispensaries that offer a variety of cannabis products. The trick is to find the best
            deals for the best weed products (and that’s why we started <a href="https://www.s7daw.com">S7DAW</a>!).
            Here are some of the cannabis products you can order:
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            EDIBLES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Edibles have been legally available in Toronto since the end of 2019. Whether you’re looking for
            cannabis-infused baked goods, beverages, candy, gummies, or cotton candy you’ll be in luck. Edibles are made
            with cannabis oil or dried flowers and are a popular alternative to inhaling smoke from pipes, joints or
            bongs.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            FLOWERS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            The trichome-covered flower is the smokable part of the marijuana plant. Also known as nuggets, nugs, or
            bud, marijuana flowers need to be ground before they can be smoked in joints and blunts, pipes and bongs, or
            vaporized. On S7DAW you can find the best flower dispensaries in Toronto.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            SEEDS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find the most popular strains of marijuana and grow your own from seed! It is legal in Toronto to own and
            grow up to 4 cannabis plants. Put those green thumbs to use and find the best seeds on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            CBD OIL
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find your favourite brands of Canadian CBD oil. CBD oil has been long used to treat a variety of conditions
            and it is now readily available in a variety of flavours.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            TINCTURES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Cannabis tinctures are liquid cannabis extracts, which can be alcohol or non-alcohol infused liquids.
            Historically used as medical cannabis, tinctures can now be enjoyed by everyone. Discover weed tinctures in{' '}
            {currCity} on S7DAW.
          </Box>
          <Typography variant="h4" className={classes.seoTitle}>
            ABOUT WEED IN {currCity.toUpperCase()}
          </Typography>
          {currCity === 'Toronto' && (
            <Typography className={classes.seoText}>
              Cannabis culture in Toronto has been alive for decades. Lately it has been called the “Denver of the Great
              White North” as it has become a hub for cannabis lovers across Canada. You can find a variety of
              cannabis-related events, from festivals to THC-infused dinner parties in Toronto. New startups and weed
              brands have sprung up across the city, offering an abundance of choice for aficionados. Time to order-up!
              We hope you enjoy your weed delivery in Toronto.
            </Typography>
          )}
          {currCity === 'Toronto West' && (
            <Typography className={classes.seoText}>
              Toronto West is the ideal hangout for casual consumers, weekend tokers and cannabis connoisseurs. For
              decades West Toronto, including neighbourhoods such as Bloor West Village, Swansea, High Park, The
              Junction, Roncesvalles Village, and Parkdale, has been home to a vibrant cannabis culture. Looking for the
              best marijuana products in Toronto’s west end? You are spoiled for choice. Even better? You don’t even
              have to leave the house. Toronto West weed delivery options are endless. You can order flower, pre-rolled
              joints, CBD oil, tinctures, seeds, edibles and more.
            </Typography>
          )}
          {currCity === 'Toronto East' && (
            <Typography className={classes.seoText}>
              For decades, Toronto East has been a marijauna hotspot. Danforth to the Beaches and in between, Toronto
              East is home to many weed startups and dispensaries. In fact, finding craft cannabis products in Toronto
              East has never been easier, and you can get marijuana ordered right to your house! Many vendors offer free
              delivery with a minimum order value. Find unbeatable prices on your favourite strains and get fast
              delivery in your neighbourhood.
            </Typography>
          )}
          <br />
        </Fragment>
      )}

      {currCity === 'Mississauga' && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            HOW TO ORDER WEED IN MISSISSAUGA ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            By law, in Mississauga, you can legally possess, carry, and share (with anyone over 19+) 30 grams of
            cannabis. To order weed online, you need to be at least 19 years old. Use{' '}
            <a href="https://www.s7daw.com">S7DAW.com </a> to find local dispensaries that offer fast weed delivery and
            mail outs in Mississauga. Browse weed deals and catalogues to find the strains, products types, and price
            range you’re looking for. From there, simply contact the dispensary and place your order!
          </Typography>
          {/* ao: Insert What kind of weed I can order in... here */}
          <Typography variant="h4" className={classes.seoTitle}>
            WHAT KIND OF WEED CAN I ORDER IN MISSISSAUGA ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            There are many dispensaries in Mississauga to choose from that offer a variety of cannabis products. The key
            is to find the best deals for the best weed products (and that’s why we started{' '}
            <a href="https://www.s7daw.com">S7DAW</a>!). Here are some cannabis products you can order:
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            EDIBLES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Edibles have been legally available in Mississauga since the end of 2019. Whether you’re looking for
            cannabis-infused baked goods, beverages, candy, gummies, or cotton candy you’ll be in luck. Edibles are made
            with cannabis oil or dried flowers and are a popular alternative to inhaling smoke from pipes, joints or
            bongs.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            FLOWERS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            The trichome-covered flower is the smokable part of the marijuana plant. Also known as nuggets, nugs, or
            bud, marijuana flowers need to be ground before they can be smoked in joints and blunts, pipes and bongs, or
            vaporized. Find the best flower dispensaries in Mississauga on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            SEEDS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Look for your favourite strains of marijuana and grow your own from seed! It is legal in Mississauga to own
            and grow up to 4 cannabis plants. Put those green thumbs to use and find the best seeds on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            CBD OIL
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find your favourite brands of Canadian CBD oil in Mississauga. CBD oil has been long used to treat a variety
            of conditions and it is now readily available in a variety of flavours.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            TINCTURES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Cannabis tinctures are liquid cannabis extracts, which can be alcohol or non-alcohol infused liquids.
            Historically used as medical cannabis, tinctures can now be enjoyed by everyone. Discover weed tinctures in
            Mississauga on S7DAW.
          </Box>
          <Typography variant="h4" className={classes.seoTitle}>
            ABOUT WEED IN MISSISSAUGA
          </Typography>
          <Typography className={classes.seoText}>
            Mississauga was one of 76 municipalities in Ontario that opted out of retail cannabis before the Jan. 22,
            2019, deadline. This means that finding retail marijuana stores in Mississauga just isn’t possible. Instead
            of making it a mission to find a brick and mortar store elsewhere in the GTA, you can easily order your weed
            online for delivery in Mississauga! Cannabis lovers in Mississauga are able to order a variety of cannabis
            products from local dispensaries.
          </Typography>
        </Fragment>
      )}

      {currCity === 'Etobicoke' && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            HOW TO ORDER WEED IN ETOBICOKE ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            By law, in Etobicoke, you can legally possess, carry, and share (with anyone over 19+) 30 grams of cannabis.
            To order weed online, you need to be at least 19 years old. Use{' '}
            <a href="https://www.s7daw.com">S7DAW.com </a> to find local dispensaries that offer fast weed delivery and
            mail outs in Etobicoke. Browse weed deals and catalogues to find the strains, products types, and price
            range you’re looking for. From there, simply contact the dispensary and place your order!
          </Typography>
          {/* ao: Insert What kind of weed I can order in... here */}
          <Typography variant="h4" className={classes.seoTitle}>
            WHICH CANNABIS PRODUCTS CAN I ORDER IN ETOBICOKE?
          </Typography>
          <Typography className={classes.seoText}>
            There are many dispensaries that deliver to Etobicoke to choose from that offer a variety of cannabis
            products. The secret is to find the best deals for the best weed products (and that’s why we started{' '}
            <a href="https://www.s7daw.com">S7DAW</a>!). Here are some cannabis products you can order:
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            EDIBLES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Edibles have been legally available in Etobicoke since the end of 2019. Whether you’re looking for
            cannabis-infused baked goods, beverages, candy, gummies, or cotton candy you’ll be in luck. Edibles are made
            with cannabis oil or dried flowers and are a popular alternative to inhaling smoke from pipes, joints or
            bongs.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            FLOWERS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            The trichome-covered flower is the smokable part of the marijuana plant. Also known as nuggets, nugs, or
            bud, marijuana flowers need to be ground before they can be smoked in joints and blunts, pipes and bongs, or
            vaporized. Find the best flower dispensaries in Etobicoke on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            SEEDS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Look for your favourite strains of marijuana and grow your own from seed! It is legal in Etobicoke to own
            and grow up to 4 cannabis plants. Put those green thumbs to use and find the best seeds on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            CBD OIL
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find your favourite brands of Canadian CBD oil in Etobicoke. CBD oil has been long used to treat a variety
            of conditions and it is now readily available in a variety of flavours.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            TINCTURES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Cannabis tinctures are liquid cannabis extracts, which can be alcohol or non-alcohol infused liquids.
            Historically used as medical cannabis, tinctures can now be enjoyed by everyone. Discover weed tinctures in
            Etobicoke on S7DAW.
          </Box>
          <Typography variant="h4" className={classes.seoTitle}>
            ABOUT WEED IN ETOBICOKE
          </Typography>
          <Typography className={classes.seoText}>
            Weed has been legal in Etobicoke since October 17, 2018. You’re allowed to smoke cannabis everywhere in
            Etobicoke where it is legal to smoke tobacco. People who call Etobicoke home have a lot of choice when it
            comes to Cannabis, with several cannabis retail locations, as well as a thriving community of dispensaries
            that offer weed delivery to Etobicoke postal codes. Find your next favourite cannabis product today!
          </Typography>
        </Fragment>
      )}

      {currCity === 'Oakville' && (
        <Fragment>
          <Typography variant="h4" className={classes.seoTitle}>
            HOW TO ORDER WEED IN OAKVILLE ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            In Oakville, you can legally possess, carry, and share (with anyone over 19+) 30 grams of cannabis. To order
            weed online, you need to be at least 19 years old. Use <a href="https://www.s7daw.com">S7DAW.com </a> to
            find dispensaries that offer weed delivery and mail outs in Oakville. Browse weed deals and catalogues to
            find the strains, products types, and price range you’re looking for. From there, contact the dispensary and
            place your order!
          </Typography>
          {/* ao: Insert What kind of weed I can order in... here */}
          <Typography variant="h4" className={classes.seoTitle}>
            WHAT TYPES OF CANNABIS CAN I ORDER IN OAKVILLE ONTARIO
          </Typography>
          <Typography className={classes.seoText}>
            There are a lot of dispensaries in Oakville that offer a variety of cannabis products. The trick is to find
            the best deals for the best weed products (and that’s why we started{' '}
            <a href="https://www.s7daw.com">S7DAW</a>!). Here are some cannabis products you can order:
          </Typography>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            EDIBLES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Edibles have been legally available in Oakville since the end of 2019. Whether you’re looking for
            cannabis-infused baked goods, beverages, candy, gummies, or cotton candy you’ll be in luck. Edibles are made
            with cannabis oil or dried flowers and are a popular alternative to inhaling smoke from pipes, joints or
            bongs.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            FLOWERS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            The trichome-covered flower is the smokable part of the marijuana plant. Also known as nuggets, nugs, or
            bud, marijuana flowers need to be ground before they can be smoked in joints and blunts, pipes and bongs, or
            vaporized. On S7DAW you can find the best flower dispensaries in Oakville.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            SEEDS
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find the most popular strains of marijuana and grow your own from seed! It is legal in Oakville to own and
            grow up to 4 cannabis plants. Put those green thumbs to use and find the best seeds on S7DAW.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            CBD OIL
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Find your favourite brands of Canadian CBD oil. CBD oil has been long used to treat a variety of conditions
            and it is now readily available in a variety of flavours.
          </Box>
          <Box
            bgcolor="primary.main"
            color="primary.contrastText"
            p={1}
            width={REASON_WIDTH}
            display="block"
            className={classes.reasonBox}
          >
            TINCTURES
          </Box>
          <Box display="block" boxShadow={3} className={classes.reasonText}>
            Cannabis tinctures are liquid cannabis extracts, which can be alcohol or non-alcohol infused liquids.
            Historically used as medical cannabis, tinctures can now be enjoyed by everyone. Discover weed tinctures in
            Oakville on S7DAW.
          </Box>
          <Typography variant="h4" className={classes.seoTitle}>
            ABOUT WEED IN OAKVILLE
          </Typography>
          <Typography className={classes.seoText}>
            Oakville is home to a variety of weed dispensaries that deliver locally. Premium quality cannabis producers
            have been popping up across the city. Residents and visitors to Oakville often ask “why are there no
            dispensaries in Oakville?”. Unfortunately Oakville city council voted to not allow retail cannabis stores in
            the city. That means finding a brick-and-mortar store in the city limits isn’t possible. However, weed
            delivery in Oakville remains fast and legal! With fast-shipping available for those who live in and around
            Oakville, you can still enjoy all of the cannabis products you love.
          </Typography>
        </Fragment>
      )}

      {currCity === 'Calgary' && Content.Calgary.remainder(classes)}
      {currCity === 'Edmonton' && Content.Edmonton.remainder(classes)}
      {currCity === 'Montreal' && Content.Montreal.remainder(classes)}
      {currCity === 'Brampton' && Content.Brampton.remainder(classes)}
      {currCity === 'Guelph' && Content.Guelph.remainder(classes)}
      {currCity === 'Hamilton' && Content.Hamilton.remainder(classes)}
      {currCity === 'Markham' && Content.Markham.remainder(classes)}
      {currCity === 'Ottawa' && Content.Ottawa.remainder(classes)}
      {currCity === 'Vancouver' && Content.Vancouver.remainder(classes)}
      {currCity === 'Richmond' && Content.Richmond.remainder(classes)}
      {currCity === 'Surrey' && Content.Surrey.remainder(classes)}
      {currCity === 'Coquitlam' && Content.Coquitlam.remainder(classes)}
      {currCity === 'Burnaby' && Content.Burnaby.remainder(classes)}
      {currCity === 'Windsor' && Content.Windsor.remainder(classes)}
    </Fragment>
  );
}
