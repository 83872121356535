/* SEO content for delivery pages
- any cities with more than on word have spaces removed (ie Toronto West = TorontoWest)
- Grouped cities / regions GTA and GTA East use multiple delcaration method, there is likely a better way
- Export each city name in an object as the defualt 
*/

import React, { Fragment } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SEOList from './SEOList';

const REASON_WIDTH = '9rem';
const GTA = {
  intro: function (currCity) {
    return `Ordering weed for delivery in ${currCity} and the Greater Toronto Area (GTA) has never been easier! At
        S7DAW we have hundreds of dispensaries listing the best quality marijuana, hash, cbd oil, edibles &
        gummies, tinctures and more. If you’re looking for reliable, cheap, and high quality cannabis products in 
        ${currCity}, you’ve come to the right place.`;
  },
};

const GTAEast = {
  intro: function (currCity) {
    return `Looking to order weed for delivery in ${currCity}? It’s never been easier to get premium quality marijuana
      products in the GTA. At S7DAW we have hundreds of dispensaries listing the best quality marijuana, hash,
      cbd oil, edibles & gummies, tinctures and more. If you’re looking for reliable, cheap, and high quality
      cannabis products in ${currCity}, you’ve come to the right place.`;
  },
};
// const GTA = ['Toronto', 'Mississauga', 'Oakville', 'Toronto West'];
// const GTAEast = ['Etobicoke', 'Toronto East'];
const [Mississauga, Toronto, Oakville, TorontoWest] = [GTA, GTA, GTA, GTA];
const [Etobicoke, TorontoEast] = [GTAEast, GTAEast];

const Calgary = {
  intro: function (currCity) {
    return `The city of Calgary is well-known for its use of Cannabis long before its legalization. Ordering weed for delivery in Calgary, Alberta has never been easier. Are you looking for high quality, cheap and reliable cannabis in Calgary? Read on!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How To Get Weed Delivered in CALGARY ALBERTA?
        </Typography>

        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          // width={'100%'}
          display="block"
          className={classes.reasonBox}
        >
          Looking to get weed delivered quickly in Calgary? You can find a large selection of cannabis products,
          including:
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          <li className={classes.seoText}>Dried flower</li>
          <li className={classes.seoText}>Milled (ground) flower</li>
          <li className={classes.seoText}>Pre-rolled joints</li>
          <li className={classes.seoText}>Edible cannabis oil (in bottle)</li>
          <li className={classes.seoText}>Edible cannabis oild (in capsule</li>
          <li className={classes.seoText}>Seeds</li>
          <li className={classes.seoText}>Tinctures</li>
          <li className={classes.seoText}>Marijuana candies, brownies, and other edibles</li>
        </Box>
        <Typography className={classes.seoText}>
          To order online for delivery in Calgary, simply browse the cannabis products you know and love, or try
          something totally different.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Calgary
        </Typography>
        <Typography className={classes.seoText}>
          Although the Calgary government hasn't finalized a system to allow smoking lounges and Cannabis cafés to
          operate, there are brick and mortar outlets. Specifically, Calgary will enable members to establish and run a
          retail outlet to satisfy people's enthusiasm for cannabis. Looking to avoid the hassle? Get marijuana
          delivered to your home today.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Numbers of Cannabis Dispensaries In Calgary
        </Typography>
        <Typography className={classes.seoText}>
          Thousands of interested individuals have expressed interest in establishing Cannabis retail stores, resulting
          in over 1240 applications. However, among the 209 city-approved stores, 63 appear in the city of Calgary,
          thanks to approval from the Alberta Gaming Liquor and Cannabis body.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Calgary's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          The city of Calgary legalized cannabis products, including leaves, edibles, and topicals. Edibles are merely
          products containing Cannabis that you can ingest, including baked goods, candy, beverages, and dissolvable
          strips. On the other hand, Topicals are Cannabis-infused products that you can apply on the skin surface,
          including oils, lotions, and Balms.
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that only people above the age of 18 are permitted to smoke or consume any Cannabis product
          according to the law, and this rule applies to both visitors and permanent residents.
        </Typography>
        <Typography className={classes.seoText}>
          Essentially, minors (people below 18) are prohibited from using the psychoactive drug and are therefore banned
          from entering Cannabis retail outlets. However, if you're above the required age to possess marijuana, the
          government doesn't permit owning more than 30 grams for recreational use, allowing you to use it moderately.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Locations to Use Cannabis in Calgary
        </Typography>
        <Typography className={classes.seoText}>
          Under the Cannabis Consumption Bylaw, you may consume Cannabis on your private property, but smoking, vaping,
          and eating edibles are strictly prohibited in public places. Additionally, property owners can restrict
          cannabis use on their property, preventing you from bringing the substance into the building, except medical
          marijuana.
        </Typography>
        <Typography className={classes.seoText}>
          Although Calgary possesses retinal outlets, consuming Cannabis In these locations is prohibited and will
          attract legal attention if violated. Similarly, members of the public cannot use Cannabis in their vehicle
          (whether moving or stationary), and while smoking lounges and Cafes aren't permitted yet, the rule is bound to
          change as time progresses.
        </Typography>
        <Typography className={classes.seoText}>
          *Note: Failure to abide by these requirements can attract a $100 ticket.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Traveling with Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          If you're a visitor to the city of Calgary and wish to bring your Cannabis along on your journey, feel free.
          It is legal to carry recreational Cannabis on domestic flights within Canada as long as it doesn't exceed 30
          grams.
        </Typography>
        <Typography className={classes.seoText}>
          Ensure your marijuana is kept securely in checked luggage or carry-on and not exposed to prevent theft, a
          robbery, or confrontation from police officers.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, Cannabis oil is subject to fluid restriction; anything than 100 ml (3.4 oz) is permitted on a
          carry-on.
        </Typography>
        <Typography className={classes.seoText}>
          However, if you're a foreigner from another country that wishes to convey their marijuana via a flight to the
          city of Calgary, it's impossible. Essentially, bringing Cannabis in or out of Canada via international flights
          or any crossing methods is illegal.
        </Typography>
        <Typography className={classes.seoText}>
          This rule applies irrespective of whether your previous location legalized or decriminalized Cannabis.
          Therefore, it's best to purchase your preferred strain from retail outlets when you arrive in Calgary.
        </Typography>
        <Typography className={classes.seoText}>
          Ultimately, Calgary is a fantastic location with an appealing atmosphere and people; it's a traveler's
          paradise and the perfect place to express your enthusiasm for Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Although the rules may seem restrictive, it's essential to prevent drug abuse, limit exposure to second-hand
          smoke, and minimize underage Cannabis Consumption.
        </Typography>
      </Fragment>
    );
  },
};

const Edmonton = {
  intro: function (currCity) {
    return `If you call the city of Edmonton home, then as a marijuana enthusiast, you've made a fantastic choice. Besides being one of the largest metropolises in North America and having a wild and adventurous landscape, Edmonton permits medical and recreational marijuana consumption. Find out how you can get weed delivered straight to your home!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Which Cannabis Products Can I Order Online in Edmonton?
        </Typography>
        <Typography className={classes.seoText}>
          You can buy various Cannabis products, including dried buds, plant seeds, Milled Cannabis, capsules, Cannabis
          oils, and Pre-rolled joints. However, irrespective of your affinity for Cannabis or the price of the
          substance, you aren't allowed to buy and carry more than 30 grams of dried Cannabis (or in any other form).
          There are hundreds of different products for you to try that are available for delivery!
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Which Cannabis Products Can I Order Online in Edmonton?
        </Typography>
        <Typography className={classes.seoText}>
          You can buy various Cannabis products, including dried buds, plant seeds, Milled Cannabis, capsules, Cannabis
          oils, and Pre-rolled joints. However, irrespective of your affinity for Cannabis or the price of the
          substance, you aren't allowed to buy and carry more than 30 grams of dried Cannabis (or in any other form).
          There are hundreds of different products for you to try that are available for delivery!
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Edmonton
        </Typography>
        <Typography className={classes.seoText}>
          Aside from ordering for delivery online, you can also visit a licensed retail cannabis store and legally buy
          recreational marijuana. These stores are run by marijuana enthusiasts who wish to turn their knowledge and
          passion for Cannabis into a source of income.
        </Typography>
        <Typography className={classes.seoText}>
          Generally, online vendors of marijuana are available to take your order at 12:01 a.m, while retail stores open
          from 10 a.m to 2 a.m, like liquor stores. Ordering online is also an easier and more discrete option, where
          you aren’t limited to a specific neighbourhood or having to scour the city for the best bud.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Edmonton's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          Cannabis is available for consumption by members of the public, irrespective of whether they take it
          recreationally or for medical purposes. However, it is illegal to buy, sell, or possess recreational marijuana
          if the individual is below 18.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, if you meet the age requirement, you may purchase and consume Cannabis in your private home
          or apartment. However, if you live in an apartment or aren't the landlord of your building, it's best to ask
          the owner whether marijuana is permitted on the property.
        </Typography>
        <Typography className={classes.seoText}>
          The recommendation is predicated on an Edmonton rule that allows landlords, rental companies, condo boards,
          etc., to restrict or permit the smoking and growing of marijuana on their property.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, usage of Cannabis in specific areas is prohibited, including playgrounds, sports fields, outdoor
          pools, off-leash areas, and more. You also have to be several meters away from a hospital, school, child-care
          facility, cemetery, bus terminal, and city-owned golf course when using your marijuana.
        </Typography>
        <Typography className={classes.seoText}>
          Just like alcohol and tobacco, you can consume Cannabis while in a vehicle, especially public ones like taxis,
          buses, and LRT. However, if this vehicle doubles as a home, like a parked RV, you can use your marijuana.
        </Typography>
        <Typography className={classes.seoText}>
          Note that while transporting Cannabis in a vehicle, ensure the item is kept in sealed packaging and out of
          reach of the driver and passengers.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Growing Cannabis in Edmonton
        </Typography>
        <Typography className={classes.seoText}>
          In addition to the liberty to erect private businesses to trade Cannabis, public members can grow their
          private marijuana plants in their guardians. However, federal rules restrict the maximum number of Cannabis
          plants to four per household, not per individual.
        </Typography>
        <Typography className={classes.seoText}>
          Fortunately, there are no rules to the size of each Cannabis plant, so you can grow them to be as large as you
          desire. In fact, cultivation experts estimate that you can increase this plant to a sufficient size that
          satisfies you at a cheaper cost than buying.
        </Typography>
        <Typography className={classes.seoText}>
          However, your landlord can disable your right to grow Cannabis on their property; therefore, it's best to
          consult them before proceeding with this project.
        </Typography>
        <Typography className={classes.seoText}>You can find marijuana seeds online for delivery.</Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Traveling with Cannabis to Edmonton
        </Typography>
        <Typography className={classes.seoText}>
          Although Edmonton supports the use of recreational marijuana, there are some rules about bringing the
          substance into the city. Essentially, traveling from within Canada to Edmonton, you're free to get your weed
          as long as it doesn't exceed 30 grams.
        </Typography>
        <Typography className={classes.seoText}>
          However, if you're traveling from another country into Edmonton, taking weed along with you is illegal and
          will be seized at the airport. Irrespective of your means of transportation, crossing Canada's border with the
          psychoactive drug is unlawful and will produce a legal backlash.
        </Typography>
        <Typography className={classes.seoText}>
          Ultimately, Edmonton is an amazing place to visit if you want adventure, amazing food, and a visually
          appealing environment. It's also the ideal location for you to express your enthusiasm for marijuana with zero
          legal repercussions, as long as you follow the rules.
        </Typography>
      </Fragment>
    );
  },
};

const Montreal = {
  intro: function (currCity) {
    return `Recreational Marijuana is currently legal in Canada, allowing adults that meet the age requirement to buy, carry, share, and trade marijuana. As part of Canada, this legalization extends to Montreal, which permits individuals to carry up to 30 grams of weed at a time. Looking for weed in Montreal? Read on!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How To Get Weed Delivered in Montreal
        </Typography>
        <Typography className={classes.seoText}>
          Luckily, you have many choices when it comes to weed delivery in Montreal. There are a wide variety of
          cannabis products available online, including flower, edibles, tinctures, cbd oil, seeds and more. To order
          weed for delivery, browse weed delivery shops in Montreal. When you’ve found the products you like, simply get
          in touch with the store and place your order. You’ll get a package delivered quickly, discreetly and cheaply.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Montreal
        </Typography>
        <Typography className={classes.seoText}>
          While regular individuals are permitted to consume Marijuana, they aren't allowed to establish businesses
          around the product. In other words, all Cannabis retail stores are owned and operated by the government.
        </Typography>
        <Typography className={classes.seoText}>
          Therefore, Cannabis enthusiasts are required to visit the newly formed, government-run Société québécoise du
          cannabis (SQDC), which is an offshoot of Montreal's liquor board, the SAQ.
        </Typography>
        <Typography className={classes.seoText}>
          Unfortunately, the number of brick-and-mortar SQDC stores is limited. As time progresses, over 150 and 160
          stores will emerge, according to the organization.
        </Typography>
        <Typography className={classes.seoText}>
          Looking for an online alternative to a brick and mortar store?
          <Link to={`/stores/quebec/montreal`} target="_blank" style={{ marginLeft: '1rem' }}>
            <u>Browse stores online for weed delivery in Montreal.</u>
          </Link>
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Cannabis Products Sold in Montreal
        </Typography>
        <Typography className={classes.seoText}>
          Currently, the government-run Société québécoise du cannabis (SQDC) has approximately 150 products available
          to cannabis enthusiasts. These included leaves (both dried and fresh), oil extracts, Marijuana in pills,
          Pre-rolled joints, vapes, accessories, and more.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, the products come in 10 different aromas, from edible items such as cheese and lemon to
          relatively odd ones like diesel fuel. However, the most common aromas are floral, woody, and earthy scents.
          They add an exciting mix to the drug's flavor.
        </Typography>
        <Typography className={classes.seoText}>
          *Note: Delivered Cannabis will be retracted if no one is home upon the order's fulfillment.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Montreal's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          As previously mentioned, Cannabis is legal in Montreal, giving members of the public freedom to purchase their
          preferred strain with no legal implications. However, to satisfy this desire for Marijuana, individuals need
          to be a minimum of 18 years to possess, buy, or consume the psychoactive substance in the city.
        </Typography>
        <Typography className={classes.seoText}>
          This age requirement is subject to change as Montreal's elected government plans to raise the barrier to age
          21, ensuring marijuana users consume based on an educated decision and not emotions or peer pressure.
        </Typography>
        <Typography className={classes.seoText}>
          *Note: Sharing Cannabis with someone below the age limit is a Criminal offense in Montreal.
        </Typography>
        <Typography className={classes.seoText}>
          Keep in mind that smoking marijuana in the exact location as those set by tobacco laws is prohibited, meaning
          you can't enjoy your favorite strain in a University, Hospital, School, CEGEP, and more.
        </Typography>
        <Typography className={classes.seoText}>
          In fact, the incoming CAQ government plans to ban the drugs from all public places further, making it a
          private or indoor activity.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Additional Regulations
        </Typography>
        <Typography className={classes.seoText}>
          If you live in a rented apartment, it's best to ask the condo owner if Marijuana is permitted on the property,
          as Montreal laws allow landlords to either ban or allow Cannabis in their building.
        </Typography>
        <Typography className={classes.seoText}>
          However, most landlords don't exploit this rule as they anticipate such a requirement will produce complaints
          from tenants and increase their vacancy rate.
        </Typography>
        <Typography className={classes.seoText}>
          Montreal also has a "zero tolerance" policy for people found using Cannabis in their vehicles because a
          psychoactive drug produces the same effect as alcohol while driving. The city possesses almost 2,000 trained
          officers that conduct roadside detection tests.
        </Typography>
        <Typography className={classes.seoText}>
          The inspection involves passing a drug saliva testing device or a blood test demand if an individual is caught
          with impaired driving not caused by alcohol.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Traveling with Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          If you're traveling within Canada, you're free to carry your weed on the journey; however, some regulations
          govern this action. For example, you cannot carry more than 30 grams of Cannabis, and the item must stay
          sealed in a bag.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, if you're traveling from another country into Canada, bringing Marijuana along with you is
          prohibited. Essentially, it is illegal to cross the Canadian border with the psychoactive drug in two,
          irrespective of your means of transportation. Refusing to abide by these rules will result in criminal
          charges.
        </Typography>
        <Typography className={classes.seoText}>
          As a part of Canada, Montreal legally recognized Cannabis as a substance individuals can consume
          recreationally. This realization allows marijuana enthusiasts to pursue their passion for the drug.
        </Typography>
      </Fragment>
    );
  },
};

const Brampton = {
  intro: function (currCity) {
    return `Looking to get weed in Brampton? Luckily there are many options for marijuana lovers in this great city. Looking for edibles, flower, cbd oil, tinctures, or something else? Read on!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Benefits of Different Types of Marijuana
        </Typography>
        <Typography className={classes.seoText}>
          Anyone conversant with Marijuana knows different strains of this psychoactive drug exist, and they are defined
          by the kind of Cannabis plant and the way it is prepared. Additionally, these strains present different
          benefits that health and cosmetic companies use to their advantage.
        </Typography>
        <Typography className={classes.seoText}>
          Currently, there are four dominant strains of Marijuana, Indica, Sativa, hybrid, and Ruderalis.
        </Typography>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Indica
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Cannabis Indica produces large amounts of THC (the main psychoactive compound) and low levels of CBD
          (cannabidiol), making it a very potent drug. These factors make it very relaxing and sedating, resulting in
          its efficacy for treating insomnia.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Sativa
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Cannabis Sativa, unlike Indica, possesses lower levels of THC and higher amounts of CBD, resulting in a more
          energizing effect on people that consume the substance. Therefore, it is commonly used to help people focus on
          alleviating depression and exhaustion.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Hybrid
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          The hybrid strain erupts from cross germinating the seeds of Indica and Sativa Cannabis plants, resulting in a
          plant with the effect of both strains. This version is mostly preferred by many, and most hybrid Cannabis
          available products have more of an energizing effect than intoxication.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Ruderalis
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Ruderalis is rarely used for recreational purposes as it has a very low level of THC compared to the other
          Cannabis plants. However, this factor makes the Ruderalis the dominant option for medicinal Marijuana.
        </Box>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis vs. Online Weed Delivery in Brampton
        </Typography>
        <Typography className={classes.seoText}>
          The Brampton City Council voted on January 21, 2019, to permit regular individuals to erect private Cannabis
          retail stores. This liberty created more convenient access to Cannabis for lovers of the drug and opened a new
          source of income for entrepreneurs looking for a new opportunity.
        </Typography>
        <Typography className={classes.seoText}>
          However, before establishing such a business, interested individuals must visit the Alcohol and Gaming
          Commission of Ontario (AGCO). This independent provincial regulator is responsible for certifying businesses
          and issuing the license necessary to operate and control a Cannabis retail business in Brampton.
        </Typography>
        <Typography className={classes.seoText}>
          Keep in mind that although these businesses are operable in Brampton, the law presents restrictions regarding
          their location. One significant rule is that Cannabis retail shops should not be closer than 150 meters from a
          school.
        </Typography>
        <Typography className={classes.seoText}>
          There are many options to order weed online in Brampton and a variety of types and flavours to suit everyone.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Cannabis Dispensaries In Brampton
        </Typography>
        <Typography className={classes.seoText}>
          If you're interested in the quantity of Cannabis retail shops in Brampton, current statistics reveal 27 active
          shops and 18 pending applications. In other words, within a few years of Cannabis legalization, Brampton's
          inhabitants are slowly capitalizing on its advantages.
        </Typography>
        <Typography className={classes.seoText}>
          Besides smoking Cannabis, there are other products available that can provide you the benefits of Marijuana
          without inhaling any fumes. These include:
        </Typography>

        <SEOList
          gridColumns={2}
          listContent={['Cannabis extracts', 'Chocolates', 'Drinks', 'Capsules', 'Baked goods', 'Gummies']}
        />

        <Typography className={classes.seoText}>
          People with medical conditions or personal values that prevent them from deliberately inhaling Cannabis fumes
          can opt for the numerous options that contain Cannabis. Some are presented in sufficient doses that do not
          result in intoxicating, leaving you with nothing but the benefits of Marijuana.
        </Typography>
        <Typography className={classes.seoText}>
          Many of these products are available to order online
          <Link to={`/stores/ontario/brampton`} target="_blank" style={{ marginLeft: '1rem' }}>
            <u>See Weed Delivery Shops in Brampton</u>
          </Link>
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Brampton's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          As previously mentioned, laws governing Brampton permit the recreational usage of Cannabis, allowing regular
          individuals and businesses to consume the substance liberally.
        </Typography>
        <Typography className={classes.seoText}>
          The legalization occurred on October 17, 2018, involving both Ontario and Brampton, permitting only people
          above the age of 19 to consume, purchase, and grow this psychoactive drug for non-medical purposes.
        </Typography>
        <Typography className={classes.seoText}>
          It's also worth noting that after the legalization of Cannabis, citizens weren't allowed to purchase Cannabis
          anywhere else but through the Ontario Cannabis Store website. This rule allowed the government to monitor
          people's purchases and limit abuse.
        </Typography>
        <Typography className={classes.seoText}>
          However, as time progressed, the government lifted the rule and launched a private retail model on April 1,
          2019, that allowed the sale of non-medical Marijuana in private retail stores.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Permitted and Prohibited Cannabis Smoking Areas in Brampton
        </Typography>
        <Typography className={classes.seoText}>
          As expected, there are limitations on where an individual can smoke Cannabis, and these include:
        </Typography>

        <SEOList
          listContent={[
            'Designated areas for smoking',
            'Permanent residence',
            "20 meters away from children's playgrounds and publicly owned sports fields and buildings",
          ]}
        />

        <Typography className={classes.seoText}>
          Additionally, specific laws exist on where smoking of Marijuana is prohibited, and these include:
        </Typography>
        <SEOList
          listContent={[
            'Indoor common areas in tertiary institutions, condos, and apartment buildings',
            'Enclosed are work and public places',
            'Places where children gather',
            'Nine meters code to a hospital',
            '20 meters from any publicly owned sports field or recreational facility',
            'Transit terminals and bus shelters',
            'Reserved seating areas at entertainment locations',
          ]}
        />
        <Typography className={classes.seoText}>
          These rules exist to ensure the general public explores or expresses their affinity for this drug without
          presenting any discomfort to other individuals.
        </Typography>
        <Typography className={classes.seoText}>
          Brampton is a progressive city with laws that permit recreational Marijuana.
        </Typography>
      </Fragment>
    );
  },
};

const Guelph = {
  intro: function (currCity) {
    return `While cannabis was illegal for several decades, Canada and it’s cities, including Guelph, decided to legalize the product, allowing members of the public to consume marijuana recreationally.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          What Kind of Weed Can Be Delivered in Guelph?
        </Typography>
        <Typography className={classes.seoText}>
          Fortunately, the number of options available for weed delivery has grown exponentially over the years.
          Nowadays, you can find a variety of cannabis products available for delivery in Guelph, including:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Edibles, such as brownies, candies and gummies',
            'Seeds',
            'Flowers',
            'Tinctures',
            'CBD Oil',
            'And more',
          ]}
        />
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Guelph
        </Typography>
        <Typography className={classes.seoText}>
          Two months after subscribing to the legalization of marijuana, Guelph proceeded to opt-in on permitting
          regular individuals to erect retail storefronts (businesses) to commercially trade Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          This legalization happened on December 17, 2018, and is currently regulated by the Alcohol Gaming Commission
          of Ontario alongside a Cannabis working group that focuses on public health, protection of youth, and
          prevention of illegal activities.
        </Typography>
        <Typography className={classes.seoText}>
          It's also worth noting that these stores are permitted to sell various products, including Topical, Pre-Rolls,
          vapes, Cannabis leaf, edibles, extracts, accessories, and many more.
        </Typography>
        <Typography className={classes.seoText}>
          This permit to erect retail shops dedicated to selling Cannabis created a new income source for Guelph
          residents. Essentially, individuals can establish their private marijuana outlets in areas specifically
          designed for commerce or mixed-use. These locations include Malls, Plazas, Neighborhood retails, strip malls,
          and more.
        </Typography>
        <Typography className={classes.seoText}>
          It is also possible to order weed online in Guelph. The easiest way is to browse weed delivery shops in
          Guelph.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Numbers of Cannabis Dispensaries In Guelph
        </Typography>
        <Typography className={classes.seoText}>
          People consider Guelph to be one of Ontario’s most green cities, and one of the reasons for this title is the
          presence of growing Cannabis stores. Currently, Guelph possesses over 25 retail stores that are fully
          functional, with more awaiting approval from the government.
        </Typography>
        <Typography className={classes.seoText}>
          According to the Alcohol and Gambling Commission of Ontario, the body responsible for controlling Guelph's
          cannabis retail stores under the Cannabis Licence Act estimates more stores will appear within the downtown
          core.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          About Cannabis in Guelph
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that a significant portion of Guelph's residents consumes Cannabis, with one-third of the
          population using the substance in the past year. According to a Canadian survey, these individuals make up 15%
          - 22% of people in the location and are expected to rise as time progresses.
        </Typography>
        <Typography className={classes.seoText}>
          The study also revealed that roughly a quarter of these individuals who consumed marijuana in the past years
          use it daily, which is advisable for maintaining a healthy body and social life.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, over a quarter of Cannabis users admit using marijuana less than one day a month, and these
          individuals include people aged 30 to 40.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Guelph's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          As previously mentioned, consuming Cannabis for medical or recreational purposes is legal in Guelph under the
          federal Cannabis Act. This permission was given to public members on October 17, 2018, enabling them to grow,
          possess, and use the substance without legal implications from law enforcement.
        </Typography>
        <Typography className={classes.seoText}>
          However, there are limits to consuming this drug, and one of the most significant inhibitors is age.
          Essentially, public members are allowed to buy, sell, and grow Cannabis as long as they are over the age of
          19, which falls in the same category as other controlled substances like alcohol and tobacco.
        </Typography>
        <Typography className={classes.seoText}>
          The Cannabis, Smoke-Free Ontario and Road Safety Statute Law Amendment Act (2017) regulates the sale,
          production, distribution, consumption, and possession of Cannabis. Their regulations include where and when
          you can use Cannabis, how much you can carry, growing Cannabis, consuming Cannabis at your workplace, and
          more.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Regulations for Marijuana Dispensaries in Guelph
        </Typography>
        <Typography className={classes.seoText}>
          While individuals are permitted to erect retail storefronts to trade Cannabis, they must follow a lift of
          rules and regulations to operate peacefully. The first rule is to acquire a building permit to operate in the
          approved areas since a Municipality cannot license a retail cannabis store.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, Cannabis stores are not permitted to be in 150 meters of sensitive areas of the community or
          publically owned locations. These locations include:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Daycares',
            'Youth centers',
            'Playgrounds',
            'Hospitals',
            'Parks',
            'and other sensitive areas in the community',
          ]}
        />
        <Typography className={classes.seoText}>
          Ultimately, these regulations ensure that Cannabis stores are always available to people that enjoy the drug,
          and at the same time, aren't a nuisance to others.
        </Typography>
      </Fragment>
    );
  },
};

const Hamilton = {
  intro: function (currCity) {
    return `Cannabis stores in Hamilton are making it easier and easier to get your goodies. And one of the easiest ways is by opting for weed delivery. Luckily, it’s now easier than ever to get a variety of products delivered right to your doorstep.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          What Cannabis Products Are Available for Weed Delivery
        </Typography>
        <Typography className={classes.seoText}>
          Instead of taking the time out of your busy schedule, marijuana delivery in Hamilton is a great option for all
          fans. You can find the following products available for delivery in Hamilton:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Edibles, including brownies and cakes, candies and gummies;',
            'Flowers in different strains;',
            'Tinctures;',
            'Seeds;',
            'Therapeutic CBD Oils;',
            'And more',
          ]}
        />
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Hamilton
        </Typography>
        <Typography className={classes.seoText}>
          Besides the legalization of marijuana, Hamilton also permits its citizens to trade Cannabis. This decision
          began in 2019 when all municipalities in Ontario were asked by the Province to decide whether to subscribe or
          opt-out of permitting regular members of the public to operate private cannabis retail stores.
        </Typography>
        <Typography className={classes.seoText}>
          The deadline for municipalities to decide to opt out was January 22, 2019, and on January 14, 2019, Hamilton
          City Council expressed their participation in the permit. In other words, citizens can operate a licensed
          retail cannabis store in Hamilton, within rules and regulations presented by the government.
        </Typography>
        <Typography className={classes.seoText}>
          With this new permit, Hamilton experienced the growth of Cannabis retail stores in areas designated for
          commerce or mixed-use. These locations include:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Plazas',
            'Business Improvement Areas (BIA)',
            'Malls',
            'Strip malls',
            'Neighborhood retail such as variety stores',
            'Big box centers',
          ]}
        />

        <Typography className={classes.seoText}>
          Despite Cannabis legalization and the citizen's liberty to consume this substance, the government also
          provides some rules to reduce illegal sales, protect youth, and secure public health. These rules require
          Cannabis retail stores to not establish shops within 150 meters of:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Libraries',
            'Parks and Open spaces',
            'Hospitals',
            'Schools',
            'Alcohol and Detox Centres',
            'Community Centres',
            'Mental Health Centres',
            'Child Care Centres',
          ]}
        />
        <Typography className={classes.seoText}>
          Looking to stay in your sweats and socks? You don’t even need to leave the comfort of your home to get
          cannabis. Check out your weed delivery options in Hamilton.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Cannabis Dispensaries In Hamilton
        </Typography>
        <Typography className={classes.seoText}>
          If you wish to visit Hamilton but worry about the quantity of Cannabis retail shops available in the area,
          it's worth noting that far over 45 pot shops operate throughout the city. Additionally, the Alcohol and Gaming
          Commission of Ontario website reveals 42 others in the application stage, meaning Hamilton will get an
          increase in Cannabis dispensaries as time progresses.
        </Typography>
        <Typography className={classes.seoText}>
          These dispensaries sell Cannabis products in different varieties, including raw marijuana, edibles, beverages,
          beauty products, oils, and many more. You're guaranteed to get your desired Cannabis product Hamilton.
          Ordering online makes it even more convenient.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Hamilton's History Of Weed Usage
        </Typography>
        <Typography className={classes.seoText}>
          Before the liberal government legalized Cannabis in Hamilton, enterprising pot peddlers throughout the city
          and established illegal retail shops to trade marijuana in plain sight.
        </Typography>
        <Typography className={classes.seoText}>
          These resilient entrepreneurs attracted law enforcement efforts to flush the problem with actions like
          Toronto's Project Claudia which eliminated several stores, but not enough to damage the market.
        </Typography>
        <Typography className={classes.seoText}>
          Eventually, after much debate, the government presented the public with a new law in 2018, allowing regular
          individuals to consume weed peacefully. However, trading marijuana was illegal, and suppliers caught
          exchanging this psychoactive drug for money were subject to fines as high as $500,000.
        </Typography>
        <Typography className={classes.seoText}>
          In fact, the government established the Cannabis Intelligence Coordination Centre intending to close every
          illegal marijuana dispensary and or send the unlawful and unsafe supply of cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Fortunately, in 2019, the ban was lifted and regular individuals can express their desire and skill in weed
          cultivation and commerce without any penalty. This freedom allows the growth of Hamilton's weed culture, and
          the strict laws on age and location limit any danger or inconvenience that might occur with consuming
          Cannabis.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Hamilton's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          As mentioned earlier, recreational use of Cannabis is considered a lawful action in Hamilton based on the
          legal agreement on October 17, 2018, by the federal government of Canada to legalize the substance.
        </Typography>
        <Typography className={classes.seoText}>
          This law sets the foundation regarding how, where, and who can purchase and own this psychoactive drug, and
          fortunately, the legal age to own Cannabis is 19. This factor ensures that the individual is developed enough
          to logically assess their decision before consuming the substance in any form.
        </Typography>
        <Typography className={classes.seoText}>
          *Note: The minimum age to possess Cannabis is the same as the one needed to consume alcohol and tobacco.
        </Typography>
        <Typography className={classes.seoText}>
          Besides implementing the Canadian law on Cannabis legalization, Hamilton also permits individuals to start
          businesses trading the substance, thereby facilitating easy access under government supervision.
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that while the government permits marijuana consumption, citizens are prohibited from
          smoking this substance in the following places:
        </Typography>
        <SEOList
          listContent={[
            'Enclosed public places',
            'Enclosed workplaces',
            'Childcare centers',
            'The reserved seating of an outdoor facility, sports arena, or entertainment venue',
            'A hospital, community health facility, or psychiatric facility',
            'Restaurant or bar patios',
            'In and within 20 meters of children’s playgrounds',
            'Sporting areas',
            'Common areas (elevators, parking garages, hallways, etc.)',
          ]}
        />

        <Typography className={classes.seoText}>
          Cannabis is legal in Hamilton and you can easily acquire the substance from multiple dispensaries online and
          offline. Additionally, several laws exist to regulate the citizens' use of marijuana, thereby promoting a
          healthy and responsible society.
        </Typography>
      </Fragment>
    );
  },
};

const Markham = {
  intro: function (currCity) {
    return `Markham, Ontario, is famous for being one of the best places in Canada to express your enthusiasm for Cannabis. Besides having extremely friendly people and a gorgeous city, there is no shortage of marijuana, with shops and weed delivery services available.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          What Weed Can I Order Online In Markham?
        </Typography>
        <Typography className={classes.seoText}>
          Nevertheless, these locations can supply you with a variety of Cannabis products, including Cannabis flowers,
          edibles, extracts and tinctures, cbd oil, and other great products. Finding the right store is the first step.
          Simply click on this link to browse weed delivery shops in Markham. Once you’ve found a product you love,
          arranging delivery with the seller is easy, fast, and discreet.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries Vs. Online Delivery In Markham
        </Typography>
        <Typography className={classes.seoText}>
          Markham has unique retail shops dedicated to trading Cannabis with regular members of the public. These shops,
          also known as Marijuana stores, are privately owned, meaning the law permits the consumption and trading of
          Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Recent years have experienced an increase in these Licence marijuana shops, pushing black market dealers aside
          to help facilitate the legal trade of this psychoactive drug. Furthermore, the presence of these retail
          outlets gives enthusiasts access to high-quality strains that do not contain harmful substances.
        </Typography>
        <Typography className={classes.seoText}>
          Getting weed delivered online takes all of the benefits of a brick and mortar experience and takes it to
          another level. Without leaving your home, you can browse local marijuana stores and have products delivered to
          your door in discreet packaging for amazing prices.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Numbers of Cannabis Dispensaries In Markham
        </Typography>
        <Typography className={classes.seoText}>
          Currently, Markham has over 40 companies that legally produce and sell marijuana to the public under Health
          Canada regulations. These businesses receive frequent visits from Health Canada to assess their facility, test
          products, assess security footage, and make sure the drugs are handled according to the rules and regulations
          provided.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Why Markham Marijuana is Impressive
        </Typography>
        <Typography className={classes.seoText}>
          Irrespective of whether you consume marijuana for medical or recreational purposes, it presents benefits that
          help millions of people daily. The THC within this psychoactive drug relieves multiple conditions for some
          individuals, and these issues include depression, anxiety, stress, aches, and many more.
        </Typography>
        <Typography className={classes.seoText}>
          While more research is needed to uncover the potential marijuana presents to consumers, Markham ensures that
          growers produce high-quality strains to keep enthusiasts satisfied.
        </Typography>
        <Typography className={classes.seoText}>
          Besides marijuana, there are other activities in this location that you can enjoy, such as entertainment, fine
          dining, arts & culture, sports, and shopping. Furthermore, Markham offers high-quality Cannabis at affordable
          prices, making this place a worthwhile location for Marijuana lovers.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Markham's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          Markham accepts marijuana as a drug that individuals can consume liberally, whether for medical or
          recreational purposes. However, this liberty comes with a few rules and regulations to limit or even remove
          the possibility of addiction, abuse, or other negative factors.
        </Typography>
        <Typography className={classes.seoText}>
          If you wish to visit this unique location, it's essential to understand the local laws regarding marijuana and
          avoid getting into complications with the law. One standard rule is that individuals below the age of 19
          cannot purchase, possess, consume, or grow recreational Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, the law permits you to use Cannabis in your private residence, outside public areas (except
          stated otherwise), personal vehicle, and designated smoking areas. However, it is illegal to smoke marijuana
          in enclosed public spaces, places where children gather, care homes, common indoor areas, schools, public
          property, and more.
        </Typography>
        <Typography className={classes.seoText}>
          These laws that identify smoking areas ensure that you exercise your right to use marijuana without inhibiting
          other people's quality of living. However, keep in mind that while you can legally purchase this psychoactive
          drug, you can limit how much you can possess.
        </Typography>
        <Typography className={classes.seoText}>
          You may not possess more than 30 grams of dried Cannabis, 15 grams of edibles, 70 grams of liquid products
          containing Cannabis, one Cannabis seed, and 0.25 grams of concentrate. If you prefer growing this plant, the
          law doesn't permit more than four cannabis plants in your garden for personal use.
        </Typography>
      </Fragment>
    );
  },
};

const Ottawa = {
  intro: function (currCity) {
    return `A lot has changed over the past few years in Ottawa when it comes to marijuana. Nowadays, you can order weed online in a fast, convenient and discreet way. You can even browse the best deals on different kinds of edibles, flowers, cbd oils, tinctures and more.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Ordering Online in Ottawa
        </Typography>
        <Typography className={classes.seoText}>
          Looking to skip the hassle of an in-person visit? Picking the right online vendor is key to making sure you
          get the product you want. We recommend you look at community reviews when you’re looking for weed delivery in
          Ottawa. That way, you can hear from other weed lovers about the quality of the product, and the service they
          received.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries
        </Typography>
        <Typography className={classes.seoText}>
          Individuals above the age of 19 are permitted to purchase Cannabis and the correct outlet is the Ontario
          Cannabis Store. Another option is to patronize certified marijuana retailers as these businesses became legal
          by April 1, 2019, and are overseen by the Alcohol and Gaming Commission of Ontario.
        </Typography>
        <Typography className={classes.seoText}>
          These locations are permitted to sell a variety of Cannabis products to adults within the legal age and these
          products may include different strains of marijuana, beauty products, beauty products, and edibles.
        </Typography>
        <Typography className={classes.seoText}>
          Consuming edible Cannabis products was illegal in Ottawa until October 17, 2018, where the government
          permitted adults to produce their consumable marijuana items. Nevertheless, Ottawa allows you to purchase your
          desired marijuana strain through brick-and-mortar retailers or via the internet.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Numbers of Cannabis Dispensaries In Ottawa
        </Typography>
        <Typography className={classes.seoText}>
          With residents of Ottawa having the liberty to establish their private marijuana outlet and offer high-quality
          strains to enthusiasts, several businesses have erupted and capitalized on the opportunity.
        </Typography>
        <Typography className={classes.seoText}>
          In fact, statistics reveal that Ottawa possesses more than 100 cannabis shops that are either open, awaiting
          approval from the government, or are in the process of opening. These entrepreneurs are skilled and highly
          knowledgeable and skilled in the budding industry, allowing this location to flourish with nothing but the
          best form of Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Therefore, if. You wish to inhale or consume marijuana of extreme quality, Ottawa retailers are ideal for this
          situation as they produce healthy plants according to government regulations, leaving you with nothing but
          perfection.
        </Typography>
        <Typography className={classes.seoText}>
          It’s easier than ever to order weed online from Ottawa, and experience some amazing bud.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Ottawa's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          On October 17, 2018, Ottawa accepted Cannabis as a substance its inhabitants can consume liberally without any
          negative response from law officials. However, to limit abuse, addiction, and inconveniences due to this
          psychoactive drug, there are specific rules citizens must follow.
        </Typography>
        <Typography className={classes.seoText}>
          One significant rule is that smoking and vaping Cannabis products on publicly owned properties like libraries,
          transit stations, city parks, recreational centers, busses, etc., is prohibited. Additionally, smoking or
          vaping marijuana in specific locations that are labeled a "no smoking zone" is prohibited and will attract a
          penalty.
        </Typography>
        <Typography className={classes.seoText}>Some "no-smoking zones" include:</Typography>
        <SEOList
          listContent={[
            'Public places like hospitals, restaurants, shopping centers, etc.',
            'Enclosed workplaces',
            'Common areas of buildings like hallways, laundry rooms, entertainment rooms, etc.',
            'In or near a school',
            'Childcare centers',
            'Hospitals',
            'Bar patios or near restaurants.',
          ]}
        />

        <Typography className={classes.seoText}>
          It's also worth noting that Cannabis is treated the same way as alcohol; drivers conveying the substance in a
          vehicle or boat are required to keep it unopened in its original packaging. In other words, having exposed
          Cannabis in your vehicle is guaranteed to get you in trouble.
        </Typography>
        <Typography className={classes.seoText}>
          *Note: people under the age of 19 cannot possess cannabis without medical authorization, and people above the
          legal age cannot possess more than 30 grams of marijuana.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Regulations for Marijuana Dispensaries in Ottawa
        </Typography>
        <Typography className={classes.seoText}>
          Currently, the Alcohol and Gaming Commission of Ontario regulates and enforces rules regarding how cannabis
          retailers can operate, including controlling their location and executions
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, this body assesses applications from interested Cannabis traders and decides whether they
          qualify to run a Cannabis store. Ultimately, they decide which stores will open and which ones are unfit to
          run.
        </Typography>
        <Typography className={classes.seoText}>
          Some rules private Cannabis stores follow is ensuring not to erect their store within 150 meters of a school
          as failing to do so defies the education act. Also, these stores are permitted to open in areas dedicated to
          commerce like malls, complexes, markers, etc.
        </Typography>
        <Typography className={classes.seoText}>Some additional laws include:</Typography>
        <SEOList
          listContent={[
            'Cannabis stores must be standalone stores and not part of a larger business',
            'Must be enclosed by walls that separate it from other commercial use or activity',
            'Cannot function as a secondary or primary entrance to another commercial establishment or area.',
          ]}
        />

        <Typography className={classes.seoText}>
          Ultimately, these rules ensure that Cannabis stores remain within reach of people that enjoy the substance
          without becoming a nuisance to others that do not consume the drug.
        </Typography>
      </Fragment>
    );
  },
};

const Vancouver = {
  intro: function (currCity) {
    return `Ordering weed online for delivery is fast, convenient, and discreet. The best part of all? You never have to leave your house to find the weed products you love. Find out what you need to know about weed in Vancouver and place your order today!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How To Get Weed Delivered in Vancouver
        </Typography>
        <Typography className={classes.seoText}>
          Instead of taking the time out of your busy schedule, marijuana delivery in Vancouver is a great option. You
          can get a variety of weed delivered right to your door, including:
        </Typography>
        <SEOList gridColumns={2} listContent={['Edibles', 'Seeds', 'Flowers', 'Tinctures', 'CBD Oil', 'And more']} />

        <Typography className={classes.seoText}>
          Vape, wax, hash, and other extracts are becoming increasingly popular, along with Cannabis oil, as it
          possesses many health benefits that Canadians understand thoroughly.
        </Typography>
        <Typography className={classes.seoText}>
          You also get other edibles like chocolate bars, gummy candies, CBD, Cannabidiol, and other options, allowing
          everyone to experience the benefits and flavor of marijuana without getting intoxicated.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How soon can I get my weed product through delivery in Vancouver?
        </Typography>
        <Typography className={classes.seoText}>
          Although every weed delivery brand is different, most brands are able to deliver within the same day or within
          5 business days depending on the size of your order and your exact location.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Vancouver
        </Typography>
        <Typography className={classes.seoText}>
          Another way to get weed in vancity is through brick and mortar locations.
        </Typography>
        <Typography className={classes.seoText}>
          The number of products available is impressive as they include variants like:
        </Typography>
        <SEOList listContent={['Animal gas', 'Jet fuel', 'Cake frosting', 'Duke Nukem']} />

        <Typography className={classes.seoText}>Number Of Cannabis Suppliers In Vancouver</Typography>
        <Typography className={classes.seoText}>
          While the number of dispensaries that trade Cannabis in Vancouver isn't in the thousands, they are
          substantial, with over 40 outlets running in the city. These numbers aren't surprising but expected of a city
          where a previously illegal substance trade began approximately three years ago (2018).
        </Typography>
        <Typography className={classes.seoText}>
          However, as time progresses, more individuals will capitalize on the market and acquire the necessary
          qualifications for government approval, thereby growing the population of Cannabis suppliers.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Vancouver's History Of Weed Usage
        </Typography>
        <Typography className={classes.seoText}>
          Vancouver has always been conversant with Cannabis, and its government has a more liberal approach towards
          this psychoactive drug. One factor that fueled this city's affinity for Cannabis is the ideal growing
          condition for the plant. For a large part of the 20th century, it flourished with an industry worth around $6
          billion.
        </Typography>
        <Typography className={classes.seoText}>
          However, Cannabis was outlawed in the 1920s after the directors of the Federal Division Of Narcotics Control
          returned from League Of Nations meetings where they broached an international control of the drug.
          Essentially, causal consumption of marijuana was no longer permitted.
        </Typography>
        <Typography className={classes.seoText}>
          Nevertheless, no arrest was made regarding the drug until roughly a decade later, in 1937, then full
          enforcement of the enactment began in the 1960s with penalties being given. But, despite the legal threats and
          penalties, the cannabis culture thrived in Vancouver, especially with the advent of the hippie era.
        </Typography>
        <Typography className={classes.seoText}>
          Cannabis remained prevalent in Vancouver, but that fact didn't mean the authorities turned a blind eye to
          smokers, joint operations, and illegal growers. However, in the 1990s, the attitude toward this psychoactive
          drug changed as scientific research revealed more positives than negatives about Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          By the mid-2000s, marijuana usage was commonplace, and although still illegal, the authorities rarely
          approached individuals using the substance. By 2012, a poll revealed that 61% of British Columbia residents
          favored Cannabis legalization, and on October 17, 2018, liberal leader Justin Trudeau legalized recreational
          marijuana.
        </Typography>
        <Typography className={classes.seoText}>
          Since the legalization, there were no significant changes as the number of underage pot users increased by a
          mere 1%. Also, 337,000 people tried Cannabis for the first time in 2017, but data reveals these individuals
          were 45 years of age and older.
        </Typography>
        <Typography className={classes.seoText}>
          Besides knowledge of its health benefits, one factor that moved the law towards marijuana legalization is to
          cripple the black market for Cannabis. Although this goal is yet to be achieved, statistics reveal that over
          47% of Canadian pot users buy from legal sources, and as time progresses, we can expect that figure to rise.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Rules And Regulations For Cannabis in Vancouver
        </Typography>
        <Typography className={classes.seoText}>
          The first question asked when Cannabis and a location are presented whether usage of the substance is
          permitted in such a location. Fortunately, the law permits the non-medical use of Cannabis and has done so
          since October 17, 2018.
        </Typography>
        <Typography className={classes.seoText}>
          According to Canadian law, Individuals must be 19 years of age and above to purchase, own, use, or grow
          non-medical Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          Additionally, regular citizens aren't allowed to grow non-medical Cannabis without a formal license from the
          government. Essentially, it is illegal to purchase Cannabis anywhere but in a government-run store, permitted
          retailer, or government online store.
        </Typography>
        <Typography className={classes.seoText}>
          Other legal requirements governing Cannabis in Vancouver include:
        </Typography>
        <SEOList
          listContent={[
            'People cannot possess more than 30 grams of Cannabis.',
            'Cannabis growers are not permitted to grow more than four pots at home, and none of these plants should be visible to the public.',
            'Cannabis consumption is prohibited in vehicles, irrespective of whether they are parked.',
            'It is illegal to smoke marijuana in areas not designated for smoking.',
            'Marijuana transportation is permitted as long as the substance is rid within its original packaging and not accessible by anyone else but the owner.',
          ]}
        />

        <Typography className={classes.seoText}>
          These are some of the Fair rules and regulations governing the use of recreational Cannabis, ensuring people
          liberally inhale or ingest the substance with little room for abuse.
        </Typography>
      </Fragment>
    );
  },
};

const Richmond = {
  intro: function (currCity) {
    return `Looking for the best weed delivery options in Richmond BC? You’ve come to the right place! At S7DAW, we have the best weed deals on the internet so that you can find the cannabis products you love at a fantastic price. The best part? Delivery straight to your door!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How To Get Weed Delivered in Richmond
        </Typography>
        <Typography className={classes.seoText}>
          Marijuana products come in different forms for both medical and recreational consumption that are available
          for purchase in Richmond. Some of these forms include:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={['Marijuana gummies', 'Joints', 'Buds', 'Topicals', 'Vape', 'CBD Oil', 'Extracts', 'Tinctures']}
        />
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries In Richmond
        </Typography>
        <Typography className={classes.seoText}>
          The City of Richmond has chosen to prohibit brick and mortar retail sale of recreational marijuana in
          Richmond. The city has indicated that it will enforce these bylaws should any brick and mortar retailers
          attempt to operate in the city.
        </Typography>
        <Typography className={classes.seoText}>
          Currently, the best way to obtain non-medical marijuana in Richmond is by ordering it online through weed
          delivery services from reputable retailers, growing it yourself, ordering from the province’s online
          distribution centre, or visiting outlets elsewhere in British Columbia.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          What You Need to Know About Marijuana in Richmond BC
        </Typography>
        <Typography className={classes.seoText}>
          Non-medical cannabis was legalized in Canada as of October 17, 2018. That means citizens are allowed to
          purchase and possess marijuana for recreational purposes. Furthermore, adults aged 19 or older are free to
          grow four marijuana plants per household, as long as they aren’t within eyeshot of the public or underaged
          individuals. Landlords and strata corporations can also add restrictions for their residents.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          What You Need to Know About Marijuana in Richmond BC
        </Typography>
        <Typography className={classes.seoText}>
          Non-medical cannabis was legalized in Canada as of October 17, 2018. That means citizens are allowed to
          purchase and possess marijuana for recreational purposes. Furthermore, adults aged 19 or older are free to
          grow four marijuana plants per household, as long as they aren’t within eyeshot of the public or underaged
          individuals. Landlords and strata corporations can also add restrictions for their residents.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          No Need To Buy Weed Illegally
        </Typography>
        <Typography className={classes.seoText}>
          While smoking weed or consuming the substance is permitted in Richmond, Richmond’s Public Health Regulation
          Bylaw No. 6989 applies to all types of smoking, including tobacco, cannabis, and vaping.
        </Typography>
        <Typography className={classes.seoText}>
          Smoking in Richmond is not permitted in public parks and school grounds, or within 9 meters of transit
          shelters, customer areas such as patios, doors, or windows.
        </Typography>
        <Typography className={classes.seoText}>
          Although your car or truck may seem private enough to consume your weed, it can attract legal issues, as It is
          a criminal offence to drive while impaired by cannabis or any other drug.
        </Typography>
        <Typography className={classes.seoText}>
          To avoid potential charges, it is recommended that you travel with your marijuana in the trunk or concealing
          it in an area that's not within reach of the driver or any passenger, similar to how you transport alcohol.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          No Need To Buy Weed Illegally
        </Typography>
        <Typography className={classes.seoText}>
          Opting for weed from sketchy vendors exposes you to products with poor quality control.
        </Typography>
        <Typography className={classes.seoText}>
          Therefore, you run the risk of consuming substances riddled with mould, pesticides, and other factors that can
          trigger seizures and other grave illnesses. Furthermore, proper lab testing and treatment allows medical
          experts to know if it's ideal for your treatment or perfect for recreational consumption.
        </Typography>
        <Typography className={classes.seoText}>
          Ultimately, we recommend purchasing from reputable vendors that outline their standards, and ideally have
          reviews from other users. This ensures your safety and the marijuana quality.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Marijuana Types and Their Benefits
        </Typography>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Sativa
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          The Sativa variant of Cannabis is tall with thin leaves and can reach up to 13 feet. While they take longer to
          mature than other options, they have a lower dose of CBD but a higher dose of THC. Essentially, Sativa
          provided the common high or energizing feeling, making users feel more productive and creative than relaxed.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Indica
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Indica is native to India, Pakistan, and turkey, and is adaptive to the harsh and dry climate. The plant
          appears short and stocky, with bushy greenery and thick leaves. Furthermore, this variant has a higher
          concentration of CBD and average THC levels. This combination results in a more relaxing feeling, helping with
          nausea, pain, and decreased appetite.
        </Box>

        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Hybrid
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Hybrid Cannabis is typically grown in greenhouses and is a combination of Sativa and Indica. The plant's look
          is unpredictable as it depends on the parent plant. Nevertheless, they have an increased THC percentage,
          allowing it to reduce anxiety, stress, and symptoms of chemotherapy.
        </Box>

        <Typography className={classes.seoText}>
          Ultimately, marijuana is legal in Richmond and can be consumed by adults aged 19 or older. However, the city
          of Richmond has decided to{' '}
          <a
            href="https://www.richmond.ca/safety/environment/cannabis.htm#:~:text=The%20City%20of%20Richmond%20has,attempt%20to%20operate%20within%20Richmond."
            target="_blank"
            rel="noopener noreferrer"
          >
            prohibit retail sales of recreational cannabis
          </a>{' '}
          in the city. This means that weed delivery in Richmond is a great alternative for enthusiasts. On this page
          you’ll find a list of retailers that offer weed delivery to Richmond, with some same-day options available for
          your favourite products.
        </Typography>
      </Fragment>
    );
  },
};

const Surrey = {
  intro: function (currCity) {
    return `Surrey BC is a great place for weed enthusiasts. Along with beautiful city views, Surrey also offers a lively weed culture and many options for weed delivery. Are you looking for fast delivery and great weed deals for delivery? Check out the storefronts in Surrey on this page and place your order for cannabis delivery today.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Which Cannabis Products Are Available For Delivery in Surrey, British Columbia
        </Typography>
        <Typography className={classes.seoText}>
          Looking for cannabis delivery in Surrey? Here are some of the products that you can buy online:
        </Typography>
        <SEOList
          gridColumns={3}
          listContent={[
            'Flowers',
            'Seeds',
            'CBD',
            'Edibles',
            'Oils',
            'Gummies',
            'Tinctures',
            'Shakes & Drinks',
            'Coffee',
            '& More',
          ]}
        />
        <Typography className={classes.seoText}>
          Besides smoking and vaping, marijuana can be consumed and purchased in different forms. For example, Cannabis
          edibles have been permitted in Surrey since October 17th, 2019.
        </Typography>
        <Typography className={classes.seoText}>
          Cannabis extracts and Topicals are other forms of the product have also been legal since October 17, 2019,
          concentrates also have strict rules governing them; for example, they can have up to 1000 milligrams of THC in
          one package.
        </Typography>
        <Typography className={classes.seoText}>
          However, these products are prohibited from being used to make claims about health, cosmetics, or diet. Also,
          they cannot be made appealing to youths or have elements associated with alcoholic beverages, tobacco, or
          vaping products.
        </Typography>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          How Weed Works in Surrey
        </Typography>
        <Typography className={classes.seoText}>
          As previously mentioned, Surrey has specific rules and regulations governing the consumption of marijuana, and
          one instruction is their age limit. Essentially, members of the public aren't permitted to consume weed if
          they're below age 19.
        </Typography>
        <Typography className={classes.seoText}>
          This restriction includes using the substance in any form such as smoking, eating, vaping, smoking, and many
          more. In addition, individuals can grow their own small Cannabis garden consisting of four plants per
          household and nothing more.
        </Typography>
        <Typography className={classes.seoText}>
          Also, sharing from your possession is permitted in Surrey; however, exchanging this hallucinogen for money or
          any other form of payment is a legal offence. All trades on Cannabis must be done by a certified vendor.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Regulations on Cannabis possession
        </Typography>
        <Typography className={classes.seoText}>
          Only adults aged 19 and above can purchase and consume marijuana in Surrey. Also, the law requires adults to
          carry nothing more than 30 grams of recreational Cannabis in a public place.
        </Typography>
        <Typography className={classes.seoText}>
          Only adults aged 19 and above can purchase and consume marijuana in Surrey. Also, the law requires adults to
          carry nothing more than 30 grams of recreational Cannabis in a public place.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Consuming Cannabis in Public In Surrey
        </Typography>
        <Typography className={classes.seoText}>
          Adults aged 19 and above are legally permitted to Vape, smoke, or use Cannabis in any shape or form in public.
          However, this consumption must only happen in places where such activities are permitted since second-hand
          smoke can irritate people, especially children.
        </Typography>
        <Typography className={classes.seoText}>
          To protect the public from discomfort caused by second-hand smoke, vaping and smoking are prohibited in the
          following public areas.
        </Typography>
        <SEOList
          listContent={[
            'Skate parks, Playgrounds, swimming areas, sports fields, spray pools, or any areas associated with these places',
            'Public workplaces, buildings, or common areas of condos, apartments, and dormitories, or within six metres of windows, air intakes, and doorways associated with these places. ',
            'Six metres of transit shelters, bus stops, train stations, ferry docks, and similar locations. ',
            'Municipal, provincial, and regional parks, except for campsites designated for smoking',
            'Public patios',
            'Health board properties, except in areas designated for smoking ',
          ]}
        />
        <Typography className={classes.seoText}>
          It's worth mentioning that these regulations are subject to change. For example, adults can smoke in a hotel
          if the establishment permits it. Also, hospitals and other medical care centres can designate specific areas
          for patients to consume their medical marijuana.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Brick and Mortar Weed Dispensaries in Surrey
        </Typography>
        <Typography className={classes.seoText}>
          All commercial transactions related to Cannabis in Surrey are handled exclusively by the government, licensed
          private retailers, and the B.C government-operated online stores.
        </Typography>
        <Typography className={classes.seoText}>
          However, it's worth noting that members of the public can receive licensing from the Liquor and Cannabis
          Regulation Branch, which regulates and monitors all the business's commercial activities.
        </Typography>
        <Typography className={classes.seoText}>
          Once licensed, vendors cannot sell liquor or tobacco, only Cannabis, and cannabis accessories. Also, the law
          requires vendors to display their valid licences in a location visible to the public.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, medical marijuana has an excise stamp on the packaging, and without this indicator, it is
          not recognized as a legal item and will result in a penalty.
        </Typography>
        <Typography variant="h4" className={classes.seoTitle}>
          Ready To Order?
        </Typography>
        <Typography className={classes.seoText}>
          Ultimately, Surrey is a fantastic place to live or visit, and the legalization of medical and recreational
          marijuana makes it even more appealing. To get your weed quickly and without hassle, check out the weed
          delivery vendors in Surrey BC listed on this page and place your order today.
        </Typography>
      </Fragment>
    );
  },
};

const Coquitlam = {
  intro: function (currCity) {
    return `Looking for fast, easy and high quality cannabis products in Coquitlam?  You’ve come to the right place! On this page you’ll find the best weed deals for delivery in Coquitlam, British Columbia.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          What Kind of Cannabis Can You Order For Delivery in Coquitlam?
        </Typography>
        <Typography className={classes.seoText}>
          Cannabis comes in different forms, giving you a lot of options to consider when placing your order. You’re
          practically spoiled for choice! Some of the products that you can order include:
        </Typography>
        <SEOList
          gridColumns={3}
          listContent={[
            'Joints',
            'Flowers',
            'Edibles, such as gummies',
            'Extracts',
            'Oils',
            'Tinctures',
            'Sodas',
            'Coffee ',
            'Concentrates',
            'Topicals',
            'Vape',
          ]}
        />
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Here are just a few of the reasons why you should order weed for delivery in Coquitlam, BC.
        </Typography>

        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Timesaving and cost-effective
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Ordering marijuana online makes it possible to acquire it whenever you want, at any time, and at any location.
          Additionally, most online vendors offer same-day delivery and allow you to cancel your order with little to no
          hassle.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Assured quality
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Ordering online grants you access to the suppliers information, as well as a host of reviews. This helps
          ensure you receive high-quality cannabis that's void of impurities and possible contaminants.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Access to more products
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Coquitlam online cannabis merchants can display their vast inventory of marijuana products including vapes,
          joints, and edibles on S7DAW. Furthermore, if your preferred vendor is out of stock, you can move to the next
          supplier with zero hassle and try something new! It's convenient and effective.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Discrete shopping
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          If you prefer privately enjoying your marijuana, then ordering online shields you from prying eyes. You can
          receive your concealed purchase at your doorstep without having your private business noticed by onlookers.
        </Box>

        <Typography variant="h4" className={classes.seoTitle}>
          Rules on Cannabis possession in Coquitlam
        </Typography>
        <Typography className={classes.seoText}>
          Canada legalized the use of recreational and medical Cannabis on October 17, 2018, under the Cannabis Act of
          the federal government. Enthusiasts can now consume their favourite hallucinogen without fear of encountering
          legal complications from the act.
        </Typography>
        <Typography className={classes.seoText}>
          Since the legalization, Coquitlam has conducted multiple background research and public consultations to
          create an effective Cannabis Regulatory Framework. This framework governs the use of Cannabis alongside its
          cultivation, processing, and retailing.
        </Typography>
        <Typography className={classes.seoText}>
          Coquitlam also has regulations on how much Cannabis adults can carry, ensuring people consume responsibly and
          don't engage in any illegal trade.
        </Typography>
        <Typography className={classes.seoText}>
          By the law, individuals aged 19 and above can carry up to 30 grams of dried marijuana, whether it's medically
          prescribed or recreational. However, this amount only applies to being in a public setting. Additionally, the
          age restriction includes vaping, smoking, and consumption of edibles since Cannabis can impede proper brain
          development in minors. As for creating a weed garden, the government doesn't permit having more than four
          plants cultivated for personal consumption.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, adults can possess a maximum of 1000 grams of dried marijuana, whether medically prescribed
          or recreational. As expected, this rule applies to non-public places like a home or private property.
        </Typography>
        <Typography className={classes.seoText}>
          Lastly, you're free to share the Cannabis you bought or grow; however, there must be no financial compensation
          attached to the exchange or the recipient being under the legal age. Failing to adhere to these requirements
          comes with strict repercussions from the law.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Coquitlam Law Against Smoking Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          While consuming Cannabis in any form is considered legal in Coquitlam, smoking or vaping the substance is
          prohibited under the City’s Smoking Control Bylaw. Essentially, this law implies that smoking and vaping this
          hallucinogen is not legal in any location that the prohibition specifies.
        </Typography>
        <Typography className={classes.seoText}>
          These locations can include City parks, transit shelters, and places of public assembly. Fundamentally, public
          places aren't ideal for smoking since second-hand smoke is uncomfortable to other individuals, so the
          government ensures your consumption isn't a hassle to someone else.
        </Typography>
        <Typography className={classes.seoText}>
          Furthermore, the Province’s Cannabis Control and Licensing Act have consumption guidelines that apply
          throughout the province. These regulations allow cannabis consumption on private residential premises, except
          otherwise indicated by the property owner.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Order Weed Delivery in Coquitlam Today
        </Typography>
        <Typography className={classes.seoText}>
          Are you ready to place an order? Ordering is fast and easy, just pick the vendor that suits you best. Whether
          you’re enjoying a beautiful walk in one of Coquitlams many parks (Como Lake, Mundy Park, Town Centre Park…),
          having a bite to eat, or Netflix and chilling, ordering cannabis for delivery has never been faster or easier.
        </Typography>
      </Fragment>
    );
  },
};

const Burnaby = {
  intro: function (currCity) {
    return `Are you looking for epic weed in Burnaby, British Columbia? You’ve come to the right place. You’ll find the best weed delivery options local to you and ready for delivery.`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          What Can You Order in Burnaby with Weed Delivery?
        </Typography>
        <Typography className={classes.seoText}>
          In Burnaby you can use Cannabis in many different forms, available for delivery. You can find what you’re
          looking for on S7DAW! Here are some of the products you may be able to order:
        </Typography>
        <SEOList
          gridColumns={2}
          listContent={[
            'Flower',
            'Joints',
            'Edibles (including gummies, candies, etc.)',
            'Vape fluid',
            'Extracts',
            'Drinks, such infused coffee and sodas',
            'Concentrates',
            'Topicals',
          ]}
        />
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick and Mortar Weed Dispensaries in Burnaby
        </Typography>
        <Typography className={classes.seoText}>
          While marijuana is legal in Burnaby, there aren't any private licensed vendors since the government hasn't
          granted this access yet. However, approval of private marijuana dispensaries will happen soon as the
          government has expressed interest in the subject.
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that future private Cannabis outlets are envisioned to supplement-government owned
          alternatives; however, they will be under strict regulations to prevent misconduct.
        </Typography>
        <Typography className={classes.seoText}>
          Ultimately, members of the public can only purchase Cannabis from brick and mortar shops in Burnaby from
          government dispensaries. You can find these physical locations in the town centre throughout Burnaby.
        </Typography>
        <Typography className={classes.seoText}>
          Ordering weed for delivery in Burnaby is much easier, discreet, and hassle-free.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Consuming Cannabis in public in Burnaby
        </Typography>
        <Typography className={classes.seoText}>
          Like all controlled substances, there are specific areas where you aren't allowed to smoke.
        </Typography>
        <Typography className={classes.seoText}>
          To ensure people can legally consume their marijuana without interfering with people's day or causing unwanted
          health complications, here are the prohibitions on public Cannabis usage:
        </Typography>
        <SEOList
          listContent={[
            'All multi-use paths and city parks ',
            'Facilities and lands owned or leased by Burnaby and intended for civic or public use, except designated a smoking area ',
            'Sidewalks, bicycle lanes, crosswalks, boulevards, right of way, and other passageways located near city parks, businesses, institutions, etc ',
            'Assembly-use properties like places of worship, schools, theatres, and similar locations.',
            'Within six metres of any bus stop, be benches, shelters, poles, transit stations, and signage.',
            'Any enclosed area of business premises or workplace open to the public ',
            'Enclosed customer service areas where food and beverages are served on site. ',
          ]}
        />

        <Typography className={classes.seoText}>
          Naturally, there are exceptions to this rule; for example, members of the public can smoke in a hotel or motel
          room where smoking is permitted.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Regulations on Cannabis possession
        </Typography>
        <Typography className={classes.seoText}>
          Similar to alcohol, marijuana usage has a legal age limit of 19 or more in Burnaby. Essentially, individuals
          ages 19 and more can smoke, vape, consume edible, and even grow their Cannabis plant with no legal
          complications.
        </Typography>
        <Typography className={classes.seoText}>
          On the other hand, individuals below 19 caught buying, selling, or consuming cannabis in any form will receive
          the legal penalty for misuse of a controlled substance.
        </Typography>
        <Typography className={classes.seoText}>
          Burnaby has unique rules on how much Cannabis members of the public can carry since the past rules were mostly
          based on dried Cannabis. However, other factors and adjustments were needed, resulting in the following
          regulations:
        </Typography>
        <SEOList
          listContent={[
            '5 grams of the fresh cannabis',
            '15 grams of edible product',
            '70 grams of liquid product',
            '0.25 grams of concentrates (solid or liquid)',
            '1 cannabis plant seed',
          ]}
        />

        <Typography className={classes.seoText}>
          An adult with the 19+ legal age to consume Cannabis can legally possess up to 150 grams of fresh marijuana.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Order Your Weed for Delivery Today
        </Typography>
        <Typography className={classes.seoText}>
          Burnaby is one of Canada's most beautiful cities with a legal system that's tolerant of medical and
          recreational marijuana use. Browse the stores on this page to find what you’re looking for! Get your cannabis
          delivered in Burnaby, and then go enjoy the beautiful parks and scenery of Burnaby, like the Capilano
          Suspension Bridge Park, Grouse Mountain, Quarry Rock, Deer Lake, and more.
        </Typography>
      </Fragment>
    );
  },
};

const Windsor = {
  intro: function (currCity) {
    return `Finding weed for delivery in Windsor has gotten easy over the past year. Many businesses are offering free (or even same day) weed delivery!`;
  },
  middle: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Benefits of Different Types of Marijuana
        </Typography>
        <Typography className={classes.seoText}>
          As a plant, Cannabis comes in different varieties containing different levels of THC (the intoxicating
          chemical in Marijuana) and CBD (cannabidiol). These changes in quantity result in different stains, which
          possess individual benefits and results that people can exploit.
        </Typography>
        <Typography className={classes.seoText}>
          Currently, there are four strains of Marijuana, which are Indica, Sativa, hybrid, and Ruderalis, and below is
          more detail about each variant.
        </Typography>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Indica
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Cannabis Indica possesses significant amounts of THC and low levels of CBD, making it an extremely powerful
          drug. However, these factors also make it very relaxing and sedating, so doctors employ them to tackle sleep
          problems.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Sativa
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          Cannabis Sativa, unlike Indica, produces very lower levels of THC and substantial amounts of CBD, thereby
          resulting in a more stimulating effect on people that consume the psychoactive drug. This factor makes it a
          good remedy for depression, exhaustion, and loss of focus.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Hybrid
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          As expected, the hybrid strain is a product of cross germinating the seeds of Indica and Sativa Cannabis
          plants to create a plant containing the effect of both strains. This type of Cannabis plant is preferred by
          many since it's engineered to produce mild intoxication by significant feelings of vigor.
        </Box>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          p={1}
          width={REASON_WIDTH}
          display="block"
          className={classes.reasonBox}
        >
          Ruderalis
        </Box>
        <Box display="block" boxShadow={3} className={classes.reasonText}>
          The Ruderalis is a doctor's favorite since it's the go-to stain for medical Marijuana. This privilege erupts
          extremely low THC levels compared to other plants, leaving the user with little to no feelings of
          intoxication.
        </Box>
      </Fragment>
    );
  },
  remainder: function (classes) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.seoTitle}>
          Brick And Mortar Cannabis Dispensaries Vs. Online Delivery In Windsor
        </Typography>
        <Typography className={classes.seoText}>
          On April 1, 2019, Windsor permitted regular citizens to erect private businesses to trade Cannabis to the
          general public. However, before such an establishment can operate, the individual must receive approval from
          the government and undergo inspection by Health Canada.
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that these stores trade Cannabis in different forms that allow people to choose between
          smoking the substance, using it via beauty products, or ingesting it via edibles. These edibles include gummy
          candies, chocolate, baked goods, weed-infused beverages, lollipops, etc.
        </Typography>
        <Typography className={classes.seoText}>
          You can also order these products online in Windsor for delivery.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Numbers of Cannabis Dispensaries In Windsor
        </Typography>
        <Typography className={classes.seoText}>
          With the legalization of Marijuana and the permission to operate a private retail outlet for the drug, Windsor
          is experiencing substantial growth in cannabis stores, with nearly two dozen locations approved across the
          location.
        </Typography>
        <Typography className={classes.seoText}>
          Specifically, there are 23 Cannabis retail stores in Windsor that are fully operational under legal terms. On
          the other hand, 26 applications are pending approval from the Alcohol and Gaming Commission of Ontario,
          indicating the rise of a new industry.
        </Typography>
        <Typography className={classes.seoText}>
          If you choose to order your weed online you can bypass the hassle of leaving the house. Fast, discreet, and
          cheap delivery are all reasons to check out weed delivery shops in Windsor.
        </Typography>

        <Typography variant="h4" className={classes.seoTitle}>
          Windsor's Rules And Regulations For Cannabis
        </Typography>
        <Typography className={classes.seoText}>
          Fortunately, Windsor has no rule against regular individuals consuming Marijuana as the legalization of this
          substance happened on October 17, 2018.
        </Typography>
        <Typography className={classes.seoText}>
          It's worth noting that individuals younger than 19 are not permitted to possess, purchase, or use Cannabis.
          This regulation is predicated on the fact that children aren't considered developers enough to control their
          urge for the drug. Additionally, a child's brain doesn't need any psychoactive drug to complicate brain
          development during those years.
        </Typography>
        <Typography className={classes.seoText}>
          Nevertheless, adults of legal age who wish to express their affinity for Cannabis can purchase the drug
          through the Ontario Cannabis Store (OCS), a subsidiary of the LCBO.
        </Typography>
        <Typography className={classes.seoText}>
          However, despite the legalization of Cannabis for adults, misuse of the substance can bring you unwanted legal
          complications. For example, it is considered impaired driving if you proceed to drive a vehicle after using
          Cannabis.
        </Typography>
        <Typography className={classes.seoText}>
          While there is no specific quantity of THC in your system (the intoxicating element of Cannabis) that's
          considered sufficient to drive a vehicle, the rule of thumb is to avoid Cannabis and drive a car.
        </Typography>
        <Typography className={classes.seoText}>
          Since the legalization, there were no significant changes as the number of underage pot users increased by a
          mere 1%. Also, 337,000 people tried Cannabis for the first time in 2017, but data reveals these individuals
          were 45 years of age and older.
        </Typography>
        <Typography className={classes.seoText}>Additional laws include:</Typography>
        <SEOList
          listContent={[
            'Passengers cannot use Cannabis in a vehicle.',
            'Drivers cannot store Cannabis in their car; however, the drug should keep it out of reach and sight of the driver and passengers during transportation.',
            'Using Cannabis in or within 20 meters of a school is illegal.',
            'You should not use tobacco, Vape, or any recreational smoking tool within 20 meters of a recreational field and building.',
          ]}
        />

        <Typography className={classes.seoText}>
          Besides being an interesting location with beautiful sceneries, Windsor is a worthwhile place to visit if
          you're enthusiastic about Cannabis, and a great place to live for marijuana lovers.
        </Typography>
      </Fragment>
    );
  },
};

const exports = {
  GTA,
  GTAEast,
  Mississauga,
  Toronto,
  Oakville,
  TorontoWest,
  Etobicoke,
  TorontoEast,
  Calgary,
  Edmonton,
  Montreal,
  Brampton,
  Guelph,
  Hamilton,
  Markham,
  Ottawa,
  Vancouver,
  Richmond,
  Surrey,
  Coquitlam,
  Burnaby,
  Windsor,
};
export default exports;
