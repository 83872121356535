import React, { useEffect, useState, Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  CardActionArea,
  CardMedia,
  // Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';
import { NAV_OPTIONS, STATUS, SCREEN_STATES, DISCOUNT_TYPES, MOCK_DEAL_DATA } from '../utils/constants';
import { parseIdFromLink, makeImageKitURL } from '../utils/helpers';
import { getLocation } from '../cookies';
import api from '../utils/api';
import moment from 'moment';
import CountdownTimer from '../components/CountdownTimer';
import LoadingError from '../components/LoadingError';
import DescriptionBox from '../components/DescriptionBox';
import PreviewContext from '../PreviewContext';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  containerWrapper: {
    maxWidth: theme.screen.maxWidth,
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  sidePanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    marginLeft: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '80%',
      marginLeft: theme.spacing(0),
    },
  },
  dealImage: {
    border: '1px solid #ebebeb',
    objectFit: 'contain',
    width: '50vw',
    height: '60vh',
    [theme.breakpoints.down('md')]: {
      width: '50vw',
      height: '40vh',
    },
  },
  dealName: {
    fontSize: '28pt',
    fontWeight: '700',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22pt',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  dealDescription: {
    fontSize: '12pt',
    marginBottom: theme.spacing(2),
  },
  interaction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  dealTime: {
    fontSize: '12pt',
  },
  claimButton: {
    width: 200,
    height: 45,
    fontWeight: '700',
    fontSize: '12pt',
    boxShadow: '0px 0px',
  },
  storeLink: {
    width: 250,
    marginTop: theme.spacing(3),
  },
  storeName: {
    margin: theme.spacing(1),
  },
  rating: {
    display: 'flex',
    alignContent: 'center',
    margin: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ratingValue: {
    marginLeft: theme.spacing(1),
  },
  storeArrow: {
    position: 'absolute',
    right: 8,
    bottom: 16,
    color: theme.palette.grey['800'],
  },
  storeImg: {
    border: '1px solid #ebebeb',
    height: 250,
    width: 250,
    marginTop: theme.spacing(0.5),
    objectFit: 'contain',
  },
  table: { width: '100%' },
  tableCellHeading: { fontSize: '14pt', border: '0px', padding: 0, width: 96 },
  tableCellDate: { fontSize: '14pt', border: '0px', padding: '0px !important' },
  claimSection: {
    // width: 425,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // border: "1px solid #ebebeb",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    fontWeight: '700',
  },
  statusSection: {},
  buttonSection: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
  },
  timerLabel: {
    fontSize: '14pt',
    marginBottom: theme.spacing(0.5),
  },
  timer: {
    fontSize: '18pt',
    fontWeight: '700',
    color: theme.palette.primary.main,
  },
  dealEnded: {
    fontSize: '18pt',
    fontWeight: '700',
    color: theme.palette.error.main,
  },
}));

const getDeal = async (id) => {
  return api
    .getDeal(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function DealDetailsView(props) {
  const classes = useStyles();

  const { handleNavBarChange, history } = props;
  const location = getLocation();

  const [deal, setDeal] = useState({});
  const [store, setStore] = useState({});

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const preview = useContext(PreviewContext);
  const dealId = !preview.state.screen ? props.match.params.id : props.match.params.dealId;

  const dealSlug = props.match.params.dealSlug;
  const province = props.match.params.province;
  const city = props.match.params.city;

  useEffect(() => {
    if (dealId === '17321') {
      if (MOCK_DEAL_DATA.dealSlug === dealSlug && MOCK_DEAL_DATA._embedded.store.published) {
        MOCK_DEAL_DATA.startDate = moment.utc(MOCK_DEAL_DATA.startDate).local().toDate();
        MOCK_DEAL_DATA.endDate = moment.utc(MOCK_DEAL_DATA.endDate).local().toDate();
        setDeal(MOCK_DEAL_DATA);
        setLoading(false);
        setStore(MOCK_DEAL_DATA._embedded.store);
      } else {
        setLoadingError(true);
      }
    }

    if (dealId !== '17321') {
      getDeal(dealId)
        .then((res) => {
          if (res._embedded.store.published) {
            res.startDate = moment.utc(res.startDate).local().toDate();
            res.endDate = moment.utc(res.endDate).local().toDate();
            setDeal(res);
            if (!preview) {
              // ao: sdaw-1162 - redirect to the correct province name
              const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
              if (urlProv !== res._embedded.store.address.province.replace(/ /gi, '-').toLowerCase()) {
                window.location.href = `/deals/${res._embedded.store.address.province
                  .replace(/ /gi, '-')
                  .toLowerCase()}/${city.replace(/ /gi, '-').toLowerCase()}/${dealId}/${dealSlug}`;
              }
            }
            setLoading(false);
            setStore(res._embedded.store);
          } else {
            window.location.assign('/deals');
          }
        })
        .catch(() => {
          setLoadingError(true);
        });
    }
  }, [dealId, dealSlug, city, preview]);

  const expired = deal.startDate && new Date() > deal.endDate;
  const upcoming = deal.endDate && new Date() < deal.startDate;

  const dealImageURL = makeImageKitURL(deal._embedded?.file?.url, { width: '700' }, 'deal');
  const storeLogoURL = makeImageKitURL(store.storeImage, { width: '250' }, 'storefront');

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${deal.name ? `${deal.name} from ${store.name} | ` : ``}S7DAW`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={deal.description ? deal.description : ``} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading && !loadingError && <Typography style={{ marginTop: '20px' }}>Loading...</Typography>}
      {loadingError && (
        <LoadingError
          onClick={() => history.push(`/deals/${location.province?.toLowerCase()}/${location.city?.toLowerCase()}`)}
          browse="Deals"
        />
      )}
      {!loading && !loadingError && (
        <div className={classes.containerWrapper}>
          <div className={classes.contentContainer}>
            <img className={classes.dealImage} src={dealImageURL} alt={deal.name}></img>
            <div className={classes.sidePanel}>
              <div className={classes.description}>
                <Typography variant="h1" className={classes.dealName}>
                  {deal.name}
                </Typography>
                {/* <Typography variant="body1" className={classes.dealDescription}>
                  {deal.description}
                </Typography> */}
                <DescriptionBox text={deal.description} fullMode></DescriptionBox>
                <Typography style={{ marginTop: '20px', marginBottom: '20px' }}></Typography>
                {deal.discount.type === DISCOUNT_TYPES[0] && (
                  <Typography>
                    <strong>Amount: </strong>
                    {deal.discount.amount}% Off
                  </Typography>
                )}
                {deal.discount.type === DISCOUNT_TYPES[1] && (
                  <Typography>
                    <strong>Amount: </strong>${deal.discount.amount} Off
                  </Typography>
                )}
                {deal.discount.type === DISCOUNT_TYPES[2] && (
                  <Typography>
                    <strong>{`Buy ${deal.buy}, Get ${deal.get} Free!`}</strong>
                  </Typography>
                )}
                {deal.spendAtLeast !== 0 && (
                  <Typography>
                    <strong>Minimum Purchase:</strong> ${deal.spendAtLeast}
                  </Typography>
                )}
                {deal.buyAtLeastGrams !== 0 && (
                  <Typography>
                    <strong>Minimum Purchase:</strong> {deal.buyAtLeastGrams} Gram{deal.buyAtLeastGrams > 1 ? 's' : ''}
                  </Typography>
                )}
                {deal.buyAtLeastQuantity !== 0 && (
                  <Typography>
                    <strong>Minimum Purchase:</strong> {deal.buyAtLeastQuantity} Unit
                    {deal.buyAtLeastQuantity > 1 ? 's' : ''}
                  </Typography>
                )}
                <Typography>
                  <strong>Applies To: </strong>
                  {deal.appliesTo}
                </Typography>
              </div>
              <div className={classes.interaction}>
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" className={classes.tableCellHeading}>
                        <strong>Start Date: </strong>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellDate}>
                        {moment(deal.startDate).format('MMMM Do, YYYY')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.tableCellHeading}>
                        <strong>End Date: </strong>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellDate}>
                        {moment(deal.endDate).format('MMMM Do, YYYY')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className={classes.claimSection}>
                  <div className={classes.statusSection}>
                    {upcoming && (
                      <Fragment>
                        <Typography className={classes.timerLabel}>STARTS IN:</Typography>
                        <CountdownTimer time={deal.startDate}></CountdownTimer>
                      </Fragment>
                    )}
                    {expired && <Typography className={classes.dealEnded}>DEAL EXPIRED</Typography>}
                    {!expired && !upcoming && (
                      <Fragment>
                        <Typography className={classes.timerLabel}>ENDS IN:</Typography>
                        <CountdownTimer time={deal.endDate}></CountdownTimer>
                      </Fragment>
                    )}
                  </div>
                  {/* <div className={classes.buttonSection}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.claimButton}
                      disabled={upcoming || expired}
                    >
                      CLAIM YOUR DEAL!
                    </Button>
                  </div> */}
                </div>
                <div className={classes.storeLink}>
                  <Typography variant="body1">Shop this deal at:</Typography>
                  <CardActionArea
                    onClick={(event) => {
                      if (!preview.state.screen) {
                        handleNavBarChange(event, store.storeType === 'Storefront' ? 1 : 2);
                      } else {
                        preview.changeScreen(SCREEN_STATES.STORE_DETAILS);
                      }
                    }}
                    component={!preview.state.screen ? Link : undefined}
                    to={
                      !preview.state.screen
                        ? `${
                            store.storeType === 'Storefront'
                              ? NAV_OPTIONS.find((option) => option.name === 'Storefronts').path
                              : NAV_OPTIONS.find((option) => option.name === 'Deliveries').path
                          }/${province.replace(/\s+/g, '-').toLowerCase()}/${city
                            .replace(/\s+/g, '-')
                            .toLowerCase()}/${parseIdFromLink(
                            deal._embedded.store._links.self.href,
                            0,
                            '{?projection}'
                          )}/${deal._embedded.store.slug}`
                        : undefined
                    }
                    style={{
                      color: '#000000',
                      textDecoration: 'none',
                    }}
                  >
                    {storeLogoURL && (
                      <CardMedia component="img" alt={store.name} image={storeLogoURL} className={classes.storeImg} />
                    )}
                    <Typography className={classes.storeName}>{store.name}</Typography>
                    <div className={classes.rating}>
                      <Rating
                        name="read-only"
                        value={store.avgRating ? Number(store.avgRating) : 0}
                        precision={0.5}
                        size="small"
                        className={classes.stars}
                        readOnly
                      />
                      <Typography variant="caption" className={classes.ratingValue}>
                        {store.avgRating ? Number(store.avgRating).toFixed(1) : 0}
                      </Typography>
                    </div>
                    <ArrowForwardIcon fontSize="large" className={classes.storeArrow}></ArrowForwardIcon>
                  </CardActionArea>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DealDetailsView;
