export default {
  'Acton Vale': { lat: 45.65, lon: -72.5667 },
  Adstock: { lat: 46.05, lon: -71.0667 },
  Albanel: { lat: 48.8833, lon: -72.45 },
  Alma: { lat: 48.55, lon: -71.65 },
  Amos: { lat: 48.5667, lon: -78.1167 },
  'Ange-Gardien': { lat: 45.3333, lon: -72.9 },
  Asbestos: { lat: 45.7667, lon: -71.9333 },
  'Baie-Comeau': { lat: 49.2167, lon: -68.15 },
  'Baie-DUrfe': { lat: 45.4167, lon: -73.9167 },
  Barraute: { lat: 48.5333, lon: -77.6333 },
  Beaconsfield: { lat: 45.4333, lon: -73.8667 },
  Beauharnois: { lat: 45.3167, lon: -73.8667 },
  Becancour: { lat: 46.3419, lon: -72.432 },
  Bedford: { lat: 45.1167, lon: -72.9833 },
  Beloeil: { lat: 45.5667, lon: -73.2 },
  Berthierville: { lat: 46.0833, lon: -73.1833 },
  Blainville: { lat: 45.6667, lon: -73.8833 },
  Boisbriand: { lat: 45.6167, lon: -73.8333 },
  'Bois-des-Filion': { lat: 45.6667, lon: -73.75 },
  Boucherville: { lat: 45.6, lon: -73.4333 },
  Bromont: { lat: 45.3167, lon: -72.65 },
  Brossard: { lat: 45.45, lon: -73.4667 },
  'Brownsburg-Chatham': { lat: 45.65, lon: -74.4167 },
  Candiac: { lat: 45.3833, lon: -73.5167 },
  'Cap-Chat': { lat: 49.1, lon: -66.6833 },
  Carignan: { lat: 45.45, lon: -73.3 },
  Chambly: { lat: 45.45, lon: -73.2833 },
  Chambord: { lat: 48.4333, lon: -72.0667 },
  Chapais: { lat: 49.7833, lon: -74.8667 },
  Charlemagne: { lat: 45.7167, lon: -73.4833 },
  Chateauguay: { lat: 45.3833, lon: -73.75 },
  Chertsey: { lat: 46.1167, lon: -73.8833 },
  Chibougamau: { lat: 49.9167, lon: -74.3667 },
  'Chute-aux-Outardes': { lat: 49.0833, lon: -68.4 },
  Coaticook: { lat: 45.1333, lon: -71.8 },
  Contrecoeur: { lat: 45.85, lon: -73.2333 },
  'Cookshire-Eaton': { lat: 45.4167, lon: -71.6333 },
  'Cote-Saint-Luc': { lat: 45.4667, lon: -73.6667 },
  Cowansville: { lat: 45.2, lon: -72.75 },
  Crabtree: { lat: 45.9667, lon: -73.4667 },
  Danville: { lat: 45.7833, lon: -72.0167 },
  Daveluyville: { lat: 46.2, lon: -72.15 },
  Delson: { lat: 45.3667, lon: -73.55 },
  'Deux-Montagnes': { lat: 45.5333, lon: -73.9 },
  'Dolbeau-Mistassini': { lat: 48.8833, lon: -72.2333 },
  'Dollard-Des Ormeaux': { lat: 45.4833, lon: -73.8167 },
  Dorval: { lat: 45.45, lon: -73.75 },
  Drummondville: { lat: 45.8833, lon: -72.4833 },
  Dunham: { lat: 45.1333, lon: -72.8167 },
  'East Angus': { lat: 45.4833, lon: -71.6667 },
  Farnham: { lat: 45.2833, lon: -72.9833 },
  'Ferme-Neuve': { lat: 46.7, lon: -75.45 },
  Fermont: { lat: 52.7833, lon: -67.0833 },
  'Fort-Coulonge': { lat: 45.85, lon: -76.7333 },
  Franklin: { lat: 45.0333, lon: -73.9167 },
  Gaspe: { lat: 48.8333, lon: -64.4833 },
  Gatineau: { lat: 45.4765, lon: -75.7013 },
  Granby: { lat: 45.4, lon: -72.7333 },
  Hampstead: { lat: 45.4833, lon: -73.65 },
  'Havre-Saint-Pierre': { lat: 50.2333, lon: -63.6 },
  Hudson: { lat: 45.45, lon: -74.15 },
  Huntingdon: { lat: 45.0833, lon: -74.1667 },
  Joliette: { lat: 46.0167, lon: -73.45 },
  'Kingsey Falls': { lat: 45.85, lon: -72.0667 },
  Kirkland: { lat: 45.45, lon: -73.8667 },
  Labelle: { lat: 46.2833, lon: -74.95 },
  'Lac-Brome': { lat: 45.2167, lon: -72.5167 },
  Lachute: { lat: 45.65, lon: -74.3333 },
  'Lac-Megantic': { lat: 45.58, lon: -70.8833 },
  Lacolle: { lat: 45.0833, lon: -73.3667 },
  'La Conception': { lat: 46.1833, lon: -74.6833 },
  'La Minerve': { lat: 46.3167, lon: -74.95 },
  Lanoraie: { lat: 46.0667, lon: -73.1833 },
  'La Prairie': { lat: 45.4167, lon: -73.5 },
  'La Sarre': { lat: 48.8, lon: -79.2 },
  LAssomption: { lat: 45.825, lon: -73.4292 },
  'La Tuque': { lat: 47.4333, lon: -72.7833 },
  Laval: { lat: 45.6, lon: -73.7333 },
  Lavaltrie: { lat: 45.8833, lon: -73.2833 },
  'Lebel-sur-Quevillon': { lat: 49.05, lon: -76.9833 },
  LEpiphanie: { lat: 45.85, lon: -73.4667 },
  'Les Cedres': { lat: 45.3, lon: -74.0667 },
  'Les Coteaux': { lat: 45.2667, lon: -74.25 },
  'LIle-Perrot': { lat: 45.3833, lon: -73.9333 },
  Longueuil: { lat: 45.5333, lon: -73.5167 },
  Lorraine: { lat: 45.6833, lon: -73.7833 },
  Louiseville: { lat: 46.25, lon: -72.95 },
  Macamic: { lat: 48.75, lon: -79.0 },
  Magog: { lat: 45.2667, lon: -72.15 },
  Malartic: { lat: 48.1333, lon: -78.1333 },
  Mandeville: { lat: 46.3833, lon: -73.3833 },
  Maniwaki: { lat: 46.3833, lon: -75.9667 },
  Marieville: { lat: 45.4333, lon: -73.1667 },
  Mascouche: { lat: 45.75, lon: -73.6 },
  Maskinonge: { lat: 46.2167, lon: -73.0333 },
  Matagami: { lat: 49.75, lon: -77.6333 },
  Mercier: { lat: 45.32, lon: -73.75 },
  Mirabel: { lat: 45.65, lon: -74.0833 },
  'Mont-Laurier': { lat: 46.55, lon: -75.5 },
  Montreal: { lat: 45.5017, lon: -73.5673 },
  'Montreal-Est': { lat: 45.6167, lon: -73.5167 },
  'Montreal-Ouest': { lat: 45.45, lon: -73.65 },
  'Mont-Royal': { lat: 45.5167, lon: -73.65 },
  'Mont-Saint-Hilaire': { lat: 45.5667, lon: -73.2 },
  'Mont-Tremblant': { lat: 46.1183, lon: -74.5961 },
  'Morin-Heights': { lat: 45.9, lon: -74.25 },
  Napierville: { lat: 45.1833, lon: -73.4 },
  Nicolet: { lat: 46.2333, lon: -72.6167 },
  Normandin: { lat: 48.8333, lon: -72.5333 },
  'Notre-Dame-de-lIle-Perrot': { lat: 45.3667, lon: -73.95 },
  'Notre-Dame-des-Prairies': { lat: 46.05, lon: -73.4167 },
  Oka: { lat: 45.4754, lon: -74.086 },
  Ormstown: { lat: 45.1302, lon: -74.0054 },
  'Otterburn Park': { lat: 45.5333, lon: -73.2167 },
  Papineauville: { lat: 45.6167, lon: -74.8833 },
  Piedmont: { lat: 45.9, lon: -74.1333 },
  Pierreville: { lat: 46.0667, lon: -72.8167 },
  Pincourt: { lat: 45.3667, lon: -73.9833 },
  'Pointe-Calumet': { lat: 45.4833, lon: -73.9667 },
  'Pointe-Claire': { lat: 45.45, lon: -73.8167 },
  'Pont Rouge': { lat: 46.7543, lon: -71.6934 },
  'Port-Cartier': { lat: 50.0333, lon: -66.8833 },
  Prevost: { lat: 45.8667, lon: -74.0833 },
  Rawdon: { lat: 46.05, lon: -73.7 },
  Repentigny: { lat: 45.7425, lon: -73.4514 },
  Richelieu: { lat: 45.4515, lon: -73.2463 },
  Richmond: { lat: 45.6667, lon: -71.9 },
  Rigaud: { lat: 45.4833, lon: -74.3 },
  'Riviere-Rouge': { lat: 46.4167, lon: -74.8667 },
  Roberval: { lat: 48.5167, lon: -72.2167 },
  'Rock Forest': { lat: 45.3544, lon: -71.9832 },
  Rosemere: { lat: 45.6333, lon: -73.8 },
  Rougemont: { lat: 45.4333, lon: -73.0333 },
  'Rouyn-Noranda': { lat: 48.2337, lon: -79.0163 },
  Saguenay: { lat: 48.4167, lon: -71.0667 },
  'Saint-Adolphe-dHoward': { lat: 46.0333, lon: -74.2833 },
  'Saint-Ambroise': { lat: 48.55, lon: -71.35 },
  'Saint-Andre-Avellin': { lat: 45.7167, lon: -75.0667 },
  'Saint-Augustin': { lat: 45.65, lon: -73.9667 },
  'Saint-Basile-le-Grand': { lat: 45.5333, lon: -73.2833 },
  'Saint-Bruno': { lat: 48.6667, lon: -71.35 },
  'Saint-Bruno-de-Guigues': { lat: 47.45, lon: -79.4333 },
  'Saint-Bruno-de-Montarville': { lat: 45.5333, lon: -73.35 },
  'Saint-Cesaire': { lat: 45.4167, lon: -73.0 },
  'Saint-Colomban': { lat: 45.7333, lon: -74.0833 },
  'Saint-Come--Liniere': { lat: 46.1667, lon: -70.6667 },
  'Saint-Constant': { lat: 45.3667, lon: -73.5667 },
  'Saint-Cyrille-de-Wendover': { lat: 45.9333, lon: -72.4833 },
  'Saint-Damase': { lat: 45.5333, lon: -73.0 },
  'Saint-Denis-sur-Richelieu': { lat: 45.7833, lon: -73.1333 },
  'Sainte-Adele': { lat: 45.95, lon: -74.1333 },
  'Sainte-Agathe-des-Monts': { lat: 46.05, lon: -74.2825 },
  'Sainte-Anne-de-Bellevue': { lat: 45.4, lon: -73.95 },
  'Sainte-Anne-des-Monts': { lat: 49.1244, lon: -66.4926 },
  'Sainte-Anne-des-Plaines': { lat: 45.7667, lon: -73.8167 },
  'Sainte-Catherine': { lat: 45.4, lon: -73.5825 },
  'Saint-Edouard': { lat: 45.2, lon: -73.5 },
  'Sainte-Julie': { lat: 45.5833, lon: -73.3333 },
  'Sainte-Julienne': { lat: 45.9667, lon: -73.7167 },
  'Sainte-Marthe-sur-le-Lac': { lat: 45.5333, lon: -73.95 },
  'Sainte-Martine': { lat: 45.2, lon: -73.8 },
  'Sainte-Sophie': { lat: 45.8167, lon: -73.9333 },
  'Sainte-Thecle': { lat: 46.8167, lon: -72.45 },
  'Sainte-Therese': { lat: 45.6406, lon: -73.8274 },
  'Saint-Eustache': { lat: 45.5602, lon: -73.9058 },
  'Saint-Felicien': { lat: 48.65, lon: -72.45 },
  'Saint-Felix-de-Valois': { lat: 46.1667, lon: -73.5167 },
  'Saint-Gabriel': { lat: 46.2833, lon: -73.3833 },
  'Saint-Gedeon': { lat: 48.5167, lon: -71.7833 },
  'Saint-Germain-de-Grantham': { lat: 45.8333, lon: -72.5667 },
  'Saint-Hippolyte': { lat: 45.9333, lon: -74.0167 },
  'Saint-Hyacinthe': { lat: 45.6167, lon: -72.95 },
  'Saint-Jacques': { lat: 45.95, lon: -73.5833 },
  'Saint-Jacques-le-Mineur': { lat: 45.2667, lon: -73.4 },
  'Saint-Jean-sur-Richelieu': { lat: 45.3167, lon: -73.25 },
  'Saint-Jerome': { lat: 45.7804, lon: -74.0036 },
  'Saint-Joseph-de-Coleraine': { lat: 45.9667, lon: -71.45 },
  'Saint-Joseph-du-Lac': { lat: 45.5333, lon: -73.9667 },
  'Saint-Laurent': { lat: 45.5, lon: -73.7 },
  'Saint-Lazare': { lat: 45.4, lon: -74.1333 },
  'Saint-Leonard': { lat: 45.5833, lon: -73.5833 },
  'Saint-Lin-Laurentides': { lat: 45.85, lon: -73.7333 },
  'Saint-Marc-des-Carrieres': { lat: 46.6833, lon: -72.05 },
  'Saint-Mathieu': { lat: 45.3333, lon: -73.5667 },
  'Saint-Michel-des-Saints': { lat: 46.6833, lon: -73.9167 },
  'Saint-Nazaire': { lat: 48.5667, lon: -71.6167 },
  'Saint-Philippe-de-La Prairie': { lat: 45.3667, lon: -73.4833 },
  'Saint-Pie': { lat: 45.5167, lon: -72.9 },
  'Saint-Pie-V': { lat: 46.1, lon: -71.9333 },
  'Saint-Pierre-les-Becquets': { lat: 46.5, lon: -72.2 },
  'Saint-Polycarpe': { lat: 45.3, lon: -74.3 },
  'Saint-Raymond': { lat: 46.9, lon: -71.8333 },
  'Saint-Remi': { lat: 45.2667, lon: -73.6167 },
  'Saint-Remi-de-Tingwick': { lat: 45.9167, lon: -71.9333 },
  'Saint-Sauveur': { lat: 45.9, lon: -74.1667 },
  'Saint-Tite': { lat: 46.7167, lon: -72.5667 },
  'Saint-Zotique': { lat: 45.25, lon: -74.2333 },
  'Salaberry-de-Valleyfield': { lat: 45.25, lon: -74.1328 },
  Senneterre: { lat: 48.3833, lon: -77.2333 },
  'Sept-Iles': { lat: 50.2167, lon: -66.3833 },
  Shawinigan: { lat: 46.5667, lon: -72.75 },
  Shawville: { lat: 45.6, lon: -76.4833 },
  Sherbrooke: { lat: 45.4, lon: -71.8833 },
  'Sorel-Tracy': { lat: 46.0333, lon: -73.1167 },
  Sutton: { lat: 45.1, lon: -72.6167 },
  Temiscaming: { lat: 46.7167, lon: -79.1 },
  Terrebonne: { lat: 45.7, lon: -73.6333 },
  Thurso: { lat: 45.6, lon: -75.25 },
  'Trois-Rivieres': { lat: 46.35, lon: -72.55 },
  Valcourt: { lat: 45.5, lon: -72.3167 },
  'Val-David': { lat: 46.0167, lon: -74.2167 },
  'Val-des-Monts': { lat: 45.65, lon: -75.7667 },
  'Val-dOr': { lat: 48.1, lon: -77.7833 },
  'Val-Morin': { lat: 46.0167, lon: -74.2 },
  Varennes: { lat: 45.6833, lon: -73.4333 },
  'Vaudreuil-Dorion': { lat: 45.4, lon: -74.0333 },
  'Venise-en-Quebec': { lat: 45.0833, lon: -73.1333 },
  Vercheres: { lat: 45.7833, lon: -73.35 },
  Victoriaville: { lat: 46.05, lon: -71.9667 },
  'Ville-Marie': { lat: 47.3333, lon: -79.4333 },
  Wakefield: { lat: 45.6333, lon: -75.9167 },
  Warwick: { lat: 45.95, lon: -71.9833 },
  Waswanipi: { lat: 49.4167, lon: -76.1333 },
  Waterloo: { lat: 45.35, lon: -72.5167 },
  Westmount: { lat: 45.4857, lon: -73.5951 },
  Windsor: { lat: 45.5667, lon: -71.9997 },
  Yamachiche: { lat: 46.2667, lon: -72.8333 },
  Beauceville: { lat: 46.2167, lon: -70.7667 },
  Beaupre: { lat: 47.05, lon: -70.8833 },
  'Chateau-Richer': { lat: 46.9667, lon: -71.0167 },
  Quebec: { lat: 46.8139, lon: -71.2082 },
  'LAnge-Gardien': { lat: 46.873, lon: -71.123 },
  Levis: { lat: 46.8033, lon: -71.1779 },
  Montmagny: { lat: 46.9833, lon: -70.55 },
  Neuville: { lat: 46.7, lon: -71.5833 },
  Portneuf: { lat: 46.7, lon: -71.8833 },
  Rimouski: { lat: 48.45, lon: -68.5167 },
  'Riviere-du-Loup': { lat: 47.8333, lon: -69.5333 },
  'LAncienne-Lorette': { lat: 46.8, lon: -71.35 },
  'St-Jean-Port-Joli': { lat: 47.2167, lon: -70.2667 },
  'Saint-Georges': { lat: 46.1167, lon: -70.6667 },
  'Sainte Catherine de la Jacques Cartier': { lat: 46.825, lon: -71.6186 },
  'Baie-Saint-Paul': { lat: 47.45, lon: -70.5 },
  'Carleton-sur-Mer': { lat: 48.1, lon: -66.1333 },
  Cacouna: { lat: 47.9167, lon: -69.5 },
  Matane: { lat: 48.85, lon: -67.5333 },
  Amqui: { lat: 48.4667, lon: -67.4333 },
  Chandler: { lat: 48.35, lon: -64.6833 },
  Bonaventure: { lat: 48.05, lon: -65.4833 },
  'New-Richmond': { lat: 48.1667, lon: -65.8667 },
  'Thetford-Mines': { lat: 46.1, lon: -71.3 },
  'New Carlisle': { lat: 48.0167, lon: -65.3333 },
  Cabano: { lat: 47.6833, lon: -68.8833 },
  'Sacre-Coeur': { lat: 48.2333, lon: -69.8833 },
  Forestville: { lat: 48.7403, lon: -69.0844 },
  'Mont-Joli': { lat: 48.5833, lon: -68.1833 },
  'La Malbaie': { lat: 47.65, lon: -70.15 },
  'Sainte-Marie': { lat: 46.45, lon: -71.0333 },
  Plessisville: { lat: 46.2167, lon: -71.7667 },
  Maliotenam: { lat: 50.2333, lon: -66.0833 },
  'Les Escoumins': { lat: 48.35, lon: -69.4167 },
  'Fossambault-sur-lac': { lat: 46.8833, lon: -71.65 },
  Jonquiere: { lat: 48.4167, lon: -71.25 },
  'Cap-Sante': { lat: 46.6753, lon: -71.7814 },
  'Saint-Augustin-de-Desmaures': { lat: 46.7375, lon: -71.4714 },
  Donnacona: { lat: 46.674, lon: -71.7194 },
  'La Pocatiere': { lat: 47.3667, lon: -70.0333 },
  'Saint-Joseph-de-Beauce': { lat: 46.3033, lon: -70.8778 },
  'Metabetchouan-Lac-a-la-Croix': { lat: 48.4167, lon: -71.8667 },
  Breakeyville: { lat: 46.7, lon: -71.2333 },
  'Saint-Antoine-de-Tilly': { lat: 46.65, lon: -71.5667 },
  Shannon: { lat: 46.8833, lon: -71.5333 },
  'Saint-Henri': { lat: 46.7242, lon: -71.0481 },
  Pohenegamook: { lat: 47.4667, lon: -69.2167 },
  'Pont-Rouge': { lat: 46.7543, lon: -71.6934 },
  Princeville: { lat: 46.1667, lon: -71.8833 },
  'Saint-Simeon': { lat: 47.8333, lon: -69.8833 },
  'Saint-Pascal': { lat: 47.5333, lon: -69.8 },
  'Vallee-Jonction': { lat: 46.3833, lon: -71.1 },
  'Saint-Antonin': { lat: 47.7667, lon: -69.3667 },
  'le Plateau': { lat: 45.5234, lon: -73.5833 },
  Kanesatake: { lat: 45.5065, lon: -74.0865 },
};
