import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: { display: "flex", flexDirection: "column" },
  headerElement: {
    fontWeight: "bold",
    margin: theme.spacing(2, 4, 2, 4),
    alignSelf: "center",
    textDecoration: "underline",
    fontSize: "18pt"
  },
  subHeaderElement: {
    fontWeight: "bold",
    margin: theme.spacing(2, 4, 2, 4),
    textDecoration: "underline"
  },
  element: {
    margin: theme.spacing(2, 4, 2, 4)
  },
  section: {
    margin: theme.spacing(2, 4, 2, 14)
  },
  bulletpoint: {
    margin: theme.spacing(1, 4, 1, 6)
  }
}));

function TermsConditionsContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.headerElement} variant="h1">
        Review Policy:
      </Typography>

      <Typography className={classes.element}>
      You can submit reviews for business and products listed on S7DAW. We encourage you to share your opinions, both favorable and unfavorable.
      Customer Reviews help customers to learn more about the business service and products and decide whether it is right for them
      Customer Reviews should give customers genuine product/service feedback from fellow shoppers. We have a zero tolerance policy for any review designed to mislead or manipulate customers.
      We don't allow anyone to write reviews as a form of promotion. If we can determine upon an investigation on any flagged reviews, we hold the right to remove them.
      The following are types of reviews that we don't allow and will have the <strong>right to remove:</strong>
      </Typography>

      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spam and fake reviews.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Multiple negative reviews from the same person or by a fake user we identify coming from the same IP address location.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inappropriate content, profanity and racial terms.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fraudulent reviews from competitors.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leaving reviews for businesses you didn’t interact with.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reviews that aren’t relevant to an actual experience.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A review by someone who has a direct or indirect financial interest in the product.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Multiple negative reviews for the same product from one customer.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A review in exchange for monetary reward.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A negative review from a seller on a competitor's service.
      </Typography>
    </div>
  );
}

export default TermsConditionsContent;
