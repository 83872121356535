import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Typography,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Button,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { filterTypes, priceRanges, NONE, ALL_CATEGORIES, PRODUCT_FLAGS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    border: '1px solid #e0e0e0',
  },
  listHeaderSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 2, 3),
  },
  listHeader: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: '16pt',
  },
  clearIcon: {
    color: theme.palette.error.main,
  },
  filterName: {
    fontSize: '12pt',
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.darkGrey,
    fontWeight: 'bold',
  },
  selectedCat: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  nested: {
    paddingLeft: theme.spacing(7),
    color: theme.palette.common.darkGrey,
    fontSize: '12pt',
  },
  nestedTwice: {
    paddingLeft: theme.spacing(9),
    color: theme.palette.common.darkGrey,
    fontSize: '12pt',
  },
  nestedThrice: {
    paddingLeft: theme.spacing(11),
    color: theme.palette.common.darkGrey,
    fontSize: '12pt',
  },
  topListItem: {
    height: '3.5em',
  },
  listItem: {
    height: '2.25em',
  },
  divider: {
    height: 1,
  },
  formControl: {
    width: '100%',
  },
  formControlLabel: {
    width: '100%',
  },
  label: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

function FiltersList(props) {
  const {
    onFilter,
    selectedCategory,
    priceRange,
    productList,
    selectedGenetics,
    selectedFlag,
    selectedBrands,
    brand,
    storeId,
    allProductsLoaded,
  } = props;

  const classes = useStyles();
  const [openCategory, setOpenCategory] = useState(true);
  const [openGenetics, setOpenGenetics] = useState(false);
  const [openBrands, setOpenBrands] = useState(selectedBrands.length > 0 ? true : false);
  const [openPrice, setOpenPrice] = useState(false);

  const [filters, setFilters] = useState({});

  const changeSelectedCat = (category, parent) => {
    changeFilter(filterTypes.CATEGORY, { category, parent });
  };

  const calculateSelectedCat = (currCategory, currParent) => {
    return currCategory === selectedCategory.category && currParent === selectedCategory.parent
      ? classes.selectedCat
      : ``;
  };

  const changeFilter = useCallback(
    (type, value) => {
      onFilter(type, value);
    },
    [onFilter]
  );

  const topLevelClick = (open, setOpen) => {
    setOpen(!open);
  };

  const createCategory = (level) => {
    return {
      level,
      count: 1,
      children: {},
    };
  };

  const matchesCategory = (product, filter) => {
    if (filter.category === ALL_CATEGORIES) return true;

    return (
      (product.categories.category === filter.category && filter.parent === NONE) ||
      (product.categories.subcategory === filter.category && product.categories.category === filter.parent) ||
      (product.categories.subsubcategory === filter.category && product.categories.subcategory === filter.parent)
    );
  };

  const matchesGenetics = (product, filter) => {
    // return true;

    const geneticsList = Object.keys(filter).filter((option) => filter[`${option}`] === true);

    if (geneticsList.length === 0) {
      return true;
    } else {
      return geneticsList.includes(product.genetics);
    }
  };

  const matchesRange = useCallback(
    (product, filter) => {
      const low = priceRanges.filter((range) => range.name === filter)[0].low;
      const high = priceRanges.filter((range) => range.name === filter)[0].high;

      return (
        (product.productPricingList &&
          product.productPricingList.filter((listing) => {
            return listing.price >= low && listing.price < high;
          }).length >= 1) ||
        (product.productCustomizations.find(
          (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
        ) &&
          product.productCustomizations
            .find(
              (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
            )
            .productPricingList.filter((listing) => {
              return listing.price >= low && listing.price < high;
            }).length >= 1)
      );
    },
    [storeId]
  );

  const matchesFlag = (product, filter) => {
    return filter === '' || (product.flags && product.flags[0] === filter);
  };

  const matchesBrand = (product, filter) => {
    return (
      filter.length === 0 ||
      (product.brand &&
        filter.map((brand) => brand.id).includes(product.brand._links.self.href.replace('{?projection}', '')))
    );
  };

  const populateFilters = useCallback(
    (products, categoryFilter, geneticsFilter, priceFilter, flagFilter, brandsFilter) => {
      const options = {
        categories: {},
        genetics: {},
        priceRanges: priceRanges.map((range) => ({ ...range, count: 0 })),
        flags: {},
        brands: [],
      };

      const categoryProducts = products.filter((product) => {
        return (
          matchesGenetics(product, geneticsFilter) &&
          (brand || matchesRange(product, priceFilter)) &&
          matchesFlag(product, flagFilter) &&
          matchesBrand(product, brandsFilter)
        );
      });
      const geneticProducts = products.filter(
        (product) =>
          matchesCategory(product, categoryFilter) &&
          (brand || matchesRange(product, priceFilter)) &&
          matchesFlag(product, flagFilter) &&
          matchesBrand(product, brandsFilter)
      );
      const priceProducts = products.filter(
        (product) =>
          matchesGenetics(product, geneticsFilter) &&
          matchesCategory(product, categoryFilter) &&
          matchesFlag(product, flagFilter) &&
          matchesBrand(product, brandsFilter)
      );
      const flagProducts = products.filter(
        (product) =>
          matchesGenetics(product, geneticsFilter) &&
          matchesCategory(product, categoryFilter) &&
          (brand || matchesRange(product, priceFilter)) &&
          matchesBrand(product, brandsFilter)
      );
      const brandProducts = products.filter(
        (product) =>
          matchesGenetics(product, geneticsFilter) &&
          matchesCategory(product, categoryFilter) &&
          (brand || matchesRange(product, priceFilter)) &&
          matchesFlag(product, flagFilter)
      );

      //Build out category info, level by level
      categoryProducts.forEach((product) => {
        if (product.categories.category) {
          if (options.categories[`${product.categories.category}`]) {
            options.categories[`${product.categories.category}`].count++;
          } else {
            options.categories[`${product.categories.category}`] = createCategory(1);
          }

          if (product.categories.subcategory) {
            if (options.categories[`${product.categories.category}`].children[`${product.categories.subcategory}`]) {
              options.categories[`${product.categories.category}`].children[`${product.categories.subcategory}`]
                .count++;
            } else {
              options.categories[`${product.categories.category}`].children[`${product.categories.subcategory}`] =
                createCategory(2);
            }

            if (product.categories.subsubcategory) {
              if (
                options.categories[`${product.categories.category}`].children[`${product.categories.subcategory}`]
                  .children[`${product.categories.subsubcategory}`]
              ) {
                options.categories[`${product.categories.category}`].children[`${product.categories.subcategory}`]
                  .children[`${product.categories.subsubcategory}`].count++;
              } else {
                options.categories[`${product.categories.category}`].children[
                  `${product.categories.subcategory}`
                ].children[`${product.categories.subsubcategory}`] = createCategory(3);
              }
            }
          }
        }
      });

      // Build out genetics info
      geneticProducts.forEach((product) => {
        if (options.genetics[`${product.genetics}`] && product.genetics !== '') {
          options.genetics[`${product.genetics}`]++;
        } else if (product.genetics !== '') {
          options.genetics[`${product.genetics}`] = 1;
        }
      });

      // Build out price range info
      !brand &&
        priceProducts.forEach((product) => {
          options.priceRanges.forEach((range) => {
            (product.productPricingList &&
              product.productPricingList.some((priceInfo) => {
                if (priceInfo.price >= range.low && priceInfo.price < range.high) {
                  range.count++;
                  return true;
                }
                return false;
              })) ||
              (product.productCustomizations.find(
                (customization) => parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
              ) &&
                product.productCustomizations
                  .find(
                    (customization) =>
                      parseIdFromLink(customization.store._links.self.href, 0, '{?projection}') === storeId
                  )
                  .productPricingList.some((priceInfo) => {
                    if (priceInfo.price >= range.low && priceInfo.price < range.high) {
                      range.count++;
                      return true;
                    }
                    return false;
                  }));
          });
        });

      // Build out flags info
      flagProducts.forEach((product) => {
        if (product.flags[0] && options.flags[`${product.flags[0]}`]) {
          options.flags[`${product.flags[0]}`]++;
        } else if (product.flags[0]) {
          options.flags[`${product.flags[0]}`] = 1;
        }
      });

      // Build out brands info
      brandProducts.forEach((product) => {
        let foundIndex;

        const found =
          product.brand &&
          options.brands.find((brand, index) => {
            if (brand.id === `${product.brand._links.self.href.replace('{?projection}', '')}`) {
              foundIndex = index;
            }
            return brand.id === `${product.brand._links.self.href.replace('{?projection}', '')}`;
          });

        if (product.brand && found) {
          options.brands[foundIndex] = {
            ...options.brands[foundIndex],
            count: options.brands[foundIndex].count + 1,
          };
        } else if (product.brand) {
          options.brands.push({
            id: product.brand._links.self.href.replace('{?projection}', ''),
            name: product.brand.name,
            count: 1,
          });
        }
      });

      return options;
    },
    [brand, matchesRange, storeId]
  );

  useEffect(() => {
    setFilters(
      populateFilters(productList, selectedCategory, selectedGenetics, priceRange, selectedFlag, selectedBrands)
    );
  }, [populateFilters, productList, selectedCategory, selectedGenetics, priceRange, selectedFlag, selectedBrands]);

  const filterSelected = () => {
    if (selectedCategory.category !== ALL_CATEGORIES) {
      return true;
    } else if (priceRange !== 'All') {
      return true;
    } else if (
      selectedGenetics.Indica !== false ||
      selectedGenetics.Hybrid !== false ||
      selectedGenetics.Sativa !== false
    ) {
      return true;
    } else if (selectedFlag) {
      return true;
    } else if (selectedBrands.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const resetFilters = () => {
    changeFilter(filterTypes.CLEAR, '');
  };

  let allCategoriesVal = 0;

  if (filters.categories) {
    Object.keys(filters.categories).forEach((category) => {
      allCategoriesVal = allCategoriesVal + filters.categories[`${category}`].count;
    });
  }

  const [openCats, setOpenCats] = useState({});

  useEffect(() => {
    const cats = {};

    const nestingExists =
      filters.categories &&
      Object.keys(filters.categories).some(
        (category) => Object.keys(filters.categories[`${category}`].children).length > 0
      );

    if (nestingExists && Object.keys(openCats).length === 0) {
      Object.keys(filters.categories).forEach((category) => {
        if (Object.keys(filters.categories[`${category}`].children).length > 0) {
          let open = Object.keys(filters.categories[`${category}`].children).some(
            (subcat) => subcat === selectedCategory.category && category === selectedCategory.parent
          );

          const subCat = Object.keys(filters.categories[`${category}`].children).find(
            (subcat) => subcat === selectedCategory.parent
          );

          if (subCat && !open) {
            open = Object.keys(filters.categories[`${category}`].children[`${subCat}`].children).some(
              (subsubcat) => subsubcat === selectedCategory.category
            );
          }

          cats[`${category}`] = open;
        }
      });

      setOpenCats(cats);
    }
  }, [filters.categories, openCats, selectedCategory]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <div className={classes.listHeaderSection}>
          <Typography className={classes.listHeader} variant="h5">
            {'FILTERS: '} {!allProductsLoaded && <CircularProgress size="1rem" />}
          </Typography>
          {filterSelected() && (
            <Button variant="outlined" style={{ padding: 1, paddingRight: 5 }} onClick={() => resetFilters()}>
              <ClearIcon className={classes.clearIcon}></ClearIcon>Clear
            </Button>
          )}
        </div>
      }
      className={classes.root}
      disablePadding
    >
      <Divider className={classes.divider} />
      {/* <ListItem> */}
      <List component="div">
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedFlag}
            onChange={(event) => changeFilter(filterTypes.FLAGS, event.target.value)}
            style={{ width: '100%' }}
          >
            {filters.flags &&
              Object.keys(filters.flags)
                .sort((x, y) => x.localeCompare(y))
                .filter((flag) => flag !== PRODUCT_FLAGS.OUTOFSTOCK)
                .map((filter, index) => (
                  <ListItem key={index} button className={`${classes.listItem} ${classes.nested}`}>
                    <FormControlLabel
                      value={filter}
                      control={<Radio />}
                      label={`${filter} ${allProductsLoaded ? `(${filters.flags[`${filter}`]})` : ' '}`}
                      style={{ width: '100%' }}
                    />
                  </ListItem>
                ))}
          </RadioGroup>
        </FormControl>
      </List>
      {/* </ListItem> */}
      <ListItem
        button
        onClick={() => topLevelClick(openCategory, setOpenCategory)}
        className={`${classes.topListItem}`}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography type="body2" className={classes.filterName}>
              CATEGORIES
            </Typography>
          }
        />
        {openCategory ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCategory} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItem
            button
            className={`${classes.listItem} ${classes.nested}`}
            onClick={() => changeSelectedCat(ALL_CATEGORIES, NONE)}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography type="body2" className={calculateSelectedCat(ALL_CATEGORIES, NONE)}>
                  {ALL_CATEGORIES} {`${' '}`}
                  {allProductsLoaded ? `(${allCategoriesVal})` : <CircularProgress size={'1rem'} />}
                </Typography>
              }
            />
          </ListItem>
          {filters.categories &&
            Object.keys(filters.categories)
              .sort((first, second) => filters.categories[`${second}`].count - filters.categories[`${first}`].count)
              .sort((filter) => (filter === 'Flower' ? -1 : 1))
              .map((filter, index) => (
                <Fragment key={index}>
                  <ListItem
                    button
                    className={`${classes.listItem} ${classes.nested}`}
                    onClick={() => {
                      changeSelectedCat(filter, NONE);
                      if (openCats[`${filter}`] !== undefined) {
                        setOpenCats({
                          ...openCats,
                          [`${filter}`]: !openCats[`${filter}`],
                        });
                      }
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography type="body2" className={calculateSelectedCat(filter, NONE)}>
                          {`${filter} ${allProductsLoaded ? `(${filters.categories[`${filter}`].count})` : ' '}`}
                          {/* {`${filter}`} */}
                        </Typography>
                      }
                    />
                    {openCats[`${filter}`] === true ? (
                      <ExpandLess />
                    ) : openCats[`${filter}`] === false ? (
                      <ExpandMore />
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </ListItem>
                  <Collapse in={openCats[`${filter}`]} timeout="auto" unmountOnExit>
                    <List component="div">
                      {Object.keys(filters.categories[`${filter}`].children) &&
                        Object.keys(filters.categories[`${filter}`].children)
                          .sort(
                            (first, second) =>
                              filters.categories[`${filter}`].children[`${second}`].count -
                              filters.categories[`${filter}`].children[`${first}`].count
                          )
                          .map((subFilter, index) => (
                            <Fragment key={index}>
                              <ListItem
                                key={index}
                                button
                                className={`${classes.listItem} ${classes.nestedTwice}`}
                                onClick={() => changeSelectedCat(subFilter, filter)}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography type="body2" className={calculateSelectedCat(subFilter, filter)}>
                                      {`${subFilter} (${
                                        filters.categories[`${filter}`].children[`${subFilter}`].count
                                      })`}
                                      {/* {`${subFilter}`} */}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                              {Object.keys(filters.categories[`${filter}`].children[`${subFilter}`].children) &&
                                Object.keys(filters.categories[`${filter}`].children[`${subFilter}`].children)
                                  .sort(
                                    (first, second) =>
                                      filters.categories[`${filter}`].children[`${subFilter}`].children[`${second}`]
                                        .count -
                                      filters.categories[`${filter}`].children[`${subFilter}`].children[`${first}`]
                                        .count
                                  )
                                  .map((bottomFilter, index) => (
                                    <Fragment key={index}>
                                      <ListItem
                                        key={index}
                                        button
                                        className={`${classes.listItem} ${classes.nestedThrice}`}
                                        onClick={() => changeSelectedCat(bottomFilter, subFilter)}
                                      >
                                        <ListItemText
                                          disableTypography
                                          primary={
                                            <Typography
                                              type="body2"
                                              className={calculateSelectedCat(bottomFilter, subFilter)}
                                            >
                                              {`${bottomFilter} (${
                                                filters.categories[`${filter}`].children[`${subFilter}`].children[
                                                  `${bottomFilter}`
                                                ].count
                                              })`}
                                              {/* {`${bottomFilter}`} */}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    </Fragment>
                                  ))}
                            </Fragment>
                          ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
        </List>
      </Collapse>
      <Divider className={classes.divider} />
      <ListItem
        button
        onClick={() => topLevelClick(openGenetics, setOpenGenetics)}
        className={`${classes.topListItem}`}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography type="body2" className={classes.filterName}>
              GENETICS
            </Typography>
          }
        />
        {openGenetics ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openGenetics} timeout="auto" unmountOnExit>
        <List component="div">
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup aria-label="genetics" name="genetics">
              {filters.genetics &&
                Object.keys(filters.genetics).map((filter, index) => (
                  <ListItem key={index} button className={`${classes.listItem} ${classes.nested}`}>
                    <FormControlLabel
                      key={index}
                      control={<Checkbox color="secondary" checked={selectedGenetics[`${filter}`]} />}
                      onChange={() => changeFilter(filterTypes.GENETICS, filter)}
                      label={`${filter} (${filters.genetics[`${filter}`]})`}
                      // label={`${filter}`}
                      className={classes.formControlLabel}
                    />
                  </ListItem>
                ))}
            </FormGroup>
          </FormControl>
        </List>
      </Collapse>
      <Divider className={classes.divider} />
      {!brand && (
        <Fragment>
          <ListItem
            button
            onClick={() => topLevelClick(openBrands, setOpenBrands)}
            className={`${classes.topListItem}`}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography type="body2" className={classes.filterName}>
                  BRANDS
                </Typography>
              }
            />
            {openBrands ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openBrands} timeout="auto" unmountOnExit>
            <List component="div">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup aria-label="brands" name="brands">
                  {filters.brands &&
                    filters.brands.map((filter, index) => (
                      <ListItem key={index} button className={`${classes.listItem} ${classes.nested}`}>
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="secondary"
                              checked={selectedBrands.find((brand) => brand.id === filter.id) ? true : false}
                            />
                          }
                          onChange={() => changeFilter(filterTypes.BRANDS, filter)}
                          label={`${filter.name} (${filter.count})`}
                          className={classes.formControlLabel}
                          classes={{
                            label: classes.label,
                          }}
                        />
                      </ListItem>
                    ))}
                </FormGroup>
              </FormControl>
            </List>
          </Collapse>
          <Divider className={classes.divider} />
        </Fragment>
      )}
      {!brand && (
        <ListItem button onClick={() => topLevelClick(openPrice, setOpenPrice)} className={`${classes.topListItem}`}>
          <ListItemText
            disableTypography
            primary={
              <Typography type="body2" className={classes.filterName}>
                PRICE RANGE
              </Typography>
            }
          />
          {openPrice ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openPrice} timeout="auto" unmountOnExit>
        <List component="div">
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="price-range"
              name="price-range"
              value={priceRange}
              onChange={(event) => changeFilter(filterTypes.PRICE_RANGE, event.target.value)}
            >
              {filters.priceRanges &&
                filters.priceRanges
                  .filter((filter) => filter.count > 0)
                  .map((filter, index) => (
                    <ListItem key={index} button className={`${classes.listItem} ${classes.nested}`}>
                      <FormControlLabel
                        value={filter.name}
                        control={<Radio color="secondary" />}
                        label={`${filter.name} (${filter.count})`}
                        // label={`${filter.name}`}
                        className={classes.formControlLabel}
                      />
                    </ListItem>
                  ))}
            </RadioGroup>
          </FormControl>
        </List>
      </Collapse>
    </List>
  );
}

export default FiltersList;
