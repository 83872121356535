export default {
  'Bay Roberts': { lat: 47.5992, lon: -53.2711 },
  Bonavista: { lat: 48.6514, lon: -53.1147 },
  Botwood: { lat: 49.1433, lon: -55.3472 },
  Burgeo: { lat: 47.6166, lon: -57.6175 },
  Carbonear: { lat: 47.7308, lon: -53.2383 },
  Catalina: { lat: 48.6144, lon: -53.0683 },
  'Channel-Port aux Basques': { lat: 47.5683, lon: -59.1369 },
  'Clarenville-Shoal Harbour': { lat: 48.1614, lon: -53.9736 },
  'Conception Bay South': { lat: 47.5163, lon: -52.9835 },
  'Corner Brook': { lat: 48.9516, lon: -57.9484 },
  'Deer Lake': { lat: 49.1759, lon: -57.4259 },
  Gambo: { lat: 48.7917, lon: -54.2167 },
  'Grand Bank': { lat: 47.1017, lon: -55.7731 },
  'Grand Falls-Windsor': { lat: 48.9344, lon: -55.6644 },
  'Happy Valley-Goose Bay': { lat: 53.3039, lon: -60.3269 },
  'Harbour Breton': { lat: 47.4758, lon: -55.7989 },
  'Labrador City': { lat: 52.9443, lon: -66.9406 },
  Lewisporte: { lat: 49.2475, lon: -55.0589 },
  Marystown: { lat: 47.1664, lon: -55.1603 },
  'Mount Pearl': { lat: 47.5217, lon: -52.8047 },
  Pasadena: { lat: 49.0139, lon: -57.5953 },
  Stephenville: { lat: 48.5503, lon: -58.5883 },
  'Stephenville Crossing': { lat: 48.5333, lon: -58.3 },
  Torbay: { lat: 47.6667, lon: -52.75 },
  Wabana: { lat: 47.6344, lon: -52.9264 },
  'St. Johns': { lat: 47.5615, lon: -52.7126 },
};
