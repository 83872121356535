import React, { useState, Fragment } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CONTENT from './Content';
import SEOStyling from './SEOStyling';
const useStyles = SEOStyling;

export default function Intro({ currCity }) {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery('(min-width: 426px)');

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const currCityNoSpaces = currCity.replace(/\s+/g, '');

  const contentToDisplay = CONTENT[currCityNoSpaces]?.intro(currCity);

  return (
    <Fragment>
      {/* !contentToDisplay will use the empty Typography in mobileview thus eliminating an empty "Learn More" button */}
      {!contentToDisplay || isLargeScreen ? (
        <Typography className={classes.seoIntro}>{contentToDisplay}</Typography>
      ) : (
        <ExpansionPanel
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className={classes.seoIntroMobile}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography className={classes.seoIntroTitleMobile}>Learn more...</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography className={classes.seoIntroTextMobile}>{contentToDisplay}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </Fragment>
  );
}
