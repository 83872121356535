import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  accountButton: {
    fontWeight: 'bold',
    boxShadow: '0px 0px',
    margin: theme.spacing(0.5),
    height: 35,
    width: 90,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default function LoginSignUpBtns() {
  const classes = useStyles();
  const routerLocation = useLocation();

  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.accountButton}
        component={Link}
        to={{ pathname: '/login', prevPath: routerLocation.pathname }}
        style={{ color: '#ffffff', textDecoration: 'inherit' }}
      >
        Login
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={classes.accountButton}
        component={Link}
        to={'/signup'}
        style={{ color: '#ffffff', textDecoration: 'inherit', padding: 0 }}
      >
        Sign Up
      </Button>
    </Fragment>
  );
}
