import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import SortItem from "../components/SortItem";
import { parseIdFromLink } from "../utils/helpers";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  sharedLists: {
    display: "flex",
  },
  leftList: {
    margin: theme.spacing(1),
  },
  rightList: {
    margin: theme.spacing(1),
  },
  pinnedItems: {
    padding: theme.spacing(1),
    border: "1px solid #cbcbcb",
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    marginTop: theme.spacing(2),
  },
  unpinnedItems: {
    padding: theme.spacing(1),
    border: "1px solid #cbcbcb",
    borderRadius: "5px",
    backgroundColor: "#ebebeb",
    marginTop: theme.spacing(2),
  },
  content: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentInnerContainer: {
    width: "100%",
    maxWidth: theme.screen.maxWidth,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  list: {
    width: "30vw",
    //display:"flex"
  },
  fullList: {
    width: 'auto',
  },
}));

export default function SwipeableTemporaryDrawer(props) {
  const { fullStores, validStores } = props
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    //onClick={toggleDrawer(anchor, false)}
    //onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        variant="h5"
        style={{ marginTop: "3%", marginLeft: "3%", marginBottom: "0", color: "rgb(74,74,74)" }}
      >
        <strong>Admin Panel</strong>
      </Typography>

      <Typography
        variant="caption"
        style={{ marginTop: "3%", marginLeft: "3%", marginBottom: "0", color: "rgb(74,74,74)" }}
      >
        Click stores to go to edit page
                </Typography>
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <div className={classes.tableWrapper}>
            <div className={classes.sharedLists}>

              <div className={classes.leftList}>
                <Typography variant="h6">
                  <strong>Valid Addreses</strong>
                </Typography>
                <div className={classes.pinnedItems}>
                  {validStores.map((item) => (
                    <div
                      onClick={(e) => {
                        window.open("/admin/stores/" + parseIdFromLink(item._links.self.href), '_blank')
                      }}
                    >
                      <SortItem
                        key={item._links.self.href}
                        item={
                          item.store
                            ? item.store
                            : item.delivery
                              ? item.delivery
                              : item.deal
                                ? item.deal
                                : item
                        }
                        type={"Maps"}
                        pinned
                      ></SortItem>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.rightList}>
                <Typography variant="h6">
                  <strong>Invalid Addresses</strong>
                </Typography>
                <div className={classes.unpinnedItems}>
                  {fullStores.map((item) => (
                    <div
                      onClick={(e) => {
                        window.open("/admin/stores/" + parseIdFromLink(item._links.self.href), '_blank')
                      }}
                    >
                      <SortItem
                        key={item._links.self.href}
                        item={
                          item.store
                            ? item.store
                            : item.delivery
                              ? item.delivery
                              : item.deal
                                ? item.deal
                                : item
                        }
                        type={"Maps"}
                      ></SortItem>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      {/*</div>
      {fullStores.map((item) => (
          <div
          onClick={(e)=> {
            window.open("/admin/stores/" + parseIdFromLink(item._links.self.href), '_blank')
         }}
>
                       <SortItem
                         key={item._links.self.href}
                         item={
                           item.store
                             ? item.store
                             : item.delivery
                             ? item.delivery
                             : item.deal
                             ? item.deal
                             : item
                         }
                         
                         type={"Delivery"}
                         pinned
                       ></SortItem>
                       </div>
                     ))}
                        */
      }

    </div>
  );


  return (
    <div>

      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{"Click For Admin View"}</Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}