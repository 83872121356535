import * as React from "react";
// import { StyleSheet } from "react-native";
import { getWeekDay, parseHour } from "../utils/helpers";
import { DAYS_OF_THE_WEEK } from "../utils/constants";
import moment from "moment";
import { MOMENT_DATE_FORMAT } from "../utils/helpers";
import { makeStyles } from "@material-ui/styles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    statusText: {
        color: "#000",
        fontWeight: "500",
        fontSize: "",
      },
  }));

  
export default function OpenStatusText({ open, hours }) {

const classes = useStyles();
  if (hours === null) {
    return <Typography>{"Until Further Notice"}</Typography>;
  }
  const openTime = hours[`${getWeekDay().toLowerCase()}Open`];
  const closeTime = hours[`${getWeekDay().toLowerCase()}Close`];

  // const prevOpen = hours[`${getWeekDay(-1).toLowerCase()}Open`];
  const prevClose = hours[`${getWeekDay(-1).toLowerCase()}Close`];

  const currentTime = moment();

  let statusText = "Until ";

  const abbreviateDay = (day) => {
    switch (day) {
      case "Monday":
        return "Mon";
      case "Tuesday":
        return "Tues";
      case "Wednesday":
        return "Wed";
      case "Thursday":
        return "Thurs";
      case "Friday":
        return "Fri";
      case "Saturday":
        return "Sat";
      case "Sunday":
        return "Sun";
      default:
        return "N/A";
    }
  };

  if (!open) {
    //Check if a day after the current day has an opening time and set the status text with the time
    for (let i = new Date().getDay(); i < 7 && statusText === "Until "; i++) {
      if (
        hours[`${DAYS_OF_THE_WEEK[i].toLowerCase()}Open`] &&
        (i !== new Date().getDay() ||
          moment().isBefore(moment(openTime, MOMENT_DATE_FORMAT)))
      ) {
        statusText = statusText.concat(
          abbreviateDay(DAYS_OF_THE_WEEK[i]),
          " ",
          parseHour(hours[`${DAYS_OF_THE_WEEK[i].toLowerCase()}Open`])
        );
      }
    }

    //If still unset, check if a day before the current day has an opening time and set the status text with the time
    if (statusText === "Until ") {
      for (let i = 0; i < new Date().getDay() && statusText === "Until "; i++) {
        if (hours[`${DAYS_OF_THE_WEEK[i].toLowerCase()}Open`]) {
          statusText = statusText.concat(
            abbreviateDay(DAYS_OF_THE_WEEK[i]),
            " ",
            parseHour(hours[`${DAYS_OF_THE_WEEK[i].toLowerCase()}Open`])
          );
        }
      }
    }
  } else {
    //If currently open, check whether if the store is open under today's hours or yesterday's hour and set the status text accordingly
    if (
      moment(openTime, MOMENT_DATE_FORMAT).isSame(
        moment(closeTime, MOMENT_DATE_FORMAT)
      )
    ) {
      statusText = "24 Hours";
    } else if (
      currentTime.isSameOrAfter(moment(openTime, MOMENT_DATE_FORMAT))
    ) {
      statusText = statusText.concat(parseHour(closeTime));
    } else {
      statusText = statusText.concat(parseHour(prevClose));
    }
  }
  return <Typography className={classes.statusText}> • {statusText}</Typography>;
}


