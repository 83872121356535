import React from "react";
import { Link } from "react-router-dom";
import { postStyle } from "./postStyle";
import classNames from 'classnames';

const useStyles = postStyle;

export function HowToStartACannabisBusinessInOntario() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.h1}>
        Understanding The Cannabis Market in Ontario
      </h2>
      <p className={classes.p}>
        The legal cannabis industry in Canada grew from C$154 million in January
        2020 to $256 million in September 2020 which is a drastic increase of
        66.36% in just eight months. Also, the number of cannabis retail stores
        opening in Ontario has increased by over 100 relative to 2019. Would you
        like to have a piece of this growing cannabis industry and earn good
        profits? If so, then this article is worth your while to read. In
        today’s article we take you through the process to help you enter the
        cannabis dispensary market in Ontario and not only that, but strategies
        to help you succeed. So without much further ado, let’s begin with the
        first step.
      </p>
      <h2 className={classes.h2}>Research Your Target Market</h2>
      <p className={classes.p}>
        Cannabis dispensaries are opening at an increased rate across Ontario
        with over 110 cannabis retail stores receiving authorizations to open
        from March 2019 to June 2020, making the competition in Ontario a lot
        tighter. Hype won’t sell your recreational cannabis products anymore
        which means you need a new approach and strategy to set you apart from
        your competitors. That strategy first starts with choosing your target
        market who are people that are willing and able to pay your asking
        prices based on the value you are able to provide. With this knowledge,
        you can also craft missile-accuracy marketing messages and it will help
        you identify which cannabis products to stock instead of carrying items
        that don’t move off the shelves. Another vital business-building
        component that knowing your target market will help you nail is choosing
        the appropriate marketing media. For example, millennials may prefer
        Instagram and video-based content for promotion. If you are a local
        dispensary in Ontario, you can leverage directories such as S7DAW to
        help drive traffic for you based on how near and popular your location
        is to what the user is searching for.
      </p>
      <div className={classes.link}>
        <Link to="/">Sign up for S7DAW here by visiting our website.</Link>
      </div>

      <h3 className={classes.h3}>
        Important Questions Your Research Should Answer
      </h3>
      <p className={classes.p}>
        There are specific questions that we suggest going through to help guide
        you in your research journey, this way you will know as much as possible
        about your ideal client. These questions pertain to the demographics and
        psychographics of your target customers which include:{" "}
      </p>

      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          <strong>Who is your ideal customer?</strong>
          <ul className={classes.uListSml}>
            <li>
              This includes age, income, marital status, sex, and what car they drive.
            </li>
          </ul>
        </li>
        <li>
          <strong>Why are they using cannabis?</strong>
          <ul className={classes.uListSml}>
            <li>
              Recreational vs. Medical use.
            </li>
          </ul>
        </li>
        <li>
          <strong>Which cannabis products do they prefer and why?</strong>
          <ul className={classes.uListSml}>
            <li>
              Understand which strains and product types your audience prefers.
            </li>
          </ul>
        </li>
        <li>
          <strong>What websites do your visitors often visit?</strong>
          <ul className={classes.uListSml}>
            <li>
            Social media channels.
            </li>
          </ul>
        </li>
      </ul>

      <p className={classes.p}>
        A good place to start your research is talking to potential customers,
        visiting local stores, and performing an analysis on competitor websites
        through common SEO tools like Ahrefs, and SEMrush.
      </p>

      <h2 className={classes.h2}>Study Your Competition</h2>
      <p className={classes.p}>
        All cannabis dispensaries face competition, which is a good thing
        because it shows that demand is present in the area and it leaves room
        for you to craft a niche for yourself. An example of a niche is a
        dispensary that also provides bulk-delivery services. With increasing
        competition, it becomes difficult to compete on price and so you must
        understand your audience and competition to see what additional problems
        you can solve or how you can improve on an existing solution. Armed with
        knowledge of your competitors and audience, you’ll be able to accomplish
        important steps in starting and growing your pot business. With this
        knowledge:{" "}
      </p>

      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          You’ll be able to strategically price your products base on quantity and value (A-AAAA)
        </li>
        <li>
          It will be easier to select the right products to sell such as edibles which are increasing in popularity.
        </li>
        <li>
          Leaves room for you to provide additional services such as delivery and pick-up.
        </li>
        <li>
          You’ll craft an unparalleled customer service by taking advantage of weaknesses of the competition.
        </li>
      </ul>
      <h3 className={classes.h3}>Where to Find Your Competitors?</h3>
      <p className={classes.p}>
        Your direct competitors are other cannabis retail stores in Ontario
        which means you can easily visit a few stores as a customer which will
        enable you to have first-hand experience of their customer service, how
        they’ve set up their store, and their product pricing. Importantly,
        you’ll be able to figure out how they’re advertising their business in
        the store. Another excellent source of competitor information is their
        websites. Here, you may find customer testimonials and complaints. In
        addition, you may decipher their online marketing strategies to identify
        appeals you could adopt.
      </p>
      <h2 className={classes.h2}>
        The Cost of Opening a Cannabis Dispensary in Ontario
      </h2>
      <p className={classes.p}>
        Canada has stringent requirements to legally sell cannabis products
        where you will need to apply for and get licenses that permit operating
        a cannabis dispensary.
      </p>
      <h3 className={classes.h3}>Costs for Licenses</h3>
      <p className={classes.p}>
        The first license is called the Retail Operator License (ROL) that gets
        issued to an individual which requires an initial investment of C$6,000
        allowing you to apply for the ROL. Once you’ve applied for the ROL,
        you’ll be ready to submit an application for the Retail Store
        Authorization permit. For this application, the Alcohol and Gambling
        Commission of Ontario (AGCO) set an investment of C$4,000 to process
        your documentation. A third license you may need is called the Cannabis
        Retail Manager License and this costs C$750. You’ll learn more about
        these licenses below. So, getting these licenses may require you to part
        with nearly $11,000. Furthermore, you also need to{" "}
        <a
          href="https://www.ontario.ca/page/registering-your-business-name"
          target="_blank"
          rel="noopener noreferrer"
        >
          register your business
        </a>{" "}
        in Ontario for a minimal fee.{" "}
      </p>
      <p>
        *Please keep in mind the prices you see here as updated as of 2020.
        These license prices may change year-after-year.
      </p>
      <h3 className={classes.h3}>Costs for Buildings and Furnishings</h3>
      <p className={classes.p}>
        To run a cannabis store you need to have a building to do business in.
        Although unlikely you will have the capital to build your retail store
        from the ground up, look for existing dispensaries that are for sale, or
        find a small space near high foot-traffic to ensure success. Obviously,
        there are other costs associated with this and the amount you will need
        depends on your store size and design. Other costs include furnishing,
        architectural improvements, a security system, initial stock, and
        employee salaries. Overall, the startup costs could be anywhere from
        $80,000 to $120,000 to run an efficient and scalable dispensary.
      </p>
      <h2 className={classes.h2}>
        Funding Your Cannabis Retail Store Business
      </h2>
      <p className={classes.p}>
        As you saw above, the cost for starting up a cannabis retail store could
        be steep. Unless you have massive savings or a relative loaded with
        cash, you may have to seek funding elsewhere through resources like your
        local bank, private lenders or investors. The key when applying for
        funding is providing ample evidence that your business is financially
        feasible. Funders don’t want to lose money, hence why they often require
        you to have a sound{" "}
        <a
          href="https://www.bplans.com/downloads/business-plan-template/"
          target="_blank"
          rel="noopener noreferrer"
        >
          business plan
        </a>
        . The best approach to seeking funding is to approach the process as if
        applying at a traditional institution like a bank. This is because these
        financiers have strict requirements. Besides an accurate business plan,
        ensure you have documentation and information like the following:{" "}
      </p>

      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>Company registration documents.</li>
        <li>Bank account details.</li>
        <li>A clean credit history and a good credit record.</li>
        <li>Tax information (both for business and yourself).</li>
      </ul>
      <p>
        Financiers such as traditional lending institutions, private equity
        firms, and private financing companies are among those you could
        approach for funds.
      </p>
      <h2 className={classes.h2}>
        Licenses Required to Operate a Cannabis Dispensary{" "}
      </h2>
      <p className={classes.p}>
        As mentioned previously to operate a legal cannabis retail store in
        Ontario you will need to have 3 licenses:{" "}
      </p>
      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>Retail Operator License.</li>
        <li>Retail Store Authorization.</li>
        <li>Cannabis Retail Manager License.</li>
      </ul>
      <p>
        The applications can be done online through the{" "}
        <a
          href="https://www.iagco.agco.ca/prod/pub/en/Login.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          iAGCO
        </a>{" "}
        portal. The first step is to create an account on the website, then
        start applying for the relevant license. If you’re starting from
        scratch, the first license to obtain is the ROL. The AGCO requires
        applicants to qualify for the ROL through an eligibility assessment set
        in the Cannabis License Act 2018. You need to furnish the legal name of
        your business, type of company, and its legal structure during the
        application. After filing for the application and paying the C$6,000
        fee, you’ll be provided with the File Number. Using this number, you may
        go ahead and apply for the Retail Store Authorization. This permit is
        required for each store that you run. Importantly, prior to applying for
        the Retail Store Authorization, check with the local municipality to
        ensure your target store meets the zoning requirements. Finally, you may
        need to apply for the Cannabis Retail Manager License if someone other
        than the owner runs the store. Such a person manages compliance to
        regulations in the store, hires and manages staff, coordinates the sales
        of cannabis, or signs for purchases of cannabis.
      </p>
      <h2 className={classes.h2}>
        Licenses Required to Operate a Cannabis Dispensary{" "}
      </h2>
      <p className={classes.p}>
        Running a cannabis dispensary is a huge responsibility. With changing
        regulations and the need to strictly adhere to the current laws, you
        need to be on top of things. That’s why you need to have others on your
        side to help stay on top of your game. Besides hiring the manager, you
        may need to bring in other employees to help with tasks like customer
        service, inventory management, and security. But every employee you hire
        must have successfully completed the{" "}
        <a
          href="https://learn.cannsell.ca/local/pages/?id=7"
          target="_blank"
          rel="noopener noreferrer"
        >
          CannSell training program
        </a>
        . This certification is available online in both English and French for
        C$64.99, plus the harmonized sales tax. Furthermore, you need to have a
        legal eye to help you stay up to date with the changing cannabis
        regulations. This is probably one of the best cannabis business-running
        decisions you can ever make. Other professional services necessary
        include accountants for preparation of financial statements. These tools
        provide a picture of what’s happening inside the business and also help
        forecast the short-term future of your dispensary.
      </p>
      <h2 className={classes.h2}>Conclusion</h2>
      <p className={classes.p}>
        In a nutshell, starting a thriving cannabis dispensary begins with
        targeting your ideal customer. Following this with spying on your
        competition helps to uniquely position your business to succeed. It can
        cost upwards of $100,000 to start a cannabis retail store which is why
        it is important to seek partners who can invest with you, or look for
        financiers to support this lucrative opportunity. Finally, build a
        strong team of trained employees and professionals like attorneys,
        accountants and an insurance agent to keep yourself from being sued.
        Taking these steps ensures that you minimize the stress that comes with
        starting a business. More importantly, you improve your chances of
        building a profitable cannabis retail store. The final thought to
        mention is if you are starting up a dispensary, chances are you won’t
        have such a high capital to invest into marketing right away. Leverage
        cannabis directories like S7DAW by creating a{" "}
        <Link to="/">free account</Link> and let us help you with driving
        traffic to your local business listing.
      </p>
      <h2 className={classes.h2}>Frequently Asked Questions</h2>
      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          <strong>Can I sell cannabis through an e-commerce platform if I have a Retail Store Authorization?</strong>
          <ul className={classes.uListSml}>
            <li>
              Only the Ontario Cannabis Store (OCS) is permitted to trade cannabis online.
            </li>
          </ul>
        </li>
        <li>
          <strong>Who handles deliveries of cannabis orders from the OCS to private retail stores?</strong>
          <ul className={classes.uListSml}>
            <li>
              The Ontario Cannabis Retail Corporation (OCRC) transports cannabis products to retail stores.
            </li>
          </ul>
        </li>
        <li>
          <strong>Am I allowed to sell medical cannabis to my customers?</strong>
          <ul className={classes.uListSml}>
            <li>
              You are allowed to sell only recreational cannabis as per your AGCO Retail Operator License and Retail Store Authorization Selling medical cannabis requires you to have a Sale for Medical Purposes license from Health Canada.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
