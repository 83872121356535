import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ProfileIcon from '@material-ui/icons/AccountBox';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import StoreIcon from '@material-ui/icons/Store';
import NotificationsIcon from '@material-ui/icons/Notifications';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Link } from 'react-router-dom';
import cookies from '../cookies';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #ebebeb',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // "&:focus": {
    //   backgroundColor: theme.palette.primary.main,
    //   "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
    //     color: theme.palette.common.white
    //   }
    // }
  },
}))(MenuItem);

export default function AccountMenu({ logout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ fontWeight: 'bold', height: 36, width: 126 }}
        id="my-account-button"
      >
        My Account
      </Button>

      <StyledMenu id="account-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem
          component={Link}
          to="/profile"
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <ProfileIcon fontSize="small" id="my-profile-button" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/notifications"
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          onClick={() => handleClose()}
        >
          <ListItemIcon>
            <NotificationsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </StyledMenuItem>
        {['ROLE_STORE_ADMIN', 'ROLE_BRAND_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole()) && (
          <StyledMenuItem
            component={Link}
            to="/client"
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            onClick={() => handleClose()}
          >
            <ListItemIcon>
              <StoreIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Client Portal" />
          </StyledMenuItem>
        )}
        {['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(cookies.getRole()) && (
          <StyledMenuItem
            component={Link}
            to="/admin"
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            onClick={() => handleClose()}
          >
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Admin Portal" />
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
