import { makeStyles } from "@material-ui/styles";

export const postStyle = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  h1: {
    fontSize: "300%",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
    fontWeight: "bold",
  },
  h2: { marginTop: theme.spacing(8), fontSize: "200%", fontWeight: "bold" },
  h3: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    fontSize: "175%",
  },
  p: {
    fontSize: "170%"
  },
  pBold: { fontWeight: "bold" },
  link: { textAlign: "center" },
  bullet: { fontSize: "170%", marginLeft: theme.spacing(4) },
  uList: { listStyleType: "'▶︎ '" },
  uListSml: { listStyleType: "'▷ '"},
  indented: { marginLeft: theme.spacing(2) }
}));
