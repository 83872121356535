export default {
  Airdrie: { lat: 51.2917, lon: -114.0134 },
  Athabasca: { lat: 54.7179, lon: -113.2854 },
  Banff: { lat: 51.1784, lon: -115.5708 },
  Barrhead: { lat: 54.1234, lon: -114.4025 },
  Bassano: { lat: 50.7847, lon: -112.4676 },
  Beaumont: { lat: 53.3566, lon: -113.4152 },
  Beaverlodge: { lat: 55.2097, lon: -119.4364 },
  'Black Diamond': { lat: 50.6896, lon: -114.2343 },
  Blackfalds: { lat: 52.3794, lon: -113.7855 },
  'Bon Accord': { lat: 53.8324, lon: -113.4155 },
  Bonnyville: { lat: 54.2677, lon: -110.7438 },
  'Bow Island': { lat: 49.8675, lon: -111.3839 },
  Brooks: { lat: 50.5648, lon: -111.8991 },
  Calgary: { lat: 51.0447, lon: -114.0719 },
  Calmar: { lat: 53.2691, lon: -113.8092 },
  Camrose: { lat: 53.0217, lon: -112.8286 },
  Canmore: { lat: 51.0894, lon: -115.3593 },
  Cardston: { lat: 49.1958, lon: -113.3026 },
  Carstairs: { lat: 51.5672, lon: -114.0981 },
  Chestermere: { lat: 51.0495, lon: -113.8236 },
  Claresholm: { lat: 50.0199, lon: -113.5787 },
  Coaldale: { lat: 49.7212, lon: -112.6192 },
  Coalhurst: { lat: 49.745, lon: -112.9106 },
  Cochrane: { lat: 51.1913, lon: -114.4672 },
  'Cold Lake': { lat: 54.4646, lon: -110.2123 },
  Crossfield: { lat: 51.4339, lon: -114.0338 },
  Devon: { lat: 53.3652, lon: -113.7357 },
  Didsbury: { lat: 51.6669, lon: -114.1372 },
  'Drayton Valley': { lat: 53.2231, lon: -114.9786 },
  Edmonton: { lat: 53.5461, lon: -113.4938 },
  Edson: { lat: 53.5856, lon: -116.4368 },
  'Elk Point': { lat: 53.8954, lon: -110.9014 },
  Fairview: { lat: 56.0735, lon: -118.3869 },
  Falher: { lat: 55.7378, lon: -117.2011 },
  'Fort Macleod': { lat: 49.7211, lon: -113.4065 },
  'Fort McMurray': { lat: 56.7268, lon: -111.379 },
  'Fort Saskatchewan': { lat: 53.7126, lon: -113.212 },
  'Fox Creek': { lat: 54.4, lon: -116.8031 },
  Gibbons: { lat: 53.8275, lon: -113.3275 },
  'Grande Cache': { lat: 53.8815, lon: -119.1197 },
  'Grande Prairie': { lat: 55.1707, lon: -118.7947 },
  Grimshaw: { lat: 56.1856, lon: -117.6084 },
  Hanna: { lat: 51.6461, lon: -111.9277 },
  'High Level': { lat: 58.5169, lon: -117.1364 },
  'High Prairie': { lat: 55.4317, lon: -116.4856 },
  'High River': { lat: 50.5801, lon: -113.8741 },
  Hinton: { lat: 53.3995, lon: -117.5801 },
  Irricana: { lat: 51.3161, lon: -113.606 },
  'Jasper Park Lodge': { lat: 52.8869, lon: -118.0577 },
  Killam: { lat: 52.7823, lon: -111.8507 },
  'Lac La Biche': { lat: 54.7682, lon: -111.9719 },
  Lacombe: { lat: 52.468, lon: -113.7354 },
  Lamont: { lat: 53.7667, lon: -112.777 },
  Leduc: { lat: 53.2647, lon: -113.5512 },
  Lethbridge: { lat: 49.6935, lon: -112.8418 },
  Magrath: { lat: 49.4236, lon: -112.8687 },
  Manning: { lat: 56.9234, lon: -117.6187 },
  'Maple Ridge': { lat: 49.2191, lon: -122.598 },
  Mayerthorpe: { lat: 53.9556, lon: -115.1345 },
  'Medicine Hat': { lat: 50.0405, lon: -110.6768 },
  Millet: { lat: 53.0925, lon: -113.4692 },
  Morinville: { lat: 53.8031, lon: -113.6503 },
  Nanton: { lat: 50.3483, lon: -113.7691 },
  Okotoks: { lat: 50.7253, lon: -113.9822 },
  Olds: { lat: 51.7921, lon: -114.1057 },
  'Peace River': { lat: 56.234, lon: -117.2854 },
  Penhold: { lat: 52.1371, lon: -113.8686 },
  'Picture Butte': { lat: 49.8739, lon: -112.7853 },
  'Pincher Creek': { lat: 49.4859, lon: -113.9455 },
  Ponoka: { lat: 52.6761, lon: -113.5819 },
  Provost: { lat: 52.3521, lon: -110.2698 },
  Raymond: { lat: 49.4617, lon: -112.6464 },
  'Red Deer': { lat: 52.2681, lon: -113.8112 },
  'Rideau Park': { lat: 51.0121, lon: -114.0872 },
  Rimbey: { lat: 52.6336, lon: -114.2347 },
  'Rocky Mountain House': { lat: 52.3764, lon: -114.9181 },
  Sexsmith: { lat: 55.3527, lon: -118.7853 },
  'Sherwood Park': { lat: 53.5232, lon: -113.3154 },
  'Slave Lake': { lat: 55.2818, lon: -114.769 },
  'Smoky Lake': { lat: 54.1136, lon: -112.4706 },
  'Spirit River': { lat: 55.7806, lon: -118.8354 },
  'Spruce Grove': { lat: 53.542, lon: -113.9007 },
  'St. Albert': { lat: 53.6305, lon: -113.6256 },
  Stettler: { lat: 52.3237, lon: -112.7103 },
  'Stony Plain': { lat: 53.5262, lon: -114.0076 },
  Strathmore: { lat: 51.0375, lon: -113.3848 },
  Sundre: { lat: 51.7962, lon: -114.6445 },
  'Swan Hills': { lat: 54.7167, lon: -115.4028 },
  'Sylvan Lake': { lat: 52.3061, lon: -114.0963 },
  Taber: { lat: 49.784, lon: -112.1519 },
  'Three Hills': { lat: 51.7031, lon: -113.2651 },
  Tofield: { lat: 53.3706, lon: -112.6667 },
  'Two Hills': { lat: 53.7111, lon: -111.7494 },
  Valleyview: { lat: 55.0694, lon: -117.2858 },
  Vegreville: { lat: 53.4953, lon: -112.0514 },
  Vermilion: { lat: 53.3542, lon: -110.854 },
  Viking: { lat: 53.0954, lon: -111.7791 },
  Vulcan: { lat: 50.4037, lon: -113.2501 },
  Wainwright: { lat: 52.8396, lon: -110.8574 },
  Wembley: { lat: 55.1514, lon: -119.1408 },
  Westlock: { lat: 54.152, lon: -113.8511 },
  Wetaskiwin: { lat: 52.9696, lon: -113.3778 },
  Whitecourt: { lat: 54.1428, lon: -115.6843 },
  'Wild Rose': { lat: 52.3105, lon: -113.775 },
  'Silver Berry': { lat: 53.4555, lon: -113.3727 },
  Laurel: { lat: 53.5014, lon: -113.459 },
  Tamarack: { lat: 53.4627, lon: -113.3772 },
  Larkspur: { lat: 53.5034, lon: -113.4268 },
};
