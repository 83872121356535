import React, { useContext } from 'react';
import { makeStyles, Typography, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SCREEN_STATES } from '../utils/constants';
import { makeImageKitURL } from '../utils/helpers';
import PreviewContext from '../PreviewContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    height: 250,
    boxShadow: '0px 0px',
    border: '1px solid #b0b0b0',
  },
  media: {
    height: 120,
    objectFit: 'cover',
  },
  content: {
    height: 130,
  },
  details: {
    height: 50,
  },
}));

export default function BrandProductItem(props) {
  const { name, image, id, brandSlug, slug } = props;

  let details = props.details;

  const classes = useStyles();

  const maxDetailsLength = 40;

  details = details.slice(0, maxDetailsLength) + (details.length > maxDetailsLength ? '...' : '');

  const preview = useContext(PreviewContext);

  const productPath = `/brands/${brandSlug}/${id}/${slug}`;

  const imageURL = makeImageKitURL(image, { width: '200' }, 'product');

  return (
    <Card
      className={classes.root}
      component={!preview.state.screen ? Link : undefined}
      to={!preview.state.screen ? productPath : undefined}
      style={{ color: '#000000', textDecoration: 'none' }}
      onClick={!preview.state.screen ? undefined : () => preview.changeScreen(SCREEN_STATES.PRODUCT_DETAILS, id)}
    >
      <CardActionArea>
        <CardMedia className={classes.media} image={imageURL} title={name} />
        <CardContent className={classes.content}>
          <Typography>{name}</Typography>
          <Typography variant="body2" color="textSecondary" className={classes.details}>
            {details}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
