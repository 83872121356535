import React, { useState, useEffect, Fragment } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
import { makeStyles } from '@material-ui/styles';
import FeaturedList from '../components/FeaturedList';
import { FEATURED_TYPES, NAV_OPTIONS, STORE_TYPES, STATUS, CITIES_USING_TOP_PICKS } from '../utils/constants';
import { Typography, Divider, Switch } from '@material-ui/core';
// import { Typography, Box, Divider } from '@material-ui/core';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleCard from '../components/SimpleCard';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import api from '../utils/api';
import { parseIdFromLink, allStoresRedirect, filterFeaturedList, deAbbreviate, openStatus } from '../utils/helpers';
import { useStateValue } from '../state';
// import Carousel from '../components/Carousel';
import CarouselManaged from '../components/CarouselManaged';
import { DeliveryFAQ } from '../components/DeliveryFAQ';
import { Helmet } from 'react-helmet';
import { deliveryBannerData } from '../utils/example-data';
import SEOIntro from '../components/seoDelivery/Intro';
import SEOMiddleSection from '../components/seoDelivery/MiddleSection';
import SEORemainder from '../components/seoDelivery/Remainder';

const useStyles = makeStyles((theme) => ({
  root: {},
  topBanner: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerWrapper: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    margin: theme.spacing(2),
    fontSize: '2rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      marginRight: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  storeGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
  seoTitle: {
    margin: theme.spacing(2, 2, 2, 2),
    color: theme.palette.primary.main,
    fontWeight: '400',
  },
  seoText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: '400',
  },
  seoIntro: {
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: '700',
    '@media (max-width: 425px)': {
      display: 'none',
    },
  },
  seoIntroMobile: {
    '@media (min-width: 426px)': {
      display: 'none',
    },
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(-1.25),
    border: 'none',
    boxShadow: 'none',
    fontWeight: '700',
  },
  seoIntroTitleMobile: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    fontWeight: '700',
    border: 'none',
    boxShadow: 'none',
  },
  seoIntroTextMobile: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(0),
    fontWeight: '700',
    border: 'none',
    boxShadow: 'none',
  },
  resultsText: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  openNowSwitchText: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      textAlign: 'left',
    },
  },
  cityText: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#9ea137',
    fontWeight: '700',
  },
  reasonBox: {
    marginLeft: theme.spacing(2),
    borderRadius: '5px',
    zIndex: 2,
    textAlign: 'center',
  },
  reasonIcon: {
    fontSize: '1rem',
  },
  reasonTitle: {
    fontWeight: '700',
  },
  reasonText: {
    marginTop: theme.spacing(-2.5),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    fontWeight: '400',
    borderRadius: '5px',
    zIndex: 1,
  },
}));

const getStores = async (type, id) => {
  return api
    .getStores(type, id, true, false)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

// const getFeaturedDeliveries = async id => {
//   return api
//     .getFeaturedDeliveries(id)
//     .then(res => res.data._embedded.featuredDeliveryRanks)
//     .catch(() => {
//       throw STATUS.ERROR;
//     });
// };

const getDeliveryRanks = async (id) => {
  return api
    .getDeliveryPageData(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function DeliveriesView(props) {
  const classes = useStyles();
  const { fetchAndBuildList, allowBookings } = useBookingUserData();

  const { banners, bannerError, bannersLoading } = props;

  const [allStores, setAllStores] = useState([]);
  const [allRanks, setAllRanks] = useState([]);
  const [featuredStores, setFeaturedStores] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [topRatedStores, setTopRatedStores] = useState([]);

  const [{ location }] = useStateValue();
  const currCity = decodeURI(location.city);
  const currProvince = location.province;

  const [loadingDeliveries, setLoadingDeliveries] = useState(true);
  const [loadingFeatured, setLoadingFeatured] = useState(true);

  const [showOpenStatus, setShowOpenStatus] = useState(false);

  // const [expanded, setExpanded] = useState(false);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  // const REASON_WIDTH = '9rem';

  // ao: sdaw-1162 - redirect to the correct province name
  const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
  // const urlCity =
  //   window.location.href.split("/")[5] &&
  //   window.location.href.split("/")[5].toLowerCase();

  // if (urlProv !== location.province.replace(/ /gi, '-').toLowerCase()) { //Checks cookie location against URL, this prevents direct urls out of set province
  if (urlProv.length === 2) {
    //Org intent was to redirect abbreviated provinces to full spelling
    //Issue this doesn't allow abbreviated provinces  to change...
    /* check abreviated province and assign to new variable for redirect */
    const abbrProvince = urlProv;
    const provFullName = deAbbreviate(abbrProvince.toUpperCase());
    const urlCity = window.location.href.split('/')[4] && window.location.href.split('/')[5].toLowerCase();

    window.location.href = `/deliveries/${provFullName.replace(/ /gi, '-').toLowerCase()}/${urlCity
      .replace(/ /gi, '-')
      .toLowerCase()}`;
  }
  //||
  //   capitalizeFirstOfEach(decodeURI(urlCity)) !== capitalizeFirstOfEach(decodeURI(location.city.toLowerCase()))
  // ) {
  //   window.location.href = `/deliveries/${abbreviate(
  //     location.province
  //   ).toLowerCase()}/${location.city.toLowerCase()}`;
  // }
  useEffect(() => {
    /* [] TODO, fix over fetching, current iteration works well linked within app, but fetches 3x with direct links or refresh - react query may help*/
    /* If the same is used in the app it should be moved to utils so it can be reused / centralized (as a data hook) */
    let mounted = true;
    const currCityID = location.link;
    const isBookingsPermitted = allowBookings(currCityID);

    const fetchAndSetDeliveries = async () => {
      try {
        // if (!loadingAllData) return;
        const fetches = await Promise.all([
          getStores(STORE_TYPES[1], location.link),
          getDeliveryRanks(parseIdFromLink(location.link)),
        ]);
        const [storesRes, ranksRes] = fetches;
        const addStoreOpenStatus = (store) => {
          return { ...store, isOpenNow: openStatus(store.hours) };
        };

        const allFiltered = storesRes
          .concat()
          .sort((first, second) => first.name.localeCompare(second.name))
          .filter((store) => store.storeType === 'Delivery' && store.published === true && store.localDelivery === true)
          .map(addStoreOpenStatus);

        const topStores = allFiltered
          .filter(
            (store) =>
              !store.nationwide ||
              (store.address.city === location.city && store.address.province === location.province)
          )
          .filter((store) => store.numOfReviews > 0)
          .sort((first, second) =>
            second.avgRating - first.avgRating !== 0
              ? second.avgRating - first.avgRating
              : second.numOfReviews - first.numOfReviews !== 0
              ? second.numOfReviews - first.numOfReviews
              : first.name.localeCompare(second.name)
          )
          .slice(0, 15);

        const validDelivery = (store) => {
          return (
            store.delivery.storeType === 'Delivery' && store.delivery.published === true && store.localDelivery === true
          );
        };
        const formatItem = (store, type) => {
          // Get open status from allFiltered obj (might be better to to add during filter feat list (but might break other categories))
          const fullStoreDetails = allFiltered.find((detailedStore) => {
            //type maybe 'store' or 'delivery' need to handle both
            const idFromAllFiltered = parseIdFromLink(detailedStore._links.self.href, 0, '{?projection}');
            const idFromStore = parseIdFromLink(store[type]._links.self.href, 0, '{?projection}');
            return idFromAllFiltered === idFromStore;
          });
          const isOpenNow = fullStoreDetails && fullStoreDetails.isOpenNow;
          const featuredStore = { ...store[type], slug: store.storeSlug, isOpenNow };
          return featuredStore;
        };
        const featuredRanks = filterFeaturedList('delivery', ranksRes.featuredDeliveryRanks, allFiltered)
          .filter(validDelivery)
          .map((store) => formatItem(store, 'delivery'));
        const popularRanks = filterFeaturedList('store', ranksRes.recommendedRanks, allFiltered)
          .filter(
            (store) =>
              store.store.storeType === 'Delivery' &&
              store.store.published === true &&
              store.store.localDelivery === true
          )
          .map((store) => formatItem(store, 'store'));
        const allStoreRanks = filterFeaturedList('delivery', ranksRes.allDeliveryRanks, allFiltered)
          .filter(validDelivery)
          .map((store) => formatItem(store, 'delivery'));
        //==============
        const itemsToDisplay = new Set(allStoreRanks);
        allFiltered.forEach((deal) => itemsToDisplay.add(deal));

        //==============
        if (mounted) {
          setAllStores(allFiltered); //[]These are similar, and likely only need one, will need to refactor rendering
          setAllRanks([...itemsToDisplay]);
          setTopRatedStores(topStores);
          if (!isBookingsPermitted) {
            setFeaturedStores(featuredRanks);
            setRecommended(popularRanks);
          }
        }

        if (isBookingsPermitted) {
          fetchAndBuildList(new Date(), 0, currCity, currProvince, 'Delivery').then((lists) => {
            const { popularStores, platinumStores } = lists;
            setFeaturedStores(platinumStores);
            setRecommended(popularStores);
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (mounted) {
          setLoadingDeliveries(false);
          setLoadingFeatured(false);
        }
      }
    };
    //============================================================useEffect Calls

    fetchAndSetDeliveries();

    return () => {
      mounted = false;
    };
    //eslint-disable-next-line
  }, [location]);
  //====================================================== Parse Lists based on open status
  //Currently lists are not parsed, to indicate open or closed an icon badge is used

  //allRanks, allStores, topRatedStores, featuredStores, recommended
  // const isOpenNow = (store) => store.isOpenNow === true;

  const parsedAllRanks = allRanks; // showOpenStatus ? allRanks.filter(isOpenNow) : allRanks;
  const parsedAllStores = allStores; // showOpenStatus ? allStores.filter(isOpenNow) : allStores;
  const parsedTopRatedStores = topRatedStores; // showOpenStatus ? topRatedStores.filter(isOpenNow) : topRatedStores;
  const parsedFeaturedStores = featuredStores; // showOpenStatus ? featuredStores.filter(isOpenNow) : featuredStores;
  const parsedRecommended = recommended; // showOpenStatus ? recommended.filter(isOpenNow) : recommended;

  const featListsExist =
    parsedFeaturedStores.length > 0 || parsedRecommended.length > 0 || parsedTopRatedStores.length > 0;

  const showOpenStatusSwitch = (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        textAlign: 'center',
      }}
      onClick={() => setShowOpenStatus(!showOpenStatus)}
    >
      <Switch checked={showOpenStatus} onChange={null} name="switchOpenNow" color="secondary" />
      <Typography className={classes.openNowSwitchText}>
        <i>Stores Open Now</i>
      </Typography>
    </span>
  );

  //====================================================== Render
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Weed Delivery ${currCity} - Fast Marijuana Delivery | S7DAW`}</title>
        <meta
          name="description"
          content={`Find the best weed dispensaries and brands in ${currCity}. Fast shipping from top rated cannabis, CBD, tinctures, and edible delivery brands! Order online.`}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {!bannersLoading && banners.length > 0 && (
        <Fragment>
          <CarouselManaged
            id="topBanners"
            type={'Deliverys'}
            banners={deliveryBannerData}
            override={`WEED DELIVERY ${decodeURI(location.city.toUpperCase())}`}
          />
        </Fragment>
      )}

      {(bannersLoading || (!bannersLoading && banners.length === 0)) && (
        <div className={classes.topBanner}>
          {bannersLoading && <Typography variant="caption">Loading...</Typography>}
          {!bannersLoading && banners.length === 0 && !bannerError && (
            <Typography variant="h3">Banners have not been set by the site admin.</Typography>
          )}
          {!bannersLoading && bannerError && <Typography variant="h3">Issue Retrieving Banner Information</Typography>}
        </div>
      )}
      <div className={classes.containerWrapper}>
        <div className={classes.contentContainer}>
          <SEOIntro currCity={location.city} />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.resultsText}>
              <i>Showing results for </i>
              <span className={classes.cityText}>
                <i>
                  {location.city}, {location.province}
                </i>
              </span>
            </Typography>
            {featListsExist ? showOpenStatusSwitch : null}
          </div>

          <Divider variant="middle" />

          <FeaturedList
            loading={loadingFeatured}
            index={1}
            listData={parsedFeaturedStores}
            title="Platinum Delivery Services"
            image="deliveries"
            type={FEATURED_TYPES.DELIVERY}
            viewAllPath="NONE"
            location={location}
            handleNavBarChange={() => {}}
            showOpenStatus={showOpenStatus}
          />

          <FeaturedList
            loading={loadingFeatured}
            index={3}
            listData={parsedRecommended}
            title={CITIES_USING_TOP_PICKS.includes(currCity) ? CITIES_USING_TOP_PICKS[0] : 'Most Popular Deliveries'}
            image="deliveries"
            type={FEATURED_TYPES.DELIVERY}
            viewAllPath="NONE"
            location={location}
            handleNavBarChange={() => {}}
            showOpenStatus={showOpenStatus}
          />

          <SEOMiddleSection currCity={location.city} />

          <FeaturedList
            loading={loadingDeliveries}
            index={2}
            listData={parsedTopRatedStores}
            title="Top Rated Cannabis Delivery Services"
            image="deliveries"
            type={FEATURED_TYPES.DELIVERY}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
            showOpenStatus={showOpenStatus}
          />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" className={classes.gridTitle}>
              ALL LOCAL DELIVERIES
            </Typography>
            {showOpenStatusSwitch}
          </div>

          {!loadingDeliveries && allStores.length === 0 && (
            <Typography className={classes.emptyList}>
              At this time we are working to bring on partnerships with your local retailers to service this area.
              Please check back later.
            </Typography>
          )}
          <div className={classes.storeGrid}>
            {loadingDeliveries && (
              <Fragment>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
              </Fragment>
            )}

            {/* TODO: All ranks and All Stores should be consolidtated to a 
            All Stores that is SORTED by rank then alphabetical or random */}
            {!loadingDeliveries &&
              parsedAllRanks.length > 0 &&
              parsedAllRanks.map((store, index) => {
                return (
                  <SimpleCard
                    key={index}
                    item={store}
                    itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Deliveries').path}/${allStoresRedirect(
                      location,
                      store
                    )}`}
                    type={FEATURED_TYPES.DELIVERY}
                    showOpenStatus={showOpenStatus}
                  />
                );
              })}

            {!loadingDeliveries &&
              parsedAllStores.length > 0 &&
              parsedAllStores
                .filter((store) => {
                  return !allRanks.some(
                    (rankedStore) =>
                      parseIdFromLink(store._links.self.href.replace('{?projection}', '')) ===
                      parseIdFromLink(rankedStore._links.self.href.replace('{?projection}', ''))
                  );
                })
                .map((store, index) => {
                  return (
                    <SimpleCard
                      key={index}
                      item={store}
                      itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Deliveries').path}/${allStoresRedirect(
                        location,
                        store
                      )}`}
                      type={FEATURED_TYPES.DELIVERY}
                      showOpenStatus={showOpenStatus}
                    />
                  );
                })}
            {loadingDeliveries && (
              <Fragment>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
              </Fragment>
            )}
          </div>

          <SEORemainder currCity={location.city} />

          {/* Implement lazy loading for store cards here once the API is hooked up */}
          {(location.city === 'Toronto' || location.city === 'London') && <DeliveryFAQ city={location.city} />}
        </div>
      </div>
    </div>
  );
}

export default DeliveriesView;
