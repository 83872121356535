export default {
  Abbotsford: { lat: 49.0504, lon: -122.3045 },
  Agassiz: { lat: 49.2381, lon: -121.7698 },
  Anmore: { lat: 49.3133, lon: -122.8566 },
  Armstrong: { lat: 50.4489, lon: -119.1955 },
  Ashcroft: { lat: 50.7248, lon: -121.2829 },
  'Bowen Island': { lat: 49.3948, lon: -123.3662 },
  Burnaby: { lat: 49.2488, lon: -122.9805 },
  'Burns Lake': { lat: 54.2317, lon: -125.7514 },
  'Cache Creek': { lat: 50.8105, lon: -121.3267 },
  'Campbell River': { lat: 50.0331, lon: -125.2733 },
  Castlegar: { lat: 49.3236, lon: -117.6593 },
  Chase: { lat: 50.8181, lon: -119.6842 },
  Chetwynd: { lat: 55.6973, lon: -121.6363 },
  Chilliwack: { lat: 49.1579, lon: -121.9515 },
  Colwood: { lat: 48.4329, lon: -123.4853 },
  Coquitlam: { lat: 49.2827, lon: -122.7967 },
  Courtenay: { lat: 49.6841, lon: -124.9905 },
  Cranbrook: { lat: 49.512, lon: -115.7694 },
  Creston: { lat: 49.1001, lon: -116.5134 },
  Cumberland: { lat: 49.6181, lon: -125.0342 },
  'Dawson Creek': { lat: 55.7606, lon: -120.2356 },
  Delta: { lat: 49.0847, lon: -123.0583 },
  Duncan: { lat: 48.7787, lon: -123.7079 },
  Elkford: { lat: 50.0248, lon: -114.91 },
  Enderby: { lat: 50.55, lon: -119.1398 },
  Fernie: { lat: 49.5045, lon: -115.0631 },
  'Fort Nelson': { lat: 58.8052, lon: -122.6973 },
  'Fort St. John': { lat: 56.2524, lon: -120.846 },
  Fruitvale: { lat: 49.1164, lon: -117.5465 },
  Gibsons: { lat: 49.4035, lon: -123.5071 },
  Golden: { lat: 51.2973, lon: -116.9629 },
  'Grand Forks': { lat: 49.0305, lon: -118.4428 },
  Hanceville: { lat: 52.0331, lon: -123.0623 },
  Hope: { lat: 49.3824, lon: -121.4418 },
  Houston: { lat: 54.4018, lon: -126.6617 },
  Invermere: { lat: 50.5147, lon: -116.0292 },
  Kamloops: { lat: 50.6745, lon: -120.3273 },
  Kelowna: { lat: 49.8879, lon: -119.496 },
  Kimberley: { lat: 49.6693, lon: -115.9787 },
  Kitimat: { lat: 54.0524, lon: -128.6523 },
  Ladysmith: { lat: 48.993, lon: -123.8157 },
  'Lake Cowichan': { lat: 48.8244, lon: -124.0534 },
  Langford: { lat: 48.4473, lon: -123.4961 },
  Langley: { lat: 49.1044, lon: -122.6613 },
  'Lions Bay': { lat: 49.4523, lon: -123.2389 },
  'Logan Lake': { lat: 50.4943, lon: -120.8124 },
  Lumby: { lat: 50.2526, lon: -118.9681 },
  'Maple Ridge': { lat: 49.2193, lon: -122.6019 },
  Merritt: { lat: 50.1123, lon: -120.7896 },
  Metchosin: { lat: 48.3836, lon: -123.5419 },
  Mission: { lat: 49.1333, lon: -122.3 },
  Nakusp: { lat: 50.244, lon: -117.8025 },
  Nanaimo: { lat: 49.1659, lon: -123.9401 },
  Nelson: { lat: 49.4938, lon: -117.2948 },
  'New Westminster': { lat: 49.2057, lon: -122.9109 },
  'North Cowichan': { lat: 48.8356, lon: -123.7183 },
  'North Saanich': { lat: 48.6486, lon: -123.4181 },
  'North Vancouver': { lat: 49.3204, lon: -123.0723 },
  'Oak Bay': { lat: 48.4266, lon: -123.3236 },
  Oliver: { lat: 49.1828, lon: -119.5524 },
  Osoyoos: { lat: 49.0321, lon: -119.4685 },
  Parksville: { lat: 49.3197, lon: -124.3135 },
  Peachland: { lat: 49.7757, lon: -119.7378 },
  Pemberton: { lat: 50.3217, lon: -122.8037 },
  Penticton: { lat: 49.4991, lon: -119.5937 },
  'Pitt Meadows': { lat: 49.2212, lon: -122.6895 },
  'Port Alberni': { lat: 49.2339, lon: -124.8056 },
  'Port Moody': { lat: 49.2846, lon: -122.8676 },
  'Powell River': { lat: 49.8353, lon: -124.524 },
  'Prince George': { lat: 53.9171, lon: -122.7497 },
  'Prince Rupert': { lat: 54.3154, lon: -130.3203 },
  Princeton: { lat: 49.4584, lon: -120.5113 },
  Quesnel: { lat: 52.9784, lon: -122.4924 },
  Revelstoke: { lat: 51.0002, lon: -118.1955 },
  Richmond: { lat: 49.1666, lon: -123.1336 },
  Rossland: { lat: 49.0785, lon: -117.802 },
  Salmo: { lat: 49.1957, lon: -117.274 },
  'Salmon Arm': { lat: 50.7001, lon: -119.2836 },
  Sechelt: { lat: 49.4744, lon: -123.7547 },
  Sicamous: { lat: 50.8371, lon: -118.9865 },
  Smithers: { lat: 54.7827, lon: -127.1688 },
  Sooke: { lat: 48.3741, lon: -123.7276 },
  Sparwood: { lat: 49.7387, lon: -114.8855 },
  Summerland: { lat: 49.5998, lon: -119.669 },
  Surrey: { lat: 49.1044, lon: -122.8214 },
  Terrace: { lat: 54.5182, lon: -128.6035 },
  Trail: { lat: 49.0958, lon: -117.7021 },
  'Tumbler Ridge': { lat: 55.1277, lon: -120.9942 },
  Ucluelet: { lat: 48.9423, lon: -125.5466 },
  Vancouver: { lat: 49.2827, lon: -123.1207 },
  Vanderhoof: { lat: 54.0184, lon: -124.0156 },
  Vernon: { lat: 50.267, lon: -119.272 },
  Victoria: { lat: 48.4284, lon: -123.3656 },
  'West End': { lat: 49.284, lon: -123.1324 },
  Whistler: { lat: 50.1163, lon: -122.9574 },
  'White Rock': { lat: 49.0251, lon: -122.8026 },
  'Williams Lake': { lat: 52.1415, lon: -122.144 },
  Tofino: { lat: 49.153, lon: -125.9066 },
  Lillooet: { lat: 50.6875, lon: -121.9335 },
  Okanagan: { lat: 49.85, lon: -119.5833 },
  'West Kelowna': { lat: 49.8636, lon: -119.5757 },
  Ladner: { lat: 49.0897, lon: -123.0823 },
  'Walnut Grove': { lat: 49.1713, lon: -122.6426 },
  Aldergrove: { lat: 49.0582, lon: -122.47 },
  'West Vancouver': { lat: 49.3271, lon: -123.16 },
  'Port Coquitlam': { lat: 49.2625, lon: -122.7811 },
};
