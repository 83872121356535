function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

function vincentyDistance(coord1, coord2) {
  const a = 6378137.0; // WGS-84 major axis
  const b = 6356752.314245; // WGS-84 minor axis
  const f = 1 / 298.257223563; // WGS-84 flattening

  const L = toRadians(coord2.lon - coord1.lon);
  const U1 = Math.atan((1 - f) * Math.tan(toRadians(coord1.lat)));
  const U2 = Math.atan((1 - f) * Math.tan(toRadians(coord2.lat)));
  const sinU1 = Math.sin(U1),
    cosU1 = Math.cos(U1);
  const sinU2 = Math.sin(U2),
    cosU2 = Math.cos(U2);

  let lambda = L,
    lambdaP,
    iterLimit = 100;
  let cosSqAlpha, sinSigma, cos2SigmaM, cosSigma, sigma;
  do {
    const sinLambda = Math.sin(lambda),
      cosLambda = Math.cos(lambda);
    sinSigma = Math.sqrt(
      cosU2 * sinLambda * (cosU2 * sinLambda) +
        (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) * (cosU1 * sinU2 - sinU1 * cosU2 * cosLambda)
    );
    // co-incident points
    if (sinSigma == 0) return 0; //eslint-disable-line
    cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda;
    sigma = Math.atan2(sinSigma, cosSigma);
    const sinAlpha = (cosU1 * cosU2 * sinLambda) / sinSigma;
    cosSqAlpha = 1 - sinAlpha * sinAlpha;
    cos2SigmaM = cosSigma - (2 * sinU1 * sinU2) / cosSqAlpha;
    if (isNaN(cos2SigmaM)) cos2SigmaM = 0; // equatorial line: cosSqAlpha=0 (§6)
    const C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
    lambdaP = lambda;
    lambda =
      L +
      (1 - C) *
        f *
        sinAlpha *
        (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
  } while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0);

  // formula failed to converge
  if (iterLimit == 0) return NaN; //eslint-disable-line

  const uSq = (cosSqAlpha * (a * a - b * b)) / (b * b);
  const A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  const B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
  const deltaSigma =
    B *
    sinSigma *
    (cos2SigmaM +
      (B / 4) *
        (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
          (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));

  const s = b * A * (sigma - deltaSigma);

  return s / 1000; // distance in kilometers
}

export default function findClosestCoordinateVincenty(userPosition, coordinatesList) {
  let closestDistance = Infinity;
  let closestCoordinate = null;

  coordinatesList.forEach((coord) => {
    const distance = vincentyDistance(userPosition, coord);
    if (distance < closestDistance) {
      closestDistance = distance;
      closestCoordinate = coord;
    }
  });

  return closestCoordinate;
}

// Example usage
// const userPosition = { lon: -113.5454, lat: 54.3432 }; // User's position
// const coordinatesList = [
//     { lon: -74.0060, lat: 40.7128 }, // New York
//     { lon: 2.3522, lat: 48.8566 },   // Paris
//     { lon: 139.6917, lat: 35.6895 }  // Tokyo
// ];

// const closestCoordVincenty = findClosestCoordinateVincenty(userPosition, coordinatesList);
// console.log('Closest Coordinate (Vincenty):', closestCoordVincenty);
