import React, { useState, useEffect, Fragment } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
import { makeStyles } from '@material-ui/styles';
import FeaturedList from '../components/FeaturedList';
import { FEATURED_TYPES, NAV_OPTIONS, STATUS, CITIES_USING_TOP_PICKS } from '../utils/constants';
import { Typography } from '@material-ui/core';
import SimpleCard from '../components/SimpleCard';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import api from '../utils/api';
import { convertToDate, parseIdFromLink, allStoresRedirect, filterFeaturedList, deAbbreviate } from '../utils/helpers';
import { useStateValue } from '../state';
// import Carousel from '../components/Carousel';
import CarouselManaged from '../components/CarouselManaged';
import { Helmet } from 'react-helmet';
import { dealBannerData } from '../utils/example-data';

const useStyles = makeStyles((theme) => ({
  root: {},
  topBanner: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '2px solid #505050',
  },
  containerWrapper: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  gridTitle: {
    margin: theme.spacing(2),
    fontSize: '2rem',
    color: theme.palette.primary.main,
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  dealGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  anchor: {
    top: -155,
    [theme.breakpoints.down('md')]: {
      top: -125,
    },
    display: 'block',
    position: 'relative',
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // hide on mobile - cfm use
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 200,
  },
}));

const getDeals = async (id) => {
  const publishedOnly = true;
  return api
    .getDealsByLocation(id, publishedOnly)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

// const getFeaturedDeals = async (id) => {
//   return (
//     api
//       .getDealRanks(id)
//       .then((res) => res.data._embedded)
//       // .getLandingProjection(id)
//       // .then((res) => res.data)
//       .catch(() => {
//         throw STATUS.ERROR;
//       })
//   );
// };

const getListByLocation = async (locID, endpoint) => {
  return api
    .getListByLocation(locID, endpoint)
    .then((res) => res.data._embedded)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function DealsView(props) {
  const classes = useStyles();

  //========Bookings
  const { fetchAndBuildList, allowBookings } = useBookingUserData();

  const { banners, bannerError, bannersLoading } = props;

  const [allDeals, setAllDeals] = useState([]);
  const [allRanks, setAllRanks] = useState([]);
  const [featuredDeals, setFeaturedDeals] = useState([]);
  const [recommendedDeals, setRecommendedDeals] = useState([]);

  const [{ location }] = useStateValue();
  const locationID = parseIdFromLink(location.link);
  const currCity = decodeURI(location.city);
  const currProvince = location.province;
  const currCityID = location.link;

  const [loadingDeals, setLoadingDeals] = useState(false);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingRecommended, setLoadingRecommended] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  // ao: sdaw-1162 - redirect to the correct province name
  const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
  //if (urlProv !== location.province.replace(/ /gi, '-').toLowerCase()) {
  if (urlProv?.length === 2) {
    const abbrProvince = urlProv;
    const provFullName = deAbbreviate(abbrProvince.toUpperCase());
    const urlCity = window.location.href.split('/')[4] && window.location.href.split('/')[5].toLowerCase();

    window.location.href = `/deliveries/${provFullName.replace(/ /gi, '-').toLowerCase()}/${urlCity
      .replace(/ /gi, '-')
      .toLowerCase()}`;
  }

  useEffect(() => {
    setAllDataLoaded(false);
  }, [location]);
  useEffect(() => {
    let mounted = true;
    setLoadingData(true);
    const fetchAndSetDeals = async () => {
      setLoadingDeals(true);
      setLoadingFeatured(true);
      setLoadingRecommended(true);

      const isBookingsPermitted = await allowBookings(currCityID);
      const today = new Date();
      const allDeals = await getDeals(location.link);

      const allCurrentDeals = allDeals
        .filter((deal) => today < convertToDate(deal.endDate) && convertToDate(deal.startDate) <= today)
        .sort((x, y) =>
          x.name
            .split(' ')
            .join('')
            .replace(/[^\w.,\s]/g, '')
            .localeCompare(
              y.name
                .toLowerCase()
                .split(' ')
                .join('')
                .replace(/[^\w.,\s]/g, ''),
              'en',
              { sensitivity: 'base' }
            )
        );
      let featuredRanks;
      let recommendedRanks;
      let allDealRanks; //Modified order of AllDeals
      //=======================Bookings Fetch
      if (isBookingsPermitted) {
        //[] Re-test booking items
        await fetchAndBuildList(today, 0, currCity, currProvince, 'deal').then((lists) => {
          const { popularStores, platinumStores } = lists;
          featuredRanks = platinumStores;
          recommendedRanks = popularStores;
        });
        /* [] TODO: Need to get allDealRanks when using booking System
          - Fetch only that list along w. fetchAndBuild (its own promise all)
          - OR Move all of this into sinlge promise with the fetches changing as needed */
      } else {
        //======================Legacy Fetching
        const listByLocEndPoints = ['featuredDealRanks', 'recommendedDealRanks', 'allDealRanks'];
        const rankedDeals = await Promise.all(listByLocEndPoints.map((ep) => getListByLocation(locationID, ep)));
        const [platinum, popular, ranksForAllDeals] = rankedDeals;

        featuredRanks = filterFeaturedList('deal', platinum.featuredDealRanks, allCurrentDeals).map(
          (item) => item.deal
        );
        recommendedRanks = filterFeaturedList('deal', popular.recommendedDealRanks, allCurrentDeals).map(
          (item) => item.deal
        );
        //[] Verify all Ranks is working (should sort AllDeals List, is it a full list or partial?)
        allDealRanks = filterFeaturedList('deal', ranksForAllDeals.allDealRanks, allCurrentDeals).map(
          (item) => item.deal
        );
      }
      //=================== Set Data
      const allDealsToDisplay = new Set(allDealRanks);
      allCurrentDeals.forEach((deal) => allDealsToDisplay.add(deal));
      if (mounted) {
        setAllDeals([...allDealsToDisplay]);
        setFeaturedDeals(featuredRanks);
        setRecommendedDeals(recommendedRanks);
        setAllRanks(allRanks);
        //[] change indv loading checks to loadingData or AllDataLoaded
        setLoadingDeals(false);
        setLoadingFeatured(false);
        setLoadingRecommended(false);
        setAllDataLoaded(true);
        setLoadingData(false);
      }
    };
    //============================
    if (allDataLoaded) return; //stops fetching on page change. (is this required if loadingData exists?)
    try {
      loadingData && fetchAndSetDeals();
    } catch (e) {
      //[]Add Error handing to the view (Make a useErrorHandling Hook to reuse in all views)
      // setLoadingError(true);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [location.link, loadingData, allDataLoaded]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Weed Deals in ${currCity} - Best Cannabis Deals`}</title>
        <meta
          name="description"
          content={`${currCity}'s best marijuana deals, UPDATED DAILY. Find the best deals today in ${currCity} on cannabis, CBD oil, edibles, concentrates, tinctures, seeds and more.`}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* <Typography variant="h6" component="h1" >{`Weed Deals in ${currCity}`}</Typography> */}
      {!bannersLoading && banners.length > 0 && (
        <Fragment>
          {/* <Carousel
            banners={dealBannerData}
            type={'Deals'}
            override={`Weed Deals in ${currCity}`.toUpperCase()}
          ></Carousel> */}
          <CarouselManaged
            id="topBanners"
            type={'Deals'}
            banners={dealBannerData}
            override={`Weed Deals in ${currCity}`.toUpperCase()}
          />
        </Fragment>
      )}
      {(bannersLoading || (!bannersLoading && banners.length === 0)) && (
        <div className={classes.topBanner}>
          {bannersLoading && <Typography variant="caption">Loading...</Typography>}
          {!bannersLoading && banners.length === 0 && !bannerError && (
            <Typography variant="h3">Banners have not been set by the site admin.</Typography>
          )}
          {!bannersLoading && bannerError && <Typography variant="h3">Issue Retrieving Banner Information</Typography>}
        </div>
      )}
      <div className={classes.containerWrapper}>
        <div className={classes.contentContainer}>
          <FeaturedList
            loading={loadingFeatured}
            index={1}
            listData={featuredDeals}
            title="Platinum Deals"
            image="deals"
            type={FEATURED_TYPES.DEAL}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
          />
          <FeaturedList
            loading={loadingRecommended}
            index={2}
            listData={recommendedDeals}
            title={CITIES_USING_TOP_PICKS.includes(currCity) ? CITIES_USING_TOP_PICKS[0] : 'Most Popular Deals'}
            image="popular"
            type={FEATURED_TYPES.DEAL}
            viewAllPath="NONE"
            handleNavBarChange={() => {}}
            location={location}
          />
          <Typography variant="h2" className={classes.gridTitle}>
            ALL DEALS
          </Typography>

          {!loadingDeals && allDeals.length === 0 && (
            <Typography className={classes.emptyList}>
              At this time we are working to bring on partnerships with your local retailers to service this area.
              Please check back later.
            </Typography>
          )}
          <div className={classes.dealGrid}>
            {/* What is allRanks? what does it do? */}
            {!loadingDeals &&
              allRanks.length > 0 &&
              allRanks
                .sort((first, second) =>
                  Number(second.rank) - Number(first.rank) !== 0
                    ? Number(second.rank) - Number(first.rank)
                    : second.name.localeCompare(first.name)
                )
                .map((deal, index) => {
                  return (
                    <SimpleCard
                      key={index}
                      item={deal}
                      itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Deals').path}/${allStoresRedirect(
                        location,
                        deal
                      )}`}
                      type={FEATURED_TYPES.DEAL}
                    ></SimpleCard>
                  );
                })}
            {!loadingDeals &&
              allDeals.length > 0 &&
              allDeals
                .filter((deal) => {
                  return !allRanks.some(
                    (rankedDeal) =>
                      parseIdFromLink(deal._links.self.href.replace('{?projection}', '')) ===
                      parseIdFromLink(rankedDeal._links.self.href.replace('{?projection}', ''))
                  );
                })

                .map((deal, index) => {
                  return (
                    <SimpleCard
                      key={index}
                      item={deal}
                      itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Deals').path}/${allStoresRedirect(
                        location,
                        deal
                      )}`}
                      type={FEATURED_TYPES.DEAL}
                    ></SimpleCard>
                  );
                })}
            {loadingDeals && (
              <Fragment>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
                <SkeletonSimpleCard></SkeletonSimpleCard>
              </Fragment>
            )}
          </div>
          {/* Implement lazy loading for deal cards here once the API is hooked up */}
        </div>
      </div>
    </div>
  );
}

export default DealsView;
