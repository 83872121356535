import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';
import { FEATURED_TYPES, SCREEN_STATES } from '../utils/constants';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import { convertToDate, parseIdFromLink, makeImageKitURL } from '../utils/helpers';
import PreviewContext from '../PreviewContext';
import openSignIcon from '../assets/icons/openSign.svg';
import closedSignIcon from '../assets/icons/closedSign.svg';

const useStyles = makeStyles((theme) => ({
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: 160,
    },
  },
  ratingContainer: {
    display: 'flex',
    alignContent: 'center',
  },
  ratingValue: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      fontSize: '0.75rem',
    },
  },
  cardActionArea: {
    position: 'relative',
  },
  dealStoreImage: {
    height: 80,
    width: 80,
    position: 'absolute',
    bottom: theme.spacing(7.5),
    right: theme.spacing(1.5),
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      width: 60,
    },
  },
  badgeContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
  },
  cardBadge: {
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      width: '55px',
      height: '55px',
    },
  },
  cardTextTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '95%',
    },
  },
  cardText: {
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '0.75rem',
    // },
  },
  location: {
    fontSize: '0.75rem',
  },
  cardContentSection: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

function SimpleCard(props) {
  const { item, itemPath, type, showOpenStatus } = props;

  const classes = useStyles();

  let openNowIndicator = null;
  if (showOpenStatus === true) {
    item.isOpenNow
      ? (openNowIndicator = <img src={openSignIcon} alt="Open Now" className={classes.cardBadge} />)
      : (openNowIndicator = <img src={closedSignIcon} alt="Closed Now" className={classes.cardBadge} />);
  }

  let dealStatus = '';

  if (type === FEATURED_TYPES.DEAL) {
    if (new Date() > convertToDate(item.endDate)) {
      dealStatus = 'Expired';
    } else if (new Date() < convertToDate(item.startDate)) {
      dealStatus = 'Upcoming';
    } else {
      dealStatus = 'Active';
    }
  }

  const preview = useContext(PreviewContext);

  const getImagePath = (item, type) => {
    switch (type) {
      case FEATURED_TYPES.DEAL:
        return item.dealImage;
      case FEATURED_TYPES.BRAND:
        return item.brandImage;
      default:
        return item.storeImage;
    }
  };

  const currImageURL = getImagePath(item, type);
  const itemImageURL = makeImageKitURL(currImageURL, { width: '400' }, type);

  let storeLogoForDeal = '';
  if (type === FEATURED_TYPES.DEAL) {
    storeLogoForDeal = makeImageKitURL(item.storeImage, { width: '75' }, 'storefront');
  }

  return (
    <Card
      component={!preview.state.screen ? Link : undefined}
      to={!preview.state.screen ? itemPath.replace('{?projection}', '') : undefined}
      style={{ color: '#000000', textDecoration: 'none', position: 'relative' }}
      onClick={
        !preview.state.screen
          ? undefined
          : () =>
              preview.changeScreen(
                SCREEN_STATES.DEAL_DETAILS,
                parseIdFromLink(item._links.self.href, 0, '{?projection}')
              )
      }
    >
      <CardActionArea className={classes.cardActionArea}>
        <div className={classes.badgeContainer}>
          {type === FEATURED_TYPES.DELIVERY && item.nationwide && (
            <img
              src={require(`../assets/navigation-icons/canadawideSticker.svg`)}
              alt={`navigation-icon-canada-wide`}
              className={classes.cardBadge}
            />
          )}
          {openNowIndicator}
        </div>

        <CardMedia className={classes.media} image={itemImageURL} title={item.name} />
        <CardContent className={classes.cardContentSection}>
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="caption">
              {dealStatus}
            </Typography>
          )}

          {type === FEATURED_TYPES.CLINIC && (
            <Typography
              noWrap
              className={classes.location}
            >{`${item.address.city}, ${item.address.province}`}</Typography>
          )}

          <Typography
            noWrap
            variant={type === FEATURED_TYPES.DEAL ? 'body2' : 'body1'}
            className={
              [FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.BRAND].includes(type)
                ? classes.cardTextTitle
                : classes.cardText
            }
          >
            {type === FEATURED_TYPES.DEAL ? item.storeName : item.name}
          </Typography>
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="body1" className={classes.cardTextTitle}>
              {item.name}
            </Typography>
          )}
          {[FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.BRAND, FEATURED_TYPES.CLINIC].includes(
            type
          ) && (
            <div className={classes.ratingContainer}>
              <Rating
                name="read-only"
                value={item.avgRating ? Number(item.avgRating) : 0}
                precision={0.5}
                size="small"
                className={classes.stars}
                readOnly
              />
              <Typography variant="body2" className={classes.ratingValue}>
                {`${item.avgRating ? Number(item.avgRating.toFixed(1)) : 0} (${
                  item.numOfReviews ? item.numOfReviews : 0
                })`}
              </Typography>
            </div>
          )}
        </CardContent>
        {type === FEATURED_TYPES.DEAL && (
          <img src={storeLogoForDeal} className={classes.dealStoreImage} alt={item.storeName}></img>
        )}
      </CardActionArea>
    </Card>
  );
}

export default SimpleCard;
