import React from "react";
import { postStyle } from "./postStyle";
import classNames from 'classnames';

const useStyles = postStyle;

export function DispensaryMarketingPlan() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.h1}>Marketing Plan For Your Dispensary</h2>
      <p className={classes.p}>
        Are you developing your cannabis dispensary business? If so, you must be
        excited about the millions of new customers who are eager to try out
        your product. But how exactly do you market towards them?
      </p>
      <p className={classes.p}>
        In today’s article we will show you how to reach your target audience
        using social media and organic marketing platforms.
      </p>
      <h2 className={classes.h2}>Your Dispensary Marketing Plan</h2>
      <p className={classes.p}>
        Although legal in many states and provinces, cannabis remains a
        sensitive topic to the public. Not everyone approaches it the same way,
        and so licensed dispensaries and producers constantly look for
        innovative ways to design a marketing strategy.
      </p>
      <p className={classes.p}>
        One of the most frequent errors is marketing your dispensary to everyone
        without distinguishing target user groups and adjusting your marketing
        to them.
      </p>
      <p className={classes.p}>
        The more clarity you have about your target audience, the easier it will
        be to understand them when you personalize messages to grab their
        attention and get them to convert into paying customers.
      </p>
      <h2 className={classes.h2}>
        Market Your Dispensary to Different Age Groups
      </h2>
      <p className={classes.p}>
        When it comes to identifying your target audience you need to do what
        every other business owner does to get their offer to the end-user:
        Create a user persona and tailor your marketing toward them.
      </p>
      <p className={classes.p}>
        This basic step is the same for every business out there, even if you
        are selling bottled air, which arguably anyone can use. On that note, I
        have added a few recommended target groups and subtle marketing tweaks
        that are necessary to get their attention.
      </p>
      <h3 className={classNames(classes.h3, classes.indented)}>Millennials: (1981 to 1996)</h3>
      <p className={classNames(classes.p, classes.indented)}>
        This audience segment covers young consumers born from 1981 to 1996. If
        you’re marketing in the US, the youngest members average about 21+ years
        old, and in Canada this is 19+ (Please confirm the legal age in your
        state or province)
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        Millennials are curious, tech-savvy, and innovative. They give everyone
        and anyone a chance as long as they perceive useful benefits from using
        a product. They are also environmentally aware and keen on doing their
        research before they invest in something, so you’ll need to convince
        them that your dispensary guarantees a quality experience while being
        cost effective.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        One of the fastest ways to reach millennials is through the use of
        social media, but remember to stay compliant with advertising rules.
      </p>
      <h3 className={classNames(classes.h3, classes.indented)}>Generation X: (1965 to 1980)</h3>
      <p className={classNames(classes.p, classes.indented)}>
        Unlike millennials, adult professionals are a bit more skeptical of
        brand messaging. The most effective way to market to them is to provide
        research-based proof of benefits and display a high level of
        transparency.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        Generation X values family, responsibility, and education, so framing
        your dispensary in a way to show how it contributes to progress and
        stability in life is the way to go. Although this group is highly
        present on social media, they don’t trust it as a relevant source of
        information.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        While you can get their attention on Facebook and Instagram, you’ll need
        to refer them to your business page and show certifications and social
        proof since they like to verify everything they read. News, radio, and
        TV ads are also a great way to reach this demographic.
      </p>
      <h3 className={classNames(classes.h3, classes.indented)}>Baby Boomers: (1946 - 1964)</h3>
      <p className={classNames(classes.p, classes.indented)}>
        This demographic consists of senior citizens, but you should never
        remind them of it. Baby boomers are proud of their achievements and were
        once rebellious youngsters eager to change the world.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        Unlike millennials and much like Gen X, Baby Boomers will need
        fact-based evidence that they benefit from your product. Rely on
        youthful imagery combined with percentages and statistics to do the
        trick with this group.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        However, keep in mind that while you should make the Baby Boomers feel
        young again, you should also make them feel safe. Avoid edgy language or
        jargon, as well as any loose references to past events since you don’t
        want to scare or offend.
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        If you want to sell to Baby Boomers, think smart, safe and a brand
        message that resonates with them. The effort will pay off since this
        group is known to be both financially secure and loyal to their trusted
        brands.{" "}
      </p>
      <p className={classNames(classes.p, classes.indented)}>
        When it comes to media, you can achieve the same effect whether you
        choose social or traditional (TV, radio, and newspaper). Remember to
        showcase permits, certifications, compliance, and research studies to
        show credibility.{" "}
      </p>
      <h2 className={classes.h2}>Follow These Cannabis Marketing Guidelines</h2>
      <p className={classes.p}>
        Now that you know how to advertise to different age groups, let’s
        establish the best marketing strategies to advertise your cannabis
        dispensary.
      </p>

      <h3 className={classes.h3}>What to Do</h3>
      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          <strong>Plan a marketing strategy:</strong>
          <ul className={classes.uListSml}>
            <li>
              It’s wise to establish which results you wish to see from your
              advertising campaign. You’ll do this by identifying your dispensary’s competition, consumer base, and value proposition.
            </li>
            <li>
              When it becomes clear what your unique offering is about, who you’re competing with, and who you’re targeting, you can start drafting carefully written messages that bridge the gap between you and the target demographic.
            </li>
          </ul>
        </li>
        <li>
          <strong>Design audience segments:</strong>
          <ul className={classes.uListSml}>
            <li>
              You may choose to market to a single or multiple demographics, but
              each of these also consists of segments within that single group.
            </li>
            <li>
              Segmenting your marketing campaign means drafting common customer
              profiles within your target groups and then designing specific ads and messages for each of them.
            </li>
            <li>
              When doing this, make sure to research audience interests and habits when it comes to cannabis.
            </li>
          </ul>
        </li>
        <li>
          <strong>Be compliant:</strong>
          <ul className={classes.uListSml}>
            <li>
              Different media have different rules when it comes to advertising
              cannabis. Account for all rules and restrictions when it comes to
              writing and posting ads and visuals.
            </li>
            <li>
              This will ensure that your campaign doesn’t get shut down and, of
              course, increase your credibility with the audience.
            </li>
          </ul>
        </li>
        <li>
          <strong>Use your SEO strategies:</strong>
          <ul className={classes.uListSml}>
            <li>
              Researching common keyword phrases that your consumer base uses when browsing for cannabis online.
            </li>
            <li>
              The key here is to leverage ‘long-tail’ keywords as these keywords are generally more specific with lower search volume but also lower
              competition. As you build your SEO presence you can leverage more
              difficult keywords down the road.
            </li>
          </ul>
        </li>
        <li>
          <strong>Offer freebies and promotions:</strong>
          <ul className={classes.uListSml}>
            <li>
              Like any other business, your offer needs to be different and more
              appealing to the competition to steal the show.
            </li>
            <li>
              Offer promotional prices, deals, curated product bundles, and holiday discounts whenever possible. This will increase customer loyalty and spread your brand outreach.
            </li>
            <li>
              Many dispensaries offer free 3.5 gram samples as a way for the
              consumer to get a taste of the product before committing.
            </li>
          </ul>
        </li>
      </ul>

      {/* <h3 className={classes.h3}>● Plan a marketing strategy:</h3>
      <p className={classes.bullet}>
        ○ It’s wise to establish which results you wish to see from your
        advertising campaign. You’ll do this by identifying your dispensary’s
        competition, consumer base, and value proposition.{" "}
      </p>
      <p className={classes.bullet}>
        ○ When it becomes clear what your unique offering is about, who you’re
        competing with, and who you’re targeting, you can start drafting
        carefully written messages that bridge the gap between you and the
        target demographic.
      </p> */}

      {/* <h3 className={classes.h3}>● Design audience segments:</h3>
      <p className={classes.bullet}>
        ○ You may choose to market to a single or multiple demographics, but
        each of these also consists of segments within that single group.
      </p>
      <p className={classes.bullet}>
        ○ Segmenting your marketing campaign means drafting common customer
        profiles within your target groups and then designing specific ads and
        messages for each of them.
      </p>
      <p className={classes.p}>
        When doing this, make sure to research audience interests and habits
        when it comes to cannabis.
      </p> */}
      {/* <h3 className={classes.h3}>● Be compliant: </h3>
      <p className={classes.bullet}>
        ○ Different media have different rules when it comes to advertising
        cannabis. Account for all rules and restrictions when it comes to
        writing and posting ads and visuals.
      </p>
      <p className={classes.bullet}>
        ○ This will ensure that your campaign doesn’t get shut down and, of
        course, increase your credibility with the audience.
      </p> */}
      {/* <h3 className={classes.h3}>● Use your SEO strategies: </h3>
      <p className={classes.bullet}>
        ○ Researching common keyword phrases that your consumer base uses when
        browsing for cannabis online.
      </p>
      <p className={classes.bullet}>
        ○ The key here is to leverage ‘long-tail’ keywords as these keywords are
        generally more specific with lower search volume but also lower
        competition. As you build your SEO presence you can leverage more
        difficult keywords down the road.
      </p> */}
      {/* <h3 className={classes.h3}>● Offer freebies and promotions: </h3>
      <p className={classes.bullet}>
        ○ Like any other business, your offer needs to be different and more
        appealing to the competition to steal the show.
      </p>
      <p className={classes.bullet}>
        ○ Offer promotional prices, deals, curated product bundles, and holiday
        discounts whenever possible. This will increase customer loyalty and
        spread your brand outreach.
      </p>
      <p className={classes.bullet}>
        ○ Many dispensaries offer free 3.5 gram samples as a way for the
        consumer to get a taste of the product before committing.
      </p> */}

<h3 className={classes.h3}>What Not to Do</h3>
      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          <strong>Test the legal boundaries:</strong>
          <ul className={classes.uListSml}>
            <li>
              Most countries have clear regulations regarding how and where to
              advertise cannabis dispensaries to avoid reaching minors or near minors. This means that there might be certain limitations to which podcasts and digital and cable media you can use to post your ads.
            </li>
            <li>
              Some states require that an overwhelming percentage of the media’s
              audience consists of adults, and some don’t. But most states have clear regulations regarding what you can and can’t do when advertising cannabis dispensaries.
            </li>
            <li>
              This doesn’t only concern viewer age. It also includes endorsements, graphics, vocabulary, and other aspects of your ad. Going against these regulations may not only get your campaign shut down. It could also result in legal problems, fines, and lawsuits.
            </li>
          </ul>
        </li>
        <li>
          <strong>Be disingenuous:</strong>
          <ul className={classes.uListSml}>
            <li>
              Don’t underestimate your audience’s intelligence. All
              target groups we previously reviewed are tech-savvy and overall science and fact-oriented.
            </li>
            <li>
              Segmenting your marketing campaign means drafting common customer
              profiles within your target groups and then designing specific ads and messages for each of them.
            </li>
            <li>
              Misleading and unsubstantiated advertising will drive
              your consumers away, and it is also illegal. There are plenty of facts and research to support your work, so there’s no reason to make inaccurate and exaggerated claims.
            </li>
          </ul>
        </li>
        <li>
          <strong>Break social media rules:</strong>
          <ul className={classes.uListSml}>
            <li>
              Avoid directly promoting and selling your products through social media.
            </li>
            <li>
              Instead, turn to educational material like posts and blog articles that don’t directly sell cannabis and related products.
              </li>
          </ul>
        </li>
      </ul>

      {/* <h3 className={classes.h3}>What Not to Do</h3>
      <h3 className={classes.h3}>● Test the legal boundaries: </h3>
      <p className={classes.bullet}>
        ○ Most countries have clear regulations regarding how and where to
        advertise cannabis dispensaries to avoid reaching minors or near minors.
        This means that there might be certain limitations to which podcasts and
        digital and cable media you can use to post your ads.
      </p>
      <p className={classes.p}>
        Some states require that an overwhelming percentage of the media’s
        audience consists of adults, and some don’t. But most states have clear
        regulations regarding what you can and can’t do when advertising
        cannabis dispensaries.
      </p>
      <p className={classes.p}>
        This doesn’t only concern viewer age. It also includes endorsements,
        graphics, vocabulary, and other aspects of your ad. Going against these
        regulations may not only get your campaign shut down. It could also
        result in legal problems, fines, and lawsuits.
      </p>
      <p className={classes.bullet}>
        ● Be disingenuous. Don’t underestimate your audience’s intelligence. All
        target groups we previously reviewed are tech-savvy and overall science
        and fact-oriented. Misleading and unsubstantiated advertising will drive
        your consumers away, and it is also illegal. There are plenty of facts
        and research to support your work, so there’s no reason to make
        inaccurate and exaggerated claims.
      </p>
      <p className={classes.bullet}>
        ● Break social media rules. Avoid directly promoting and selling your
        products through social media. Instead, turn to educational material
        like posts and blog articles that don’t directly sell cannabis and
        related products.
      </p> */}

      <h2 className={classes.h2}>Marketing Strategies for Cannabis FAQ</h2>
      <p className={classes.p}>
        Now that you know what to do and what not to do when advertising your
        cannabis dispensary, as well as which audience groups to target, let’s
        answer a couple of the most common questions regarding advertising
        cannabis dispensaries.
      </p>
      <h3 className={classes.h3}>
        How Do You Blog About Your Cannabis Dispensary?
      </h3>
      <p className={classes.p}>Publish educational content.</p>
      <p className={classes.p}>
        Whether you choose to write blogs or articles, high-quality content is
        the best way to appeal to your audience. Tackle important yet useful
        topics that highlight the benefits of using your products to solve some
        of the readers’ problems.
      </p>
      <p className={classes.p}>
        If your content is fact-based and of good quality, your readers will
        come back for more. Even better, they will share your articles on their
        social media pages, further increasing your brand’s outreach.
      </p>
      <p className={classes.p}>
        It is important to have a clear blogging strategy to reach potential
        customers and appeal to their specific problems. To do this, you should
        plan your content according to the following steps:
      </p>
      <ul className={classNames(classes.bullet, classes.uList)}>
        <li>
          List at least three main topics that interest your readers (e.g.,
          health, beauty, fitness) and the problems they touch on.
        </li>
        <li>
          List at least 10 subtopics for each of the main topics that cover more
          detailed aspects and relevant questions. This will give you a long list
          of 30 potential blog posts that can be written in a month but are
          sufficient for an entire year’s worth of publishing.
        </li>
        <li>
          Reflect on your audience segments and related keywords and terms that
          they might use when looking for solutions for their problems and use
          them to structure your blog posts.
        </li>

      </ul>

      {/* <p className={classes.bullet}>
        ● List at least three main topics that interest your readers (e.g.,
        health, beauty, fitness) and the problems they touch on.
      </p>
      <p className={classes.bullet}>
        ● List at least 10 subtopics for each of the main topics that cover more
        detailed aspects and relevant questions. This will give you a long list
        of 30 potential blog posts that can be written in a month but are
        sufficient for an entire year’s worth of publishing.
      </p>
      <p className={classes.bullet}>
        ● Reflect on your audience segments and related keywords and terms that
        they might use when looking for solutions for their problems and use
        them to structure your blog posts.
      </p> */}

      <h3 className={classes.h3}>Should You Host and Attend Podcasts?</h3>
      <p className={classes.p}>
        Yes. Podcasts are increasing in popularity, and they can be included in
        your blog posts for extra information. This way, the podcast can be
        spread across social media without breaking any rules.
      </p>
      <h3 className={classes.h3}>
        Should You Create and Publish Video Content?
      </h3>
      <p className={classes.p}>
        YouTube, LinkedIn, and your website are all great places to publish
        educational and informative videos. Remember to stay fact-based and
        promote the benefits of using cannabis that were noted by research.
      </p>
      <p className={classes.p}>
        When addressing research and studies in videos, don’t forget to include
        relevant sources in your description box. This will give your viewers a
        chance to verify what’s being said in the video, and it will also
        improve your reputation and credibility.
      </p>
      <p className={classes.p}>
        When it comes to topics to cover in your videos, you can interview
        influencers and other important people who can speak on the topic with
        authority, or you can make educational videos that answer the most
        popular questions and reveal the truth behind the most common
        misconceptions.
      </p>
      <p className={classes.p}>
        You can also film unboxing videos and tutorials and share what your work
        looks like behind the scenes.
      </p>
      <h2 className={classes.h2}>Conclusion</h2>
      <p className={classes.p}>
        The main goal of marketing your cannabis dispensary online is to gain
        organic reach and more readers. This means that your marketing and
        promotions should result in people coming to your store or website on
        their own.
      </p>
      <p className={classes.p}>
        Considering that paid advertising in this industry has its limits, and
        testing those limits might get you into legal trouble, your best bet is
        to use free promotional methods and work your way up through careful
        planning, targeting, and content quality.
      </p>
      <p className={classes.p}>
        Finally, keep your audience in mind. Always think about whether you’re
        writing for millennials, Generation X, the Baby Boomers, or the Silent
        Generation. These segments require different information and vocabulary
        and, of course, specially designed messages that align with their
        values.
      </p>
    </div>
  );
}
