import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, IconButton } from '@material-ui/core';
import {
  openStatus,
  formatPhoneNumber,
  generateSlug,
  parseIdFromLink,
  convertToDate,
  makeImageKitURL,
} from '../utils/helpers';
import Rating from '@material-ui/lab/Rating';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SimpleCard from '../components/SimpleCard';
import { Typography, Fab, Button } from '@material-ui/core';
import api from '../utils/api';
import { NAV_OPTIONS, FEATURED_TYPES, STATUS } from '../utils/constants';
import { Call, Email, Share /*, Add*/ } from '@material-ui/icons';

const getStore = async (id) => {
  return api
    .getStoreDetailedProjection(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getDeals = async (id) => {
  return api
    .getDealsForStore(id)
    .then((res) => res.filter((deals) => new Date() < convertToDate(deals.endDate)))
    .catch((err) => {
      throw err;
    });
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  container: {
    width: '100%',
    marginTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    //cursor: "pointer",
  },
  storeImage: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    marginLeft: '10px',
  },
  storeInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    fontSize: '11px',
  },
  storeTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  storeActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '10px',
  },
  storeActionButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  storeProfile: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E3E3E3',
    paddingBottom: '10px',
  },
  storeProfileBackBtn: {
    display: 'flex',
    flexDirection: 'flex-row',
    cursor: 'pointer',
    paddingRight: '1em',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default function MapsStoreProfile(props) {
  const { store, setClickedStoreData, location } = props;

  const history = useHistory();
  const [storeDetailsData, setStoreDetailsData] = useState({});
  const [deals, setDeals] = useState({});
  const [openTooltip, setOpenTooltip] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDeals, setLoadingDeals] = useState(true);

  const navOption = () => {
    if (store.storeType === 'Delivery') {
      return 'Deliveries';
    }
    if (store.storeType === 'Storefront') {
      return 'Storefronts';
    }
    if (store.storeType === 'Clinic') {
      return 'Clinics';
    }
  };

  // here we need to pass the slug in to /store/:id/:storeSlug
  const baseURL = `${window.location.protocol}//${window.location.host}`;
  const itemPath = `${NAV_OPTIONS.find((option) => option.name === navOption()).path}/${location.province}/${
    location.city
  }/${parseIdFromLink(store._links.self.href, 0, '{?projection}')}/${store.slug}`;

  const handleStoreRedirect = (event) => {
    history.push(itemPath);
  };

  useEffect(() => {
    getStore(parseIdFromLink(store._links.self.href)).then((res) => {
      if (res.published) {
        setStoreDetailsData(res);
        setLoading(false);
      }
    });

    getDeals(parseIdFromLink(store._links.self.href)).then((res) => {
      //console.log("res: ", res)
      setDeals(res);
      setLoadingDeals(false);
    });
  }, [store]);

  const styles = useStyles();

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 3000);
  };

  const storeImageURL = makeImageKitURL(store.storeImage, { width: '100' });

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'flex-row', cursor: 'pointer' }}>
        <span className={styles.storeProfileBackBtn} onClick={setClickedStoreData}>
          <IconButton style={{ marginLeft: '5px' }}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" style={{ color: 'rgb(74,74,74)', marginTop: '9px' }}>
            {'Back'}
          </Typography>
        </span>
      </div>
      <Divider variant="middle" />

      <div id={parseIdFromLink(store._links.self.href)} className={styles.container}>
        <div className={styles.storeProfile}>
          <img src={storeImageURL} alt="store-logo" className={styles.storeImage} />
          <div className={styles.storeInfo}>
            <span className={styles.storeTitle} style={{ cursor: 'pointer' }} onClick={(e) => handleStoreRedirect(e)}>
              {store.name}
            </span>
            <span>{store.storeType}</span>
            <span>{`${location.city}, ${location.province}`}</span>
            {!loading && <span>{formatPhoneNumber(storeDetailsData.phone)}</span>}
            {openStatus(store.hours) && <span style={{ color: 'green' }}>Open </span>}
            {!openStatus(store.hours) && <span style={{ color: 'red' }}>Closed </span>}
            <Rating name="read-only" value={store.avgRating} precision={0.5} size="small" readOnly />
            {`${Number(store.avgRating).toFixed(2)} (${store.numOfReviews} reviews)`}
          </div>
        </div>
        {!loading && (
          <div className={styles.storeActionButtons} id="store-actions">
            <Tooltip title="Call" bottom="true" arrow>
              <Fab
                disabled={!storeDetailsData.phone}
                // color="primary"
                size="small"
                aria-label="call"
                className={styles.storeActionButton}
                href={`tel:${storeDetailsData.phone}`}
                style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
              >
                <Call />
              </Fab>
            </Tooltip>
            <Tooltip title="Email" bottom="true" arrow>
              <Fab
                disabled={!storeDetailsData.email}
                color="primary"
                size="small"
                aria-label="email"
                className={styles.storeActionButton}
                href={`mailto:${storeDetailsData.email}`}
                style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
              >
                <Email />
              </Fab>
            </Tooltip>

            <Tooltip title={openTooltip ? 'Copied Store URL' : 'Share URL'} bottom="true" arrow>
              <CopyToClipboard className={styles.storeActionButton} text={baseURL + itemPath}>
                <Fab color="primary" size="small" aria-label="share" onClick={handleTooltipOpen}>
                  <Share />
                </Fab>
              </CopyToClipboard>
            </Tooltip>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="primary" style={{ marginTop: '20px' }} onClick={(e) => handleStoreRedirect(e)}>
            Go to Store Page
          </Button>
        </div>

        {!loadingDeals && deals.length > 0 && (
          <Typography variant="h6" style={{ color: 'rgb(74,74,74)', margin: '20px' }}>
            {'Deals'}
            <Divider variant="middle" style={{ marginTop: '10px' }} />
          </Typography>
        )}

        {!loadingDeals &&
          deals.length > 0 &&
          deals.map((deal, index) => {
            return (
              <SimpleCard
                key={index}
                item={deal}
                itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Deals').path}/${location.province}/${
                  location.city
                }/${parseIdFromLink(deal._links.self.href)}/${generateSlug(deal.name)}`}
                type={FEATURED_TYPES.DEAL}
              ></SimpleCard>
            );
          })}
      </div>
    </div>
  );
}
