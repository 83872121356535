/* Hook to find closest regions (with stores) to the user
Only updates when triggered by the user
- Uses browser geolocation to get user location
- Fetches locations with live stores

- TODO - Add error handling for when geolocation fails
- TODO - Add error handling for when user denies geolocation - explain why nothing happens (default loc would be unchanged)
- TODO - consider using location list from props (if available) instead of fetching it < API would be 'latest' and more accurate
- TODO - Add loading state for indicator when working
*/

import { useState, useEffect } from 'react';
import CITIES_BY_PROVINCE from '../utils/locations/CITIES_BY_PROVINCE';
import api from '../utils/api';
import findClosestCoordinateVincenty from './findClosestLocation';

const useClientLocation = ({ locationList }) => {
  // const [locationsWithLiveStores, setLocationsWithLiveStores] = useState([...locationList]);
  // const [userCoords, setUserCoords] = useState(null);
  const [updateUserLocation, setUpdateUserLocation] = useState(false); //Used to trigger useEffect to get user location
  const [closestLocation, setClosestLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //========================= Browers geoLocate throwbacks
    const geoSuccess = async function (userPos) {
      const crd = { lat: userPos.coords.latitude, lon: userPos.coords.longitude };
      try {
        //Get list of locs with coords
        const locList = await fetchLocationsWithLiveStores();
        //Determine Closest
        const closestLocationFullDetails = findClosestCoordinateVincenty(crd, locList);

        setClosestLocation(closestLocationFullDetails);

        // setUserCoords(crd);
        setUpdateUserLocation(false);
        return crd;
      } catch (error) {
        console.error('Error getting user location:', error);
      } finally {
        setLoading(false);
      }
    };

    function geoError(err) {
      console.error('Failed to get geoLocation', err);
      setLoading(false);
      //-TODO: Should display error and explain when locations fails
    }

    const fetchLocationsWithLiveStores = async () => {
      try {
        const rawLiveLoc = await api.getLocations(); //Locations with live stores
        const liveLocations = rawLiveLoc._embedded.locations.map((location) => {
          //Find lat/lon of location
          const city = location.city;
          const province = location.province;
          const provinceSnakeCase = province.replaceAll(' ', '_');
          const cityLocData = CITIES_BY_PROVINCE[provinceSnakeCase][city];
          const locationLink = location._links.location.href.replace('{?projection}', '');
          const newLocObj = { ...location, ...cityLocData, link: locationLink };
          return newLocObj;
        });
        // setLocationsWithLiveStores(liveLocations);
        return liveLocations;
      } catch (error) {
        console.error('Error fetching locations with live stores:', error);
      }
    };

    const getAndSetUserLocation = async () => {
      setLoading(true);
      await navigator.geolocation.getCurrentPosition(geoSuccess, geoError); //geoSuccess handles numerous actions
    };
    updateUserLocation === true && getAndSetUserLocation();
  }, [updateUserLocation]);

  return { closestLocation, updateClostestLocation: () => setUpdateUserLocation(true), loadingNearestLoc: loading };
};

export default useClientLocation;
