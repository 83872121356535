import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Checkbox, useMediaQuery } from "@material-ui/core";
import ReviewTermsContent from "./ReviewTermsContent";

const useStyles = makeStyles(theme => ({
  root: {},
  openButton: { textTransform: "none" },
  termsConditionsActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function ScrollDialog(props) {
  const { termsAccepted, setTermsAccepted } = props;

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  //const [bottomReached, setBottomReached] = useState(false);

  const classes = useStyles();

  const handleClose = () => {
    // setBottomReached(false);
    setOpen(false);
  };

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
    if (bottom) {
     // setBottomReached(true);
    }
  };

  return (
    <div>
      <div className={classes.termsConditionsActions}>
        <Checkbox
          checked={termsAccepted}
          value="termAccepted"
          color="primary"
          disabled={!termsAccepted}
          onClick={() => setTermsAccepted(false)}
        />
        <Button
          onClick={() => {
            setScroll("paper");
            setOpen(true);
          }}
          className={classes.openButton}
        >
          {useMediaQuery("(max-width:768px)") 
            ? <span style={{"font-size":"9pt"}}> Read and Accept Our Review Policys</span>
            : "Read and Accept Our Review Policy"

        }
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        onScroll={e => handleScroll(e)}
        aria-labelledby="terms-and-conditions"
        maxWidth="md"
      >
        <DialogTitle id="terms-and-conditions-title">
          Read and Accept our Reviews Policy
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* <DialogContentText> */}
            <ReviewTermsContent></ReviewTermsContent>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              setTermsAccepted(true);
            }}
            color="primary"
            //disabled={!bottomReached}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
