import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function SEOList({ listContent, gridColumns }) {
  const listStyle = {};
  if (gridColumns && !isNaN(gridColumns)) {
    listStyle['display'] = 'grid';
    listStyle['gridTemplateColumns'] = 'auto';
    for (let i = 0; i < gridColumns - 1; i++) {
      listStyle['gridTemplateColumns'] += ' auto';
    }
  }
  return (
    <List style={listStyle}>
      {listContent &&
        listContent.map((text) => {
          return (
            <ListItem>
              <ListItemIcon style={{ width: '1.5rem', minWidth: '1.5rem', marginLeft: '1rem' }}>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          );
        })}
    </List>
  );
}
