import { Fragment } from 'react';
import React from 'react';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
export default function MapFilter({
  handleFilterSettings,
  filterSettings,
  showFilter,
  handleTabChange,
  value,
  displayFilterSettings,
  mobile,
}) {
  const useStyles = makeStyles((theme) => ({
    tabRoot: {
      // marginBottom: mobile ?  "0px" :"30px",
      marginBottom: '0px',
      maxHeight: '50%',
      paddingTop: '2%',
      flexGrow: 1,
      //   width: '50%',
    },
    indicator: {
      position: 'absolute',
      height: 0,
      top: 0,
      transition: 'none',
    },
    storeTab: {
      backgroundColor: '#FFFF',
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      borderRadius: '1rem',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      minWidth: 5,
      opacity: 1,
    },
    storeTabSelected: {
      backgroundColor: '#ebebeb',
      borderRadius: '1rem',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      minWidth: 0,
      opacity: 1,
    },

    checkboxContainer: {
      width: '100%',
      flexDirection: 'row',
      paddingTop: theme.spacing(5),
      paddingHorizontal: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomWidth: theme.spacing(1),
      borderBottomColor: '#f0f0f0',
    },
    filterButton: {
      flexDirection: 'row',
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      alignItems: 'center',
      marginTop: 3,
      marginHorizontal: 3,
      paddingHorizontal: theme.spacing(5),
      paddingVertical: theme.spacing(5),
      justifyContent: 'center',
    },
    label: {},
    buttonContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: 'lightgreen',
    },
    tabIcon: { height: mobile ? '30px' : '50px' },
  }));
  const classes = useStyles();
  return (
    <Fragment>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="tabs"
        variant="fullWidth"
        centered
        classes={{ indicator: classes.indicator, root: classes.tabRoot }}
      >
        <Tab
          indicatorColor="primary"
          value={'stores'}
          icon={
            <img
              alt="Toggle Stores"
              src={require('../assets/icons-for-map-feature/StoreFrontsFiltericon.svg')}
              className={classes.tabIcon}
              //   style={{ height: mobile ? '30px' : '70px', marginBottom: '0' }}
              //aria-controls={`tabpanel-menu`}
            />
          }
          className={displayFilterSettings.stores ? classes.storeTabSelected : classes.storeTab}
          onClick={() => handleFilterSettings('stores')}
          label={mobile ? '' : 'Store Fronts'}
        ></Tab>
        <Tab
          value={'delivery'}
          icon={
            <img
              alt="Toggle Delivery"
              src={require('../assets/icons-for-map-feature/DeliveriesFilterIcon.svg')}
              className={classes.tabIcon}
              //   style={{ height: mobile ? '30px' : '70px' }}
              //aria-controls={`tabpanel-menu`}
            />
          }
          className={displayFilterSettings.delivery ? classes.storeTabSelected : classes.storeTab}
          onClick={() => handleFilterSettings('delivery')}
          label={mobile ? '' : 'delivery'}
        ></Tab>

        <Tab
          value={'clinic'}
          icon={
            <img
              alt="Toggle Clinics"
              src={require('../assets/icons-for-map-feature/ClinicsFilterIcon.svg')}
              className={classes.tabIcon}
              //   style={{ height: mobile ? '30px' : '70px' }}
              //aria-controls={`tabpanel-menu`}
            />
          }
          className={displayFilterSettings.clinic ? classes.storeTabSelected : classes.storeTab}
          onClick={() => handleFilterSettings('clinic')}
          label={mobile ? '' : 'Clinics'}
        ></Tab>
        <Tab
          value={'open'}
          icon={
            <img
              alt="Toggle Open Now"
              src={require('../assets/icons-for-map-feature/OpenNowicon.svg')}
              className={classes.tabIcon}
              //   style={{ height: mobile ? '30px' : '70px' }}
            />
          }
          //aria-controls={`tabpanel-menu`}
          className={displayFilterSettings.open ? classes.storeTabSelected : classes.storeTab}
          onClick={() => handleFilterSettings('open')}
          label={mobile ? '' : 'Open Now'}
        ></Tab>

        <Tab
          value={'mail'}
          icon={
            <img
              alt="Toggle Mail Out"
              src={require('../assets/icons-for-map-feature/MailOutFiltericon.svg')}
              className={classes.tabIcon}
              //   style={{ height: mobile ? '30px' : '70px' }}
            />
          }
          // aria-controls={`tabpanel-menu`}
          className={displayFilterSettings.mailout ? classes.storeTabSelected : classes.storeTab}
          onClick={() => handleFilterSettings('mailout')}
          label={mobile ? '' : 'Mail Outs'}
        ></Tab>
      </Tabs>
    </Fragment>
  );
}
