import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TermsConditionsContent from '../components/TermsConditionsContent';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(40),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
  },
}));

function TermsConditonsView(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>S7DAW | Terms and Conditions</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="S7DAW Terms and Conditions" />
      </Helmet>
      <div className={classes.innerContainer}>
        <TermsConditionsContent></TermsConditionsContent>
      </div>
    </div>
  );
}

export default TermsConditonsView;
