import React from "react";
import { Link } from "react-router-dom";
import { postStyle } from "./postStyle";

const useStyles = postStyle;

export function TopCanadianCannabisBrands() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.h1}>
        Top 8 Canadian Cannabis Brands to Follow in 2020
      </h2>
      <p className={classes.p}>
        Although medical marijuana has been legal in Canada since 2001, the
        recreational use of cannabis was not permitted by law until 17 years
        later in October of 2018. After this progressive decision was made,
        businesses centered around the production and distribution of weed
        sprung up and flourished throughout Canada. As with any industry, weed
        brands must maintain a standard of product quality and improve on their
        customer service to not only rise above the teeming competition, but
        also win over new customers. Read on to find out what the top 8 Canadian
        cannabis brands have to offer, and what puts them ahead of the pack.
      </p>
      <h2 className={classes.h2}>Sundial</h2>
      <p className={classes.p}>
        When you take in the clean design of the Sundial website and their
        simple logo, you’ll quickly be enveloped in serenity. This sense of calm
        spreads even to the names of their products with options like Zen Berry
        and Twilight. Sundial is intentionally branded in this way to appeal to
        their specific demographic which is the health and wellness market. A
        portion of the Sundial webpage encourages weed users to keep a journal
        and document their experience with weed. By using this approach and
        following their community on social media, you’ll be able to customize
        your cannabis experience and get the most joy from your buds. Sundial is
        based in Alberta, and its cannabis strains are known to contain high THC
        levels. While products from this brand may not provide the same
        “trippiness” as other strains, they can be trusted to produce that happy
        and calming effect that is typical of Sundial.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://www.sundialcannabis.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sundial
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Top Leaf</h2>
      <p className={classes.p}>
        This brand was actually launched by Sundial, who still maintains
        ownership of it. However, Top Leaf bears no resemblance to its parent
        company in both its aesthetics and products. Sundial uses blue and white
        to emphasize peace and calmness while targeting the wellness market. In
        contrast, Top Leaf is all about luxury cannabis, and its matte black and
        brown color scheme achieves an exquisite, high-end feeling. As the name
        of the company suggests, it is undeniably one of the top cannabis brands
        in Canada, as well as in other parts of the world. Its customers are
        those who do not mind spending that extra buck to get the very best and
        most genetically exclusive cultivars. Top Leaf boasts that its cannabis
        plants are grown by master weed enthusiasts. As stated in their
        Instagram bio, “Anyone can grow weed, but…we believe in
        #TheCraftOfCannabis.”
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://www.topleaf.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Top Leaf
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Cove</h2>
      <p className={classes.p}>
        Cove is famous for being a remarkably diverse weed dispensary in Canada.
        Whatever your pleasure is, you will most likely find the bud that meets
        your needs. You can get cannabis oil, dried flowers, vape extracts, and
        more from Cove. This brand is also home to high quality strains of
        cannabis. Cove's Rest pre-rolls have a THC level of 16.9% and deliver a
        soothing experience as advertised. They can be used to relieve pain or
        to induce feelings of calmness and happiness. If you would much rather
        feel upbeat and sociable, then you should try out their Revive line. It
        comes in oil, vape, or flower and has 22% THC and 1% CBD.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://covecannabis.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cove
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Doja</h2>
      <p className={classes.p}>
        Hand trimming cannabis plants ensures that both the shape of the flower
        and the quality of the weed are maintained. Doja emphasizes the fact
        that all their buds are hand trimmed, therefore producing only the
        highest quality cannabis. If you’re an investor, you might be interested
        in the philanthropic activities of different cannabis brands. Doja is
        very public about its intentions to secure pardons for convicts who are
        serving time for minor cannabis offences. In March of 2019, they
        petitioned Ottawa to expunge past records of minor drug possession and
        offer former convicts a clean slate.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://www.doja.life/verify-age/?redirect="
            target="_blank"
            rel="noopener noreferrer"
          >
            Doja
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Burb</h2>
      <p className={classes.p}>
        In pop culture, the use of recreational weed is often associated with
        Rastafarians. As such, when you think of pot, you might think of a man
        with dreadlocks sporting a crocheted hat with green, red, yellow, and
        black colors. However, Burb is working hard to change this image and
        make weed more relatable. With recreational cannabis now legal in
        Canada, demand for the plant is coming from a more diverse demographic.
        Suburban parents and young academics alike need cannabis for a variety
        of reasons, and Burb is making sure that weed is no longer solely
        associated with any of its former stereotypes. Besides marijuana, they
        also sell clothes and other accessories that further their message. As
        Burb CEO John Kaye put it, “We want to sell products...that speak to a
        new cannabis culture [that is] inspired by our own environment.”
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://shopburb.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Burb
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>House Plant</h2>
      <p className={classes.p}>
        You probably enjoy movies starring Seth Rogan, but did you know that you
        can also enjoy weed from a company that he co-founded? Since the
        SuperBad star announced in 2019 that he would be launching his cannabis
        company, the three strains offered by House Plant have been on
        everyone's lips (no pun intended). Houseplant Sativa, Houseplant Indica,
        and Houseplant Hybrid are the three strains produced by this company,
        which is owned by both Evan Goldberg and Seth Rogan. Among their range
        of products are pre-rolled joints and soft gels.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://fireandflower.com/houseplant"
            target="_blank"
            rel="noopener noreferrer"
          >
            House Plant
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Tantalus Labs</h2>
      <p className={classes.p}>
        The growth of cannabis has historically been cultivated indoors, aided
        by artificial light. Over time, however, the electricity costs start to
        add up. Weed growers have been challenged to find a cost-effective yet
        uncompromising alternative. For the innovative minds at Tantalus Labs,
        the alternative was to return to nature's own energy source for plants:
        good old sunlight. They designed a sunlab with a transparent roof to
        utilize sunlight for photosynthesis. The facility is also able to retain
        and triple filter enough rain water to be used for irrigation all year
        round. This greenhouse edifice was the first of its kind in North
        America.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://tantaluslabs.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tantalus Labs
          </a>
        </strong>
      </div>
      <h2 className={classes.h2}>Tweed</h2>
      <p className={classes.p}>
        One of the most famous Canadian brands, Tweed offers everything from
        edibles to vapes. They also produce a large variety of cannabis strains.
        Founded 4 years before the recreational use of pot became legal in
        Canada, Tweed holds the records for accomplishing many firsts in the
        marijuana industry. As a North American cannabis company, they beat
        similar brands in establishing an online marketplace, exporting weed,
        and becoming publicly listed. Tweed Marijuana Inc. and Bedrocan Canada
        Inc. merged in 2013 to form the Canopy Growth Corporation, which became
        the parent company for Tweed and other brands. Today, Tweed has earned
        its spot as a luxury cannabis brand, and as one of the largest cannabis
        companies in the world.
      </p>
      <div className={classes.link}>
        <strong>
          View Website Here:{" "}
          <a
            href="https://www.tweed.com/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tweed
          </a>
        </strong>
      </div>
      <h3 className={classes.h3}>Final Words</h3>
      <p className={classes.p}>
        Now that the Canadian government has legalized the recreational use of
        cannabis, residents can enjoy their favorite brands of weed with little
        worry of breaking the law. If you would like to view our list of top
        rated dispensaries, brands and delivery companies near you{" "}
        <Link to="/">visit our homepage here.</Link>
      </p>
    </div>
  );
}
