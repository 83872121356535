export default {
  Alberton: { lat: 46.8137, lon: -64.0704 },
  Belfast: { lat: 46.2018, lon: -62.9721 },
  Charlottetown: { lat: 46.2382, lon: -63.1311 },
  Cornwall: { lat: 46.2224, lon: -63.2072 },
  Kensington: { lat: 46.4368, lon: -63.6284 },
  Montague: { lat: 46.1668, lon: -62.6478 },
  Souris: { lat: 46.3598, lon: -62.2574 },
  Summerside: { lat: 46.3938, lon: -63.7901 },
};
