import React, { useContext, Fragment } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import PreviewContext from "../PreviewContext";
import { SCREEN_STATES } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
  },
}));

function LinkButton(props) {
  const classes = useStyles();

  const preview = useContext(PreviewContext);

  const { label, to, disabled, bold } = props;

  const fontWeight = bold ? "bold" : "normal";

  return (
    <Fragment>
      {!preview.state.screen && (
        <Button
          className={classes.root}
          component={Link}
          to={to}
          style={{ color: "#000000", textDecoration: "none", fontWeight }}
          disabled={disabled}
        >
          {label}
        </Button>
      )}
      {preview.state.screen && (
        <Button
          className={classes.root}
          onClick={() => {
            preview.state.brandId
              ? preview.changeScreen(SCREEN_STATES.BRAND_DETAILS, to.query)
              : preview.changeScreen(SCREEN_STATES.STORE_DETAILS, to.query);
          }}
          disabled={disabled}
          style={{ color: "#000000", textDecoration: "none", fontWeight }}
        >
          {label}
        </Button>
      )}
    </Fragment>
  );
}

export default LinkButton;
