import React from 'react';
import { postStyle } from './postStyle';
// import classNames from 'classnames';

const useStyles = postStyle;

export function WeedDeliveryInSelectedCities(locBlog) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.h2}>{locBlog.intro.title && locBlog.intro.title}</div>
      <div className={classes.p}>{locBlog.intro.content && locBlog.intro.content}</div>

      <div className={classes.h2}>{locBlog.deliveries.title && locBlog.deliveries.title}</div>
      <div className={classes.p}>{locBlog.deliveries.content && locBlog.deliveries.content}</div>

      <div className={classes.h2}>{locBlog.deliveryTime.title && locBlog.deliveryTime.title}</div>
      <div className={classes.p}>{locBlog.deliveryTime.content && locBlog.deliveryTime.content}</div>

      <div className={classes.h2}>{locBlog.onlineOrders.title && locBlog.onlineOrders.title}</div>
      <div className={classes.p}>{locBlog.onlineOrders.content && locBlog.onlineOrders.content}</div>

      <div className={classes.h2}>{locBlog.storeFronts.title && locBlog.storeFronts.title}</div>
      <div className={classes.p}>{locBlog.storeFronts.content && locBlog.storeFronts.content}</div>

      <div className={classes.h2}>{locBlog.dispensaries.title && locBlog.dispensaries.title}</div>
      <div className={classes.p}>{locBlog.dispensaries.content && locBlog.dispensaries.content}</div>

      <div className={classes.h2}>{locBlog.numbers.title && locBlog.numbers.title}</div>
      <div className={classes.p}>{locBlog.numbers.content && locBlog.numbers.content}</div>

      <div className={classes.h2}>{locBlog.products.title && locBlog.products.title}</div>
      <div className={classes.p}>{locBlog.products.content && locBlog.products.content}</div>

      <div className={classes.h2}>{locBlog.benefits.title && locBlog.benefits.title}</div>
      <div className={classes.p}>{locBlog.benefits.content && locBlog.benefits.content}</div>

      <div className={classes.h2}>{locBlog.aboutCannabis.title && locBlog.aboutCannabis.title}</div>
      <div className={classes.p}>{locBlog.aboutCannabis.content && locBlog.aboutCannabis.content}</div>

      <div className={classes.h2}>{locBlog.history.title && locBlog.history.title}</div>
      <div className={classes.p}>{locBlog.history.content && locBlog.history.content}</div>

      <div className={classes.h2}>{locBlog.rulesAndReg.title && locBlog.rulesAndReg.title}</div>
      <div className={classes.p}>{locBlog.rulesAndReg.content && locBlog.rulesAndReg.content}</div>

      <div className={classes.h2}>{locBlog.additionalReg.title && locBlog.additionalReg.title}</div>
      <div className={classes.p}>{locBlog.additionalReg.content && locBlog.additionalReg.content}</div>

      <div className={classes.h2}>{locBlog.whereToUse.title && locBlog.whereToUse.title}</div>
      <div className={classes.p}>{locBlog.whereToUse.content && locBlog.whereToUse.content}</div>

      <div className={classes.h2}>{locBlog.growing.title && locBlog.growing.title}</div>
      <div className={classes.p}>{locBlog.growing.content && locBlog.growing.content}</div>

      <div className={classes.h2}>{locBlog.travelingWith.title && locBlog.travelingWith.title}</div>
      <div className={classes.p}>{locBlog.travelingWith.content && locBlog.travelingWith.content}</div>
    </div>
  );
}
