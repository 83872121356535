import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { Typography, IconButton, useMediaQuery } from '@material-ui/core';
import { ABOUT_CONTENT, NAV_OPTIONS } from '../utils/constants';
import EmailIcon from '@material-ui/icons/Email';
import { useStateValue } from '../state';

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  footerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.screen.maxWidth,
    padding: theme.spacing(5, 1, 5, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  mainFooter: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  bottomFooterContainer: {
    height: 60,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  bottomFooter: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    color: theme.palette.common.white,
  },
  footerLogo: {
    height: 300,
    [theme.breakpoints.down('md')]: {
      height: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  footerCopyright: {
    fontWeight: '700',
    fontSize: '9pt',
  },
  aboutSection: {
    flexGrow: 4,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexGrow: 1,
      width: '100%',
      flexDirection: 'column',
      // justifyContent
    },
  },
  navigationSection: {
    minWidth: 250,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  logo: {
    alignSelf: 'center',
    marginRight: theme.spacing(1),
  },
  aboutContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: theme.screen.aboutContent.width,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  navigationLinks: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  footerTitle: {
    fontSize: '18pt',
    fontWeight: '900',
    color: theme.palette.secondary.main,
  },
  keepInTouch: {
    fontSize: '18pt',
    fontWeight: '900',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  keepInTouchMobile: {
    fontSize: '18pt',
    fontWeight: '900',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  footerText: {
    fontSize: '14pt',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  navigationLink: {
    fontSize: '14pt',
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  aboutAndMedia: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '70%',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
  },
  mediaIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  appBadgeContainer: {
    display: 'flex',
    position: 'absolute',
    top: '63%',
    left: '45%',
    gap: '8px',
    //"transform": "translate(-30%, -30%)",
    //transform: "translate(-50%, -50%)",
    //"background-color": "#555",
    //justifyContent: "space-between",
    color: 'white',
    fontSize: '16px',
    padding: '12px 24px',
    border: 'none',
    // borderRadius: '5px',
    [theme.breakpoints.down('md')]: {
      //display: "none",
      top: '50%',
      left: '60%',
      display: 'flex',
      flexDirection: 'column',
    },

    [theme.breakpoints.down('sm')]: {
      //display: "none",
      top: '50%',
      left: '52%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  appButton: {
    borderRadius: '5px',
    padding: 0,
  },
  badge: {
    borderRadius: '5px',
    height: '30px',
    padding: 0,
    cursor: 'pointer',
    width: '100%',
    objectFit: 'cover',

    [theme.breakpoints.up('xl')]: {
      height: '60px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '50px',
      width: '95px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      height: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      height: '30px',
    },
  },
}));

function Footer(props) {
  const classes = useStyles();
  const [{ location }] = useStateValue();
  const { navigate } = props;
  const mobile = useMediaQuery('(max-width:1024px)');

  return (
    <div className={classes.footerRoot}>
      <div style={{ position: 'relative' }}>
        <img
          src={mobile ? require(`../assets/banners/appBannerMobile.webp`) : require(`../assets/banners/appBanner.webp`)}
          alt={`sdaw-footer-logo`}
          style={{ height: 'auto', width: '100%' }}
          //className={classes.footerLogo}
        />
        <div className={classes.appBadgeContainer}>
          <IconButton
            href="https://apps.apple.com/ca/app/s7daw/id1533319065?itsct=apps_box&itscg=30200"
            target="_blank"
            className={classes.appButton}
          >
            <img
              src={require(`../assets/badges/iosStoreBadge.svg`)}
              alt={`iosstore-main-logo`}
              className={classes.badge}
            />
          </IconButton>
          <IconButton
            href="https://play.google.com/store/apps/details?id=com.s7daw.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            className={classes.appButton}
          >
            <img
              src={require(`../assets/badges/playStoreBadge.svg`)}
              alt={`playstore-main-logo`}
              className={classes.badge}
            />
          </IconButton>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <div className={classes.mainFooter}>
          <div className={classes.aboutSection}>
            <img
              src={require(`../assets/logos/logoFooter.svg`)}
              alt={`sdaw-footer-logo`}
              className={classes.footerLogo}
            />
            <div className={classes.aboutAndMedia}>
              <Typography className={classes.footerText}>{ABOUT_CONTENT}</Typography>
              <Typography className={classes.keepInTouchMobile}>LET'S KEEP IN TOUCH.</Typography>
              <div className={classes.socialMedia}>
                <Typography className={classes.keepInTouch}>LET'S KEEP IN TOUCH.</Typography>
                <IconButton aria-label="Email S7DAW" href="mailto: info@s7daw.com" className={classes.mediaIcon}>
                  <EmailIcon color="secondary" fontSize="large"></EmailIcon>
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/s7daw.canada/"
                  target="_blank"
                  className={classes.mediaIcon}
                >
                  <img
                    src={require(`../assets/logos/iconInstagramFooter.svg`)}
                    alt={`instagram-main-logo`}
                    height="30"
                  />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/S7DAWCanada-105072091624959"
                  target="_blank"
                  className={classes.mediaIcon}
                >
                  <img src={require(`../assets/logos/iconFacebookFooter.svg`)} alt={`facebook-main-logo`} height="30" />
                </IconButton>
                {/* <IconButton
                  href="https://x.com/daw_s7"
                  target="_blank"
                  className={classes.mediaIcon}
                >
                  <img
                    src={require(`../assets/logos/iconTwitterFooter.svg`)}
                    alt={`twitter-main-logo`}
                    height="30"
                  />
                </IconButton> */}
              </div>
            </div>
          </div>
          <div className={classes.navigationSection}>
            <Typography variant="h2" className={classes.footerTitle}>
              SITE NAVIGATION.
            </Typography>
            <Typography variant="body1" className={classes.navigationLinks}>
              {[
                ...NAV_OPTIONS,
                { path: '/blog', name: '*NEW* Blog' },
                { path: '/terms-and-conditions', name: 'Terms and Conditions' },
                { path: '/privacy-policy', name: 'Privacy Policy' },
                {
                  path: 'mailto: info@s7daw.com',
                  name: 'Email Us',
                  type: 'email',
                },
              ].map((option, index) => {
                let pathWithLocation = null;
                if (['/deliveries', '/stores', '/deals'].some((category) => option.path === category)) {
                  //Add location to the above paths
                  pathWithLocation = `${
                    option.path
                  }/${location?.province.toLowerCase()}/${location?.city.toLowerCase()}`;
                }
                if (option.type === 'email') {
                  return (
                    <a
                      href={option.path}
                      key={option.name}
                      className={classes.navigationLink}
                      style={{ color: 'inherit', textDecoration: 'inherit' }}
                    >
                      {option.name}
                    </a>
                  );
                }
                return (
                  <Link
                    to={pathWithLocation || option.path}
                    key={option.name}
                    onClick={() => navigate(index)}
                    className={classes.navigationLink}
                    style={{
                      color: 'inherit',
                      textDecoration: 'inherit',
                      fontWeight: option.path === '/blog' ? 'bold' : 'normal',
                    }}
                  >
                    {option.name}
                  </Link>
                );
              })}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bottomFooterContainer}>
        <div className={classes.bottomFooter}>
          <Typography className={classes.footerCopyright}>Copyright © 2020 S7DAW. All rights reserved.</Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
