import { lazy } from 'react';

export function lazyLoad(path, namedExport) {
  //Named export is optional, if not provided, default export will be used
  return lazy(() => {
    const promise = import(`${path}`);

    return namedExport == null ? promise : promise.then((module) => ({ default: module[namedExport] }));
  });
}
