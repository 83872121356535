import React, { useEffect, useState, Fragment } from 'react';
import useBookingUserData from '../components/Bookings/hooks/useBookingUserData';
// import moment from 'moment';
// import { makeStyles } from '@material-ui/styles';
import FeaturedList from '../components/FeaturedList';
import { FEATURED_TYPES, NAV_OPTIONS, STATUS, STORE_TYPES } from '../utils/constants';
import { Typography } from '@material-ui/core';
import SimpleCard from '../components/SimpleCard';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import api from '../utils/api';
import { parseIdFromLink, allStoresRedirect, filterFeaturedList } from '../utils/helpers';
import { useStateValue } from '../state';
// import Carousel from '../components/Carousel';
import CarouselManaged from '../components/CarouselManaged';
import { Helmet } from 'react-helmet';

import viewTheme from './styles/clinicsViewTheme';

const useStyles = viewTheme;

const getClinics = async (type, id) => {
  return api
    .getStores(type, null, true)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getAllClinicRanks = async (id) => {
  return api
    .getAllStoreRanksByLocation(id)
    .then((res) => res.data._embedded.allStoreRanks)
    .catch((e) => {
      throw STATUS.ERROR;
    });
};

const fetchHref = async (href, dataKey) => {
  return api
    .fetchHref(href, dataKey)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw STATUS.ERROR;
    });
};

function ClinicsView(props) {
  const classes = useStyles();

  //========Bookings
  const { fetchAndBuildList, allowBookings } = useBookingUserData();

  const [allStores, setAllStores] = useState([]);
  const [allRanks, setAllRanks] = useState([]);
  const [featuredStores, setFeaturedStores] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [topRatedStores, setTopRatedStores] = useState([]);

  const [loadingStores, setLoadingStores] = useState(true);
  const [loadingFeatured, setLoadingFeatured] = useState(true);

  const [{ location }] = useStateValue();
  const currCity = decodeURI(location.city);
  const currProvince = location.province;

  const currCityID = location.link;
  const currLocationID = parseIdFromLink(location.link);

  useEffect(() => {
    let mounted = true;
    const isBookingsPermitted = allowBookings(currCityID);
    setLoadingStores(true);
    setLoadingFeatured(true);

    Promise.all([getClinics(STORE_TYPES[2], location.link), getAllClinicRanks(currLocationID)])
      .then(async (res) => {
        const [allStoresRes, allStoreRanksRes] = res;

        const recommendedLinks = allStoresRes.map((x) => x._links.recommendedRanks.href);
        let allRecommendedRanks = await Promise.all(recommendedLinks.map((req) => fetchHref(req, 'recommendedRanks')));
        allRecommendedRanks = allRecommendedRanks.flat();

        const featLinks = allStoresRes.map((x) => x._links.featuredStoreRanks.href);
        let allPlatinumRanks = await Promise.all(featLinks.map((req) => fetchHref(req, 'featuredStoreRanks')));
        allPlatinumRanks = allPlatinumRanks.flat();

        const unOrderedStores = allStoresRes?.concat().sort((first, second) => first.name.localeCompare(second.name));
        const topStores = allStoresRes
          ?.concat()
          .filter((store) => store.numOfReviews > 0)
          .sort((first, second) =>
            second.avgRating - first.avgRating !== 0
              ? second.avgRating - first.avgRating
              : second.numOfReviews - first.numOfReviews !== 0
              ? second.numOfReviews - first.numOfReviews
              : first.name.localeCompare(second.name)
          )
          .slice(0, 15);
        const ranksForAllStores = allStoreRanksRes
          ?.filter((x) => x.store.storeType === 'Clinic')
          .sort((store1, store2) =>
            store1.rank - store2.rank !== 0
              ? store1.rank - store2.rank
              : store1.store.name.localeCompare(store2.store.name)
          )
          .map((store) => store.store);

        //===========================
        const featuredRanks = filterFeaturedList('store', allPlatinumRanks, unOrderedStores).map((store) => {
          return store.store;
        });

        const recommendedRanks = filterFeaturedList('store', allRecommendedRanks, unOrderedStores)
          .filter((store) => store.store.storeType === 'Clinic')
          .map((store) => {
            return store.store;
          });
        if (mounted) {
          setAllStores(unOrderedStores);
          setAllRanks(ranksForAllStores);
          setTopRatedStores(topStores);
          setLoadingStores(false);
        }
        if (mounted && !isBookingsPermitted) {
          setFeaturedStores(featuredRanks);
          setRecommended(recommendedRanks);
          setLoadingFeatured(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    if (isBookingsPermitted) {
      fetchAndBuildList(new Date(), 0, currCity, currProvince, 'Clinic').then((lists) => {
        const { popularStores, platinumStores } = lists;
        if (mounted) {
          setFeaturedStores(platinumStores);
          setRecommended(popularStores);
          setLoadingFeatured(false);
        }
      });
    }
    return () => {
      mounted = false;
    };
    //eslint-disable-next-line
  }, []);

  const SkeletonCards = (
    <Fragment>
      <SkeletonSimpleCard></SkeletonSimpleCard>
      <SkeletonSimpleCard></SkeletonSimpleCard>
      <SkeletonSimpleCard></SkeletonSimpleCard>
    </Fragment>
  );

  const PlatinumList = (
    <FeaturedList
      loading={loadingFeatured}
      index={1}
      listData={featuredStores}
      title="Platinum Clinics"
      image="clinics"
      type={FEATURED_TYPES.CLINIC}
      viewAllPath="NONE"
      handleNavBarChange={() => {}}
    ></FeaturedList>
  );
  const PopularList = (
    <FeaturedList
      loading={loadingFeatured}
      index={3}
      listData={recommended}
      title="Most Popular Clinics"
      image="clinics"
      type={FEATURED_TYPES.CLINIC}
      viewAllPath="NONE"
      handleNavBarChange={() => {}}
    ></FeaturedList>
  );
  const TopRatedList = (
    <FeaturedList
      loading={loadingStores}
      index={2}
      listData={topRatedStores}
      title="Top Rated"
      image="clinics"
      type={FEATURED_TYPES.CLINIC}
      viewAllPath="NONE"
      handleNavBarChange={() => {}}
    ></FeaturedList>
  );

  const bannerData = [
    {
      name: 'Medical Cannabis Clinics Near You',
      header: `Medical Cannabis Clinics`.toUpperCase(),
      variant: 'h1',
      type: 'clinics',
      // caption: '',
      image: 'secondaryClinic.webp',
    },
    {
      name: 'Medical Cannabis Clinics Near You',
      header: `Medical Cannabis Clinics`.toUpperCase(),
      variant: 'h1',
      type: 'clinics',
      // caption: '',
      image: 'secondaryClinic.webp',
    },
  ];

  return (
    <div className={classes.root}>
      <Helmet>
        {/* [] TODO: Update title, meta and H1 for clinics */}
        <title>{`Medical Cannabis Clinics Near You | S7DAW`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={`Looking for medical cannabis? Find clinics in your area that assess you for medical weed and provide you with medical cannabis prescriptions.`}
        />
      </Helmet>
      <CarouselManaged id="topBanners" banners={bannerData} type={'Clinics'} override="MEDICAL CANNABIS CLINICS" />
      <div className={classes.containerWrapper}>
        <div className={classes.contentContainer}>
          {PlatinumList}
          {PopularList}
          {TopRatedList}
          {/* [] TODO build all ranks and allstores in the useEffect, move below into variable like others  */}
          <Typography variant="h2" className={classes.gridTitle}>
            ALL CLINICS
          </Typography>
          {!loadingStores && allStores.length === 0 && (
            <Typography className={classes.emptyList}>
              At this time we are working to bring on partnerships with your local retailers to service this area.
              Please check back later.
            </Typography>
          )}
          <div className={classes.storeGrid}>
            {!loadingStores &&
              allRanks.length > 0 &&
              allRanks.map((store, index) => {
                return (
                  <SimpleCard
                    key={index}
                    item={store}
                    itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Clinics').path}/${allStoresRedirect(
                      location,
                      store
                    )}`}
                    type={FEATURED_TYPES.CLINIC}
                  ></SimpleCard>
                );
              })}
            {!loadingStores &&
              allStores.length > 0 &&
              allStores
                .filter((store) => {
                  return !allRanks.some(
                    (rankedStore) =>
                      parseIdFromLink(store._links.self.href.replace('{?projection}', '')) ===
                      parseIdFromLink(rankedStore._links.self.href.replace('{?projection}', ''))
                  );
                })
                .map((store, index) => {
                  return (
                    <SimpleCard
                      key={index}
                      item={store}
                      itemPath={`${NAV_OPTIONS.find((option) => option.name === 'Clinics').path}/${parseIdFromLink(
                        store._links.self.href
                      )}/${store.slug}`}
                      type={FEATURED_TYPES.CLINIC}
                    ></SimpleCard>
                  );
                })}
            {loadingStores && SkeletonCards}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicsView;
