export default {
  Bouctouche: { lat: 46.4683, lon: -64.7269 },
  Dieppe: { lat: 46.0994, lon: -64.8154 },
  Fredericton: { lat: 45.9636, lon: -66.6431 },
  Hampton: { lat: 45.5255, lon: -65.8329 },
  'Lutes Mountain': { lat: 46.0876, lon: -64.9289 },
  Miramichi: { lat: 47.0199, lon: -65.464 },
  Moncton: { lat: 46.1155, lon: -64.8019 },
  Nackawic: { lat: 45.9889, lon: -67.2396 },
  Oromocto: { lat: 45.8471, lon: -66.4783 },
  Richibucto: { lat: 46.6769, lon: -64.8704 },
  Sackville: { lat: 45.9002, lon: -64.371 },
  'Saint Andrews': { lat: 45.0733, lon: -67.0526 },
  'Saint John': { lat: 45.2807, lon: -66.0761 },
  Salisbury: { lat: 46.0386, lon: -65.0991 },
  Shediac: { lat: 46.2211, lon: -64.5413 },
  Sussex: { lat: 45.7202, lon: -65.5139 },
  Edmundston: { lat: 47.3663, lon: -68.3128 },
  Campbellton: { lat: 48.0071, lon: -66.6724 },
  Bathurst: { lat: 47.6194, lon: -65.6514 },
  'Tracadie-Sheila': { lat: 47.5175, lon: -64.9206 },
  'Saint-Leonard': { lat: 47.1686, lon: -67.9281 },
  'Harrison Brook': { lat: 45.6506, lon: -67.3338 },
  'Florenceville-Bristol': { lat: 46.4446, lon: -67.6113 },
};
