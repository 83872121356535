import React from 'react';
import { makeStyles } from '@material-ui/styles';
import lightUpLogo from './lightUpLogo.webp';
import eventPoster from './eventPoster.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    fontSize: '1.5rem',
    '& p': { fontSize: '1.5rem' },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      '& p': { fontSize: '1.2rem' },
    },
  },
  newsletterImages: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
}));
export default function NewsletterSept2023() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <article>
        <h2>High S7 FAM!</h2>
        <p>
          We have an exciting fall planned and headed your way and we wanted to make sure you were the first to know!
        </p>
        <p>
          “Light up events started in 2023 to provide memorable experience fulfilled by their love of cannabis and
          electronic music. They strive for an inclusive environment while supporting the best talent that the cannabis
          and electronic music scene has to offer, creating a thriving community.
        </p>
        <p>
          Patrons should expect that alcohol will not be the focus and alternatives will be presented to expand the mind
          as well as a vibe where they truly can be themselves.
        </p>
        <p>These events will be pleasing to the mind body and soul, join the light up movement!</p>

        <div>
          <h3 style={{ textTransform: 'capitalize' }}>Upcoming events for fall</h3>
          <ul>
            <li>FALL CRAWL - OCTOBER 14TH, 2023</li>
            <li>LIFTED MARKET - OCTOBER 29TH, 2023</li>
          </ul>
          <h4>
            HAVE AN EVENT YOU WANT US TO ATTEND? Simply email us at
            <a href="email:info@s7daw.com"> info@s7daw.com</a>!
          </h4>
          <h4>HAVE A FRIEND WHO WANTS TO LIST A BUSINESS AS WELL? DONT FORGET TO ASK US ABOUT OUR REFERRAL PROGRAM!</h4>
        </div>
      </article>
      <aside className={classes.newsletterImages}>
        <img
          className={classes.newsletterImage}
          src={eventPoster}
          alt="Electric Green - Saturday 16 Sept 2023, 4pm to Late"
        />
        <img className={classes.newsletterImage} src={lightUpLogo} alt="LightUp Events" />
      </aside>
    </div>
  );
}
