export default {
  Ajax: { lat: 43.8506, lon: -79.0204 },
  Amherstburg: { lat: 42.1013, lon: -83.1086 },
  Angus: { lat: 44.3168, lon: -79.883 },
  Arnprior: { lat: 45.4341, lon: -76.3511 },
  Atikokan: { lat: 48.7552, lon: -91.6216 },
  Aylmer: { lat: 42.7722, lon: -80.9832 },
  Bancroft: { lat: 45.0553, lon: -77.8543 },
  Barrie: { lat: 44.3894, lon: -79.6903 },
  Belleville: { lat: 44.1782, lon: -77.3848 },
  'Bells Corners': { lat: 45.3448, lon: -75.828 },
  Bluewater: { lat: 43.4168, lon: -81.6168 },
  Bourget: { lat: 45.4464, lon: -75.2576 },
  Bracebridge: { lat: 45.0381, lon: -79.3074 },
  Brampton: { lat: 43.7315, lon: -79.7624 },
  Brant: { lat: 43.1667, lon: -80.2497 },
  Brantford: { lat: 43.1394, lon: -80.2644 },
  Brockville: { lat: 44.5895, lon: -75.6842 },
  Burlington: { lat: 43.3255, lon: -79.799 },
  Cambridge: { lat: 43.3601, lon: -80.3127 },
  Camlachie: { lat: 42.9446, lon: -82.1058 },
  'Carleton Place': { lat: 45.136, lon: -76.1436 },
  Casselman: { lat: 45.3133, lon: -75.0892 },
  'Clarence-Rockland': { lat: 45.5507, lon: -75.2912 },
  Cobourg: { lat: 43.9597, lon: -78.1652 },
  Collingwood: { lat: 44.4997, lon: -80.2169 },
  Concord: { lat: 43.8006, lon: -79.5076 },
  Cornwall: { lat: 45.0185, lon: -74.7284 },
  'Deep River': { lat: 46.0969, lon: -77.4994 },
  Delaware: { lat: 42.8572, lon: -81.4236 },
  Deseronto: { lat: 44.1955, lon: -77.0483 },
  Dorchester: { lat: 42.9482, lon: -81.0834 },
  Dryden: { lat: 49.7833, lon: -92.8333 },
  'Ear Falls': { lat: 50.6333, lon: -93.2833 },
  'Elliot Lake': { lat: 46.3833, lon: -82.6333 },
  Englehart: { lat: 47.8333, lon: -79.8667 },
  Espanola: { lat: 46.25, lon: -81.7667 },
  Etobicoke: { lat: 43.7, lon: -79.5667 },
  'Fort Erie': { lat: 42.9167, lon: -79.0333 },
  'Fort Frances': { lat: 48.6, lon: -93.4 },
  Gananoque: { lat: 44.3333, lon: -76.1667 },
  Goderich: { lat: 43.75, lon: -81.7167 },
  Georgetown: { lat: 43.6503, lon: -79.9036 },
  Gravenhurst: { lat: 44.9167, lon: -79.3667 },
  'Greater Napanee': { lat: 44.25, lon: -76.95 },
  Greenstone: { lat: 49.75, lon: -86.9833 },
  Guelph: { lat: 43.55, lon: -80.25 },
  Hamilton: { lat: 43.25, lon: -79.8333 },
  Hanover: { lat: 44.15, lon: -81.0333 },
  Hawkesbury: { lat: 45.6, lon: -74.6 },
  Hearst: { lat: 49.6833, lon: -83.6667 },
  Hornepayne: { lat: 49.2167, lon: -84.7833 },
  Huntsville: { lat: 45.3333, lon: -79.2167 },
  'Huron East': { lat: 43.7333, lon: -81.2833 },
  Ingersoll: { lat: 43.0333, lon: -80.8833 },
  Innisfil: { lat: 44.3, lon: -79.5833 },
  'Iroquois Falls': { lat: 48.7667, lon: -80.6833 },
  Kapuskasing: { lat: 49.4167, lon: -82.4333 },
  Keswick: { lat: 44.2333, lon: -79.4667 },
  Kincardine: { lat: 44.1833, lon: -81.6333 },
  Kingston: { lat: 44.2333, lon: -76.4833 },
  'Kirkland Lake': { lat: 48.1333, lon: -80.0333 },
  Kitchener: { lat: 43.45, lon: -80.5 },
  'Lambton Shores': { lat: 43.1833, lon: -81.8667 },
  'Little Current': { lat: 45.9833, lon: -81.9333 },
  London: { lat: 42.9833, lon: -81.25 },
  Lucan: { lat: 43.2167, lon: -81.3667 },
  Marathon: { lat: 48.7167, lon: -86.3833 },
  Markham: { lat: 43.8667, lon: -79.2667 },
  Mattawa: { lat: 46.3167, lon: -78.7 },
  Midland: { lat: 44.75, lon: -79.8833 },
  Milton: { lat: 43.5167, lon: -79.8833 },
  Mississauga: { lat: 43.15, lon: -79.5 },
  Neebing: { lat: 48.45, lon: -89.25 },
  Newmarket: { lat: 44.05, lon: -79.4667 },
  'Niagara Falls': { lat: 43.1, lon: -79.05 },
  'Niagara on the Lake': { lat: 43.2529, lon: -79.0838 },
  'North Bay': { lat: 46.3091, lon: -79.4608 },
  'North Perth': { lat: 43.7248, lon: -80.9446 },
  'North York': { lat: 43.7615, lon: -79.4111 },
  Oakville: { lat: 43.4675, lon: -79.6877 },
  Orangeville: { lat: 43.9195, lon: -80.0943 },
  Orillia: { lat: 44.6083, lon: -79.4207 },
  Oshawa: { lat: 43.8975, lon: -78.8658 },
  Ottawa: { lat: 45.4215, lon: -75.6972 },
  'Owen Sound': { lat: 44.569, lon: -80.9406 },
  'Parry Sound': { lat: 45.3491, lon: -80.0353 },
  Pembroke: { lat: 45.8267, lon: -77.1102 },
  Perth: { lat: 44.8988, lon: -76.2482 },
  Petawawa: { lat: 45.8988, lon: -77.2824 },
  Peterborough: { lat: 44.3091, lon: -78.3197 },
  Petrolia: { lat: 42.8615, lon: -82.1463 },
  Pickering: { lat: 43.8384, lon: -79.0868 },
  Picton: { lat: 44.0076, lon: -77.1458 },
  'Port Colborne': { lat: 42.8734, lon: -79.2324 },
  Powassan: { lat: 46.0782, lon: -79.366 },
  Prescott: { lat: 44.7065, lon: -75.515 },
  'Prince Edward': { lat: 44.0001, lon: -77.25 },
  'Quinte West': { lat: 44.1836, lon: -77.5569 },
  'Rayside-Balfour': { lat: 46.5858, lon: -81.193 },
  'Red Lake': { lat: 51.0085, lon: -93.8285 },
  Renfrew: { lat: 45.4734, lon: -76.6842 },
  'Richmond Hill': { lat: 43.8828, lon: -79.4403 },
  Sarnia: { lat: 42.9748, lon: -82.4066 },
  'Sault Ste. Marie': { lat: 46.5219, lon: -84.3461 },
  Shelburne: { lat: 44.0805, lon: -80.2042 },
  'Smiths Falls': { lat: 44.9011, lon: -76.0217 },
  'South Huron': { lat: 43.3134, lon: -81.5006 },
  'South River': { lat: 45.8419, lon: -79.3793 },
  'St. Catharines': { lat: 43.1594, lon: -79.2469 },
  Stratford: { lat: 43.37, lon: -80.9822 },
  'St. Thomas': { lat: 42.7777, lon: -81.1825 },
  Sudbury: { lat: 46.5, lon: -80.9667 },
  'Temiskaming Shores': { lat: 47.4955, lon: -79.6829 },
  Thessalon: { lat: 46.2561, lon: -83.5586 },
  Thorold: { lat: 43.1201, lon: -79.1987 },
  'Thunder Bay': { lat: 48.3809, lon: -89.2477 },
  Timmins: { lat: 48.4758, lon: -81.3305 },
  Tobermory: { lat: 45.2536, lon: -81.6642 },
  Toronto: { lat: 43.6532, lon: -79.3832 },
  Uxbridge: { lat: 44.1095, lon: -79.1214 },
  Vaughan: { lat: 43.8363, lon: -79.4983 },
  'Walpole Island': { lat: 42.5793, lon: -82.459 },
  'Wasaga Beach': { lat: 44.5205, lon: -80.0165 },
  Waterloo: { lat: 43.4643, lon: -80.5204 },
  Welland: { lat: 42.9925, lon: -79.248 },
  Windsor: { lat: 42.3149, lon: -83.0364 },
  Wingham: { lat: 43.8875, lon: -81.311 },
  Woodstock: { lat: 43.1315, lon: -80.7472 },
  Paris: { lat: 43.2006, lon: -80.3833 },
  Scarborough: { lat: 43.7725, lon: -79.2577 },
  Omemee: { lat: 44.3146, lon: -78.5584 },
  'East York': { lat: 43.701, lon: -79.3216 },
  'Napanee Downtown': { lat: 44.2486, lon: -76.9544 },
  Skatepark: { lat: 43.8993, lon: -78.8658 },
  Ancaster: { lat: 43.2151, lon: -79.9873 },
  Willowdale: { lat: 43.7675, lon: -79.4081 },
  'Trent Hills': { lat: 44.3091, lon: -77.8502 },
  Aurora: { lat: 44.0065, lon: -79.4504 },
  Bradford: { lat: 44.1143, lon: -79.5603 },
  'Stoney Creek': { lat: 43.2185, lon: -79.7197 },
  'Chatham-Kent': { lat: 42.4048, lon: -82.191 },
  Oneida: { lat: 42.9057, lon: -81.3548 },
  Southwold: { lat: 42.786, lon: -81.389 },
  Ohsweken: { lat: 43.0827, lon: -80.0664 },
  'Six Nations': { lat: 43.06, lon: -80.0937 },
  'Toronto Downtown': { lat: 43.6548, lon: -79.3883 },
  'Toronto East': { lat: 43.6829, lon: -79.3311 },
  'Toronto West': { lat: 43.6418, lon: -79.411 },
  'Port Stanley': { lat: 42.6667, lon: -81.2167 },
  Listowel: { lat: 43.7352, lon: -80.9532 },
  Alliston: { lat: 44.15, lon: -79.8667 },
  Arthur: { lat: 43.8331, lon: -80.5332 },
  Fergus: { lat: 43.705, lon: -80.3771 },
  Markdale: { lat: 44.3168, lon: -80.6475 },
  Meaford: { lat: 44.6068, lon: -80.5956 },
  'Sauble Beach': { lat: 44.6344, lon: -81.2632 },
  Creemore: { lat: 44.3241, lon: -80.1065 },
  'Grand Valley': { lat: 43.9061, lon: -80.3189 },
  Palmerston: { lat: 43.8271, lon: -80.8576 },
  'Mount Forest': { lat: 43.9811, lon: -80.738 },
  Durham: { lat: 44.1776, lon: -80.8188 },
  Walkerton: { lat: 44.1305, lon: -81.1488 },
  Tiverton: { lat: 44.2901, lon: -81.5441 },
  Southampton: { lat: 44.4953, lon: -81.3671 },
  Dobbinton: { lat: 44.4182, lon: -81.1815 },
  'Walter Falls': { lat: 44.4888, lon: -80.7556 },
  Maxwell: { lat: 44.3124, lon: -80.4495 },
  Whitby: { lat: 43.8975, lon: -78.9429 },
  'Essex Region': { lat: 42.175, lon: -82.824 },
  Leamington: { lat: 42.0531, lon: -82.5996 },
  Jarvis: { lat: 42.9151, lon: -80.0541 },
  Selkirk: { lat: 42.8651, lon: -79.9441 },
  Hagersville: { lat: 42.96, lon: -80.0531 },
  Simcoe: { lat: 42.8371, lon: -80.3049 },
  Grimsby: { lat: 43.2001, lon: -79.5661 },
  Jordan: { lat: 43.1539, lon: -79.3675 },
  Newcastle: { lat: 43.9236, lon: -78.5908 },
  'Port Hope': { lat: 43.9519, lon: -78.2928 },
  Bowmanville: { lat: 43.9129, lon: -78.688 },
  'Huron County': { lat: 43.6912, lon: -81.6559 },
  Waterdown: { lat: 43.3325, lon: -79.9028 },
  'Huron Perth': { lat: 43.5758, lon: -81.2994 },
  'Norfolk County': { lat: 42.8333, lon: -80.3833 },
  Mitchell: { lat: 43.4667, lon: -81.2 },
  Beamsville: { lat: 43.1667, lon: -79.4667 },
  Curtis: { lat: 43.9, lon: -78.75 },
  Newtonville: { lat: 43.95, lon: -78.6167 },
  Hampton: { lat: 43.9833, lon: -78.7667 },
  Orono: { lat: 43.9667, lon: -78.6167 },
  Clarington: { lat: 43.9333, lon: -78.6167 },
  Brussels: { lat: 43.75, lon: -81.25 },
  Monkton: { lat: 43.5667, lon: -81.0167 },
  Woodbridge: { lat: 43.7833, lon: -79.6 },
  Haliburton: { lat: 45.045, lon: -78.508 },
  'King City': { lat: 43.9256, lon: -79.5286 },
  Thamesford: { lat: 43.1333, lon: -81.1167 },
  LaSalle: { lat: 42.2403, lon: -83.0797 },
  Tecumseh: { lat: 42.2833, lon: -82.9167 },
  Thornhill: { lat: 43.8, lon: -79.4167 },
  Cayuga: { lat: 42.95, lon: -79.8833 },
  Caledonia: { lat: 43.0667, lon: -79.95 },
  Dunnville: { lat: 42.9, lon: -79.6167 },
  'Grand Bend': { lat: 43.3167, lon: -81.75 },
  Exeter: { lat: 43.35, lon: -81.4833 },
  Strathroy: { lat: 42.95, lon: -81.6167 },
  Binbrook: { lat: 43.1, lon: -79.8333 },
  Tillsonburg: { lat: 42.8667, lon: -80.7333 },
  Stouffville: { lat: 43.9667, lon: -79.25 },
};
