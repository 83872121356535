import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '300%',
    fontWeight: 'bold',
    marginTop: theme.spacing(26),
    marginBottom: 0,
  },
  link: {
    color: '#000',
    fontWeight: 'normal',
    fontSize: '125%',
    marginBottom: theme.spacing(2),
  },
  headerImage: {
    position: 'absolute',
    top: 0,
    width: '150%',
    height: 200,
    opacity: 0.8,
    objectFit: 'cover',
  },
  sortedBy: {
    marginBottom: theme.spacing(6),
  },
  space: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  thumbnail: {
    objectFit: 'cover',
  },
  linkCTA: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  linkSml: {
    // color: "#000",
    fontSize: '110%',
    marginLeft: theme.spacing(2),
  },
}));

function BlogLink({ title, link, date, description, img }) {
  const classes = useStyles();

  return (
    <div className={classes.space}>
      <img
        // ao: sdaw-1208 - pending defining images for location blogs.
        src={img.slice(0, 13) !== 'weed-delivery' ? require(`../assets/blogImages/${img}.png`) : null}
        alt={img}
        style={styles.thumbnail}
        width={250}
        height={180}
      ></img>
      <div className={classes.linkCTA}>
        <Link className={classes.link} to={link}>
          {title} →
        </Link>
        <p>{date}</p>
        <p>{description}</p>
      </div>
    </div>
  );
}

function BlogsView(props) {
  const classes = useStyles();
  const isSubPathPresent = props.location.pathname.split('/').filter((item) => item !== '').length > 1;
  if (isSubPathPresent) props.history.replace('/blog'); //Strip out non-existent subpaths

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Blog | S7DAW</title>
        <meta name="description" content="S7DAW Blog Content" />
      </Helmet>
      <img className={classes.headerImage} alt="blog header" src={require(`../assets/banners/homeHeader.webp`)} />
      <div className={classes.innerContainer}>
        <h1 className={classes.title}>Explore our Blog</h1>
        <p className={classes.sortedBy}>Sorted by Latest</p>
        <BlogLink title="S7DAW Newsletter - September 2023" link="/blog/newsletter-september-2023" img="sdawLogo" />
        <BlogLink
          title="Dispensary Marketing Plan: Use Media To Market"
          link="/blog/dispensary-marketing-plan"
          img="top-canadian-cannabis-brands"
        />
        <Divider />
        <BlogLink
          title="How To Start a Cannabis Business in Ontario"
          link="/blog/how-to-start-a-cannabis-business-in-ontario"
          description="Starting a business isn’t easy, and starting a cannabis business is even harder due to the many licenses and certifications you will need to claim to run a fully legal dispensary. Take a look at the steps you need to take in order to successfully start your cannabis business in Ontario."
          img="how-to-start-a-cannabis-business-in-ontario"
        />
        <Divider />
        <div className={classes.space}>
          <img
            src={require(`../assets/blogImages/instagram-is-shutting-down-legal-canadian-cannabis-businesses-profiles.png`)}
            alt={`instagram-is-shutting-down-legal-canadian-cannabis-businesses-profiles`}
            style={styles.thumbnail}
            width={250}
            height={180}
          ></img>
          <div className={classes.linkCTA}>
            <a
              href="https://merryjane.com/news/instagram-is-shutting-down-legal-canadian-cannabis-businesses-profiles"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Instagram is Shutting Down Legal Cannabis Business Profiles
            </a>
            <p>
              Instagram has made a decision to no longer support legal cannabis businesses by removing their profiles
              from their platform. Although a lot of businesses find this unfair, Instagram feels they cannot prevent
              those who are underage to view these accounts and posts.
            </p>
          </div>
        </div>
        <Divider />
        <BlogLink
          title="Canadian Cannabis Brands to Follow in 2020"
          link="/blog/top-canadian-cannabis-brands"
          description="With so many cannabis brands appearing due to its legalization in many states and provinces, it becomes more difficult to know which companies to trust. Today we will show you the top brands in North America, and how you can purchase products directly from them."
          img="top-canadian-cannabis-brands"
        />
      </div>
    </div>
  );
}

export default BlogsView;
