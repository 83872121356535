import React from 'react';
import { makeStyles, Card, Typography, useMediaQuery } from '@material-ui/core';
import { parseIdFromLink, convertToDate, makeImageKitURL } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileRoot: {
    fontSize: '9pt',
    display: 'flex',
    flexDirection: 'column',
    margin: '2px',
    padding: '2px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    height: 60,
    width: 60,
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      //display: "none",
    },
  },
  condensedText: {
    fontSize: '9pt',
  },
  mobileLogo: {
    height: 50,
    width: 50,
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
  },

  active: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  expired: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  upcoming: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

function SortItem(props) {
  const classes = useStyles();

  const { item, type, pinned, brand, setFeatListData, setUnpinnedData, itemRankInfo, isMailout } = props;
  const itemsRankId = itemRankInfo._links.self.href?.replace('{?projection}', '');
  const entityId = item._links.self.href?.replace('{?projection}', '');

  let dealStatus = '';

  const mobile = useMediaQuery('(max-width:500px)');

  if (['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type)) {
    if (new Date() > convertToDate(item.endDate)) {
      // was previous
      dealStatus = 'Expired';
    } else if (new Date() < convertToDate(item.startDate)) {
      dealStatus = 'Upcoming';
    } else {
      dealStatus = 'Active';
    }
  }
  const rawImageURL = brand ? item.brandImage : item.storeImage;

  const removeItemFromRankedList = (event) => {
    /* Should exist in parent - should pass in function not state */
    setFeatListData((prev) => {
      const newList = [...prev].filter((listItem) => {
        const listItemId = listItem._links.self.href?.replace('{?projection}', '');
        return listItemId !== itemsRankId;
      });
      return newList;
    });
    setUnpinnedData((prev) => {
      // Add Item to unpinned list
      return [...prev, item];
    });
  };

  const addItemToFeatList = (e) => {
    /* Should exist in parent - should pass in function not state */
    //Add to feat list and rmv from unpinned
    setFeatListData((prev) => {
      const newList = [...prev, item]; //Item is the entity (store, delivery etc).
      return newList;
    });
    setUnpinnedData((prev) => {
      const newList = [...prev].filter((listItem) => {
        const listItemId = listItem._links.self.href?.replace('{?projection}', '');
        return listItemId !== entityId;
      });
      return newList;
    });
  };

  const itemImageURL = makeImageKitURL(rawImageURL, { width: '100' });
  return (
    <Card className={mobile ? classes.mobileRoot : classes.root} style={{ backgroundColor: pinned ? '#ede49f' : '' }}>
      <span style={{ display: 'flex' }}>
        {!pinned && (
          <div
            style={{ marginRight: '8px', alignSelf: 'center', cursor: 'pointer' }}
            onClick={addItemToFeatList}
            title="Move to feat. list (bottom)"
          >{`➕`}</div>
        )}
        <div className={classes.details}>
          <Typography variant="body2" className={mobile ? classes.condensedText : ''}>
            {item.name}
          </Typography>

          {['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) && (
            <Typography color="textSecondary" className={mobile ? classes.condensedText : ''}>
              Store Name: {item.storeName}
            </Typography>
          )}
          {isMailout && (
            <Typography color="textSecondary" variant="body2" hidden={mobile}>
              Store Type: {item.storeType}
            </Typography>
          )}
          {!['Deal', 'RecommendedDeal', 'FeaturedDeal', 'Maps'].includes(type) && (
            <Typography color="textSecondary" variant="body2" hidden={mobile}>
              Owned By: {item.contactFirstName} {item.contactLastName}
            </Typography>
          )}
          <Typography color="textSecondary" hidden={mobile}>
            {['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) ? 'Deal ' : ''}ID:{' '}
            {parseIdFromLink(item._links.self.href.replace('{?projection}', ''))}
          </Typography>
          <Typography
            variant="body2"
            // color="textSecondary"
            className={
              dealStatus === 'Active'
                ? classes.active
                : dealStatus === 'Expired'
                ? classes.expired
                : dealStatus === 'Upcoming'
                ? classes.upcoming
                : ''
            }
          >
            {['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) && dealStatus === 'Active'
              ? 'Active'
              : ['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) && dealStatus === 'Expired'
              ? 'Expired'
              : ['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) && dealStatus === 'Upcoming'
              ? 'Upcoming'
              : ''}
          </Typography>
        </div>
      </span>
      <span style={{ display: 'flex' }}>
        <img className={mobile ? classes.mobileLogo : classes.logo} src={itemImageURL} alt={item.name} />
        {pinned && (
          <div
            style={{ marginLeft: '8px', alignSelf: 'center', cursor: 'pointer' }}
            onClick={removeItemFromRankedList}
            title="Remove from List"
          >
            {`❌`}
          </div>
        )}
      </span>
    </Card>
  );
}

export default SortItem;
