import React from 'react';
import { makeStyles, Typography, Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import { abbreviate, makeImageKitURL } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
  },
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 200,
    [theme.breakpoints.down('md')]: {
      height: 160,
    },
  },
  // content: {
  //   height: 130,
  // },
  rating: {
    display: 'flex',
  },
  stars: {
    marginRight: theme.spacing(1),
  },
  cardActionArea: {
    position: 'relative',
  },
  brandImage: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(1),
    objectFit: 'cover',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    // backgroundColor: "#ebebeb",
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 50,
    },
  },
  cardTitleText: {
    fontWeight: 'bold',
  },
}));

export default function FeaturedProductItem(props) {
  const { productName, productImage, reviews, rating, address, brandImage, id, brandId, brandSlug, productSlug } =
    props;

  const classes = useStyles();
  const itemImageURL = makeImageKitURL(productImage);
  const brandLogoURL = makeImageKitURL(brandImage);

  return (
    <Card key={id} className={classes.root}>
      <CardActionArea
        component={Link}
        // to={`/brands/${brandId}/products/${id}`}
        to={`/brands/${brandSlug}/${id}/${productSlug}`}
        style={{ color: '#000000', textDecoration: 'none' }}
        className={classes.cardActionArea}
      >
        <CardMedia className={classes.media} image={itemImageURL} title={productName} />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="caption" noWrap>
            {`${address.city}, ${abbreviate(address.province)}`}
          </Typography>
          <Typography gutterBottom variant="body1" noWrap className={classes.cardTitleText}>
            {productName}
          </Typography>
          <div className={classes.rating}>
            <Rating
              name="read-only"
              value={rating ? Number(rating) : 0}
              precision={0.5}
              size="small"
              className={classes.stars}
              readOnly
            />
            <Typography variant="caption" className={classes.cardText}>
              {`${rating ? Number(rating.toFixed(1)) : 0} (${reviews ? reviews : 0})`}
            </Typography>
          </div>
          <img src={brandLogoURL} className={classes.brandImage} alt={`brand-${brandId}`}></img>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
