import React, { Fragment, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  CardActionArea,
  Card,
  // useMediaQuery,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import PercentageCircle from './PercentageCircle';
import { capitalizeFirst, parseIdFromLink } from '../utils/helpers';
import ProductImages from './ProductImages';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import PreviewContext from '../PreviewContext';
import productPlaceholder from '../utils/assets/placeholders/productPlaceholder.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  mainInformation: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  productImage: {
    marginTop: theme.spacing(2),
    // border: "1px solid #ebebeb",
    // width: 300,
    // height: 300,
    // objectFit: "contain",
    // [theme.breakpoints.down("md")]: {
    //   width: 300,
    //   height: 300,
    // },
    [theme.breakpoints.down('sm')]: {
      // width: 300,
      // height: 300,
      // marginBottom: theme.spacing(4),
      marginTop: theme.spacing(0),
    },
  },
  productName: {
    display: 'flex',
  },
  productNameText: {
    fontSize: '20pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16pt',
    },
    fontWeight: 'bold',
  },
  rating: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  stars: {
    marginRight: theme.spacing(1),
  },
  productBreakdownSection: {
    display: 'flex',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },
  productBreakdownItem: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: theme.spacing(0.7),
    //   marginRight: theme.spacing(0.7)
    // }
  },
  productBreakdownItemBrandCard: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: theme.spacing(0.7),
    //   marginRight: theme.spacing(0.7)
    // }
    color: 'inherit',
    width: 120,
    maxHeight: 180,
  },
  productBreakdownText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75em',
    },
  },
  productBreakdownImg: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
  },
  productPricing: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  pricingBox: {
    minWidth: 115,
    color: theme.palette.grey.A400,
    display: 'flex',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '5%',
    borderColor: theme.palette.grey.A200,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    transition: '0.5s',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
  },
  pricingTextQ: {
    width: '100%',
    fontSize: '12pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12pt',
    },
  },
  pricingTextP: {
    width: '100%',
    fontSize: '18pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18pt',
    },
  },
  productBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  brandCard: {
    margin: theme.spacing(2, 0, 1, 0),
    color: 'inherit',
    width: 350,
  },
  brandCardActionArea: {
    padding: theme.spacing(1),
    color: 'inherit',
  },
  brandImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  brandImageMobile: {
    width: 40,
    height: 40,
    objectFit: 'cover',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
  },
  brandImageContainer: {
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    position: 'relative',
    width: 105,
    height: 100,
  },
  verifiedBadge: {
    color: '#00aced',
    height: 35,
    width: 35,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  verifiedBadgeMobile: {
    color: '#00aced',
    height: 45,
    width: 45,
    position: 'absolute',
    top: -1,
    right: 2,
  },
}));

function ProductProfile(props) {
  const classes = useStyles();

  const { product, images, loadingImgs, imageError, brand, custom, storeSlug } = props;

  // const desktop = useMediaQuery("(min-width:1024px)");

  const preview = useContext(PreviewContext);
  return (
    <div className={classes.root}>
      {!loadingImgs && !imageError && (
        <div className={classes.productImage}>
          <ProductImages
            // product={product}
            name={product.name}
            images={images}
            flags={
              brand || !product.brand
                ? product.flags
                : product.productCustomizations.length > 0 &&
                  product.productCustomizations.find((custom) => custom.store.slug === storeSlug).flags
            }
            loadingImgs={loadingImgs}
            imageError={imageError}
          ></ProductImages>
        </div>
      )}
      {loadingImgs && <Skeleton variant="rect" width={300} height={300} />}
      {imageError && (
        <img src={productPlaceholder} alt="Product photos coming soon" style={{ width: '300px', height: '300px' }} />
      )}
      <div className={classes.mainInformation}>
        <div className={classes.productName}>
          <Typography variant="h1" className={classes.productNameText}>
            {product.name + ` - ${product.categories.category}`}
          </Typography>
        </div>
        <div className={classes.rating}>
          <Rating
            name="read-only"
            value={product.avgRating}
            precision={0.5}
            size="small"
            className={classes.stars}
            readOnly
          />
          <Typography variant="body2">{`${Number(product.avgRating).toFixed(1)} (${product.numOfReviews} review${
            product.reviews === 1 ? '' : 's'
          })`}</Typography>
        </div>
        {/* {product.brand &&
          !brand &&
          (!desktop ||
            (!product.genetics &&
              !product.timeOfUse &&
              !product.thc &&
              !product.cbd)) && ( */}
        {product.brand && !brand && (
          <Card className={classes.brandCard}>
            <CardActionArea
              // disabled={preview.state.screen}
              className={classes.brandCardActionArea}
              component={Link}
              to={`/brands/${parseIdFromLink(product.brand._links.self.href, 0, '{?projection}')}`}
              target={preview.state.screen ? '_blank' : null}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <img src={product.brand.brandImage} alt={product.brand.name} className={classes.brandImageMobile} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '7px',
                    maxWidth: product.productCustomizations.find((custom) => custom.store.slug === storeSlug).verified
                      ? '58%'
                      : '70%',
                  }}
                >
                  <Typography style={{ position: 'absolute', top: 0, left: '14.5%' }} variant="caption">
                    Made By:
                  </Typography>
                  <Typography style={{ marginTop: '12px' }} noWrap>{`${product.brand.name}`}</Typography>
                </div>
                {product.productCustomizations.find((custom) => custom.store.slug === storeSlug).verified && (
                  <Tooltip className={classes.verifiedBadge} title="Verified Reseller" arrow placement="top">
                    <img src={require(`../assets/badges/verifiedBlueCheck.svg`)} alt={`verified-blue-check`} />
                  </Tooltip>
                )}
              </div>
            </CardActionArea>
          </Card>
        )}
        {/* )} */}
        <div className={classes.productBreakdownSection}>
          {product.genetics && (
            <Fragment>
              <div className={classes.productBreakdownItem}>
                <Tooltip title={capitalizeFirst(product.genetics)} arrow placement="top">
                  <img
                    src={require(`../assets/genetic-types/${product.genetics.toLowerCase().replace(/ /g, '')}.svg`)}
                    alt={`strain-${product.genetics}`}
                    className={classes.productBreakdownImg}
                  ></img>
                </Tooltip>
                <Typography variant="body2" align="center" className={classes.productBreakdownText}>
                  Type
                </Typography>
              </div>
              <Divider orientation="vertical" className={classes.divider} />
            </Fragment>
          )}
          {product.timeOfUse && (
            <Fragment>
              <div className={classes.productBreakdownItem}>
                <Tooltip title={capitalizeFirst(product.timeOfUse)} arrow placement="top">
                  <img
                    src={require(`../assets/time-of-use/${product.timeOfUse.toLowerCase().replace(/ /g, '')}.svg`)}
                    alt={`strain-${product.timeOfUse}`}
                    className={classes.productBreakdownImg}
                  ></img>
                </Tooltip>
                <Typography variant="body2" align="center" className={classes.productBreakdownText}>
                  Time of Use
                </Typography>
              </div>
              <Divider orientation="vertical" className={classes.divider} />
            </Fragment>
          )}
          {Number(product.thc) > 0 && product.thc && (
            <Fragment>
              <div className={classes.productBreakdownItem}>
                <PercentageCircle percent={product.thc} className={classes.productBreakdownImg}></PercentageCircle>
                <Typography variant="body2" align="center" className={classes.productBreakdownText}>
                  THC
                </Typography>
              </div>
              <Divider orientation="vertical" className={classes.divider} />
            </Fragment>
          )}
          {Number(product.cbd) > 0 && product.cbd && (
            <Fragment>
              <div className={classes.productBreakdownItem}>
                <PercentageCircle percent={product.cbd} className={classes.productBreakdownImg}></PercentageCircle>
                <Typography variant="body2" align="center" className={classes.productBreakdownText}>
                  CBD
                </Typography>
              </div>
              {/* {product.brand && !brand && desktop && (
                <Divider orientation="vertical" className={classes.divider} />
              )} */}
            </Fragment>
          )}
          {/* {product.brand &&
            !brand &&
            desktop &&
            (product.genetics ||
              product.timeOfUse ||
              product.thc ||
              product.cbd) && (
              <Card className={classes.productBreakdownItemBrandCard}>
                <CardActionArea
                  // disabled={preview.state.screen}
                  className={classes.brandCardActionArea}
                  component={Link}
                  to={`/brands/${parseIdFromLink(
                    product.brand._links.self.href,
                    0,
                    "{?projection}"
                  )}`}
                  target={preview.state.screen ? "_blank" : null}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "center",
                      position: "relative",
                    }}
                  >
                    <div className={classes.brandImageContainer}>
                      <img
                        src={product.brand.brandImage}
                        alt={product.brand.name}
                        className={classes.brandImage}
                      />
                      {product.productCustomizations.find(
                        (custom) =>
                          parseIdFromLink(
                            custom.store._links.self.href,
                            0,
                            "{?projection}"
                          ) === storeId
                      ).verified && (
                        <Tooltip
                          className={classes.verifiedBadge}
                          title="Verified Reseller"
                          arrow
                          placement="top"
                        >
                          <img
                            src={require(`../assets/badges/verifiedBlueCheck.svg`)}
                            alt={`verified-blue-check`}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="body2">{`Made by`}</Typography>
                      <Typography
                        variant="body2"
                        noWrap
                      >{`${product.brand.name}`}</Typography>
                    </div>
                  </div>
                </CardActionArea>
              </Card>
            )} */}
        </div>
        {!brand && (
          <div className={classes.productPricing}>
            {product.productPricingList &&
              product.productPricingList.length > 0 &&
              product.productPricingList
                .sort((x, y) => x.price - y.price)
                .map((sku, index) => {
                  return (
                    <div key={index} className={classes.pricingBox}>
                      <Typography variant="body1" className={classes.pricingTextQ}>
                        {sku.quantity ? sku.quantity : 'Each'}
                      </Typography>
                      <Typography color="inherit" variant="h5" className={classes.pricingTextP}>
                        {`$${sku.price === Math.floor(sku.price) ? sku.price : sku.price?.toFixed(2)}`}
                      </Typography>
                    </div>
                  );
                })}
            {custom.productPricingList &&
              custom.productPricingList.length > 0 &&
              custom.productPricingList
                .sort((x, y) => x.price - y.price)
                .map((sku, index) => {
                  return (
                    <div key={index} className={classes.pricingBox}>
                      <Typography variant="body1" className={classes.pricingTextQ}>
                        {sku.quantity ? sku.quantity : 'Each'}
                      </Typography>
                      <Typography color="inherit" variant="h5" className={classes.pricingTextP}>
                        {`$${sku.price === Math.floor(sku.price) ? sku.price : sku.price?.toFixed(2)}`}
                      </Typography>
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductProfile;
