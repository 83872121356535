import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  searchBar: {
    width: "100%"
  }
});

function SearchBar(props) {
  const { value, onChange, onClear } = props;

  const classes = useStyles();

  return (
    <TextField
      className={classes.searchBar}
      label="Search"
      variant="outlined"
      margin="dense"
      value={value}
      onChange={event => onChange(event.target.value)}
      InputProps={{
        endAdornment: (
          props.value !== "" && <IconButton onClick={() => onClear()}>
            <ClearIcon style={{ fontSize: 16 }} />
          </IconButton>
        )
      }}
    />
  );
}

export default SearchBar;
