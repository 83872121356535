import React, { useEffect, Fragment } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import { fromLonLat, transform } from 'ol/proj';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import axios from 'axios';

import './styles/StaticMap.css';
function StaticMap(props) {
  const { address, storeName, storeCoords } = props;
  const createMapAndMarker = (coords) => {
    const map = new Map({
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        center: transform([coords.long, coords.lat], 'EPSG:4326', 'EPSG:3857'),
        zoom: 15,
      }),
      target: 'map',
    });

    const marker = new Overlay({
      position: fromLonLat([storeCoords.long, storeCoords.lat]),
      positioning: 'center-center',
      element: document.getElementById('marker'),
      stopEvent: false,
    });
    map.addOverlay(marker);
  };

  useEffect(() => {
    if (storeCoords?.lat && storeCoords?.long) {
      return createMapAndMarker({ long: storeCoords.long, lat: storeCoords.lat });
    } else if (address.postalCode) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address.postalCode}&key=${process.env.REACT_APP_MAPS_API_KEY}`
        )
        .then((response) => {
          /* Depending on errors google map api will still succeed thus the following if statement */
          if (response.data.results && response.data.results[0]) {
            createMapAndMarker({
              long: response.data.results[0].geometry.location.lng,
              lat: response.data.results[0].geometry.location.lat,
            });
          }
        });
    }

    // if (!storeCoords && !address.postalCode && address.street){
    //   axios
    //   .get(
    //     `https://maps.googleapis.com/maps/api/geocode/json?address=${address.city},${address.province}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    //   )
    //   .then((response) => {
    //     if (response.data.results && response.data.results[0]) {
    //       createMapAndMarker({
    //         long: response.data.results[0].geometry.location.lng,
    //         lat: response.data.results[0].geometry.location.lat,
    //       });
    //     }
    // }
    // }

    //=================
    // if (address.postalCode) {
    //   axios
    //     .get(
    //       `https://maps.googleapis.com/maps/api/geocode/json?address=${address.postalCode}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    //     )
    //     .then((response) => {
    //       /* Depending on errors google map api will still succeed thus the following if statement */
    //       if (response.data.results && response.data.results[0]) {
    //         createMapAndMarker({
    //           long: response.data.results[0].geometry.location.lng,
    //           lat: response.data.results[0].geometry.location.lat,
    //         });
    //       } else {
    //         axios
    //           .get(
    //             `https://maps.googleapis.com/maps/api/geocode/json?address=${address.city},${address.province}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    //           )
    //           .then((response) => {
    //             if (response.data.results && response.data.results[0]) {
    //               createMapAndMarker({
    //                 long: response.data.results[0].geometry.location.lng,
    //                 lat: response.data.results[0].geometry.location.lat,
    //               });
    //             } else {
    //               /* Uses long lat from SDAW backend, ideally this should be default but need to verify accuracy... */
    //               createMapAndMarker({ long: storeCoords.long, lat: storeCoords.lat });
    //             }
    //           });
    //       }
    //     })
    //     .catch((err) => console.error(err));
    // } else {
    //   /* No postal code from API */
    //   axios
    //     .get(
    //       `https://maps.googleapis.com/maps/api/geocode/json?address=${address.city},${address.province}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    //     )
    //     .then((response) => {
    //       if (response.data.results && response.data.results[0]) {
    //         createMapAndMarker({
    //           long: response.data.results[0].geometry.location.lng,
    //           lat: response.data.results[0].geometry.location.lat,
    //         });
    //       }
    //     })
    //     .catch((err) => console.error(err));
    // }
    // eslint-disable-next-line
  }, [address]);

  return (
    <Fragment>
      <div id="map" className="map"></div>
      <div style={{ display: 'none' }}>
        <div id="marker" title={storeName}></div>
      </div>
    </Fragment>
  );
}

export default StaticMap;
