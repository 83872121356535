import React from "react";
import { makeStyles } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LinkButton from "./LinkButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
}));

function GeneralBreadcrumbs(props) {
  const classes = useStyles();

  const { value, preview } = props;

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {value.map((crumb, index) => {
          return (
            <LinkButton
              key={`crumb-${index}`}
              label={crumb.label}
              to={{ pathname: crumb.path }}
              disabled={!crumb.path || (preview && index === 0)}
              bold={!crumb.path}
            ></LinkButton>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default GeneralBreadcrumbs;
