export default {
  Altona: { lat: 49.1045, lon: -97.5607 },
  Beausejour: { lat: 50.0647, lon: -96.5157 },
  Boissevain: { lat: 49.231, lon: -100.0562 },
  Brandon: { lat: 49.8469, lon: -99.9531 },
  Carberry: { lat: 49.8681, lon: -99.3604 },
  Carman: { lat: 49.491, lon: -98.0017 },
  Dauphin: { lat: 51.1494, lon: -100.0502 },
  Deloraine: { lat: 49.1883, lon: -100.5071 },
  'Flin Flon': { lat: 54.7682, lon: -101.8762 },
  Gimli: { lat: 50.6309, lon: -96.9907 },
  Headingley: { lat: 49.8758, lon: -97.3495 },
  Killarney: { lat: 49.1817, lon: -99.6741 },
  'La Broquerie': { lat: 49.5364, lon: -96.5092 },
  'Lac du Bonnet': { lat: 50.2537, lon: -96.0614 },
  Melita: { lat: 49.2689, lon: -100.9964 },
  Minnedosa: { lat: 50.2446, lon: -99.8437 },
  Morden: { lat: 49.1917, lon: -98.1015 },
  Morris: { lat: 49.3539, lon: -97.3601 },
  Neepawa: { lat: 50.2287, lon: -99.4524 },
  Niverville: { lat: 49.6044, lon: -97.0419 },
  'Portage la Prairie': { lat: 49.9723, lon: -98.2913 },
  Rivers: { lat: 50.0304, lon: -100.2404 },
  Roblin: { lat: 51.2281, lon: -101.3529 },
  Selkirk: { lat: 50.1435, lon: -96.8836 },
  Souris: { lat: 49.6176, lon: -100.2581 },
  Steinbach: { lat: 49.5259, lon: -96.6839 },
  Stonewall: { lat: 50.1342, lon: -97.3276 },
  'Swan River': { lat: 52.1051, lon: -101.2675 },
  'The Pas': { lat: 53.8251, lon: -101.2541 },
  Thompson: { lat: 55.7433, lon: -97.8558 },
  Virden: { lat: 49.8509, lon: -100.9321 },
  Winkler: { lat: 49.1819, lon: -97.9392 },
  Winnipeg: { lat: 49.8951, lon: -97.1384 },
  'Rural Municipality of Yellowhead': { lat: 51.1069, lon: -100.2857 },
};
