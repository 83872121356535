import * as React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    open: {
        color: "#3e9444",
        fontWeight: "500",
        fontSize: "",
      },
      closed: {
        color: "red",
        fontWeight: "500",
        fontSize: "",
      },
  }));


export default function OpenStatusBadge({ open }) {
const classes = useStyles();
  return (
    <Typography className={open ? classes.open : classes.closed}>
      {open ? "OPEN NOW" : "CLOSED"}
    </Typography >
  );
}

