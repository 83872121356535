/* 
Form to allow users to request deletion of account and data
- Must be logged in - route is protected 
- Must confirm email address

- Triggers request to delete - Process TBC
    Proposed - vierify user, change user field to delete requested
    - Admins can have a table to can view and approve deletion

    Alternative - user can request deletion, and then confirm deletion via email link


    - TODO - need to have direct link to this dialog
    or need a delete policy page that instructs...
    //How to link directly?
    /profile/delete-account - triggers open dialog
    Will need to fix /profile direct access (currently just goes to landing page instead of login)... at least catch and prompt to login
*/

import React, { useState } from 'react';
import api from '../../utils/api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  reqDeleteBtn: {
    color: 'red',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  deleteButton: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
    },
  },
}));

export default function Index({ userEmail, logout, userId }) {
  const classes = useStyles();
  //Pull their userID / email - ideally from context but doesn't seem to use... Will need to pass in fr parent
  //Parent goes a get for user details so they are current

  const [typedEmail, setTypedEmail] = useState(''); //Email entered for confirmation
  const [open, setOpen] = useState(false); //Dialog open or closed
  const [mode, setMode] = useState('confirm'); //confirm, success, error

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout(); //clears app state and cookies - changes to landing page - but this may break when /profiles route is fixed
    // Redirect to landing page automatically when state is cleared
  };

  const handleTypedEmail = (e) => {
    setTypedEmail(e.target.value);
  };

  const handleSubmitRequest = async () => {
    await api.requestDeleteAccountEmail(userEmail); //Send email to user to confirm
    await api.disableUser(userId); //Disable User before logout
    //Show success message
    setMode('success'); //On success Ok - Log out user + disable account + redirect to landing page
  };

  const allowDelete = typedEmail === userEmail;
  const DAYS_TO_DELETE = 14;

  //=================== Modes
  const confirm = (
    <>
      <DialogTitle id="dialog-title">Delete Your Account and Data, Are you sure?</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{`Once your request is sent it will be processed within ${DAYS_TO_DELETE} business days`}</Typography>
        <Typography variant="body1">Your request will start the process to delete your account.</Typography>
        <ul>
          <li> You will be logged out</li>
          <li>Your account will be disabled</li>
          <li>{`Within business ${DAYS_TO_DELETE} days your account will be deleted`}</li>
          <li>
            <strong>This cannot be undone</strong>
          </li>
        </ul>
        <hr />
        <Typography variant="body1">{`Confirm you understand and accept by typing your email address (${userEmail}):`}</Typography>
        <form className={null} noValidate autoComplete="off">
          <TextField id="standard-basic" label="Confrim Email" onChange={handleTypedEmail} fullWidth />
        </form>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          className={classes.deleteButton}
          disabled={!allowDelete}
          onClick={handleSubmitRequest}
        >
          Delete my Account
        </Button>
      </DialogActions>
    </>
  );

  const success = (
    <>
      <DialogTitle id="dialog-title">Sorry to see you go,</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{`Your request has been send and will be processed within ${DAYS_TO_DELETE} business days`}</Typography>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={handleLogout}>Ok</Button>
      </DialogActions>
    </>
  );

  const error = (
    <>
      <DialogTitle id="dialog-title">Oops, an error occured,</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{`An error occured prventing your request from being submitted.  Please try again`}</Typography>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={() => setMode('confirm')}>Try Again</Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <Typography variant="body2" className={classes.reqDeleteBtn} onClick={handleOpen}>
        Delete your account
      </Typography>

      <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
        {mode === 'confirm' && confirm}
        {mode === 'success' && success}
        {mode === 'error' && error}
      </Dialog>
    </>
  );
}
