import Alberta from './CITIES/ALBERTA';
import Ontario from './CITIES/ONTARIO';
import Manitoba from './CITIES/MANITOBA';
import Saskatchewan from './CITIES/SASKATCHEWAN';
import British_Columbia from './CITIES/BRITISH_COLUMBIA';
import Quebec from './CITIES/QUEBEC';
import New_Brunswick from './CITIES/NEW_BRUNSWICK';
import Nova_Scotia from './CITIES/NOVA_SCOTIA';
import Prince_Edward_Island from './CITIES/PEI';
import Newfoundland_and_Labrador from './CITIES/NEWFOUNDLAND';
import Yukon from './CITIES/YUKON';
import Northwest_Territories from './CITIES/NWT';
import Nunavut from './CITIES/NUNAVUT';

export default {
  Alberta,
  Ontario,
  Manitoba,
  Saskatchewan,
  British_Columbia,
  Quebec,
  New_Brunswick,
  Nova_Scotia,
  Prince_Edward_Island,
  Newfoundland_and_Labrador,
  Yukon,
  Northwest_Territories,
  Nunavut,
};
