import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
  image: {
    height: 200,
    [theme.breakpoints.down("md")]: {
      height: 160
    }
  },
  city: {
    margin: theme.spacing(2, 2, 0.1, 2)
  },
  name: {
    margin: theme.spacing(0.1, 2, 0.1, 2)
  },
  rating: { margin: theme.spacing(0.1, 2, 2, 2) }
}));

function SkeletonSimpleCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Skeleton className={classes.image} variant="rect" animation="wave" />
      <Skeleton className={classes.city} width={"70%"} animation="wave" />
      <Skeleton className={classes.rating} width={"50%"} animation="wave" />
    </Card>
  );
}

export default SkeletonSimpleCard;
