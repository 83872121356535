// import { useEffect } from 'react';
import moment from 'moment';
import api from '../../../utils/api';
// import { allBookings } from '../__mocks__/testdata';

export default function useBookingUserData() {
  /* Hook is used to get booking settings, fetch this week's data for a page (user view)*/

  const todaysDate = new Date();

  const fetchSettings = async () => {
    const { systemSettings, devSystemSettings } = await import('../__mocks__/testdata'); //[]Eventually replce w. API Call
    const bookingSettings = (await process.env.REACT_APP_ENV) === 'qaapi' ? systemSettings : devSystemSettings; //TEMP
    return bookingSettings;
  };

  const allowBookings = () => false; //Booking kill switch (userside)
  // const allowBookings = async (currCityID) => {
  //   console.log('CHECKING BOOKINGS...');
  //   const settings = await fetchSettings();
  //   const bookingsCityIDs = settings.permittedCities ? Object.keys(settings.permittedCities) : [];
  //   const isAllowed = bookingsCityIDs.includes(currCityID);
  //   console.log(`allow`, isAllowed);
  //   return isAllowed;
  // };
  //========================================================================
  //======================================================================== Create popular and Platinum lists
  const createBookingsLists = (bookings, type) => {
    type = type === 'brand' || type === 'deal' ? type : 'store';
    const popularStores = [];
    const platinumStores = [];
    bookings.forEach((booking) => {
      // Uses .includes method because mailout bookings are pre-pended with 'm'
      if (booking.list.includes('popular')) {
        popularStores.push(booking);
      }
      if (booking.list.includes('platinum')) {
        platinumStores.push(booking);
      }
    });
    const sortedLists = {
      platinumStores: platinumStores.sort((a, b) => a.displayRank - b.displayRank).map((x) => x[type]),
      popularStores: popularStores.sort((a, b) => a.displayRank - b.displayRank).map((x) => x[type]),
    };
    return sortedLists;
  };

  //======================================================================== Filter bookings By Type
  const filterBookingsByType = (bookings, storeType) => {
    //storeType == Delivery, brand, deal, storefront,
    let bookingsByType = [];
    if (storeType === 'Storefront' || storeType === 'Delivery') {
      bookingsByType = bookings.filter((booking) => {
        return booking.store?.storeType === storeType;
      });
    } else {
      if (storeType === 'mail') {
        return bookings.filter((booking) => booking.list[0] === 'm');
      }
      bookingsByType = bookings.filter((booking) => booking[storeType]); //[] Cfm this will take all w. matching type
    }
    return bookingsByType;
  };
  //======================================================================== Filter bookings By Location
  const filterBookingsByLocation = (bookings, location) => {
    const { city, province } = location;
    if (!city || !province) {
      return bookings;
    }
    const filteredBookings = bookings.filter(
      (booking) => booking.location.province === province && booking.location.city === city
    );
    return filteredBookings;
  };
  //======================================================================== Fetch bookings by Date Range
  const fetchBookingsByDates = async (startDate = todaysDate, weeksToFetch = 0, status = null, fetchSize = 200) => {
    const dateRange = {
      startWeek: moment(startDate).week(),
      startYear: moment(startDate).year(),
      endWeek: moment(startDate).add(weeksToFetch, 'weeks').week(),
      endYear: moment(startDate).add(weeksToFetch, 'weeks').year(),
    };
    // console.table(dateRange);
    try {
      const bookings = await api.getAllBookingsByRange(dateRange, status, fetchSize).then((res) => {
        if (fetchSize === 1) {
          return res.page.totalElements;
        }
        return res._embedded.bookings;
      });
      //console.log('Bookings by Range', bookings);
      return bookings;
    } catch (error) {
      throw error;
    }
  };

  //======================================================================== fetch and Build List
  const fetchAndBuildList = async (startDate, leadTime, city, province, storeType) => {
    try {
      const allBookings = await fetchBookingsByDates(startDate, leadTime, 'approved');
      const locationBookings = await filterBookingsByLocation(allBookings, { city, province });
      const bookingsByType = await filterBookingsByType(locationBookings, storeType);
      const lists = await createBookingsLists(bookingsByType, storeType);
      return lists;
    } catch (e) {
      throw e;
    }
  };
  //======================================================================== fetch store details
  const fetchStoreDetails = async (storeID) => {
    try {
      let store = await api.getStoreDetailedProjection(storeID);
      store = store.data;
      return {
        phone: store.phone,
        email: store.email,
      };
    } catch (e) {
      return {
        phone: 'API Error',
        email: 'API Error',
      };
    }
  };
  //========================================================================
  return {
    createBookingsLists,
    filterBookingsByType,
    filterBookingsByLocation,
    fetchBookingsByDates,
    fetchAndBuildList,
    fetchSettings,
    fetchStoreDetails,
    allowBookings,
  };
}
