import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import { TopCanadianCannabisBrands } from '../components/BlogPosts/TopCanadianCannabisBrands';
import { Link } from 'react-router-dom';
import { HowToStartACannabisBusinessInOntario } from '../components/BlogPosts/HowToStartACannabisBusinessInOntario';
import { DispensaryMarketingPlan } from '../components/BlogPosts/DispensaryMarketingPlan';
import { WeedDeliveryInSelectedCities } from '../components/BlogPosts/WeedDeliveryInSelectedCities';
import NewsletterSept2023 from '../components/BlogPosts/blogContent/NewsletterSept2023';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  backLink: { marginTop: theme.spacing(2), marginLeft: theme.spacing(2) },
  headerImage: {
    // position: "absolute",
    // top: 0,
    width: '150%',
    marginLeft: -250,
    height: 200,
    opacity: 0.8,
    objectFit: 'cover',
  },
  h1: {
    position: 'absolute',
    top: 0,
    fontSize: '350%',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: '250%',
    },
  },
}));

function IndividualBlogView({ id, locBlog }) {
  const classes = useStyles();

  let titleTag = '';
  let metaDesc = '';
  let content = <p></p>;

  switch (id) {
    case 'top-canadian-cannabis-brands':
      titleTag = 'Top 8 Canadian Cannabis Brands to Follow in 2020 | S7DAW';
      metaDesc =
        'Do you need cannabis for recreational or medical purposes? Are you thinking of investing in the cannabis industry? Discover the top 8 Canadian cannabis brands here!';
      content = TopCanadianCannabisBrands();
      break;

    case 'how-to-start-a-cannabis-business-in-ontario':
      titleTag = 'How to Start a Cannabis Dispensary in Ontario? | S7DAW';
      metaDesc =
        'Would you like to start and run a cannabis dispensary in Ontario? Discover a step-by-step process to legally start and operate a cannabis retail business.';
      content = HowToStartACannabisBusinessInOntario();
      break;

    case 'dispensary-marketing-plan':
      titleTag = 'Dispensary Marketing Plan: Use Media To Market | S7DAW';
      metaDesc =
        'Are you confused about how to make your own dispensary marketing plan? Read on to find out how to successfully market your dispensary all over social media.';
      content = DispensaryMarketingPlan();
      break;

    case 'location-blogs':
      titleTag = locBlog.meta.title;
      metaDesc = locBlog.meta.description;
      content = WeedDeliveryInSelectedCities(locBlog);
      break;

    case 'newsletter-september-2023':
      titleTag = 'S7DAW Newsletter - September 2023 | S7DAW';
      metaDesc = 'S7DAW Newsletter - September 2023';
      content = <NewsletterSept2023 />;
      break;

    default:
      break;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{titleTag}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className={classes.innerContainer}>
        <div style={{ position: 'relative' }}>
          <img className={classes.headerImage} alt="blog header" src={require(`../assets/banners/homeHeader.webp`)} />
          <h1 className={classes.h1}>{titleTag.replace(' | S7DAW', '')}</h1>
        </div>
        <div className={classes.backLink}>
          <Link to="/blog">Back to List View</Link>
        </div>
        {content}
      </div>
    </div>
  );
}

export default IndividualBlogView;
