import React, { createContext, useContext } from 'react';

import useAdminLogsData from '../hooks/useAdminLogsData';

export const AdminLogsContext = createContext();

export default function AdminLogsProvider(props) {
  const { children, mode } = props;

  const adminData = useAdminLogsData({ mode });
  return <AdminLogsContext.Provider value={{ ...adminData }}>{children}</AdminLogsContext.Provider>;
}

export function useAdminLogsContext() {
  const context = useContext(AdminLogsContext);
  if (!context) {
    throw new Error('useAdminLogsContext must be used within a AdminLogsProvider');
  }
  return context;
}
